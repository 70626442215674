import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, Button, DivBlock, LinkBlock } from "../modules/Basic";
import { CheckBoxOption, FormBlock, Label, TextInput } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Image } from "../modules/Media";
import { MenuButton, NavBar, NavMenu } from "../modules/Navigation";
import { Heading, Span, TextLink } from "../modules/Typography";
import ImageCrop from "../modules/ImageCrop";

class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      uploadImage: "#",
      setCropper: "",
      image: "",
      imageName: "",
      width: 0,
      imageCrop: 300,
      imageURl: "",
      errorMessage: "",
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    let files;
    if (e.target) {
      var img = document.createElement("img");
      var that = this;
      files = e.target.files;
      this.setState({ imageName: e.target.files?.[0]?.name });
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = (ended) => {
      img.src = ended.target.result;
      img.onload = function () {
        that.setState({ width: this.width });
        if (this.width >= 300) {
          if (this.width >= 600) {
            that.setState({ imageCrop: 600 });
          }
          that.setState({
            uploadImage: reader.result,
            modal: true,
            errorMessage: "",
          });
        } else {
          that.setState({
            errorMessage: "Image resolution should be more than 300px.",
          });
        }
      };
    };
  };
  render() {
    //let { row, index, errors } = this.props;
    let { DuplicateEmail, DuplicateSchool, FormSignupErrors } = this.props;

    const getCropData = () => {
      if (this.state.setCropper !== "undefined") {
        this.setState({
          uploadImage: this.state.setCropper
            .getCroppedCanvas({
              width: this.state.imageCrop,
              height: this.state.imageCrop,
            })
            .toDataURL(),
          modal: false,
        });
      }

      const imageData = dataURLtoFile(
        this.state.setCropper
          .getCroppedCanvas({
            width: this.state.imageCrop,
            height: this.state.imageCrop,
          })
          .toDataURL(),
        this.state.imageName
      );
      this.setState({
        image: imageData,
        imageURl: URL.createObjectURL(imageData),
      });
    };

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl?.split(","),
        mime = arr?.[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr?.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    return (
      <Fragment>
        <Body
          className={"body"}
          // actions={[
          //   {
          //     action: "Get Schools",
          //     trigger: "onload",
          //     timeout: "",
          //     pollingPeriod: "",
          //     inputVariables: {},
          //   },
          // ]}
        >
          <DivBlock>
            <NavBar
              breakPoint={"sm"}
              style={{ backgroundColor: "#000000", color: "#ffffff" }}
              type={"down"}
            >
              <LinkBlock
                href={"/"}
                style={{ float: "left" }}
                target={"_self"}
                type={"spa"}
              >
                <DivBlock
                  style={{
                    paddingBottom: "7.5px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "7.5px",
                  }}
                >
                  <Image
                    src={process.env.REACT_APP_LOGO}
                    style={{ height: "37.5px", maxWidth: "auto" }}
                  ></Image>
                </DivBlock>
              </LinkBlock>

              <MenuButton>
                <DivBlock
                  style={{
                    backgroundColor: "black",
                    border: "0px",
                    height: "3px",
                    margin: "4px 0",
                    minWidth: "30px",
                    width: "30px",
                  }}
                ></DivBlock>

                <DivBlock
                  style={{
                    backgroundColor: "black",
                    border: "0px",
                    height: "3px",
                    margin: "4px 0",
                    minWidth: "30px",
                    width: "30px",
                  }}
                ></DivBlock>

                <DivBlock
                  style={{
                    backgroundColor: "black",
                    border: "0px",
                    height: "3px",
                    margin: "4px 0",
                    minWidth: "30px",
                    width: "30px",
                  }}
                ></DivBlock>
              </MenuButton>

              <NavMenu>
                <TextLink
                  href={"http://coachtoolsllc.com/"}
                  style={{
                    color: "#ffffff",
                    padding: "20px",
                    textDecoration: "none",
                  }}
                  type={"external"}
                >
                  Home
                </TextLink>

                <TextLink
                  href={"https://coachtoolsllc.com/about"}
                  style={{
                    color: "#ffffff",
                    padding: "20px",
                    textDecoration: "none",
                  }}
                  target={"_self"}
                  type={"external"}
                >
                  About Us
                </TextLink>

                <TextLink
                  href={"https://coachtoolsllc.com/contact"}
                  style={{
                    color: "#ffffff",
                    padding: "20px",
                    textDecoration: "none",
                  }}
                  target={"_self"}
                  type={"external"}
                >
                  Contact Us
                </TextLink>

                <TextLink
                  href={"/login"}
                  style={{
                    color: "#ffffff",
                    padding: "20px",
                    textDecoration: "none",
                  }}
                  type={"spa"}
                >
                  Login
                </TextLink>
              </NavMenu>
            </NavBar>

            <DivBlock className={"mt-5"}>
              <DivBlock className={"mb-5"}>
                <Columns style={{ display: "flex", justifyContent: "center" }}>
                  <Column
                    lg={6}
                    md={6}
                    sm={10}
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    xs={11}
                  >
                    <DivBlock className={"card"} style={{}}>
                      <DivBlock className={"card-body"}>
                        <DivBlock className={"text-center"}>
                          <Heading className={"h1"} style={{}} type={"h1"}>
                            Coach Signup
                          </Heading>
                        </DivBlock>

                        <DivBlock className={"paragraph-div"} style={{}}>
                          <Span className={"Bold-Heading"} style={{}}>
                            Sign up today to start tracking and evaluating
                            player development within your football program.
                          </Span>
                        </DivBlock>

                        <DivBlock className={"paragraph-div"} style={{}}>
                          <Span className={"Heading-span"} style={{}}>
                            Head coach signup only. Once your account is setup,
                            you can build your team, inviting your players and
                            position coaches from your account.
                          </Span>

                          <DivBlock
                            className={"invalid-school-feedback"}
                            style={{}}
                          >
                            <If condition={DuplicateSchool ? true : false}>
                              <Then>
                                <DivBlock
                                  className={"invalid-school-feedback"}
                                  style={{}}
                                >
                                  <Span style={{}}>
                                    Sorry, but that school already exists. If
                                    you think there is an issue, please
                                  </Span>

                                  <TextLink
                                    className={"a text"}
                                    href={"http://coachtoolsllc.com/contact"}
                                    style={{}}
                                    target={"_blank"}
                                  >
                                    contact us
                                  </TextLink>
                                </DivBlock>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock
                            className={"invalid-school-feedback"}
                            style={{}}
                          >
                            <If condition={DuplicateEmail ? true : false}>
                              <Then>
                                <DivBlock
                                  className={"invalid-school-feedback"}
                                  style={{}}
                                >
                                  <Span>{DuplicateEmail}</Span>
                                </DivBlock>
                              </Then>
                            </If>
                          </DivBlock>
                        </DivBlock>

                        <FormBlock
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          actions={[
                            {
                              action: "Create User",
                              trigger: "onsubmit",
                              timeout: "",
                              pollingPeriod: "",
                              inputVariables: {},
                            },
                          ]}
                        >
                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Full Name</Label>

                            <TextInput
                              className={"form-control"}
                              name={"FormSignup.name"}
                              placeholder={""}
                              style={{}}
                            ></TextInput>

                            <If
                              condition={
                                FormSignupErrors && FormSignupErrors["name"]
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormSignupErrors
                                    ? FormSignupErrors["name"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Your School</Label>

                            <TextInput
                              className={"form-control"}
                              name={"FormSignup.school"}
                              placeholder={""}
                            ></TextInput>

                            <If
                              condition={
                                FormSignupErrors && FormSignupErrors["name"]
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"} style={{}}>
                                  {FormSignupErrors
                                    ? FormSignupErrors["school"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Email</Label>

                            <TextInput
                              className={"form-control"}
                              name={"FormSignup.email"}
                              placeholder={""}
                            ></TextInput>

                            <If
                              condition={
                                FormSignupErrors && FormSignupErrors["email"]
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormSignupErrors
                                    ? FormSignupErrors["email"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Password</Label>

                            <TextInput
                              className={"form-control"}
                              name={"FormSignup.password"}
                              placeholder={""}
                              type={"password"}
                            ></TextInput>

                            <If
                              condition={
                                FormSignupErrors && FormSignupErrors["password"]
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormSignupErrors
                                    ? FormSignupErrors["password"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock className={"form-group"}>
                            <Label className={"label"}>Confirm Password</Label>

                            <TextInput
                              className={"form-control"}
                              name={"FormSignup.confirm_password"}
                              placeholder={""}
                              type={"password"}
                            ></TextInput>

                            <If
                              condition={
                                FormSignupErrors &&
                                FormSignupErrors["confirm_password"]
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormSignupErrors
                                    ? FormSignupErrors["confirm_password"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <DivBlock className="form-group">
                            <Label className={"label"}>Profile Image</Label>
                            <input
                              type="file"
                              onChange={(e) => this.handleChange(e)}
                              key={this.state.uploadImage || ""}
                              accept="image/png, image/jpeg, image/jpg"
                            />
                          </DivBlock>
                          {}
                          <Span className={"invalid-feedback"}>
                            {this.state?.errorMessage}
                          </Span>

                          {this.state.imageURl ? (
                            <img
                              style={{
                                width: "150px",
                                height: "150px",
                                marginBottom: "20px",
                              }}
                              src={this.state.imageURl}
                              alt="cropped"
                            />
                          ) : null}

                          <DivBlock className={"form-group"}>
                            <DivBlock
                              className="privacy-policy-check"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <CheckBoxOption
                                initialValue={false}
                                name={"FormSignup.terms_and_conditions"}
                                value={false}
                              ></CheckBoxOption>

                              <DivBlock
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                <Span
                                  style={{
                                    fontFamily: "'Poppins', sans-serif",
                                    marginRight: "2px",
                                  }}
                                >
                                  {"I have read and agree to the "}
                                  <TextLink
                                    className={"a"}
                                    href={"https://coachtoolsllc.com/terms"}
                                    target={"_blank"}
                                  >
                                    Terms and Conditions
                                  </TextLink>

                                  {/* <Span
                                  style={{
                                    fontFamily:"'Poppins', sans-serif",
                                    marginLeft: "2px",
                                  }}
                                > */}
                                  {" and the "}
                                  {/* </Span> */}

                                  <TextLink
                                    className={"a"}
                                    href={"https://coachtoolsllc.com/cookies"}
                                    target={"_blank"}
                                  >
                                    Cookies Policy.
                                  </TextLink>
                                </Span>
                              </DivBlock>
                            </DivBlock>

                            <If
                              condition={
                                FormSignupErrors &&
                                FormSignupErrors["terms_and_conditions"]
                                  ? true
                                  : false
                              }
                              show={true}
                            >
                              <Then>
                                <Span className={"invalid-feedback"}>
                                  {FormSignupErrors
                                    ? FormSignupErrors["terms_and_conditions"]
                                    : ""}
                                </Span>
                              </Then>
                            </If>
                          </DivBlock>

                          <Button
                            className={"btn btn-primary"}
                            actions={[
                              {
                                action: "Create User",
                                trigger: "onclick",
                                timeout: "",
                                pollingPeriod: "",
                                inputVariables: {
                                  image: this.state.image,
                                  // this.state.uploadImage
                                },
                              },
                            ]}
                          >
                            Register
                          </Button>
                        </FormBlock>

                        <DivBlock className={"mt-4"}>
                          <DivBlock style={{ display: "flex" }}>
                            <Span
                              style={{
                                fontFamily: "'Poppins', sans-serif",
                                marginRight: "0.5rem",
                              }}
                            >
                              {"Have account? "}

                              <TextLink
                                className={"a"}
                                href={"/login"}
                                style={{}}
                                type={"spa"}
                              >
                                Login
                              </TextLink>
                            </Span>
                          </DivBlock>
                        </DivBlock>

                        <If
                          condition={this.state.modal ? true : false}
                          show={false}
                        >
                          <Then>
                            <DivBlock className={"modal"}>
                              <DivBlock className={"modal-dialog"}>
                                <DivBlock className={"modal-dialog"}>
                                  <DivBlock className={"modal-content"}>
                                    <DivBlock className={"modal-header"}>
                                      <Heading
                                        className="modal-heading"
                                        type={"h5"}
                                      >
                                        Crop Image Before Upload
                                      </Heading>

                                      <span
                                        className={"close"}
                                        onClick={() =>
                                          this.setState({
                                            modal: false,
                                            uploadImage: "",
                                          })
                                        }
                                      >
                                        x
                                      </span>
                                    </DivBlock>
                                    <DivBlock
                                      style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        overflow: "auto",
                                      }}
                                    >
                                      <DivBlock style={{ width: "70%" }}>
                                        <ImageCrop
                                          initialAspectRatio={1 / 1}
                                          // aspectRatio
                                          preview=".img-preview"
                                          guides={true}
                                          src={this.state.uploadImage}
                                          onInitialized={(instance) => {
                                            this.setState({
                                              setCropper: instance,
                                            });
                                          }}
                                          viewMode={1}
                                          dragMode="move"
                                          cropBoxMovable={false}
                                          checkOrientation={false}
                                          toggleDragModeOnDblclick={false}
                                          modal
                                          background={false}
                                          minCropBoxHeight={50}
                                          minCropBoxWidth={50}
                                        />
                                      </DivBlock>
                                      <DivBlock
                                        style={{
                                          width: "30%",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <DivBlock
                                          className="img-preview"
                                          style={{
                                            width: "100%",
                                            height: "300px",
                                          }}
                                        />
                                      </DivBlock>
                                    </DivBlock>
                                    <DivBlock style={{ marginLeft: "10px" }}>
                                      <Span className={"invalid-feedback"}>
                                        Note : Zoom in or zoom out for cropping
                                        the image.
                                      </Span>
                                    </DivBlock>
                                    <DivBlock
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginRight: "20px",
                                      }}
                                    >
                                      <button
                                        className={"btn btn-reset DeleteBtn"}
                                        onClick={() =>
                                          this.setState({
                                            modal: false,
                                            uploadImage: "",
                                          })
                                        }
                                      >
                                        Cancel
                                      </button>

                                      <button
                                        className={"btn btn-primary"}
                                        onClick={getCropData}
                                      >
                                        Crop
                                      </button>
                                    </DivBlock>
                                  </DivBlock>
                                </DivBlock>
                              </DivBlock>
                            </DivBlock>
                          </Then>
                        </If>
                      </DivBlock>
                    </DivBlock>
                  </Column>
                </Columns>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(SignUpPage);
