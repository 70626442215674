import { DivBlock } from "../../../Basic";
import Label from "../Label";
import { connect } from "react-redux";
import { Heading, Span } from "../../../Typography";
import { If, Then } from "../../../Logic";
import TextInput from "../TextInput";
import { useState } from "react";


const HudlVideoUpload = ({ ...props }) => {
  const [value, setValue] = useState("");
  const { state } = props;

  const handleChange = (e) => {
    const { value } = e.target;
    const trimmedValue = value.trim();
    const httpsUrl = trimmedValue.replace(/^http:/, "https:");
    setValue(httpsUrl);
  };

  const uploadHudlVideo = () => {
    const { dispatch } = props;
    dispatch({ type: "SET_UPLOAD_HUDL_VIDEO" });
    dispatch({ type: "REMOVE_UPLOAD_HUDL_VIDEO_ERROR" });
    setValue("");
  };

  const handleUploadHudlVideo = () => {
    const { dispatch } = props;
    // if (value.trim() !== "") {
    dispatch({
      type: "UPLOAD_HUDL_VIDEO",
      inputVariables: { link: value?.trim() },
    });
    // } else {
    //   setError("Please enter link");
    // }
  };

  return (
    <div>
      <button className={"btn btn-primary"} onClick={uploadHudlVideo}>
        Upload Hudl Video
      </button>
      <If condition={state?.ToggleHudlVideoUpload ? true : false} show={true}>
        <Then>
          <DivBlock className={"modal play-modal"}>
            <DivBlock className={"modal-dialog"}>
              <DivBlock className={"modal-dialog"}>
                <DivBlock className={"modal-content"}>
                  <DivBlock className={"modal-header"}>
                    <Heading className="modal-heading" type={"h5"}>
                      Upload Hudl Video
                    </Heading>

                    <Span
                      className={"close"}
                      actions={[
                        {
                          action: "SET_UPLOAD_HUDL_VIDEO",
                          trigger: "onclick",
                          timeout: "",
                          pollingPeriod: "",
                          inputVariables: {},
                        },
                      ]}
                    >
                      x
                    </Span>
                  </DivBlock>

                  <DivBlock className={"modal-body"}>
                    <DivBlock className={"form-group"}>
                      <Label className={"label"}>Link</Label>

                      <input
                        className="form-control"
                        onChange={handleChange}
                        value={value}
                      />
                      {/* <TextInput
                        className={"form-control"}
                        initialValue={""}
                        name={"FormHudlUploadVideo.uploadVideo"}
                        placeholder={""}
                      ></TextInput> */}
                      <If
                        condition={
                          state.FormUploadVideoErrors &&
                          state.FormUploadVideoErrors.name
                            ? true
                            : false
                        }
                      >
                        <Then>
                          <Span className={"invalid-feedback"}>
                            {state.FormUploadVideoErrors
                              ? state.FormUploadVideoErrors.name
                              : ""}
                          </Span>
                        </Then>
                      </If>
                    </DivBlock>

                    <DivBlock
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className={"btn btn-primary"}
                        onClick={handleUploadHudlVideo}
                      >
                        Submit
                      </button>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Then>
      </If>
      <If
        condition={state?.ToggleHudlVideoUploadMessage ? true : false}
        show={true}
      >
        <Then>
          <DivBlock className={"modal play-modal"}>
            <DivBlock className={"modal-dialog"}>
              <DivBlock className={"modal-dialog"}>
                <DivBlock className={"modal-content"}>
                  <DivBlock className={"modal-header"}>
                    <Heading className="modal-heading" type={"h5"}>
                      Upload Hudl Video
                    </Heading>

                    <Span
                      className={"close"}
                      actions={[
                        {
                          action: "REMOVE_UPLOAD_HUDL_VIDEO",
                          trigger: "onclick",
                          timeout: "",
                          pollingPeriod: "",
                          inputVariables: {},
                        },
                      ]}
                    >
                      x
                    </Span>
                  </DivBlock>

                  <DivBlock className={"modal-body"}>
                    <DivBlock className={"form-group"}>
                      <Label className={"label"}>
                        We're importing your videos. This may take some time.
                        You can close this box at any point. Your videos will
                        display once all videos have imported. Please check back
                        shortly.
                      </Label>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Then>
      </If>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    state: state.reducer,
  };
};
export default connect(mapStateToProps, null)(HudlVideoUpload);
