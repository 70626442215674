import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, Button, DivBlock } from "../modules/Basic";
import { FormBlock, Label, TextInput } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { Image } from "../modules/Media";
import ImageCrop from "../modules/ImageCrop";
import { startPageTimer, trackPageDuration } from "../utils/function";
import { CircularProgress } from "@material-ui/core";

// var check=true
class EditCoachPage extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.schoolLogoRef = React.createRef();
    this.state = {
      changeImage: null,
      schoolImageLogo: null,
      // uploadImage:'#',
      setCropper: "",
      setSchoolLogoCropper: "",
      image: "",
      schoolLogoImage: "",
      imageName: "",
      schoolLogoName: "",
      width: 0,
      schoolLogoWidth: 0,
      imageCrop: 300,
      schoolLogoCrop: 300,
      imageURl: "",
      errorMessage: "Click image above to edit.",
      schoolLogoErrorMessage: "",
      startTime: null,
      zoomLevel: 0.1, // Initial zoom level
    };
  }

  componentDidMount() {
    startPageTimer("Edit Profile Coach");
    this.setState({ startTime: Date.now() });
    if (this.props?.CurrentUser) {
      this.setState({
        changeImage:
          process.env.REACT_APP_PROFILE +
          this.props?.CurrentUser?.profile_image?.medium
            ? process.env.REACT_APP_PROFILE +
              this.props?.CurrentUser?.profile_image?.medium
            : null,
        schoolImageLogo: this.props?.CurrentUser?.school_logo?.medium
          ? process.env.REACT_APP_PROFILE +
            this.props?.CurrentUser?.school_logo?.medium
          : null,
      });
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.CurrentUser !== this.props.CurrentUser) {
      this.setState({
        changeImage:
          process.env.REACT_APP_PROFILE +
          this.props?.CurrentUser?.profile_image?.medium,
        schoolImageLogo: this.props?.CurrentUser?.school_logo?.medium
          ? process.env.REACT_APP_PROFILE +
            this.props?.CurrentUser?.school_logo?.medium
          : null,
      });
    }
  }

  componentWillUnmount() {
    trackPageDuration("Edit Profile Coach", this.state.startTime);
  }

  handleChange = (e) => {
    e.preventDefault();
    let files;
    if (e.target) {
      var img = document.createElement("img");
      var that = this;
      files = e.target.files;
      this.setState({ imageName: e.target.files?.[0]?.name });
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = (ended) => {
      img.src = ended.target.result;
      img.onload = function () {
        that.setState({ width: this.width });
        if (this.width >= 300) {
          if (this.width >= 600) {
            that.setState({ imageCrop: 600 });
          }
          that.setState({
            changeImage: reader.result,
            modal: true,
            errorMessage: "",
          });
        } else {
          that.setState({
            errorMessage: "Image resolution should be more than 300px.",
          });
        }
      };
    };
  };

  handleSchoolLogoChange = (e) => {
    e.preventDefault();
    let files;
    if (e.target) {
      var img = document.createElement("img");
      var that = this;
      files = e.target.files;
      this.setState({ schoolLogoName: e.target.files?.[0]?.name });
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = (ended) => {
      img.src = ended.target.result;
      img.onload = function () {
        that.setState({ schoolLogoWidth: this.width });
        if (this.width >= 300) {
          if (this.width >= 600) {
            that.setState({ schoolLogoCrop: 600 });
          }

          that.setState({
            schoolLogoImage: reader.result,
            schoolLogoModal: true,
            schoolLogoErrorMessage: "",
          });
        } else {
          that.setState({
            schoolLogoErrorMessage:
              "Image resolution should be more than 300px.",
          });
        }
      };
    };
  };

  setSchoolLogoCanvasAndCropBoxData = () => {
    const { setSchoolLogoCropper } = this.state;
    if (setSchoolLogoCropper) {
      const imageData = setSchoolLogoCropper.getImageData();
      const containerData = setSchoolLogoCropper.getContainerData();

      const cropBoxSize = 300; // Fixed crop box size (300x300)
      let canvasWidth, canvasHeight, canvasLeft, canvasTop;

      // Calculate the aspect ratio of the image
      const imageAspectRatio = imageData.width / imageData.height;

      // Fit the image to the cropping area (300x300) while maintaining aspect ratio
      if (imageAspectRatio > 1) {
        // Landscape image or very wide
        canvasHeight = cropBoxSize; // Set height to match the crop box height
        canvasWidth = canvasHeight * imageAspectRatio; // Calculate width based on aspect ratio

        // If the width exceeds the cropping area, scale down to fit
        if (canvasWidth > cropBoxSize) {
          canvasWidth = cropBoxSize;
          canvasHeight = canvasWidth / imageAspectRatio;
        }
      } else {
        // Portrait image or very tall
        canvasWidth = cropBoxSize; // Set width to match the crop box width
        canvasHeight = canvasWidth / imageAspectRatio; // Calculate height based on aspect ratio

        // If the height exceeds the cropping area, scale down to fit
        if (canvasHeight > cropBoxSize) {
          canvasHeight = cropBoxSize;
          canvasWidth = canvasHeight * imageAspectRatio;
        }
      }

      // Center the image within the cropping area
      canvasLeft = (containerData.width - canvasWidth) / 2;
      canvasTop = (containerData.height - canvasHeight) / 2;

      // Set the fixed crop box dimensions (300x300)
      setSchoolLogoCropper.setCropBoxData({
        left: (containerData.width - cropBoxSize) / 2,
        top: (containerData.height - cropBoxSize) / 2,
        width: cropBoxSize,
        height: cropBoxSize,
      });

      // Set the canvas data to center and fit the image within the cropping area
      setSchoolLogoCropper.setCanvasData({
        left: canvasLeft,
        top: canvasTop,
        width: canvasWidth,
        height: canvasHeight,
      });
    }
  };

  handleSchoolLogoCropperInitialized = (instance) => {
    this.setState({ setSchoolLogoCropper: instance }, () => {
      setTimeout(() => {
        if (this.state.setSchoolLogoCropper) {
          const imageData = this.state.setSchoolLogoCropper.getImageData();
          const cropBoxSize = 300; // Fixed crop box size (300x300)

          // Calculate minimum zoom based on the image's natural dimensions
          const minZoomWidth = cropBoxSize / imageData.naturalWidth;
          const minZoomHeight = cropBoxSize / imageData.naturalHeight;
          const minZoomLevel = Math.min(minZoomWidth, minZoomHeight);

          // Set the initial zoom level and min zoom level in state
          this.setState({ zoomLevel: minZoomLevel, minZoomLevel });

          // Apply the initial zoom to the cropper
          this.state.setSchoolLogoCropper.zoomTo(minZoomLevel);

          // Set the canvas and crop box data
          this.setSchoolLogoCanvasAndCropBoxData();
        }
      }, 100); // Adjust the delay as needed
    });
  };

  setCanvasAndCropBoxData = () => {
    const { setCropper } = this.state;
    if (setCropper) {
      const imageData = setCropper.getImageData();

      // Ensure the crop box height is 100% of the image height
      const cropBoxHeight = imageData.height;
      const cropBoxWidth = cropBoxHeight; // for 1:1 aspect ratio

      // Calculate the positions to center the crop box within the image
      const cropBoxLeft = (imageData.width - cropBoxWidth) / 2;
      const cropBoxTop = (imageData.height - cropBoxHeight) / 2;

      // Set the crop box data to be centered and maintain 1:1 aspect ratio
      setCropper.setCropBoxData({
        left: cropBoxLeft,
        top: cropBoxTop,
        width: cropBoxWidth,
        height: cropBoxHeight,
      });

      // Set the canvas data to center the image within the container
      setCropper.setCanvasData({
        left: (setCropper.getContainerData().width - imageData.width) / 2,
        top: (setCropper.getContainerData().height - imageData.height) / 2,
        width: imageData.width,
        height: imageData.height,
      });
    }
  };

  handleCropperInitialized = (instance) => {
    this.setState({ setCropper: instance }, () => {
      // Delay setting canvas and crop box data to ensure the cropper is fully initialized
      setTimeout(() => {
        this.setCanvasAndCropBoxData();
      }, 100); // Adjust the delay as needed
    });
  };

  handleProfileLogoCancel = () => {
    this.setState({
      modal: false,
      changeImage: this.props?.CurrentUser?.profile_image?.medium
        ? process.env.REACT_APP_PROFILE +
          this.props?.CurrentUser?.profile_image?.medium
        : null,
      image: "",
    });
    if (this.imageRef?.current) {
      this.imageRef.current.value = "";
    }
  };
  handleSchoolLogoCancel = () => {
    this.setState({
      schoolLogoModal: false,
      schoolImageLogo: this.props?.CurrentUser?.school_logo?.medium
        ? process.env.REACT_APP_PROFILE +
          this.props?.CurrentUser?.school_logo?.medium
        : null,
      schoolLogoImage: null,
    });
    if (this.schoolLogoRef?.current) {
      this.schoolLogoRef.current.value = "";
    }
  };

  handleUpdateCoach = () => {
    const { dispatch } = this.props;
    dispatch({
      type: "EDIT_COACH_USER",
      inputVariables: {
        image: this.state.image,
        schoolLogoImage: this.state.schoolLogoImage,
      },
    });
    this.setState({ image: "", schoolLogoImage: null, schoolImageLogo: null });
  };

  handleZoomChange = (event) => {
    let newZoomLevel = parseFloat(event.target.value);

    if (this.state.setSchoolLogoCropper) {
      // Clamp the zoom level to the calculated minimum zoom level
      newZoomLevel = Math.max(newZoomLevel, this.state.minZoomLevel || 0.1);

      // Zoom the image
      this.state.setSchoolLogoCropper.zoomTo(newZoomLevel);

      // Update the state with the new zoom level
      this.setState({ zoomLevel: newZoomLevel });

      // Center the crop box
      const cropBoxData = this.state.setSchoolLogoCropper.getCropBoxData();
      const cropBoxCenterX = cropBoxData.left + cropBoxData.width / 2;
      const cropBoxCenterY = cropBoxData.top + cropBoxData.height / 2;

      const newImageData = this.state.setSchoolLogoCropper.getImageData();
      const newCropBoxLeft = cropBoxCenterX - cropBoxData.width / 2;
      const newCropBoxTop = cropBoxCenterY - cropBoxData.height / 2;

      this.state.setSchoolLogoCropper.setCropBoxData({
        left: newCropBoxLeft,
        top: newCropBoxTop,
        width: cropBoxData.width,
        height: cropBoxData.height,
      });
    }
  };
  // handleZoomChange = (event) => {
  //   let newZoomLevel = parseFloat(event.target.value);

  //   if (this.state.setSchoolLogoCropper) {
  //     // Get the image and container data to calculate the minimum zoom level
  //     const imageData = this.state.setSchoolLogoCropper.getImageData();
  //     const containerData = this.state.setSchoolLogoCropper.getContainerData();
  //     const cropBoxData = this.state.setSchoolLogoCropper.getCropBoxData();

  //     // Calculate the minimum zoom level based on the image's aspect ratio and container size
  //     const cropBoxSize = 300; // Fixed crop box size (300x300)
  //     const minZoomWidth = cropBoxSize / imageData.naturalWidth;
  //     const minZoomHeight = cropBoxSize / imageData.naturalHeight;
  //     const minZoomLevel = Math.min(minZoomWidth, minZoomHeight);

  //     // Clamp the zoom level to ensure it doesn't go below the minimum
  //     newZoomLevel = Math.max(newZoomLevel, minZoomLevel);

  //     // Get the crop box center relative to the image before zooming
  //     const cropBoxCenterX = cropBoxData.left + cropBoxData.width / 2;
  //     const cropBoxCenterY = cropBoxData.top + cropBoxData.height / 2;

  //     // Zoom the image
  //     this.state.setSchoolLogoCropper.zoomTo(newZoomLevel);

  //     // Get the new image data after zooming
  //     const newImageData = this.state.setSchoolLogoCropper.getImageData();

  //     // Calculate the new crop box position to maintain the center
  //     const newCropBoxLeft = cropBoxCenterX - cropBoxData.width / 2;
  //     const newCropBoxTop = cropBoxCenterY - cropBoxData.height / 2;

  //     // Set the new crop box data to maintain the center position
  //     this.state.setSchoolLogoCropper.setCropBoxData({
  //       left: newCropBoxLeft,
  //       top: newCropBoxTop,
  //       width: cropBoxData.width,
  //       height: cropBoxData.height,
  //     });

  //     // Update the state with the new zoom level
  //     this.setState({ zoomLevel: newZoomLevel });
  //   }
  // };

  render() {
    let {
      CurrentUser,
      FormEditCoachErrors,
      FormLoginErrors,
      NavBarDropDownIndex,
      editCoachLoader,
    } = this.props;

    const getCropData = () => {
      if (this.state.setCropper !== "undefined") {
        this.setState({
          changeImage: this.state.setCropper
            .getCroppedCanvas({
              width: this.state.imageCrop,
              height: this.state.imageCrop,
            })
            .toDataURL(),
          modal: false,
        });
      }

      const imageData = dataURLtoFile(
        this.state.setCropper
          .getCroppedCanvas({
            width: this.state.imageCrop,
            height: this.state.imageCrop,
          })
          .toDataURL(),
        this.state.imageName
      );
      this.setState({
        image: imageData,
        changeImage: URL.createObjectURL(imageData),
      });
    };

    const getSchoolLogoCropData = () => {
      if (this.state.setSchoolLogoCropper !== "undefined") {
        const cropper = this.state.setSchoolLogoCropper;
        const croppedCanvas = cropper.getCroppedCanvas({
          width: this.state.schoolLogoCrop,
          height: this.state.schoolLogoCrop,
        });

        let dataURL;

        if (
          this.state.schoolLogoName.toLowerCase().endsWith(".jpeg") ||
          this.state.schoolLogoName.toLowerCase().endsWith(".jpg")
        ) {
          // Create a new canvas for JPEG images
          const newCanvas = document.createElement("canvas");
          newCanvas.width = croppedCanvas.width;
          newCanvas.height = croppedCanvas.height;

          // Get the context of the new canvas
          const ctx = newCanvas.getContext("2d");

          // Set the background color to white
          ctx.fillStyle = "#ffffff";
          ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);

          // Draw the cropped image onto the new canvas
          ctx.drawImage(croppedCanvas, 0, 0);

          // Get the data URL of the new canvas
          dataURL = newCanvas.toDataURL("image/jpeg");
        } else {
          // Get the data URL of the original cropped canvas for non-JPEG images
          dataURL = croppedCanvas.toDataURL();
        }

        // Set the state with the new image data
        this.setState({
          schoolLogoImage: dataURL,
          schoolLogoModal: false,
        });

        const imageData = dataURLtoFile(dataURL, this.state.schoolLogoName);
        this.setState({
          schoolLogoImage: imageData,
          schoolImageLogo: URL.createObjectURL(imageData),
        });
      }
    };

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl?.split(","),
        mime = arr?.[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr?.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    return (
      <Fragment>
        <Body
          className={"body"}
          actions={[
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 2 : 2,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>
            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>
              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <Heading
                        className={"h2"}
                        style={{ justifyContent: "center" }}
                        type={"h2"}
                      >
                        Edit Profile
                      </Heading>
                      <Columns
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Column
                          lg={8}
                          md={8}
                          sm={12}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          xs={12}
                        >
                          <DivBlock className={"card"} style={{}}>
                            <DivBlock className={"card-body"}>
                              <FormBlock
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Email</Label>
                                  <TextInput
                                    className={"form-control"}
                                    initialValue={
                                      CurrentUser ? CurrentUser["email"] : ""
                                    }
                                    name={"FormEditCoach.email"}
                                    placeholder={""}
                                    enableReinitialize={true}
                                  ></TextInput>
                                  <If
                                    condition={
                                      FormLoginErrors &&
                                      FormLoginErrors["email"]
                                        ? true
                                        : false
                                    }
                                    show={true}
                                  >
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {FormEditCoachErrors
                                          ? FormEditCoachErrors["email"]
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>
                                </DivBlock>
                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Name</Label>
                                  <TextInput
                                    className={"form-control"}
                                    initialValue={
                                      CurrentUser ? CurrentUser["name"] : ""
                                    }
                                    name={"FormEditCoach.name"}
                                    placeholder={""}
                                    enableReinitialize={true}
                                  ></TextInput>
                                  <If
                                    condition={
                                      FormLoginErrors &&
                                      FormLoginErrors["email"]
                                        ? true
                                        : false
                                    }
                                    show={true}
                                  >
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {FormEditCoachErrors
                                          ? FormEditCoachErrors["name"]
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>
                                </DivBlock>
                                <DivBlock
                                  className={"form-group"}
                                  onClick={() => this.imageRef.current.click()}
                                >
                                  <Label className={"label"}>
                                    Profile Image
                                  </Label>
                                  <Image
                                    src={this.state.changeImage}
                                    style={{
                                      height: "150px",
                                      width: "150px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </DivBlock>
                                <Span className={"invalid-feedback"}>
                                  {this.state.errorMessage}
                                </Span>
                                <DivBlock className="remove-input">
                                  <input
                                    type="file"
                                    ref={this.imageRef}
                                    key={this.state.changeImage || ""}
                                    onChange={(e) => this.handleChange(e)}
                                    accept=".jpg, .jpeg, .png"
                                  />
                                </DivBlock>

                                {/* Schol Logo Image */}
                                <If
                                  condition={
                                    CurrentUser["user_type"] === "Coach"
                                  }
                                >
                                  <Then>
                                    <DivBlock
                                      className={"form-group"}
                                      style={{ marginTop: "15px" }}
                                      onClick={() =>
                                        this.schoolLogoRef.current.click()
                                      }
                                    >
                                      <Label className={"label"}>
                                        School Logo
                                      </Label>
                                      {CurrentUser?.school_logo?.medium ? (
                                        <>
                                          <Image
                                            src={this.state.schoolImageLogo}
                                            style={{
                                              height: "150px",
                                              width: "150px",
                                              cursor: "pointer",
                                            }}
                                          />
                                          <Span className={"invalid-feedback"}>
                                            {this.state.schoolLogoErrorMessage
                                              ? this.state
                                                  .schoolLogoErrorMessage
                                              : "Click image above to edit."}
                                          </Span>
                                        </>
                                      ) : null}
                                    </DivBlock>
                                    {CurrentUser?.school_logo?.medium ? (
                                      <DivBlock className="remove-input">
                                        <input
                                          type="file"
                                          key={this.state.schoolImageLogo || ""}
                                          ref={this.schoolLogoRef}
                                          onChange={(e) =>
                                            this.handleSchoolLogoChange(e)
                                          }
                                          accept=".jpg, .jpeg, .png"
                                        />
                                      </DivBlock>
                                    ) : (
                                      <DivBlock
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <input
                                          type="file"
                                          ref={this.schoolLogoRef}
                                          onChange={(e) =>
                                            this.handleSchoolLogoChange(e)
                                          }
                                          accept=".jpg, .jpeg, .png"
                                        />
                                        <Span className={"invalid-feedback"}>
                                          {this.state.schoolLogoErrorMessage}
                                        </Span>

                                        {this.state.schoolImageLogo &&
                                        !CurrentUser?.school_logo?.medium ? (
                                          <>
                                            <div
                                              style={
                                                this.state.schoolImageLogo
                                                  ? {
                                                      display: "flex",
                                                      marginTop: "10px",
                                                    }
                                                  : { display: "none" }
                                              }
                                            >
                                              <img
                                                src={this.state.schoolImageLogo}
                                                style={{
                                                  height: "150px",
                                                  width: "150px",
                                                  cursor: "pointer",
                                                }}
                                                alt={"Logo"}
                                              />
                                            </div>
                                          </>
                                        ) : null}
                                      </DivBlock>
                                    )}
                                  </Then>
                                </If>
                                <button
                                  className={"btn btn-primary"}
                                  style={{
                                    ...(editCoachLoader
                                      ? {
                                          backgroundColor: "#a0d2a0",
                                          borderColor: "#a0d2a0",
                                        }
                                      : {
                                          backgroundColor: "#48c46e",
                                          borderColor: "#48c46e",
                                        }),
                                    alignSelf: "flex-start",
                                  }}
                                  onClick={this.handleUpdateCoach}
                                  disabled={editCoachLoader}
                                >
                                  {editCoachLoader ? (
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <CircularProgress
                                        size={25}
                                        thickness={5}
                                        style={{
                                          display: [
                                            "flex",
                                            "-webkit-inline-box",
                                          ],
                                          color: "white",
                                          margin: "0 5px",
                                        }}
                                      />
                                      Loading ...
                                    </div>
                                  ) : (
                                    <div>Update</div>
                                  )}
                                </button>
                              </FormBlock>
                            </DivBlock>
                          </DivBlock>
                        </Column>
                      </Columns>
                      <Columns
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Column
                          lg={8}
                          md={8}
                          sm={12}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          xs={12}
                        >
                          <DivBlock className={"card"} style={{}}>
                            <DivBlock className={"card-body"}>
                              <FormBlock
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                                actions={[
                                  {
                                    action: "Login",
                                    trigger: "onsubmit",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Password</Label>
                                  <TextInput
                                    className={"form-control"}
                                    name={"FormEditCoach.password"}
                                    placeholder={""}
                                    type={"password"}
                                  ></TextInput>
                                  <If
                                    condition={
                                      FormEditCoachErrors &&
                                      FormEditCoachErrors["password"]
                                        ? true
                                        : false
                                    }
                                    show={true}
                                  >
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {FormEditCoachErrors
                                          ? FormEditCoachErrors["password"]
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>
                                </DivBlock>
                                <Button
                                  className={"btn btn-primary"}
                                  style={{ alignSelf: "flex-start" }}
                                  actions={[
                                    {
                                      action: "Edit User Password",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {},
                                    },
                                  ]}
                                >
                                  Update
                                </Button>
                              </FormBlock>
                            </DivBlock>
                          </DivBlock>
                        </Column>
                      </Columns>
                      <If
                        condition={this.state.modal ? true : false}
                        show={false}
                      >
                        <Then>
                          <DivBlock className={"modal"}>
                            <DivBlock className={"modal-dialog"}>
                              <DivBlock className={"modal-dialog"}>
                                <DivBlock className={"modal-content"}>
                                  <DivBlock className={"modal-header"}>
                                    <Heading
                                      className="modal-heading"
                                      type={"h5"}
                                    >
                                      Crop Image Before Upload
                                    </Heading>

                                    <span
                                      className={"close"}
                                      onClick={this.handleProfileLogoCancel}
                                    >
                                      x
                                    </span>
                                  </DivBlock>
                                  <DivBlock
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      overflow: "auto",
                                    }}
                                  >
                                    <DivBlock style={{ width: "70%" }}>
                                      <ImageCrop
                                        initialAspectRatio={1 / 1}
                                        // aspectRatio={1}
                                        preview=".img-preview"
                                        guides={true}
                                        src={this.state.changeImage}
                                        onInitialized={
                                          this.handleCropperInitialized
                                        }
                                        viewMode={1}
                                        dragMode="move"
                                        cropBoxMovable={false}
                                        checkOrientation={false}
                                        toggleDragModeOnDblclick={false}
                                        modal
                                        background={false}
                                        minCropBoxHeight={50}
                                        minCropBoxWidth={50}
                                      />
                                    </DivBlock>
                                    <DivBlock
                                      style={{
                                        width: "30%",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <DivBlock
                                        className="img-preview"
                                        style={{
                                          width: "100%",
                                          height: "300px",
                                        }}
                                      />
                                    </DivBlock>
                                  </DivBlock>
                                  <DivBlock style={{ marginLeft: "10px" }}>
                                    <Span className={"invalid-feedback"}>
                                      Note : Zoom in or zoom out for cropping
                                      the image.
                                    </Span>
                                  </DivBlock>
                                  <DivBlock
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginRight: "20px",
                                    }}
                                  >
                                    <button
                                      className={"btn btn-reset DeleteBtn"}
                                      onClick={this.handleProfileLogoCancel}
                                    >
                                      Cancel
                                    </button>

                                    <button
                                      className={"btn btn-primary"}
                                      onClick={getCropData}
                                    >
                                      Crop
                                    </button>
                                  </DivBlock>
                                </DivBlock>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </Then>
                      </If>
                      <If
                        condition={this.state.schoolLogoModal ? true : false}
                        show={false}
                      >
                        <Then>
                          <DivBlock className={"modal"}>
                            <DivBlock className={"modal-dialog"}>
                              <DivBlock className={"modal-dialog"}>
                                <DivBlock className={"modal-content"}>
                                  <DivBlock className={"modal-header"}>
                                    <Heading
                                      className="modal-heading"
                                      type={"h5"}
                                    >
                                      Crop Image Before Upload
                                    </Heading>

                                    <span
                                      className={"close"}
                                      onClick={this.handleSchoolLogoCancel}
                                    >
                                      x
                                    </span>
                                  </DivBlock>
                                  <DivBlock
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                      overflow: "auto",
                                    }}
                                  >
                                    <DivBlock style={{ width: "70%" }}>
                                      <ImageCrop
                                        initialAspectRatio={1 / 1}
                                        // aspectRatio
                                        preview=".img-preview"
                                        guides={true}
                                        src={this.state.schoolLogoImage}
                                        onInitialized={
                                          this
                                            .handleSchoolLogoCropperInitialized
                                        }
                                        viewMode={0}
                                        dragMode="move"
                                        cropBoxMovable={false}
                                        checkOrientation={false}
                                        toggleDragModeOnDblclick={false}
                                        modal
                                        background={false}
                                        minCropBoxHeight={100}
                                        minCropBoxWidth={100}
                                        zoomOnWheel={false}
                                      />
                                    </DivBlock>
                                    <DivBlock
                                      style={{
                                        width: "30%",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <DivBlock
                                        className="img-preview"
                                        style={{
                                          width: "100%",
                                          height: "300px",
                                        }}
                                      />
                                    </DivBlock>
                                  </DivBlock>
                                  {/* <DivBlock style={{ marginLeft: "10px" }}>
                                    <Span className={"invalid-feedback"}>
                                      Note : Zoom in or zoom out for cropping
                                      the image.
                                    </Span>
                                  </DivBlock> */}
                                  <DivBlock
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <input
                                      type="range"
                                      value={this.state.zoomLevel}
                                      onChange={this.handleZoomChange}
                                      min={this.state.minZoomLevel || 0.1} // Set the minimum dynamically
                                      max="3"
                                      step="0.1"
                                    />
                                    <Span
                                      className={"invalid-feedback"}
                                      style={{ color: "black" }}
                                    >
                                      Adjust the zoom level using the slider.
                                    </Span>
                                  </DivBlock>
                                  <DivBlock
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginRight: "20px",
                                    }}
                                  >
                                    <button
                                      className={"btn btn-reset DeleteBtn"}
                                      onClick={this.handleSchoolLogoCancel}
                                    >
                                      Cancel
                                    </button>

                                    <button
                                      className={"btn btn-primary"}
                                      onClick={getSchoolLogoCropData}
                                    >
                                      Crop
                                    </button>
                                  </DivBlock>
                                </DivBlock>
                              </DivBlock>
                            </DivBlock>
                          </DivBlock>
                        </Then>
                      </If>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(EditCoachPage);
