import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isArray } from "lodash";
import { Body, Button, DivBlock } from "../modules/Basic";
import { SelectDropDown, FormBlock, TextInput } from "../modules/Forms";
import { Col, Column, Columns, Row } from "../modules/Layout";
import { Heading, Paragraph, Span } from "../modules/Typography";
import SideNavBarComponent from "../components/SideNavBarComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { If, Then } from "../modules/Logic";
import profileImage from "../assets/images/qb_medium.png";
import {
  handleScaleTypeValue,
  startPageTimer,
  trackPageDuration,
} from "../utils/function";
import BarChartLine from "../modules/charts/BarChartLine";

class PlayerReportsDataPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null,
      // message:'',
    };
  }
  componentDidMount() {
    startPageTimer("Player Reports");
    this.setState({ startTime: Date.now() });
    this.props?.getPlayerData();
    if (!this.props.Positions) {
      this.props.positionData();
    }
  }

  componentDidUpdate(previousProps, previousState) {
    const { dispatch } = this.props;
    if (previousProps.selectedSeasonYear !== this.props?.selectedSeasonYear) {
      dispatch({ type: "LOADING_EVENT", inputVariables: { loader: true } });
      // dispatch({ type: "CLEAR_PLAYER_DROPDOWN_VALUES"});
      this.props?.getPlayerData();
    }
  }

  componentWillUnmount() {
    trackPageDuration("Player Reports", this.state.startTime);
    this.props?.clearPlayerReportData();
  }

  handleAverages = (avgData) => {
    if (!avgData) return "None";
    if (avgData && isArray(avgData)) {
      return `${Number(
        avgData
          ?.map((value) => Number(value?.integer_value))
          ?.reduce((item, accumlator) => item + accumlator, 0) / avgData?.length
      ).toFixed(2)}%`;
    } else return "None";
  };

  render() {
    //let { row, index, errors } = this.props;
    let {
      AverageIntangible,
      CurrentGame,
      Intangibles,
      NavBarDropDownIndex,
      PlayerReportUsersCount,
      PlayerReportsData,
      GetScaleTypeData,
      TotalPlays,
      Users,
      PlayerSortingTable,
      PlayerReportDataUser,
      Games,
      Practices,
      SelectedPlayerGameAndPracticeId,
      CustomFieldTitles,
      NoteInfo,
      NoteInfoData,
      PlayerProductionPointData,
      isDefaultLoaded,
    } = this.props;

    let finalArray;
    if ((Games && isArray(Games)) || (Practices && isArray(Practices))) {
      if (Games && isArray(Games) && Practices && isArray(Practices)) {
        finalArray = Games?.concat(Practices);
      } else if (Games && isArray(Games) && !isArray(Practices)) {
        finalArray = Games;
      } else if (Practices && isArray(Practices) && !isArray(Games)) {
        finalArray = Practices;
      }
      finalArray = finalArray?.map(({ title: label, Id: value, ...rest }) => ({
        label,
        value,
        ...rest,
      }));
      let processFinalArr = [...finalArray];
      processFinalArr.unshift({ label: "All", value: -1 });
      finalArray = processFinalArr;
    }
    if (Users && isArray(Users)) {
      let processArr = [...Users];
      processArr.unshift({ name: "All", Id: -1 });
      Users = processArr;
    }

    let playerName =
      Users &&
      isArray(Users) &&
      Users.filter((item, index) => {
        return item.Id === this.props?.PlayerReportDataUser;
      });

    let storeSelectedGame;
    if (finalArray && isArray(finalArray)) {
      storeSelectedGame = finalArray.filter(
        (item) => item.value == SelectedPlayerGameAndPracticeId
      );
    }

    const overallAverage =
      AverageIntangible.length > 0 ? AverageIntangible[0].overall_average : 0;
    return (
      <Fragment>
        <Body
          actions={[
            {
              action: "Set Navbar Dropdown Index",
              trigger: "onload",
              timeout: "",
              pollingPeriod: "",
              inputVariables: {
                "input navbar dropdown index": NavBarDropDownIndex ? 1 : 1,
              },
            },
          ]}
        >
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              context={{
                elements: {
                  144: { type: "Body" },
                  145: { type: "DivBlock" },
                  146: { type: "DivBlock" },
                  151: { type: "DivBlock" },
                  152: { type: "DivBlock" },
                  153: { type: "List" },
                  154: { type: "ListItem" },
                  155: { type: "LinkBlock" },
                  156: { type: "DivBlock" },
                  157: { type: "Span" },
                  158: { type: "ListItem" },
                  159: { type: "LinkBlock" },
                  160: { type: "DivBlock" },
                  161: { type: "Span" },
                  162: { type: "ListItem" },
                  163: { type: "LinkBlock" },
                  164: { type: "DivBlock" },
                  165: { type: "Span" },
                  166: { type: "DivBlock" },
                  167: { type: "DivBlock" },
                  168: { type: "DivBlock" },
                  169: { type: "DivBlock" },
                  184: { type: "LinkBlock" },
                  185: { type: "DivBlock" },
                  186: { type: "Image" },
                  187: { type: "DivBlock" },
                  188: { type: "Button" },
                  189: { type: "If" },
                  190: { type: "Then" },
                  191: { type: "DivBlock" },
                  192: { type: "DivBlock" },
                  193: { type: "Span" },
                },
                getters: {},
                listeners: [
                  { name: "mouseOver.boundaries" },
                  { name: "mouseOut.boundaries" },
                  { name: "dragEnter" },
                  { name: "dragLeave" },
                  { name: "dragStart" },
                  { name: "dragEnd" },
                  { name: "mouseDown.mousemenu" },
                  { name: "showSettingsBox.desk" },
                  { name: "textChanged.desk" },
                  { name: "editTextMode.desk" },
                  { name: "componentClick.desk" },
                  { name: "pathnameChanged.desk" },
                  { name: "cut.desk" },
                  { name: "copy.desk" },
                  { name: "editSnippet.desk" },
                  { name: "clone.desk" },
                  { name: "delete.desk" },
                  { name: "before.desk" },
                  { name: "after.desk" },
                  { name: "first.desk" },
                  { name: "last.desk" },
                  { name: "replace.desk" },
                  { name: "select.desk" },
                  { name: "highlight.desk" },
                  { name: "dropZoneHover" },
                  { name: "onDragEnd" },
                  { name: "copyStyleProps" },
                  { name: "pasteStyleProps" },
                  { name: "updateSelectedProps" },
                ],
                triggerCache: {
                  componentClick: [{ name: "componentClick.desk" }],
                  editSnippet: [{ name: "editSnippet.desk" }],
                  editTextMode: [{ name: "editTextMode.desk" }],
                  mouseDown: [{ name: "mouseDown.mousemenu" }],
                  mouseOut: [{ name: "mouseOut.boundaries" }],
                  mouseOver: [{ name: "mouseOver.boundaries" }],
                  pathnameChanged: [{ name: "pathnameChanged.desk" }],
                  textChanged: [{ name: "textChanged.desk" }],
                },
              }}
              data-snippet-id={297}
              df-snippet-instance-id={297}
              draggable={true}
              eleKey={"146"}
              inline={{ "df-snippet-instance-id": 297, draggable: true }}
              isEdit={true}
              root-snippet={true}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>

            <DivBlock className={"body-container"} style={{}}>
              <SideNavBarComponent
                data-snippet-id={298}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></SideNavBarComponent>

              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                  paddingTop: "0px",
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"} style={{}}>
                      <DivBlock style={{}}>
                        <DivBlock
                          className="game_title"
                          style={{
                            backgroundColor: "#f8f8fb",
                            color: "#ffffff",
                            display: "flex",
                            marginBottom: "0.5em",
                            paddingBottom: "0px",
                          }}
                        >
                          <Heading className={"h2"} style={{}} type={"h2"}>
                            Player Reports
                          </Heading>

                          <Span className={"h2 h2-span"} style={{}}>
                            Reports /
                          </Span>

                          <Span className={"h2 h2-inner-span"} style={{}}>
                            Player Reports
                          </Span>
                        </DivBlock>
                      </DivBlock>

                      {isDefaultLoaded ? null : (
                        <>
                          <DivBlock
                            className="player_filter div-para"
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              marginBottom: "1em",
                            }}
                          >
                            {/* <Heading className={"h4"} style={{ marginRight: '1rem' }} type={"h4"}>
                          Filter by :
                        </Heading> */}
                            <DivBlock
                              className={"dropdown-option"}
                              style={{ marginRight: "1rem" }}
                            >
                              <Heading
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  marginBottom: 0,
                                  marginLeft: 0,
                                  marginRight: 0,
                                  marginTop: 0,
                                }}
                                type={"h5"}
                              >
                                Choose Player
                              </Heading>

                              <SelectDropDown
                                dataSet={Users}
                                dataSetLabel={"name"}
                                dataSetValue={"Id"}
                                className={"form-control"}
                                initialValue={
                                  PlayerReportDataUser === undefined ||
                                  PlayerReportDataUser === -1
                                    ? Users?.[0]?.Id
                                    : PlayerReportDataUser
                                }
                                name={"PlayerReportDataUser"}
                                options={[]}
                                enableReinitialize={true}
                                type={"number"}
                                actions={[
                                  {
                                    action: "On Player Position Filter Data",
                                    trigger: "onchange",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      "game iddd": CurrentGame
                                        ? CurrentGame["Id"]
                                        : " 0",
                                      "user idd": "",
                                      gameAndPracticeId:
                                        finalArray?.length > 0 &&
                                        finalArray[0]?.value,
                                    },
                                  },
                                ]}
                              ></SelectDropDown>
                            </DivBlock>

                            <DivBlock className={"dropdown-option"}>
                              <Heading
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  marginBottom: 0,
                                  marginLeft: 0,
                                  marginRight: 0,
                                  marginTop: 0,
                                }}
                                type={"h5"}
                              >
                                Game / Practice
                              </Heading>
                              <SelectDropDown
                                className={"form-control"}
                                enableReinitialize={true}
                                name={"SelectedPlayerGameAndPracticeId"}
                                options={finalArray?.length > 0 && finalArray}
                                initialValue={
                                  SelectedPlayerGameAndPracticeId ===
                                    undefined ||
                                  SelectedPlayerGameAndPracticeId === -1
                                    ? finalArray?.[0]?.value
                                    : SelectedPlayerGameAndPracticeId
                                }
                                actions={[
                                  {
                                    action: "On Player Position Filter Data",
                                    trigger: "onchange",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {
                                      gameAndPracticeId:
                                        finalArray?.length > 0 &&
                                        finalArray[0]?.value,
                                    },
                                  },
                                ]}
                              ></SelectDropDown>
                            </DivBlock>
                            <DivBlock
                              className={"div-print player-report-print-btn"}
                              style={{}}
                            >
                              <Button
                                className={"btn btn-primary"}
                                actions={[
                                  {
                                    action: "Print Reports",
                                    trigger: "onclick",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                Print
                              </Button>
                            </DivBlock>
                          </DivBlock>
                          <Paragraph
                            style={{
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: "14px",
                            }}
                          >
                            {(PlayerReportDataUser === 0 ||
                              PlayerReportDataUser === -1) &&
                            (SelectedPlayerGameAndPracticeId === "" ||
                              SelectedPlayerGameAndPracticeId === "-1")
                              ? "Choose a game / practice and player above to see play breakdown."
                              : (SelectedPlayerGameAndPracticeId === "-1" ||
                                  SelectedPlayerGameAndPracticeId === "") &&
                                (PlayerReportDataUser !== -1 ||
                                  PlayerReportDataUser !== 0)
                              ? "Choose a game or practice above to see play breakdown."
                              : PlayerReportDataUser === -1
                              ? "Choose a player above to see play breakdown."
                              : null}
                          </Paragraph>
                          <Columns
                            selectedLayout={"9/3"}
                            style={{
                              backgroundColor: "#ffffff",
                              fontSize: "0px",
                              marginBottom: "10px",
                              marginTop: "15px",
                            }}
                          >
                            <Column
                              lg={9}
                              md={9}
                              sm={9}
                              style={{ paddingLeft: "10px" }}
                              xs={9}
                            >
                              <DivBlock style={{ display: "flex" }}>
                                {playerName ? (
                                  <DivBlock className="player-report-img-container">
                                    <img
                                      className="player-report-img"
                                      src={
                                        playerName?.[0]?.profile_image
                                          ? process.env.REACT_APP_PROFILE +
                                            playerName?.[0]?.profile_image
                                              ?.medium
                                          : profileImage
                                      }
                                      alt="logo"
                                    />
                                  </DivBlock>
                                ) : null}
                                <DivBlock
                                  style={{
                                    alignItems: "center",
                                    display: "block",
                                    fontSize: "13px",
                                    justifyContent: "flex-start",
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Heading
                                    style={{
                                      alignItems: "flex-start",
                                      display: "block",
                                      fontFamily: "'Poppins', sans-serif",
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      justifyContent: "flex-start",
                                      marginBottom: 5,
                                      marginLeft: 0,
                                      marginRight: 0,
                                      marginTop: 0,
                                    }}
                                    type={"h1"}
                                  >
                                    {this.props?.PlayerReportDataUser > 0
                                      ? playerName && playerName[0]?.["name"]
                                      : "All Players"}
                                  </Heading>
                                  <DivBlock
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Span
                                      style={{
                                        fontFamily: "'Poppins', sans-serif",
                                      }}
                                    >
                                      {SelectedPlayerGameAndPracticeId ===
                                        undefined ||
                                      Number(
                                        SelectedPlayerGameAndPracticeId
                                      ) === -1 ||
                                      SelectedPlayerGameAndPracticeId === ""
                                        ? "All Games & Practices"
                                        : storeSelectedGame?.[0]?.label}
                                    </Span>
                                    <Span
                                      style={{
                                        fontFamily: "'Poppins', sans-serif",
                                      }}
                                    >
                                      {(PlayerReportDataUser === -1 ||
                                        PlayerReportDataUser === undefined) &&
                                      (SelectedPlayerGameAndPracticeId ===
                                        undefined ||
                                        Number(
                                          SelectedPlayerGameAndPracticeId
                                        ) === -1 ||
                                        SelectedPlayerGameAndPracticeId === "")
                                        ? TotalPlays
                                          ? TotalPlays +
                                            " " +
                                            "/" +
                                            " " +
                                            (TotalPlays
                                              ? TotalPlays + " " + "Plays"
                                              : "0")
                                          : " "
                                        : PlayerReportUsersCount
                                        ? PlayerReportUsersCount +
                                          " " +
                                          "/" +
                                          " " +
                                          (TotalPlays
                                            ? TotalPlays + " " + "Plays"
                                            : "0")
                                        : " "}
                                    </Span>
                                  </DivBlock>
                                </DivBlock>
                              </DivBlock>
                            </Column>
                          </Columns>
                          <Row
                            className={
                              AverageIntangible?.length > 9
                                ? "player-reports-fullprint"
                                : "player-reports-print"
                            }
                            style={{
                              marginTop: "1.25rem",
                              marginBottom: "1.25rem",
                            }}
                          >
                            <Col sm={12} md={12} lg={6} xl={6}>
                              <Heading className="h3" type="h3">
                                Averages
                              </Heading>
                              <DivBlock
                                id="player_report_average"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "auto",
                                  flexWrap: "wrap",
                                }}
                              >
                                {AverageIntangible &&
                                isArray(AverageIntangible) &&
                                AverageIntangible?.length > 0 ? (
                                  <Fragment>
                                    <DivBlock
                                      style={{
                                        alignItems: "center",
                                        display: "block",
                                        fontSize: "13px",
                                        justifyContent: "flex-start",
                                        marginBottom: "10px",
                                        marginLeft: "0px",
                                        marginRight: "20px",
                                        marginTop: "10px",
                                        paddingTop: "0px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Heading
                                        type={"h1"}
                                        style={{
                                          backgroundColor: "#677bac",
                                          fontFamily: "'Poppins', sans-serif",
                                          fontSize: "50px",
                                          fontWeight: "500",
                                          padding: "8px 8px",
                                          margin: 0,
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        {Math.round(overallAverage)}
                                        <span
                                          style={{
                                            verticalAlign: "super",
                                            fontSize: "50%",
                                          }}
                                        >
                                          %
                                        </span>
                                      </Heading>
                                      <DivBlock
                                        style={{
                                          display: "inline-block",
                                          marginRight: "40px",
                                          marginTop: "5px",
                                          paddingLeft: "10px",
                                          width: "100%",
                                        }}
                                      >
                                        <Span
                                          className={"table-span"}
                                          style={{}}
                                        >
                                          Overall
                                        </Span>
                                      </DivBlock>
                                    </DivBlock>
                                    {AverageIntangible.map((row, index) => (
                                      <DivBlock
                                        key={index}
                                        style={{
                                          alignItems: "center",
                                          display: "block",
                                          fontSize: "13px",
                                          justifyContent: "flex-start",
                                          marginBottom: "10px",
                                          marginLeft: "0px",
                                          marginRight: "20px",
                                          marginTop: "10px",
                                          paddingTop: "0px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Heading
                                          style={{
                                            backgroundColor: "#ffffff",
                                            fontFamily: "'Poppins', sans-serif",
                                            fontSize: "50px",
                                            fontWeight: "500",
                                            padding: "8px 8px",
                                            margin: 0,
                                            textAlign: "center",
                                          }}
                                          type={"h1"}
                                        >
                                          {Math.round(row["avg"])}
                                          <span
                                            style={{
                                              verticalAlign: "super",
                                              fontSize: "50%",
                                            }}
                                          >
                                            %
                                          </span>
                                        </Heading>

                                        <DivBlock
                                          style={{
                                            display: "inline-block",
                                            marginRight: "40px",
                                            marginTop: "5px",
                                            paddingLeft: "10px",
                                            width: "100%",
                                          }}
                                        >
                                          <Span
                                            className={"table-span"}
                                            style={{}}
                                          >
                                            {row["name"]}
                                          </Span>
                                        </DivBlock>
                                      </DivBlock>
                                    ))}
                                  </Fragment>
                                ) : (
                                  <Paragraph
                                    style={{
                                      fontFamily: "'Poppins', sans-serif",
                                      fontSize: "14px",
                                    }}
                                  >
                                    No grades have been entered for this player.
                                  </Paragraph>
                                )}
                              </DivBlock>
                            </Col>
                            <Col sm={12} md={12} lg={6} xl={6}>
                              <div>
                                {AverageIntangible &&
                                isArray(AverageIntangible) ? (
                                  <Fragment>
                                    <Heading className="h3" type="h3">
                                      Averages Chart
                                    </Heading>
                                    <DivBlock style={{ width: "auto" }}>
                                      {AverageIntangible &&
                                        Array.isArray(AverageIntangible) &&
                                        AverageIntangible.length > 0 && (
                                          <BarChartLine
                                            isSkipYaxis={false}
                                            barBorderRadius={10}
                                            maintainAspectRatio={false}
                                            lengthData={
                                              AverageIntangible.length + 1
                                            }
                                            chartData={[
                                              Math.round(overallAverage),
                                              ...AverageIntangible.map((val) =>
                                                Math.round(val.avg)
                                              ),
                                            ]}
                                            chartLabels={[
                                              "Overall",
                                              ...AverageIntangible.map(
                                                (val) => val.name
                                              ),
                                            ]}
                                          />
                                        )}
                                    </DivBlock>
                                  </Fragment>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <DivBlock className="player-production-point">
                            <Heading className="h3" type="h3">
                              Production
                            </Heading>
                            {Object.entries(PlayerProductionPointData).length >
                            0 ? (
                              Object.entries(PlayerProductionPointData)?.map(
                                (item) => (
                                  <DivBlock className="player-production-point-box">
                                    <Paragraph
                                      style={{
                                        fontFamily: "'Poppins', sans-serif",
                                        fontSize: "14px",
                                        marginBottom: 0,
                                        marginLeft: 0,
                                        marginRight: 0,
                                        marginTop: 0,
                                      }}
                                    >
                                      {item[0]} : {item[1]}
                                    </Paragraph>
                                  </DivBlock>
                                )
                              )
                            ) : (
                              <Paragraph
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                  fontSize: "14px",
                                }}
                              >
                                No production points tracked.
                              </Paragraph>
                            )}
                          </DivBlock>

                          <DivBlock
                            className={"hide-chart"}
                            style={{ overflowX: "auto", marginBottom: "50px" }}
                          >
                            <DivBlock className="table-responsive-sm">
                              {(PlayerReportsData &&
                                PlayerReportsData.data === null) ||
                              PlayerReportsData === undefined ? (
                                <Paragraph
                                  style={{
                                    fontFamily: "'Poppins', sans-serif",
                                    fontSize: "14px",
                                  }}
                                >
                                  {(PlayerReportDataUser === 0 ||
                                    PlayerReportDataUser === -1) &&
                                  (SelectedPlayerGameAndPracticeId === "" ||
                                    SelectedPlayerGameAndPracticeId === "-1")
                                    ? null
                                    : (SelectedPlayerGameAndPracticeId ===
                                        "-1" ||
                                        SelectedPlayerGameAndPracticeId ===
                                          "") &&
                                      (PlayerReportDataUser !== -1 ||
                                        PlayerReportDataUser !== 0)
                                    ? null
                                    : PlayerReportDataUser === -1
                                    ? null
                                    : null}
                                </Paragraph>
                              ) : (
                                <table className={"table sticky-col"}>
                                  <thead>
                                    <tr className={"thead-bg"}>
                                      <th className={"th-border"}>
                                        <Span
                                          className={"Sorting-span th-span"}
                                          actions={[
                                            {
                                              action: "TOGGLESORTINGPLAYER",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                inputTogglePlayerName: {
                                                  TogglePlayerSorting:
                                                    PlayerSortingTable !== true,
                                                  sortingPlayerField: "index",
                                                },
                                              },
                                            },
                                          ]}
                                        >
                                          #
                                        </Span>
                                      </th>
                                      <th className={"th-border"}>
                                        <Span
                                          className={"Sorting-span th-span"}
                                          actions={[
                                            {
                                              action: "TOGGLESORTINGPLAYER",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                inputTogglePlayerName: {
                                                  TogglePlayerSorting:
                                                    PlayerSortingTable !== true,
                                                  sortingPlayerField: "index",
                                                },
                                              },
                                            },
                                          ]}
                                        >
                                          Pos
                                        </Span>
                                      </th>
                                      <th className={"th-border"}>
                                        <Span
                                          className={"Sorting-span th-span"}
                                          actions={[
                                            {
                                              action: "TOGGLESORTINGPLAYER",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                inputTogglePlayerName: {
                                                  TogglePlayerSorting:
                                                    PlayerSortingTable !== true,
                                                  sortingPlayerField: "name",
                                                },
                                              },
                                            },
                                          ]}
                                        >
                                          Name
                                        </Span>
                                      </th>
                                      <th className={"th-border"}>
                                        <Span
                                          className={"Sorting-span th-span"}
                                          actions={[
                                            {
                                              action: "TOGGLESORTINGPLAYER",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                inputTogglePlayerName: {
                                                  TogglePlayerSorting:
                                                    PlayerSortingTable !== true,
                                                  sortingPlayerField: "type",
                                                },
                                              },
                                            },
                                          ]}
                                        >
                                          Type
                                        </Span>
                                      </th>
                                      <th className={"th-border"}>
                                        <Span
                                          className={"Sorting-span th-span"}
                                          actions={[
                                            {
                                              action: "TOGGLESORTINGPLAYER",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                inputTogglePlayerName: {
                                                  TogglePlayerSorting:
                                                    PlayerSortingTable !== true,
                                                  sortingPlayerField: "qtr",
                                                },
                                              },
                                            },
                                          ]}
                                        >
                                          Qtr
                                        </Span>
                                      </th>
                                      <th className={"th-border"}>
                                        <Span
                                          className={"Sorting-span th-span"}
                                          actions={[
                                            {
                                              action: "TOGGLESORTINGPLAYER",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                inputTogglePlayerName: {
                                                  TogglePlayerSorting:
                                                    PlayerSortingTable !== true,
                                                  sortingPlayerField: "series",
                                                },
                                              },
                                            },
                                          ]}
                                        >
                                          Series
                                        </Span>
                                      </th>
                                      <th className={"th-border"}>
                                        <Span
                                          className={"Sorting-span th-span"}
                                          actions={[
                                            {
                                              action: "TOGGLESORTINGPLAYER",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                inputTogglePlayerName: {
                                                  TogglePlayerSorting:
                                                    PlayerSortingTable !== true,
                                                  sortingPlayerField: "down",
                                                },
                                              },
                                            },
                                          ]}
                                        >
                                          Down
                                        </Span>
                                      </th>
                                      <th
                                        className={"th-border"}
                                        style={{
                                          width: "auto",
                                          minWidth: "120px",
                                        }}
                                      >
                                        <Span
                                          className={"Sorting-span th-span"}
                                          actions={[
                                            {
                                              action: "TOGGLESORTINGPLAYER",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                inputTogglePlayerName: {
                                                  TogglePlayerSorting:
                                                    PlayerSortingTable !== true,
                                                  sortingPlayerField: "title",
                                                },
                                              },
                                            },
                                          ]}
                                        >
                                          Package
                                        </Span>
                                      </th>
                                      <th
                                        className={"th-border"}
                                        style={{
                                          width: "auto",
                                          minWidth: "120px",
                                        }}
                                      >
                                        <Span
                                          className={"Sorting-span th-span"}
                                          actions={[
                                            {
                                              action: "TOGGLESORTINGPLAYER",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                inputTogglePlayerName: {
                                                  TogglePlayerSorting:
                                                    PlayerSortingTable !== true,
                                                  sortingPlayerField:
                                                    "production_point",
                                                },
                                              },
                                            },
                                          ]}
                                        >
                                          PP
                                        </Span>
                                      </th>
                                      <th
                                        className={"th-border"}
                                        style={{
                                          width: "auto",
                                          minWidth: "120px",
                                        }}
                                      >
                                        <Span className={"th-span"}>Notes</Span>
                                      </th>
                                      {CustomFieldTitles &&
                                        isArray(CustomFieldTitles) &&
                                        CustomFieldTitles.map((val, index) => {
                                          return (
                                            <th
                                              className={"th-border"}
                                              style={{
                                                width: "auto",
                                                minWidth: "120px",
                                              }}
                                              key={index}
                                            >
                                              <Span
                                                className={
                                                  "Sorting-span th-span"
                                                }
                                                actions={[
                                                  {
                                                    action:
                                                      "TOGGLE_SORTING_CUSTOM_FIELD_PLAYER",
                                                    trigger: "onclick",
                                                    timeout: "",
                                                    pollingPeriod: "",
                                                    inputVariables: {
                                                      inputTogglePlayerName: {
                                                        TogglePlayerSorting:
                                                          PlayerSortingTable !==
                                                          true,
                                                        sortingPlayerField:
                                                          val?.title,
                                                      },
                                                    },
                                                  },
                                                ]}
                                              >
                                                {val.title}
                                              </Span>
                                            </th>
                                          );
                                        })}
                                      {Intangibles &&
                                        isArray(Intangibles) &&
                                        Intangibles.map((row, index) => {
                                          return (
                                            <Fragment key={index}>
                                              <th className={"th-border"}>
                                                <Span
                                                  className={
                                                    "Sorting-span th-span"
                                                  }
                                                  actions={[
                                                    {
                                                      action:
                                                        "TOGGLE_SORTING_PLAYER_INTANGIBLES",
                                                      trigger: "onclick",
                                                      timeout: "",
                                                      pollingPeriod: "",
                                                      inputVariables: {
                                                        inputTogglePlayerName: {
                                                          TogglePlayerSorting:
                                                            PlayerSortingTable !==
                                                            true,
                                                          sortingPlayerField:
                                                            row?.["name"],
                                                        },
                                                      },
                                                    },
                                                  ]}
                                                >
                                                  {`${row["name"]}`}
                                                </Span>
                                              </th>
                                            </Fragment>
                                          );
                                        })}
                                      <th className={"th-border"}>
                                        <Span
                                          className={"Sorting-span th-span"}
                                          actions={[
                                            {
                                              action:
                                                "TOGGLE_SORTING_PLAYER_AVG",
                                              trigger: "onclick",
                                              timeout: "",
                                              pollingPeriod: "",
                                              inputVariables: {
                                                inputTogglePlayerName: {
                                                  TogglePlayerSorting:
                                                    PlayerSortingTable !== true,
                                                  sortingPlayerField: "avg",
                                                },
                                              },
                                            },
                                          ]}
                                        >
                                          AVG
                                        </Span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {PlayerReportsData &&
                                      isArray(PlayerReportsData) &&
                                      PlayerReportsData.map((row, index) => {
                                        return (
                                          <Fragment key={row?.id}>
                                            <tr>
                                              <td
                                                style={{
                                                  borderBottomColor: "#000000",
                                                  borderBottomStyle: "none",
                                                  borderBottomWidth: "",
                                                  borderLeftColor: "#000000",
                                                  borderLeftStyle: "none",
                                                  borderLeftWidth: "",
                                                  borderRightColor: "#000000",
                                                  borderRightStyle: "none",
                                                  borderRightWidth: "",
                                                  borderStyle: "none",
                                                  borderTopColor: "#000000",
                                                  borderTopStyle: "none",
                                                  borderTopWidth: "",
                                                  paddingBottom: "10px",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                <Span className={"table-span"}>
                                                  {row["index"]}
                                                </Span>
                                              </td>
                                              <td
                                                style={{
                                                  borderBottomColor: "#000000",
                                                  borderBottomStyle: "none",
                                                  borderBottomWidth: "",
                                                  borderLeftColor: "#000000",
                                                  borderLeftStyle: "none",
                                                  borderLeftWidth: "",
                                                  borderRightColor: "#000000",
                                                  borderRightStyle: "none",
                                                  borderRightWidth: "",
                                                  borderStyle: "none",
                                                  borderTopColor: "#000000",
                                                  borderTopStyle: "none",
                                                  borderTopWidth: "",
                                                  paddingBottom: "10px",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                <Span className={"table-span"}>
                                                  {row["position_name"]
                                                    ? row["position_name"] +
                                                      (row["alias_name"] ===
                                                      null
                                                        ? ""
                                                        : `(${row["alias_name"]})`)
                                                    : null}
                                                </Span>
                                              </td>
                                              <td
                                                style={{
                                                  borderBottomColor: "#000000",
                                                  borderBottomStyle: "none",
                                                  borderBottomWidth: "",
                                                  borderLeftColor: "#000000",
                                                  borderLeftStyle: "none",
                                                  borderLeftWidth: "",
                                                  borderRightColor: "#000000",
                                                  borderRightStyle: "none",
                                                  borderRightWidth: "",
                                                  borderStyle: "none",
                                                  borderTopColor: "#000000",
                                                  borderTopStyle: "none",
                                                  borderTopWidth: "",
                                                  paddingBottom: "10px",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                <Span className={"table-span"}>
                                                  {row["name"]}
                                                </Span>
                                              </td>
                                              <td
                                                style={{
                                                  borderBottomColor: "#000000",
                                                  borderBottomStyle: "none",
                                                  borderBottomWidth: "",
                                                  borderLeftColor: "#000000",
                                                  borderLeftStyle: "none",
                                                  borderLeftWidth: "",
                                                  borderRightColor: "#000000",
                                                  borderRightStyle: "none",
                                                  borderRightWidth: "",
                                                  borderStyle: "none",
                                                  borderTopColor: "#000000",
                                                  borderTopStyle: "none",
                                                  borderTopWidth: "",
                                                  paddingBottom: "10px",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                <Span className={"table-span"}>
                                                  {row["type"]}
                                                </Span>
                                              </td>
                                              <td
                                                style={{
                                                  borderBottomColor: "#000000",
                                                  borderBottomStyle: "none",
                                                  borderBottomWidth: "",
                                                  borderLeftColor: "#000000",
                                                  borderLeftStyle: "none",
                                                  borderLeftWidth: "",
                                                  borderRightColor: "#000000",
                                                  borderRightStyle: "none",
                                                  borderRightWidth: "",
                                                  borderStyle: "none",
                                                  borderTopColor: "#000000",
                                                  borderTopStyle: "none",
                                                  borderTopWidth: "",
                                                  paddingBottom: "10px",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                <Span className={"table-span"}>
                                                  {row["qtr"]}
                                                </Span>
                                              </td>
                                              <td
                                                style={{
                                                  borderBottomColor: "#000000",
                                                  borderBottomStyle: "none",
                                                  borderBottomWidth: "",
                                                  borderLeftColor: "#000000",
                                                  borderLeftStyle: "none",
                                                  borderLeftWidth: "",
                                                  borderRightColor: "#000000",
                                                  borderRightStyle: "none",
                                                  borderRightWidth: "",
                                                  borderStyle: "none",
                                                  borderTopColor: "#000000",
                                                  borderTopStyle: "none",
                                                  borderTopWidth: "",
                                                  paddingBottom: "10px",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                <Span className={"table-span"}>
                                                  {row["series"]}
                                                </Span>
                                              </td>
                                              <td
                                                style={{
                                                  borderBottomColor: "#000000",
                                                  borderBottomStyle: "none",
                                                  borderBottomWidth: "",
                                                  borderLeftColor: "#000000",
                                                  borderLeftStyle: "none",
                                                  borderLeftWidth: "",
                                                  borderRightColor: "#000000",
                                                  borderRightStyle: "none",
                                                  borderRightWidth: "",
                                                  borderStyle: "none",
                                                  borderTopColor: "#000000",
                                                  borderTopStyle: "none",
                                                  borderTopWidth: "",
                                                  paddingBottom: "10px",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                <Span className={"table-span"}>
                                                  {row["down"]}
                                                </Span>
                                              </td>
                                              <td
                                                style={{
                                                  borderBottomColor: "#000000",
                                                  borderBottomStyle: "none",
                                                  borderBottomWidth: "",
                                                  borderLeftColor: "#000000",
                                                  borderLeftStyle: "none",
                                                  borderLeftWidth: "",
                                                  borderRightColor: "#000000",
                                                  borderRightStyle: "none",
                                                  borderRightWidth: "",
                                                  borderStyle: "none",
                                                  borderTopColor: "#000000",
                                                  borderTopStyle: "none",
                                                  borderTopWidth: "",
                                                  paddingBottom: "10px",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                <Span className={"table-span"}>
                                                  {row["title"]
                                                    ? row["title"]
                                                    : "-"}
                                                </Span>
                                              </td>
                                              <td
                                                style={{
                                                  borderBottomColor: "#000000",
                                                  borderBottomStyle: "none",
                                                  borderBottomWidth: "",
                                                  borderLeftColor: "#000000",
                                                  borderLeftStyle: "none",
                                                  borderLeftWidth: "",
                                                  borderRightColor: "#000000",
                                                  borderRightStyle: "none",
                                                  borderRightWidth: "",
                                                  borderStyle: "none",
                                                  borderTopColor: "#000000",
                                                  borderTopStyle: "none",
                                                  borderTopWidth: "",
                                                  paddingBottom: "10px",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                <Span className={"table-span"}>
                                                  {row["production_point"] ===
                                                  ""
                                                    ? "None"
                                                    : row["production_point"]}
                                                </Span>
                                              </td>
                                              <td
                                                style={{
                                                  borderBottomColor: "#000000",
                                                  borderBottomStyle: "none",
                                                  borderBottomWidth: "",
                                                  borderLeftColor: "#000000",
                                                  borderLeftStyle: "none",
                                                  borderLeftWidth: "",
                                                  borderRightColor: "#000000",
                                                  borderRightStyle: "none",
                                                  borderRightWidth: "",
                                                  borderStyle: "none",
                                                  borderTopColor: "#000000",
                                                  borderTopStyle: "none",
                                                  borderTopWidth: "",
                                                  paddingBottom: "10px",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                <Span
                                                  className={"table-span"}
                                                  actions={[
                                                    {
                                                      action: "Set Notes",
                                                      trigger: "onclick",
                                                      timeout: "",
                                                      pollingPeriod: "",
                                                      inputVariables: {
                                                        "set notes": true,
                                                        "set notes data": row,
                                                      },
                                                    },
                                                  ]}
                                                >
                                                  <i
                                                    className="fa fa-eye"
                                                    df-snippet-instance-id={298}
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </Span>
                                              </td>
                                              {CustomFieldTitles &&
                                                isArray(CustomFieldTitles) &&
                                                CustomFieldTitles.map(
                                                  (item, index) => {
                                                    let customData =
                                                      row?.["custom_fields"] &&
                                                      isArray(
                                                        row?.["custom_fields"]
                                                      ) &&
                                                      row?.[
                                                        "custom_fields"
                                                      ].filter(
                                                        (inItem) =>
                                                          inItem.title_id ===
                                                          item.id
                                                      );
                                                    return (
                                                      <td
                                                        key={index}
                                                        style={{
                                                          borderBottomColor:
                                                            "#000000",
                                                          borderBottomStyle:
                                                            "none",
                                                          borderBottomWidth: "",
                                                          borderLeftColor:
                                                            "#000000",
                                                          borderLeftStyle:
                                                            "none",
                                                          borderLeftWidth: "",
                                                          borderRightColor:
                                                            "#000000",
                                                          borderRightStyle:
                                                            "none",
                                                          borderRightWidth: "",
                                                          borderStyle: "none",
                                                          borderTopColor:
                                                            "#000000",
                                                          borderTopStyle:
                                                            "none",
                                                          borderTopWidth: "",
                                                          paddingBottom: "10px",
                                                          paddingLeft:
                                                            "0.75rem",
                                                          paddingRight:
                                                            "0.75rem",
                                                          paddingTop: "10px",
                                                        }}
                                                      >
                                                        <Span
                                                          className={
                                                            "table-span"
                                                          }
                                                        >
                                                          {isArray(
                                                            customData
                                                          ) &&
                                                          customData[0]?.data
                                                            ? customData[0]
                                                                ?.data
                                                            : "-"}
                                                        </Span>
                                                      </td>
                                                    );
                                                  }
                                                )}
                                              {Intangibles &&
                                                isArray(Intangibles) &&
                                                Intangibles.map((item) => {
                                                  let value =
                                                    row["user_grades"] &&
                                                    isArray(
                                                      row["user_grades"]
                                                    ) &&
                                                    row["user_grades"]?.filter(
                                                      (innItem) =>
                                                        innItem?.intangible_name ===
                                                        item?.["name"]
                                                    );
                                                  let getScaleTypeArray = [];
                                                  if (value && isArray(value)) {
                                                    getScaleTypeArray =
                                                      isArray(
                                                        GetScaleTypeData
                                                      ) &&
                                                      GetScaleTypeData?.filter(
                                                        (val) =>
                                                          val?.position_id ===
                                                          value[0]?.position_id
                                                      );
                                                  }
                                                  return (
                                                    <Fragment>
                                                      <td
                                                        style={{
                                                          borderBottomColor:
                                                            "#000000",
                                                          borderBottomStyle:
                                                            "none",
                                                          borderBottomWidth: "",
                                                          borderLeftColor:
                                                            "#000000",
                                                          borderLeftStyle:
                                                            "none",
                                                          borderLeftWidth: "",
                                                          borderRightColor:
                                                            "#000000",
                                                          borderRightStyle:
                                                            "none",
                                                          borderRightWidth: "",
                                                          borderStyle: "none",
                                                          borderTopColor:
                                                            "#000000",
                                                          borderTopStyle:
                                                            "none",
                                                          borderTopWidth: "",
                                                          fontSize: "0px",
                                                          marginTop: "0px",
                                                          paddingBottom:
                                                            "0.375em",
                                                          paddingLeft:
                                                            "0.75rem",
                                                          paddingRight:
                                                            "0.75rem",
                                                          paddingTop: "0.375em",
                                                        }}
                                                      >
                                                        <Span
                                                          className={
                                                            "table-span"
                                                          }
                                                        >
                                                          {handleScaleTypeValue(
                                                            getScaleTypeArray?.[0]
                                                              ?.scale_type,
                                                            value
                                                          )}
                                                        </Span>
                                                      </td>
                                                    </Fragment>
                                                  );
                                                })}
                                              <td
                                                style={{
                                                  borderBottomColor: "#000000",
                                                  borderBottomStyle: "none",
                                                  borderBottomWidth: "",
                                                  borderLeftColor: "#000000",
                                                  borderLeftStyle: "none",
                                                  borderLeftWidth: "",
                                                  borderRightColor: "#000000",
                                                  borderRightStyle: "none",
                                                  borderRightWidth: "",
                                                  borderStyle: "none",
                                                  borderTopColor: "#000000",
                                                  borderTopStyle: "none",
                                                  borderTopWidth: "",
                                                  fontSize: "0px",
                                                  marginTop: "0px",
                                                  paddingBottom: "0.375em",
                                                  paddingLeft: "0.75rem",
                                                  paddingRight: "0.75rem",
                                                  paddingTop: "0.375em",
                                                }}
                                              >
                                                <Span
                                                  className={"table-span"}
                                                  style={{ fontWeight: "700" }}
                                                >
                                                  {/* {this.handleAverages(row['user_grades'])} */}
                                                  {row["avg"]
                                                    ? row["avg"]
                                                    : "None"}
                                                </Span>
                                              </td>
                                            </tr>
                                          </Fragment>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              )}
                            </DivBlock>
                          </DivBlock>
                        </>
                      )}
                      <DivBlock>
                        <If condition={NoteInfo ? true : false} show={false}>
                          <Then>
                            <DivBlock className={"modal"}>
                              <DivBlock className={"modal-dialog"} style={{}}>
                                <DivBlock className={"modal-dialog"} style={{}}>
                                  <DivBlock
                                    className={"modal-content"}
                                    style={{}}
                                  >
                                    <DivBlock className={"modal-header"}>
                                      <Heading
                                        className="modal-heading"
                                        type={"h5"}
                                      >
                                        Notes
                                      </Heading>

                                      <Span
                                        className={"close"}
                                        actions={[
                                          {
                                            action: "Set Notes",
                                            trigger: "onclick",
                                            timeout: "",
                                            pollingPeriod: "",
                                            inputVariables: {
                                              "toggle depth chart": false,
                                            },
                                          },
                                        ]}
                                      >
                                        x
                                      </Span>
                                    </DivBlock>

                                    <DivBlock className={"modal-body"}>
                                      <FormBlock
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          width: "100%",
                                        }}
                                        // actions={[
                                        //   {
                                        //     action: "Create Depth Chart",
                                        //     trigger: "onsubmit",
                                        //     timeout: "",
                                        //     pollingPeriod: "",
                                        //     inputVariables: {},
                                        //   },
                                        // ]}
                                      >
                                        <DivBlock
                                          style={{ textAlign: "center" }}
                                        >
                                          {NoteInfoData &&
                                          (NoteInfoData.notes === "" ||
                                            NoteInfoData.notes === null) ? (
                                            <Span
                                              style={{
                                                fontFamily:
                                                  "'Poppins', sans-serif",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                textAlign: "center",
                                              }}
                                            >
                                              No notes available
                                            </Span>
                                          ) : (
                                            <TextInput
                                              className={"form-control"}
                                              name={"NoteInfoData?.notes"}
                                              placeholder={""}
                                              initialValue={
                                                NoteInfoData?.notes
                                                  ? NoteInfoData.notes
                                                  : "No notes available"
                                              }
                                              disabled={true}
                                              style={{}}
                                            ></TextInput>
                                          )}
                                        </DivBlock>
                                      </FormBlock>
                                    </DivBlock>
                                  </DivBlock>
                                </DivBlock>
                              </DivBlock>
                            </DivBlock>
                          </Then>
                        </If>
                      </DivBlock>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};
const mapDispatchToProps = (dispatch) => {
  return {
    getScaleData: () => {
      dispatch({ type: "GET_SCALE_TYPE" });
      return Promise.resolve();
    },
    positionData: () => {
      dispatch({ type: "GET_POSITIONS" });
      return Promise.resolve();
    },
    getPlayerData: () => {
      dispatch({
        type: "GET_PLAYER_GAME",
        inputVariables: {
          isDefault: true,
        },
      });
      return Promise.resolve();
    },
    getTotalPlaysData: (inputVariables) => {
      dispatch({ type: "GET_TOTAL_PLAYS", inputVariables });
      return Promise.resolve();
    },
    clearPlayerReportData: () => {
      dispatch({ type: "CLEAR_PLAYER_REPORT_VALUES" });
      return Promise.resolve();
    },
    dispatch: dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerReportsDataPage);
