import * as actions from "../redux/actions.js";
import * as serverApi from "../api/api.js";
import * as Yup from "yup";
import { push } from "react-router-redux";
import { isArray } from "lodash";
import {
  averageCalculate,
  loginEvent,
  logoutEvent,
  printDiv,
  toggleClassInbody,
  updateDepthChartId,
  updateNotesById,
  updateValueById,
  videoUploadValueById,
} from "../utils/function.js";
import { put, call, take, fork, select } from "redux-saga/effects";
import FileSaver from "file-saver";

// let call, put, take, fork, select, all;
// export const initEffects = function (effects) {
//   ({ call, put, take, fork, select, all } = effects);
//   if (window.__configureStore) {
//     window.__configureStore(saga);
//   }
// };
// window.__initEffects = initEffects;

async function _getValidationErrors(schema, obj) {
  // debugger
  let validationErrors = {};
  try {
    await schema.validate(obj);
  } catch (err) {
    validationErrors = err;
  }

  return validationErrors;
}

function _isEmpty(data) {
  // debugger
  let validationErrors = {};
  try {
    const message =
      isArray(data) && data?.length ? "" : "Please select atleast one position";
    validationErrors["position_data"] = message;
  } catch (err) {
    validationErrors["position_data"] = err;
  }

  return validationErrors["position_data"];
}

function* log_event() {
  while (true) {
    let payload = yield take(actions.log_event);
    try {
    } catch (error) {
      console.warn(error);
    }
  }
}

function* ADD_NEW_FIELD() {
  while (true) {
    yield take(actions.ADD_NEW_FIELD);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.logEvent({
          id: 11255,
          options: {
            field_key: "NewField",
            field_value: state.reducer["ToggleTrueReset"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("NewField", state.reducer["ToggleTrueReset"])
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_CUSTOM_FIELD_DATA() {
  while (true) {
    let { inputVariables } = yield take(actions.CREATE_CUSTOM_FIELD_DATA);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayIdVariable = inputVariables["play id"];

      let TitleIdVariable = inputVariables["title id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["title_id"] = TitleIdVariable;
      payload.query_variables["play_id"] = PlayIdVariable;
      payload["body_variables"] = {};
      payload.body_variables["data"] = state.reducer["FormNewData"];
      const response = yield call(
        serverApi.CreateCustomFieldDataEndpoint,
        payload
      );

      const CreateCustomFieldDataResponseStatus = response.status;
      // yield put(
      //   actions.logEvent({
      //     id: 12050,
      //     options: {
      //       body_variables: {
      //         "d8c45f8f-2a9d-49be-97ec-06cf41d05052.12841":
      //           state.reducer["FormNewData"],
      //       },
      //       query_variables: {
      //         "62082e01-0e18-470d-8596-e3a2fdd03298": TitleIdVariable,
      //         "aec4a647-18c2-4ab0-a30c-0c9d8ed43026": PlayIdVariable,
      //       },
      //       response_code_as: CreateCustomFieldDataResponseStatus,
      //     },
      //     type: "event",
      //     time: Date.now(),
      //   })
      // );
      if (CreateCustomFieldDataResponseStatus === state.reducer["TwoHundred"]) {
        // yield put(
        //   actions.logEvent({
        //     id: 12052,
        //     options: {
        //       parameter_mapping: {
        //         "e556c934-6791-465b-99d5-4ccad85c63f3":
        //           state.reducer["CurrentGame"]["Id"],
        //       },
        //     },
        //     type: "event",
        //     time: Date.now(),
        //   })
        // );
        // yield put(
        //   actions.runAction("GET_PLAY_BY_PLAY_INFO", {
        //     inputVariables: { gameid: state.reducer["CurrentGame"]["Id"] },
        //   })
        // );
        // yield put(
        //   actions.logEvent({
        //     id: 12053,
        //     options: {
        //       field_key: "NewData",
        //     },
        //     type: "event",
        //     time: Date.now(),
        //   })
        // );
        // yield put(actions.changeInput("NewData",false));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_CUSTOM_FIELD_DATA() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_CUSTOM_FIELD_DATA);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    try {
      yield put(actions.changeInput("FormNewData", inputVariables["name"]));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_CUSTOM_PLAY_DATA() {
  while (true) {
    let { inputVariables } = yield take(actions.CREATE_CUSTOM_PLAY_DATA);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayidVariable = inputVariables["playid"];

      let TitleidVariable = inputVariables["titleid"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
      payload["body_variables"] = {};
      payload.body_variables["data"] = state.reducer["Emptystring"];
      payload.body_variables["play id"] = PlayidVariable;
      payload.body_variables["title id"] = TitleidVariable;
      payload.body_variables["game id"] = state.reducer["CurrentGame"]["Id"];
      const response11820 = yield call(
        serverApi.CreateCustomPlayDataEndpoint,
        payload
      );

      const CreateCustomPlayDataResponseCodeVariable = response11820.status;
      yield put(
        actions.logEvent({
          id: 11820,
          options: {
            body_variables: {
              "92090a79-33b7-49a3-8672-ddcbea68af42.12841":
                state.reducer["Emptystring"],
              "92090a79-33b7-49a3-8672-ddcbea68af42.12842": PlayidVariable,
              "92090a79-33b7-49a3-8672-ddcbea68af42.12843": TitleidVariable,
              "92090a79-33b7-49a3-8672-ddcbea68af42.12933":
                state.reducer["CurrentGame"]["Id"],
            },
            query_variables: {
              "a7e3fc24-7ad9-427d-8bef-68b7a654a905":
                state.reducer["CurrentGame"]["Id"],
            },
            response_code_as: CreateCustomPlayDataResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        CreateCustomPlayDataResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12210,
            options: {
              parameter_mapping: {
                "e556c934-6791-465b-99d5-4ccad85c63f3":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_BY_PLAY_INFO", {
            inputVariables: { gameid: state.reducer["CurrentGame"]["Id"] },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_DEPTH_CHART() {
  while (true) {
    yield take(actions.CREATE_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload, defaultPayload;

    try {
      var SharedVariable = false;

      let TagsVariable;
      try {
        TagsVariable = window.CreateTags({
          tagsstring: state.reducer["FormCreateDepthChart"]["Tags"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 4458,
          options: {
            parameter_mapping: {
              "c0ac754c-ace8-4a3a-961e-96c1098756b8":
                state.reducer["FormCreateDepthChart"]["Tags"],
            },
            return_as: TagsVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      var schema3500;
      var err3500;
      var IsValidVariable = true;
      err3500 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a name"),
        state.reducer["FormCreateDepthChart"]["title"]
      );
      IsValidVariable = !IsValidVariable
        ? false
        : err3500.name !== "ValidationError";
      if (err3500.name === "ValidationError") {
        console.warn(err3500.errors);
        yield put(
          actions.changeInput(
            "FormCreateDepthChartErrors.title",
            err3500.errors
          )
        );
      } else {
        yield put(actions.removeField("FormCreateDepthChartErrors.title"));
      }
      err3500 = yield call(
        _getValidationErrors,
        Yup.number()
          .integer()
          .required("Please enter a week")
          .min(1, "Please enter a week"),
        state.reducer["FormCreateDepthChart"]["week"]
      );
      IsValidVariable = !IsValidVariable
        ? false
        : err3500.name !== "ValidationError";
      if (err3500.name === "ValidationError") {
        console.warn(err3500.errors);
        yield put(
          actions.changeInput("FormCreateDepthChartErrors.week", err3500.errors)
        );
      } else {
        yield put(actions.removeField("FormCreateDepthChartErrors.week"));
      }
      if (IsValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["title"] =
          state.reducer["FormCreateDepthChart"]["title"];
        payload.body_variables["week"] =
          state.reducer["FormCreateDepthChart"]["week"];
        payload.body_variables["category"] =
          state.reducer["FormCreateDepthChart"]["category"];
        payload.body_variables["Tags"] = TagsVariable;
        payload.body_variables["shared"] = SharedVariable;
        const response = yield call(
          serverApi.CreateDepthChartEndpoint,
          payload
        );
        const reponseData = response.data;
        const CreateDepthChartResponseCodeVariable = response.status;
        yield put(
          actions.logEvent({
            id: 3502,
            options: {
              body_variables: {
                "418712b6-417a-447b-a125-5208547082a2.6023":
                  state.reducer["FormCreateDepthChart"]["title"],
                "418712b6-417a-447b-a125-5208547082a2.6024":
                  state.reducer["FormCreateDepthChart"]["week"],
                "418712b6-417a-447b-a125-5208547082a2.6025":
                  state.reducer["FormCreateDepthChart"]["category"],
                "418712b6-417a-447b-a125-5208547082a2.6028": TagsVariable,
                "418712b6-417a-447b-a125-5208547082a2.6053": SharedVariable,
              },
              response_code_as: CreateDepthChartResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          CreateDepthChartResponseCodeVariable === state.reducer["TwoHundred"]
        ) {
          defaultPayload = {};
          defaultPayload["query_variables"] = {};
          defaultPayload.query_variables["depth_chart_id"] = reponseData.id;
          const response = yield call(
            serverApi.DefaultDepthCharts,
            defaultPayload
          );
          yield put(
            actions.logEvent({
              id: 3505,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_BASE_DEPTH_CHARTS", { inputVariables: {} })
          );

          yield put(
            actions.logEvent({
              id: 3506,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_GROUPED_CHARTS", { inputVariables: {} })
          );

          yield put(
            push(
              `/depth_chart?depthchartid=${reponseData?.id}&year=${state.reducer?.selectedSeasonYear}`
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_DEPTH_CHART_ALIAS() {
  while (true) {
    yield take(actions.CREATE_DEPTH_CHART_ALIAS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["body_variables"] = {};
      payload.body_variables["depth_chart_id"] =
        state.reducer["CurrentDepthChart"]["Id"];
      payload.body_variables["position_id"] =
        state.reducer["CurrentAlias"]["id"];
      payload.body_variables["alias"] = state.reducer["FormAlias"];
      payload.body_variables["row_id"] =
        state.reducer["CurrentAlias"]["row_id"];
      const response = yield call(
        serverApi.CreateDepthChartAliasEndpoint,
        payload
      );
      const CreateDepthChartAliasResponse = response.data;
      const CreateDepthChartAliasResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 4551,
          options: {
            body_variables: {
              "c92c5ff3-eae1-4408-8cef-9b1a856db491.4592":
                state.reducer["CurrentDepthChart"]["Id"],
              "c92c5ff3-eae1-4408-8cef-9b1a856db491.4593":
                state.reducer["CurrentAlias"]["id"],
              "c92c5ff3-eae1-4408-8cef-9b1a856db491.4594":
                state.reducer["FormAlias"],
              "c92c5ff3-eae1-4408-8cef-9b1a856db491.5188":
                state.reducer["CurrentAlias"]["row_id"],
            },
            response_as: CreateDepthChartAliasResponse,
            response_code_as: CreateDepthChartAliasResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (CreateDepthChartAliasResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 4555,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_DEPTH_CHART_USERS", { inputVariables: {} })
        );

        yield put(
          actions.logEvent({
            id: 4556,
            options: {
              field_key: "CurrentAlias",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("CurrentAlias"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_DEPTH_CHART_REGISTER() {
  while (true) {
    yield take(actions.CREATE_DEPTH_CHART_REGISTER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var PageRedirectionVariable = "";

      let TagVariable;
      try {
        TagVariable = window.CreateTags({
          tagsstring: state.reducer["FormCreateDepthChartRegister"]["Tags"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 8881,
          options: {
            parameter_mapping: {
              "c0ac754c-ace8-4a3a-961e-96c1098756b8":
                state.reducer["FormCreateDepthChartRegister"]["Tags"],
            },
            return_as: TagVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      payload = {};
      payload["body_variables"] = {};
      payload.body_variables["title"] =
        state.reducer["FormCreateDepthChartRegister"]["title"];
      payload.body_variables["week"] =
        state.reducer["FormCreateDepthChartRegister"]["week"];
      payload.body_variables["category"] =
        state.reducer["FormCreateDepthChartRegister"]["category"];
      payload.body_variables["Tags"] = TagVariable;
      payload.body_variables["shared"] = state.reducer["FalseCheckbox"];
      payload.body_variables["school_id"] =
        state.reducer["FormCreateDepthChartRegister"]["school_id"];
      const response8883 = yield call(
        serverApi.CreateDepthChartRegisterEndpoint,
        payload
      );

      const CreateDepthChartRegisterResponseCodeVariable = response8883.status;
      yield put(
        actions.logEvent({
          id: 8883,
          options: {
            body_variables: {
              "8bf71c29-8d40-4ced-b446-e5ca7040dc32.10327":
                state.reducer["FormCreateDepthChartRegister"]["title"],
              "8bf71c29-8d40-4ced-b446-e5ca7040dc32.10328":
                state.reducer["FormCreateDepthChartRegister"]["week"],
              "8bf71c29-8d40-4ced-b446-e5ca7040dc32.10329":
                state.reducer["FormCreateDepthChartRegister"]["category"],
              "8bf71c29-8d40-4ced-b446-e5ca7040dc32.10330": TagVariable,
              "8bf71c29-8d40-4ced-b446-e5ca7040dc32.10331":
                state.reducer["FalseCheckbox"],
              "8bf71c29-8d40-4ced-b446-e5ca7040dc32.10332":
                state.reducer["FormCreateDepthChartRegister"]["school_id"],
            },
            response_code_as: CreateDepthChartRegisterResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        CreateDepthChartRegisterResponseCodeVariable ===
        state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 8885,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_BASE_DEPTH_CHARTS", { inputVariables: {} })
        );

        yield put(
          actions.logEvent({
            id: 8886,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_GROUPED_CHARTS", { inputVariables: {} })
        );

        yield put(push(`${PageRedirectionVariable}`));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_EXERCISE() {
  while (true) {
    yield take(actions.CREATE_EXERCISE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var err;
      var FormNewExerciseIsValidVariable = true;
      err = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a name"),
        state.reducer["FormNewExercise"]["name"]
      );
      FormNewExerciseIsValidVariable = !FormNewExerciseIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput("FormNewExerciseErrors.name", err.errors)
        );
      } else {
        yield put(actions.removeField("FormNewExerciseErrors.name"));
      }
      if (FormNewExerciseIsValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["name"] =
          state.reducer["FormNewExercise"]["name"];
        const response = yield call(serverApi.CreateExerciseEndpoint, payload);
        const CreateExerciseResponse = response.data;
        const CreateExerciseResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 4006,
            options: {
              body_variables: {
                "1e9557b1-fe21-499a-8cff-94be5da0be33.4626":
                  state.reducer["FormNewExercise"]["name"],
              },
              response_as: CreateExerciseResponse,
              response_code_as: CreateExerciseResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (CreateExerciseResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 4008,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.runAction("GET_EXERCISES", { inputVariables: {} }));

          yield put(
            actions.logEvent({
              id: 4009,
              options: {
                field_key: "NewExercise",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("NewExercise"));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_GAMES() {
  while (true) {
    let { inputVariables } = yield take(actions.CREATE_GAMES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      if (inputVariables?.type === "qwikcut") {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["type"] = state.reducer["FormGames"]["type"];
        payload.body_variables["title"] = state.reducer["FormGames"]["title"];
        payload.body_variables["authorization"] = inputVariables?.qwikcutToken;
        payload.body_variables["date"] = state.reducer["FormGames"]["date"];
        payload.body_variables["team"] = inputVariables?.teamData;
        payload.body_variables["game_id"] = inputVariables?.gameId;
        payload.body_variables["team_id"] = inputVariables?.teamId;
        payload.body_variables["depth_chart_id"] =
          inputVariables?.depthChartId === ""
            ? null
            : Number(inputVariables?.depthChartId);
        console.log("payload :>> ", payload);
        const response = yield call(
          serverApi.CreateQwikcutGamesEndpoint,
          payload
        );
        const CreateQwikcutGameResposne = response.data;
        console.log("CreateQwikcutGameResposne: ", CreateQwikcutGameResposne);
        const CreateQwikcutGameResponseStatus = response.status;

        if (CreateQwikcutGameResponseStatus === state.reducer["TwoHundred"]) {
          yield put(actions.removeField("NewGame"));
          yield put(
            actions.runAction("GET_GAMES_AND_PRACTICES", { inputVariables: {} })
          );
          yield put(actions.runAction("GET_TEAMS"));
        }
      } else {
        var err;
        var FormGamesIsValidVariable = true;
        err = yield call(
          _getValidationErrors,
          Yup.string().required("Please enter the title"),
          state.reducer["FormGames"]["title"]
        );
        FormGamesIsValidVariable = !FormGamesIsValidVariable
          ? false
          : err.name !== "ValidationError";
        if (err.name === "ValidationError") {
          console.warn(err.errors);
          yield put(actions.changeInput("FormGamesError.title", err.errors));
        } else {
          yield put(actions.removeField("FormGamesError.title"));
        }
        err = yield call(
          _getValidationErrors,
          Yup.string().required("Please enter the date"),
          state.reducer["FormGames"]["date"]
        );
        FormGamesIsValidVariable = !FormGamesIsValidVariable
          ? false
          : err.name !== "ValidationError";
        if (err.name === "ValidationError") {
          console.warn(err.errors);
          yield put(actions.changeInput("FormGamesError.date", err.errors));
        } else {
          yield put(actions.removeField("FormGamesError.date"));
        }
        err = yield call(
          _getValidationErrors,
          Yup.string().required("Please select one"),
          state.reducer["FormGames"]["type"]
        );
        FormGamesIsValidVariable = !FormGamesIsValidVariable
          ? false
          : err.name !== "ValidationError";
        if (err.name === "ValidationError") {
          console.warn(err.errors);
          yield put(actions.changeInput("FormGamesError.type", err.errors));
        } else {
          yield put(actions.removeField("FormGamesError.type"));
        }
        if (FormGamesIsValidVariable === state.reducer["TrueCheckbox"]) {
          payload = {};
          payload["body_variables"] = {};
          payload.body_variables["type"] = state.reducer["FormGames"]["type"];
          payload.body_variables["title"] = state.reducer["FormGames"]["title"];
          payload.body_variables["school_id"] =
            state.reducer["CurrentUser"]["school_id"];
          payload.body_variables["date"] = state.reducer["FormGames"]["date"];
          payload.body_variables["team"] = inputVariables?.teamData;
          const response = yield call(serverApi.CreateGamesEndpoint, payload);
          const CreateGameResposne = response.data;
          const CreateGameResponseStatus = response.status;
          yield put(
            actions.logEvent({
              id: 9950,
              options: {
                body_variables: {
                  "267b25cc-5999-47e4-b6b2-d12562a1ea00.13257":
                    state.reducer["FormGames"]["type"],
                  "267b25cc-5999-47e4-b6b2-d12562a1ea00.7196":
                    state.reducer["FormGames"]["title"],
                  "267b25cc-5999-47e4-b6b2-d12562a1ea00.7198":
                    state.reducer["CurrentUser"]["school_id"],
                  "267b25cc-5999-47e4-b6b2-d12562a1ea00.7274":
                    state.reducer["FormGames"]["date"],
                },
                response_as: CreateGameResposne,
                response_code_as: CreateGameResponseStatus,
              },
              type: "event",
              time: Date.now(),
            })
          );
          if (CreateGameResponseStatus === state.reducer["TwoHundred"]) {
            yield put(
              actions.logEvent({
                id: 12256,
                options: {
                  field_key: "CurrentGame",
                  field_value: CreateGameResposne,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(actions.changeInput("CurrentGame", CreateGameResposne));

            yield put(
              actions.logEvent({
                id: 12053,
                options: {
                  field_key: "NewGame",
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(actions.removeField("NewGame"));

            yield put(
              actions.logEvent({
                id: 12213,
                options: {},
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.runAction("GET_GAMES_AND_PRACTICES", {
                inputVariables: {},
              })
            );
          }
          yield put(actions.runAction("GET_TEAMS"));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* VALIDATE_QWIKCUT_GAMES() {
  while (true) {
    let { inputVariables } = yield take(actions.VALIDATE_QWIKCUT_GAMES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      var err;
      var FormGamesIsValidVariable = true;
      err = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter the title"),
        state.reducer["FormGames"]["title"]
      );
      FormGamesIsValidVariable = !FormGamesIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(actions.changeInput("FormGamesError.title", err.errors));
      } else {
        yield put(actions.removeField("FormGamesError.title"));
      }
      err = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter the date"),
        state.reducer["FormGames"]["date"]
      );
      FormGamesIsValidVariable = !FormGamesIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(actions.changeInput("FormGamesError.date", err.errors));
      } else {
        yield put(actions.removeField("FormGamesError.date"));
      }
      err = yield call(
        _getValidationErrors,
        Yup.string().required("Please select one"),
        state.reducer["FormGames"]["type"]
      );
      FormGamesIsValidVariable = !FormGamesIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(actions.changeInput("FormGamesError.type", err.errors));
      } else {
        yield put(actions.removeField("FormGamesError.type"));
      }
      if (FormGamesIsValidVariable === state.reducer["TrueCheckbox"]) {
        if (inputVariables?.callback) {
          inputVariables?.callback(FormGamesIsValidVariable);
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_GRADE() {
  while (true) {
    let { inputVariables } = yield take(actions.CREATE_GRADE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputIntangibleIdVariable = inputVariables["input intangible id"];

      let InputUserIdVariable = inputVariables["input user id"];

      let FormattedDateResponseVariable;
      try {
        FormattedDateResponseVariable = window.FormatDate({
          datestring: state.reducer["FormSearchGradesDate"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 4421,
          options: {
            parameter_mapping: {
              "1eb12004-d380-4086-9731-feecf4591dc6":
                state.reducer["FormSearchGradesDate"],
            },
            return_as: FormattedDateResponseVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (state.reducer["currentintegervalue"] < state.reducer["Zero"]) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["user id"] = InputUserIdVariable;
        payload.body_variables["intangible id"] = InputIntangibleIdVariable;
        payload.body_variables["date"] = FormattedDateResponseVariable;
        const response4420 = yield call(serverApi.DeleteGradeEndpoint, payload);

        const DeleteGradeResponseCodeVariable = response4420.status;
        yield put(
          actions.logEvent({
            id: 4420,
            options: {
              body_variables: {
                "0c95ca1b-ad7d-4d78-9516-ceef379a0641.4615":
                  InputUserIdVariable,
                "0c95ca1b-ad7d-4d78-9516-ceef379a0641.4616":
                  InputIntangibleIdVariable,
                "0c95ca1b-ad7d-4d78-9516-ceef379a0641.4840":
                  FormattedDateResponseVariable,
              },
              response_code_as: DeleteGradeResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (DeleteGradeResponseCodeVariable === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 4427,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_AVERAGE_GAME_GRADES", { inputVariables: {} })
          );
        }
      }
      if (state.reducer["currentintegervalue"] >= state.reducer["Zero"]) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["user id"] = InputUserIdVariable;
        payload.body_variables["intangible id"] = InputIntangibleIdVariable;
        payload.body_variables["integer value"] =
          state.reducer["currentintegervalue"];
        payload.body_variables["type"] = state.reducer["FormSearchGradesType"];
        payload.body_variables["date"] = FormattedDateResponseVariable;
        const response4423 = yield call(serverApi.CreateGradeEndpoint, payload);
        const CreateGradeResponsesVariable = response4423.data;
        const CreateGradeResponseCodesVariable = response4423.status;
        yield put(
          actions.logEvent({
            id: 4423,
            options: {
              body_variables: {
                "60e77c2f-14ef-4c2c-8aee-71d532cfdd3d.4615":
                  InputUserIdVariable,
                "60e77c2f-14ef-4c2c-8aee-71d532cfdd3d.4616":
                  InputIntangibleIdVariable,
                "60e77c2f-14ef-4c2c-8aee-71d532cfdd3d.4617":
                  state.reducer["currentintegervalue"],
                "60e77c2f-14ef-4c2c-8aee-71d532cfdd3d.4819":
                  state.reducer["FormSearchGradesType"],
                "60e77c2f-14ef-4c2c-8aee-71d532cfdd3d.4840":
                  FormattedDateResponseVariable,
              },
              response_as: CreateGradeResponsesVariable,
              response_code_as: CreateGradeResponseCodesVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (CreateGradeResponseCodesVariable === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 4425,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_AVERAGE_GAME_GRADES", { inputVariables: {} })
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_INJURED_PLAYER() {
  while (true) {
    yield take(actions.CREATE_INJURED_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var schema3515;
      var err3515;
      var FormCreateInjuredPlayerIsValidVariable = true;
      err3515 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(1, "Please select a user"),
        state.reducer["FormInjuredPlayer"]["user id"]
      );
      FormCreateInjuredPlayerIsValidVariable =
        !FormCreateInjuredPlayerIsValidVariable
          ? false
          : err3515.name !== "ValidationError";
      if (err3515.name === "ValidationError") {
        console.warn(err3515.errors);
        yield put(
          actions.changeInput("FormInjuredPlayerErrors.user id", err3515.errors)
        );
      } else {
        yield put(actions.removeField("FormInjuredPlayerErrors.user id"));
      }
      if (
        FormCreateInjuredPlayerIsValidVariable === state.reducer["TrueCheckbox"]
      ) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["user_id"] =
          state.reducer["FormInjuredPlayer"]["user id"];
        const response = yield call(
          serverApi.CreateInjuredPlayerEndpoint,
          payload
        );
        const CreateInjuredPlayerResponse = response.data;
        const CreateInjuredPlayerResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 9476,
            options: {
              body_variables: {
                "ee2dd390-6b78-45db-9d71-1bd882157198.4665":
                  state.reducer["FormInjuredPlayer"]["user id"],
                "ee2dd390-6b78-45db-9d71-1bd882157198.4666":
                  state.reducer["FormInjuredPlayer"]["school id"],
              },
              response_as: CreateInjuredPlayerResponse,
              response_code_as: CreateInjuredPlayerResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          CreateInjuredPlayerResponseStatus === state.reducer["TwoHundred"] &&
          CreateInjuredPlayerResponse !== state.reducer["InjuredPlayerError"]
        ) {
          yield put(
            actions.logEvent({
              id: 9478,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_INJURED_PLAYERS", { inputVariables: {} })
          );

          yield put(
            actions.logEvent({
              id: 9479,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.runAction("GET_USERS", { inputVariables: {} }));

          yield put(
            actions.logEvent({
              id: 9480,
              options: {
                field_key: "NewInjuredPlayer",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("NewInjuredPlayer"));

          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["depthchartuid"] =
            state.reducer["FormInjuredPlayer"]["user id"];
          const response = yield call(
            serverApi.DeleteDepthChartUsersEndpoint,
            payload
          );

          const DeleteDepthChartUsersResponseStatus = response.status;
          yield put(
            actions.logEvent({
              id: 9481,
              options: {
                query_variables: {
                  "6759aae8-6a6a-4975-a5bb-13b86e9b4e9c":
                    state.reducer["FormInjuredPlayer"]["user id"],
                },
                response_code_as: DeleteDepthChartUsersResponseStatus,
              },
              type: "event",
              time: Date.now(),
            })
          );
        }
        if (
          CreateInjuredPlayerResponse === state.reducer["InjuredPlayerError"]
        ) {
          yield put(
            actions.logEvent({
              id: 9483,
              options: {
                field_key: "NullPositionError",
                field_value: state.reducer["InjuredPlayerError"],
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "NullPositionError",
              state.reducer["InjuredPlayerError"]
            )
          );

          yield put(
            actions.logEvent({
              id: 9484,
              options: {
                field_key: "NewInjuredPlayer",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("NewInjuredPlayer"));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_INTANGIBLE() {
  while (true) {
    let { inputVariables } = yield take(actions.CREATE_INTANGIBLE);

    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var FormNewIntangibleIsValidVariable = true;

      if (FormNewIntangibleIsValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};

        let finArr = inputVariables?.listPayload?.map((v) => ({
          position_id: v.position_id,
          name: v.name,
        }));
        let GetSeasonYearResponse;
        if (state.reducer?.selectedSeasonYear === "") {
          const seasonYearResponse = yield call(
            serverApi.GetSeasonYearEndpoint,
            payload
          );
          GetSeasonYearResponse = seasonYearResponse.data;
        }
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["year"] =
          state.reducer?.selectedSeasonYear === ""
            ? GetSeasonYearResponse?.[0]?.year
            : state.reducer?.selectedSeasonYear;
        payload["body_variables"] = {};
        if (finArr && isArray(finArr)) {
          payload.body_variables = finArr[0];
        }
        const response = yield call(
          serverApi.CreateIntangibleEndpoint,
          payload
        );

        const CreateIntangibleResponse = response.data;
        const CreateIntangibleResponseStatus = response.status;

        if (CreateIntangibleResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.setField("AddedIntangibleData", CreateIntangibleResponse)
          );
          yield put(
            actions.logEvent({
              id: 4002,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_INTANGIBLES", { inputVariables: {} })
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_NEW_COLUMN() {
  while (true) {
    yield take(actions.CREATE_NEW_COLUMN);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var err;
      var IsTitleValidVariable = true;
      err = yield call(
        _getValidationErrors,
        Yup.string().trim().required("Please enter title"),
        state.reducer["FormNewPlayHeading"]["title"]
      );
      IsTitleValidVariable = !IsTitleValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput("FormNewPlayHeadingErrors.title", err.errors)
        );
      } else {
        yield put(actions.removeField("FormNewPlayHeadingErrors.title"));
      }
      if (IsTitleValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["title"] =
          state.reducer["FormNewPlayHeading"]["title"];
        payload.body_variables["game_id"] = state.reducer["CurrentGame"]["Id"];
        const response = yield call(
          serverApi.CreatePlayHeadingEndpoint,
          payload
        );
        const CreatePlayHeadingResponse = response.data;
        const CreatePlayHeadingResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 12103,
            options: {
              body_variables: {
                "8f388aa2-afaf-409a-bd55-ce21167dacae.12647":
                  state.reducer["FormNewPlayHeading"]["title"],
                "8f388aa2-afaf-409a-bd55-ce21167dacae.12687":
                  state.reducer["CurrentGame"]["Id"],
              },
              response_as: CreatePlayHeadingResponse,
              response_code_as: CreatePlayHeadingResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (CreatePlayHeadingResponseStatus === state.reducer["TwoHundred"]) {
          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["game_id"] =
            state.reducer["CurrentGame"]["Id"];
          payload["body_variables"] = {};
          payload.body_variables["data"] = state.reducer["Emptystring"];
          payload.body_variables["play_id"] = state.reducer["CurrentPlayId"];
          payload.body_variables["title_id"] = CreatePlayHeadingResponse["Id"];
          payload.body_variables["game_id"] =
            state.reducer["CurrentGame"]["Id"];
          const response = yield call(serverApi.GetAllPlayIdsEndpoint, payload);

          const GetAllPlayIdsResponseStatus = response.status;
          yield put(
            actions.logEvent({
              id: 12105,
              options: {
                body_variables: {
                  "ffb50e50-13da-4b49-9cb6-1f39131a0625.12841":
                    state.reducer["Emptystring"],
                  "ffb50e50-13da-4b49-9cb6-1f39131a0625.12842":
                    state.reducer["CurrentPlayId"],
                  "ffb50e50-13da-4b49-9cb6-1f39131a0625.12843":
                    CreatePlayHeadingResponse["Id"],
                  "ffb50e50-13da-4b49-9cb6-1f39131a0625.12933":
                    state.reducer["CurrentGame"]["Id"],
                },
                query_variables: {
                  "eeecd971-f800-4975-8dfe-702bf27f281c":
                    state.reducer["CurrentGame"]["Id"],
                },
                response_code_as: GetAllPlayIdsResponseStatus,
              },
              type: "event",
              time: Date.now(),
            })
          );
          if (GetAllPlayIdsResponseStatus === state.reducer["TwoHundred"]) {
            yield put(
              actions.logEvent({
                id: 12107,
                options: {
                  parameter_mapping: {
                    "e556c934-6791-465b-99d5-4ccad85c63f3":
                      state.reducer["CurrentGame"]["Id"],
                  },
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.runAction("UPDATE_CONFIGURABLE_COLUMNS", {
                inputVariables: {
                  ...state.reducer?.initialCheckbox,
                  [CreatePlayHeadingResponse?.title]: true,
                },
              })
            );
            if (
              state.reducer?.selectedDefaultView ===
              state.reducer?.defaultViewData?.[0]?.id
            ) {
            } else {
              let obj = {};
              state.reducer?.CustomFieldTitles &&
                state.reducer?.CustomFieldTitles?.forEach((data) => {
                  obj[data?.title] = true;
                });
              let column = {
                "#": true,
                play: true,
                qtr: true,
                series: true,
                down: true,
                distance: true,
                type: true,
                package: true,
                ...obj,
              };
              let columnsValue = {
                ...column,
                [CreatePlayHeadingResponse?.title]: true,
              };

              payload = {};
              payload["query_variables"] = {};
              payload.query_variables["game_id"] =
                state.reducer["CurrentGame"]["Id"];
              payload.query_variables["user_id"] =
                state.reducer?.CurrentUser?.id;
              payload["body_variables"] = {};
              payload.body_variables["columns"] = columnsValue;
              payload.body_variables["name"] =
                state.reducer?.defaultViewData?.[0]?.name;

              const saveViewresponse = yield call(
                serverApi.CreateSavedViewsEndpoint,
                payload
              );

              const saveViewsResponse = saveViewresponse.data;
              const saveViewsResponseStatus = saveViewresponse.status;

              if (saveViewsResponseStatus === state.reducer["TwoHundred"]) {
                yield put(
                  actions.logEvent({
                    id: 11930,
                    options: {
                      field_key: "initialCheckbox",
                      field_value: saveViewsResponse?.columns,
                    },
                    type: "event",
                    time: Date.now(),
                  })
                );
                yield put(
                  actions.changeInput(
                    "initialCheckbox",
                    saveViewsResponse?.columns
                  )
                );
              }
            }
            yield put(
              actions.runAction("GET_PLAY_BY_PLAY_INFO", {
                inputVariables: {
                  gameid: state.reducer["CurrentGame"]["Id"],
                },
              })
            );

            yield put(
              actions.logEvent({
                id: 12108,
                options: {
                  parameter_mapping: {
                    "622746b7-b556-48d5-8936-04dab55ffddc":
                      state.reducer["CurrentGame"]["Id"],
                  },
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.runAction("GET_CUSTOM_FIELD_TITLES", {
                inputVariables: {
                  "game id": state.reducer["CurrentGame"]["Id"],
                },
              })
            );

            yield put(
              actions.logEvent({
                id: 12109,
                options: {
                  field_key: "NewRow",
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(actions.changeInput("NewRow", false));
          }
        }
        yield put(
          actions.logEvent({
            id: 12112,
            options: {
              field_key: "NewRow",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("NewRow", false));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_NEW_PLAY_ROW() {
  while (true) {
    yield take(actions.CREATE_NEW_PLAY_ROW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var err;
      var FormPlaysIsValidVariable = true;
      err = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter positive play no."),
        state.reducer["FormCreateNewPlay"]["index"]
      );
      FormPlaysIsValidVariable = !FormPlaysIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput("FormCreateNewPlayErrors.index", err.errors)
        );
      } else {
        yield put(actions.changeInput("FormCreateNewPlayErrors.index", null));
      }
      err = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter name"),
        state.reducer["FormCreateNewPlay"]["name"]
      );
      FormPlaysIsValidVariable = !FormPlaysIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput("FormCreateNewPlayErrors.name", err.errors)
        );
      } else {
        yield put(actions.changeInput("FormCreateNewPlayErrors.name", null));
      }
      err = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter positive series"),
        state.reducer["FormCreateNewPlay"]["series"]
      );
      FormPlaysIsValidVariable = !FormPlaysIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput("FormCreateNewPlayErrors.series", err.errors)
        );
      } else {
        yield put(actions.changeInput("FormCreateNewPlayErrors.series", null));
      }
      err = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter positive down"),
        state.reducer["FormCreateNewPlay"]["down"]
      );
      FormPlaysIsValidVariable = !FormPlaysIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput("FormCreateNewPlayErrors.down", err.errors)
        );
      } else {
        yield put(actions.changeInput("FormCreateNewPlayErrors.down", null));
      }
      if (FormPlaysIsValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["game_id"] = state.reducer["CurrentGame"]["Id"];
        payload.body_variables["name"] =
          state.reducer["FormCreateNewPlay"]["name"];
        payload.body_variables["type"] =
          state.reducer["FormCreateNewPlay"]["type"];
        payload.body_variables["qtr"] =
          state.reducer["FormCreateNewPlay"]["qtr"];
        payload.body_variables["series"] =
          state.reducer["FormCreateNewPlay"]["series"];
        payload.body_variables["down"] =
          state.reducer["FormCreateNewPlay"]["down"];
        payload.body_variables["depth_chart_id"] =
          state.reducer["FormCreateNewPlay"]["depth chart id"];
        payload.body_variables["index"] =
          state.reducer["FormCreateNewPlay"]["index"];
        const response = yield call(serverApi.CreatePlayRowEndpoint, payload);
        const CreatePlayResponse = response.data;
        const CreatePlayResponseStatus = response.status;
        if (CreatePlayResponseStatus === state.reducer["TwoHundred"]) {
          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["game_id"] =
            state.reducer["CurrentGame"]["Id"];
          payload["body_variables"] = {};
          payload.body_variables["data"] = state.reducer["Emptystring"];
          payload.body_variables["play_id"] = CreatePlayResponse["Id"];
          payload.body_variables["game_id"] = CreatePlayResponse["game id"];
          const response = yield call(
            serverApi.CreateCustomPlayDataEndpoint,
            payload
          );

          const CreateCustomPlayDataStatus = response.status;
          if (CreateCustomPlayDataStatus === state.reducer["TwoHundred"]) {
            payload = {};
            payload["query_variables"] = {};
            payload.query_variables["depth_chart_id"] =
              CreatePlayResponse["depth chart id"];
            payload["body_variables"] = {};
            payload.body_variables["play_id"] = CreatePlayResponse["Id"];
            payload.body_variables["production_point"] = state.reducer["TD"];
            payload.body_variables["notes"] = state.reducer["Emptystring"];
            payload.body_variables["game_id"] =
              state.reducer["CurrentGame"]["Id"];
            const response11954 = yield call(
              serverApi.CreatePlayDepthChartRowEndpoint,
              payload
            );
            const CreatePlayDepthChartRowResponseCodeVariable =
              response11954.status;
            if (
              CreatePlayDepthChartRowResponseCodeVariable ===
              state.reducer["TwoHundred"]
            ) {
              yield put(
                actions.logEvent({
                  id: 12212,
                  options: {
                    field_key: "NewPlayRow",
                  },
                  type: "event",
                  time: Date.now(),
                })
              );
              yield put(actions.removeField("NewPlayRow"));
              window.RefreshPage();
            }
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_NEW_POSITION_COACH() {
  while (true) {
    // debugger
    yield take(actions.CREATE_NEW_POSITION_COACH);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var DuplicateCoachEmailErrorVariable =
        "That email is already being used. Please use another email address.";

      var schema7633;
      var err7633;
      var formPositionCoachName;
      var preProcessPosData;
      var preFinalPositionData;
      var FormPositionCoachIsValidVariable = true;
      err7633 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter name"),
        state.reducer["FormPositionsCoach"]["name"]
      );
      FormPositionCoachIsValidVariable = !FormPositionCoachIsValidVariable
        ? false
        : err7633.name !== "ValidationError";
      if (err7633.name === "ValidationError") {
        console.warn(err7633.errors);
        yield put(
          actions.changeInput("FormPositionsCoachError.name", err7633.errors)
        );
      } else {
        yield put(actions.removeField("FormPositionsCoachError.name"));
      }
      err7633 = yield call(
        _getValidationErrors,
        Yup.string()
          .email("Please Enter valid Email Address")
          .required("Please enter email"),
        state.reducer["FormPositionsCoach"]["email"]
      );
      FormPositionCoachIsValidVariable = !FormPositionCoachIsValidVariable
        ? false
        : err7633.name !== "ValidationError";
      if (err7633.name === "ValidationError") {
        console.warn(err7633.errors);
        yield put(
          actions.changeInput("FormPositionsCoachError.email", err7633.errors)
        );
      } else {
        yield put(actions.removeField("FormPositionsCoachError.email"));
      }

      if (state.reducer["FormPositionsCoach"]["position name"] !== undefined) {
        formPositionCoachName =
          state.reducer["FormPositionsCoach"]["position name"];
        preProcessPosData = Object.keys(formPositionCoachName);
        preFinalPositionData = preProcessPosData?.filter(function (key) {
          return formPositionCoachName[key];
        });
      }

      let fullObject = state.reducer["Positions"]?.filter((item) =>
        preFinalPositionData?.includes(item?.name)
      );
      let newArr1 = fullObject.map((item) => ({ id: item.id }));
      const err7768 = _isEmpty(newArr1);
      if (err7768) {
        yield put(
          actions.changeInput("FormPositionsCoachError.position_data", err7768)
        );
      } else {
        yield put(actions.removeField("FormPositionsCoachError.position_data"));
        if (
          FormPositionCoachIsValidVariable === state.reducer["TrueCheckbox"]
        ) {
          payload = {};
          payload["body_variables"] = {};
          payload.body_variables["email"] =
            state.reducer["FormPositionsCoach"]["email"];
          payload.body_variables["user_type"] =
            state.reducer["PositionsCoachUserType"];
          payload.body_variables["name"] =
            state.reducer["FormPositionsCoach"]["name"];
          payload.body_variables["school_id"] =
            state.reducer["CurrentUser"]["school_id"];
          payload.body_variables["active"] = Boolean(
            state.reducer["FormPositionsCoach"]["active"]
          );
          payload.body_variables["position_id"] = newArr1;
          // state.reducer["FormPositionsCoach"]["position name"];
          payload.body_variables["category"] =
            state.reducer["FormPositionsCoach"]["category"];
          const response9452 = yield call(
            serverApi.CreateNewPositionCoachEndpoint,
            payload
          );

          const CreateNewPositionCoachResponseCodeVariable =
            response9452.status;
          yield put(
            actions.logEvent({
              id: 9452,
              options: {
                body_variables: {
                  "a11e54b0-d778-4436-8bc6-4bab448e4b2e.4553":
                    state.reducer["FormPositionsCoach"]["email"],
                  "a11e54b0-d778-4436-8bc6-4bab448e4b2e.4556":
                    state.reducer["PositionsCoachUserType"],
                  "a11e54b0-d778-4436-8bc6-4bab448e4b2e.4557":
                    state.reducer["FormPositionsCoach"]["name"],
                  "a11e54b0-d778-4436-8bc6-4bab448e4b2e.4558":
                    state.reducer["CurrentUser"]["school_id"],
                  "a11e54b0-d778-4436-8bc6-4bab448e4b2e.4561":
                    state.reducer["FormPositionsCoach"]["active"],
                  "a11e54b0-d778-4436-8bc6-4bab448e4b2e.4565":
                    state.reducer["FormPositionsCoach"]["position id"],
                  "a11e54b0-d778-4436-8bc6-4bab448e4b2e.8209":
                    state.reducer["FormPositionsCoach"]["category"],
                },
                response_code_as: CreateNewPositionCoachResponseCodeVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          if (
            CreateNewPositionCoachResponseCodeVariable ===
            state.reducer["TwoHundred"]
          ) {
            yield put(
              actions.logEvent({
                id: 15839,
                options: {},
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.runAction("GET_POSITION_COACHES", { inputVariables: {} })
            );

            yield put(
              actions.logEvent({
                id: 9454,
                options: {},
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.runAction("GET_CURRENT_USER", { inputVariables: {} })
            );

            yield put(
              actions.logEvent({
                id: 9455,
                options: {},
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.runAction("GET_POSITION_COACHES", { inputVariables: {} })
            );

            yield put(
              actions.logEvent({
                id: 9456,
                options: {
                  field_key: "NewPositionCoach",
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(actions.removeField("NewPositionCoach"));
            yield put(actions.changeInput("FormPositionsCoach", undefined));
          }
          if (
            CreateNewPositionCoachResponseCodeVariable !==
            state.reducer["TwoHundred"]
          ) {
            yield put(
              actions.logEvent({
                id: 9458,
                options: {
                  field_key: "FormPositionsCoachError.email",
                  field_value: DuplicateCoachEmailErrorVariable,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.changeInput(
                "FormPositionsCoachError.email",
                DuplicateCoachEmailErrorVariable
              )
            );
          }
        }
      }
      // err7633 = yield call(
      //   _getValidationErrors,
      //   Yup.number().integer().required("Position is required"),
      //   state.reducer["FormPositionsCoach"]["position name"]
      // );
      // FormPositionCoachIsValidVariable = !FormPositionCoachIsValidVariable
      //   ? false
      //   : err7633.name !== "ValidationError";
      // if (err7633.name === "ValidationError") {
      //   console.warn(err7633.errors);
      //   yield put(
      //     actions.changeInput(
      //       "FormPositionsCoachError.position name",
      //       err7633.errors
      //     )
      //   );
      // } else {
      //   yield put(actions.removeField("FormPositionsCoachError"));
      // }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_NEW_ROW() {
  while (true) {
    const { inputVariables } = yield take(actions.CREATE_NEW_ROW);
    const { row_deletable } = inputVariables;
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var schema4525;
      var err4525;
      var CreateDepthChartRowIsValidVariable = true;
      if (
        CreateDepthChartRowIsValidVariable === state.reducer["TrueCheckbox"]
      ) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["depth_chart_id"] =
          state.reducer["CurrentDepthChart"]["Id"];
        payload.body_variables["row_deletable"] = row_deletable;
        payload.body_variables["position_id"] =
          state.reducer["FormNewDepthChartRow"]["position id"];
        const response4527 = yield call(
          serverApi.CreateDepthChartRowEndpoint,
          payload
        );
        const CreateDepthChartRowResponseVariable = response4527.data;
        const CreateDepthChartRowResponseCodeVariable = response4527.status;
        yield put(
          actions.logEvent({
            id: 4527,
            options: {
              body_variables: {
                "bfd4f2bf-37b2-43c0-90c4-33e0fb7405c0.5170":
                  state.reducer["CurrentDepthChart"]["Id"],
                "bfd4f2bf-37b2-43c0-90c4-33e0fb7405c0.5171":
                  state.reducer["FormNewDepthChartRow"]["position id"],
              },
              response_as: CreateDepthChartRowResponseVariable,
              response_code_as: CreateDepthChartRowResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          CreateDepthChartRowResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 4529,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_DEPTH_CHART_USERS", { inputVariables: {} })
          );

          yield put(
            actions.logEvent({
              id: 4530,
              options: {
                field_key: "NewRow",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("NewRow"));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_PLAY_DEPTH_CHART_ROW() {
  while (true) {
    let { inputVariables } = yield take(actions.CREATE_PLAY_DEPTH_CHART_ROW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputDepthChartIdVariable = inputVariables["input depth chart id"];

      let InputPlayVariable = inputVariables["input play"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["depth_chart_id"] = InputDepthChartIdVariable;
      payload["body_variables"] = {};
      payload.body_variables["play_id"] = InputPlayVariable;
      payload.body_variables["production_point"] = state.reducer["TD"];
      payload.body_variables["notes"] = state.reducer["Emptystring"];
      payload.body_variables["game_id"] = state.reducer["CurrentGame"]["Id"];
      const response11954 = yield call(
        serverApi.CreatePlayDepthChartRowEndpoint,
        payload
      );
      const CreatePlayDepthChartRowResponseVariable = response11954.data;
      const CreatePlayDepthChartRowResponseCodeVariable = response11954.status;
      yield put(
        actions.logEvent({
          id: 11954,
          options: {
            body_variables: {
              "e845ef8a-67c1-42aa-8b4a-2ef5cef4a2d1.12651": InputPlayVariable,
              "e845ef8a-67c1-42aa-8b4a-2ef5cef4a2d1.12656": state.reducer["TD"],
              "e845ef8a-67c1-42aa-8b4a-2ef5cef4a2d1.12659":
                state.reducer["Emptystring"],
              "e845ef8a-67c1-42aa-8b4a-2ef5cef4a2d1.13167":
                state.reducer["CurrentGame"]["Id"],
            },
            query_variables: {
              "5b5863ca-b531-44c2-b7f6-95b9b27e5fd0": InputDepthChartIdVariable,
            },
            response_as: CreatePlayDepthChartRowResponseVariable,
            response_code_as: CreatePlayDepthChartRowResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        CreatePlayDepthChartRowResponseCodeVariable ===
        state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 11956,
            options: {
              parameter_mapping: {
                "e556c934-6791-465b-99d5-4ccad85c63f3":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        // yield put(
        //   actions.runAction("GET_PLAY_BY_PLAY_INFO", {
        //     inputVariables: { gameid: state.reducer["CurrentGame"]["Id"] },
        //   })
        // );
      }
      // window.RefreshPage();
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_POSITION() {
  while (true) {
    yield take(actions.CREATE_POSITION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var schema3529;
      var err3529;
      var FormPositionIsValidVariable = true;
      err3529 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a name"),
        state.reducer["FormPosition"]["name"]
      );
      FormPositionIsValidVariable = !FormPositionIsValidVariable
        ? false
        : err3529.name !== "ValidationError";
      if (err3529.name === "ValidationError") {
        console.warn(err3529.errors);
        yield put(
          actions.changeInput("FormPositionErrors.name", err3529.errors)
        );
      } else {
        yield put(actions.removeField("FormPositionErrors.name"));
      }
      err3529 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a rank"),
        state.reducer["FormPosition"]["rank"]
      );
      FormPositionIsValidVariable = !FormPositionIsValidVariable
        ? false
        : err3529.name !== "ValidationError";
      if (err3529.name === "ValidationError") {
        console.warn(err3529.errors);
        yield put(
          actions.changeInput("FormPositionErrors.rank", err3529.errors)
        );
      } else {
        yield put(actions.removeField("FormPositionErrors.rank"));
      }
      err3529 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a abbreviation"),
        state.reducer["FormPosition"]["abbreviation"]
      );
      FormPositionIsValidVariable = !FormPositionIsValidVariable
        ? false
        : err3529.name !== "ValidationError";
      if (err3529.name === "ValidationError") {
        console.warn(err3529.errors);
        yield put(
          actions.changeInput("FormPositionErrors.abbreviation", err3529.errors)
        );
      } else {
        yield put(actions.removeField("FormPositionErrors.abbreviation"));
      }
      if (FormPositionIsValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["name"] = state.reducer["FormPosition"]["name"];
        payload.body_variables["abbreviation"] =
          state.reducer["FormPosition"]["abbreviation"];
        payload.body_variables["category"] =
          state.reducer["FormPosition"]["category"];
        payload.body_variables["rank"] = state.reducer["FormPosition"]["rank"];
        const response3531 = yield call(
          serverApi.CreatePositionEndpoint,
          payload
        );
        const CreatePositionResponseVariable = response3531.data;
        const CreatePositionResponseCodeVariable = response3531.status;
        yield put(
          actions.logEvent({
            id: 3531,
            options: {
              body_variables: {
                "07d37b8a-f603-4ded-af70-ee7e7074993e.4570":
                  state.reducer["FormPosition"]["name"],
                "07d37b8a-f603-4ded-af70-ee7e7074993e.4571":
                  state.reducer["FormPosition"]["abbreviation"],
                "07d37b8a-f603-4ded-af70-ee7e7074993e.4572":
                  state.reducer["FormPosition"]["category"],
                "07d37b8a-f603-4ded-af70-ee7e7074993e.4573":
                  state.reducer["FormPosition"]["rank"],
              },
              response_as: CreatePositionResponseVariable,
              response_code_as: CreatePositionResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          CreatePositionResponseCodeVariable === state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 3533,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.runAction("GET_POSITIONS", { inputVariables: {} }));

          yield put(
            actions.logEvent({
              id: 3534,
              options: {
                field_key: "NewPosition",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("NewPosition"));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_QUIZ() {
  while (true) {
    yield take(actions.CREATE_QUIZ);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["body_variables"] = {};
      payload.body_variables["category"] =
        state.reducer["FormNewQuiz"]["category"];
      payload.body_variables["expiration"] =
        state.reducer["FormNewQuiz"]["expiration"];
      const response3255 = yield call(serverApi.CreateQuizEndpoint, payload);
      const CreateQuizResponseVariable = response3255.data;
      const CreateQuizResponseCodeVariable = response3255.status;
      yield put(
        actions.logEvent({
          id: 3255,
          options: {
            body_variables: {
              "fdb800a0-ecc9-4d71-9fec-219337de80c6.4631":
                state.reducer["FormNewQuiz"]["category"],
              "fdb800a0-ecc9-4d71-9fec-219337de80c6.4636":
                state.reducer["FormNewQuiz"]["expiration"],
            },
            response_as: CreateQuizResponseVariable,
            response_code_as: CreateQuizResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (CreateQuizResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3258,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.runAction("GET_QUIZZES", { inputVariables: {} }));

        yield put(
          actions.logEvent({
            id: 3325,
            options: {
              field_key: "NewQuiz",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("NewQuiz"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_QUIZ_QUESTION() {
  while (true) {
    yield take(actions.CREATE_QUIZ_QUESTION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["body_variables"] = {};
      payload.body_variables["quiz id"] = state.reducer["EditQuiz"]["Id"];
      payload.body_variables["question"] =
        state.reducer["FormNewQuizQuestion"]["question"];
      const response3264 = yield call(
        serverApi.CreateQuizQuestionEndpoint,
        payload
      );
      const CreateOneResponseVariable = response3264.data;
      const CreateOneResponseCodeVariable = response3264.status;
      yield put(
        actions.logEvent({
          id: 3264,
          options: {
            body_variables: {
              "855555a6-095b-4e93-b26e-63c08606f772.4634":
                state.reducer["EditQuiz"]["Id"],
              "855555a6-095b-4e93-b26e-63c08606f772.4635":
                state.reducer["FormNewQuizQuestion"]["question"],
            },
            response_as: CreateOneResponseVariable,
            response_code_as: CreateOneResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (CreateOneResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3266,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_QUIZ_QUESTIONS", { inputVariables: {} })
        );

        yield put(
          actions.logEvent({
            id: 3326,
            options: {
              field_key: "NewQuizQuestion",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("NewQuizQuestion"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_QUIZ_RESPONSE() {
  while (true) {
    yield take(actions.CREATE_QUIZ_RESPONSE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["body_variables"] = {};
      payload.body_variables["question responses"] =
        state.reducer["FormQuizResponse"];
      payload.body_variables["comment"] =
        state.reducer["FormQuizResponse"]["comment"];
      payload.body_variables["quiz id"] =
        state.reducer["FormQuizResponse"]["quiz id"];
      const response3274 = yield call(
        serverApi.CreateQuizResponseEndpoint,
        payload
      );

      const CreateQuizResponseResponseCodeVariable = response3274.status;
      yield put(
        actions.logEvent({
          id: 3274,
          options: {
            body_variables: {
              "36e8a5fb-b294-425c-9fed-a12c5f7f8444.5640":
                state.reducer["FormQuizResponse"],
              "36e8a5fb-b294-425c-9fed-a12c5f7f8444.5641":
                state.reducer["FormQuizResponse"]["comment"],
              "36e8a5fb-b294-425c-9fed-a12c5f7f8444.5642":
                state.reducer["FormQuizResponse"]["quiz id"],
            },
            response_code_as: CreateQuizResponseResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        CreateQuizResponseResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* CREATE_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.CREATE_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var DuplicateEmailErrorVariable = "Email already being used";
      var schema3422;
      var FormSignUpValidVariable = true;
      var error;
      error = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a name"),
        state.reducer["FormSignup"]["name"]
      );
      FormSignUpValidVariable = !FormSignUpValidVariable
        ? false
        : error.name !== "ValidationError";
      if (error.name === "ValidationError") {
        console.warn(error.errors);
        yield put(actions.changeInput("FormSignupErrors.name", error.errors));
      } else {
        yield put(actions.removeField("FormSignupErrors.name"));
      }
      error = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a school name"),
        state.reducer["FormSignup"]["school"]
      );
      FormSignUpValidVariable = !FormSignUpValidVariable
        ? false
        : error.name !== "ValidationError";
      if (error.name === "ValidationError") {
        console.warn(error.errors);
        yield put(actions.changeInput("FormSignupErrors.school", error.errors));
      } else {
        yield put(actions.removeField("FormSignupErrors.school"));
      }
      error = yield call(
        _getValidationErrors,
        Yup.string()
          .email("Please enter a valid email")
          .required("Please enter a valid email"),
        state.reducer["FormSignup"]["email"]
      );
      FormSignUpValidVariable = !FormSignUpValidVariable
        ? false
        : error.name !== "ValidationError";
      if (error.name === "ValidationError") {
        console.warn(error.errors);
        yield put(actions.changeInput("FormSignupErrors.email", error.errors));
      } else {
        yield put(actions.removeField("FormSignupErrors.email"));
      }

      error = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a password"),
        state.reducer["FormSignup"]["password"]
      );
      FormSignUpValidVariable = !FormSignUpValidVariable
        ? false
        : error.name !== "ValidationError";
      if (error.name === "ValidationError") {
        console.warn(error.errors);
        yield put(
          actions.changeInput("FormSignupErrors.password", error.errors)
        );
      } else {
        yield put(actions.removeField("FormSignupErrors.password"));
      }
      error = yield call(
        _getValidationErrors,
        Yup.string().test("Compare", "Passwords don't match", (value) =>
          window.Compare({
            value,
            left: state.reducer["FormSignup"]["password"],
            right: state.reducer["FormSignup"]["confirm_password"],
          })
        ),
        state.reducer["FormSignup"]["confirm_password"]
      );
      FormSignUpValidVariable = !FormSignUpValidVariable
        ? false
        : error.name !== "ValidationError";
      if (error.name === "ValidationError") {
        console.warn(error.errors);
        yield put(
          actions.changeInput("FormSignupErrors.confirm_password", error.errors)
        );
      } else {
        yield put(actions.removeField("FormSignupErrors.confirm_password"));
      }

      error = yield call(
        _getValidationErrors,
        Yup.boolean()
          .oneOf([true], "Please accept our terms and conditions")
          .required("Please accept our terms and conditions"),
        state.reducer["FormSignup"]["terms_and_conditions"]
      );
      FormSignUpValidVariable = !FormSignUpValidVariable
        ? false
        : error.name !== "ValidationError";
      if (error.name === "ValidationError") {
        console.warn(error.errors);
        yield put(
          actions.changeInput(
            "FormSignupErrors.terms_and_conditions",
            error.errors
          )
        );
      } else {
        yield put(actions.removeField("FormSignupErrors.terms_and_conditions"));
      }

      if (FormSignUpValidVariable === state.reducer["TrueCheckbox"]) {
        let formData = new FormData();
        formData.append("email", state.reducer["FormSignup"]["email"]);
        formData.append("password", state.reducer["FormSignup"]["password"]);
        formData.append("user_type", state.reducer["Coach"]);
        formData.append("name", state.reducer["FormSignup"]["name"]);
        formData.append("school_name", state.reducer["FormSignup"]["school"]);
        formData.append(
          "uploadfile",
          inputVariables?.["image"] ? inputVariables?.["image"] : ""
        );

        payload = {};
        payload["body_variables"] = {};
        payload.body_variables = formData;

        const response = yield call(serverApi.SignUpEndpoint, payload);
        const SignUpResponse = response.data;
        const SignUpResponseStatus = response.status;

        if (SignUpResponseStatus === state.reducer["TwoHundred"]) {
          if (response?.isSuccess) {
            localStorage.setItem("token", SignUpResponse?.token);
          }
          yield put(push("/roster"));
          yield put(
            actions.runAction("GET_CURRENT_USER", { inputVariables: {} })
          );
        } else {
          if (SignUpResponse?.message === "School already exists!") {
            yield put(
              actions.logEvent({
                id: 9475,
                options: {
                  field_key: "DuplicateSchool",
                  field_value: SignUpResponse?.message,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.changeInput("DuplicateSchool", SignUpResponse?.message)
            );
            yield put(actions.changeInput("FormSignupErrors.email", ""));
          } else {
            yield put(
              actions.logEvent({
                id: 9473,
                options: {
                  field_key: "FormSignupErrors.email",
                  field_value: SignUpResponse?.message,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.changeInput(
                "FormSignupErrors.email",
                SignUpResponse?.message
              )
            );
            yield put(actions.changeInput("DuplicateSchool", ""));
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_USER_EXERCISE() {
  while (true) {
    let { inputVariables } = yield take(actions.CREATE_USER_EXERCISE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DateStringVariable;
      try {
        DateStringVariable = window.FormatDate({
          datestring: state.reducer["FormCreateUserExercise"]["date"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 4391,
          options: {
            parameter_mapping: {
              "1eb12004-d380-4086-9731-feecf4591dc6":
                state.reducer["FormCreateUserExercise"]["date"],
            },
            return_as: DateStringVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      var err;
      var FormCreateUserExerciseIsValidVariable = true;
      err = yield call(
        _getValidationErrors,
        Yup.number().integer().min(1, "Please select an exercise"),
        state.reducer["FormCreateUserExercise"]["exercise id"]
      );
      FormCreateUserExerciseIsValidVariable =
        !FormCreateUserExerciseIsValidVariable
          ? false
          : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput(
            "FormCreateUserExerciseErrors.exercise id",
            err.errors
          )
        );
      } else {
        yield put(
          actions.removeField("FormCreateUserExerciseErrors.exercise id")
        );
      }
      err = yield call(
        _getValidationErrors,
        Yup.number().min(0, "Please enter a positive value"),
        state.reducer["FormCreateUserExercise"]["integer value"]
      );
      FormCreateUserExerciseIsValidVariable =
        !FormCreateUserExerciseIsValidVariable
          ? false
          : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput(
            "FormCreateUserExerciseErrors.integer value",
            err.errors
          )
        );
      } else {
        yield put(
          actions.removeField("FormCreateUserExerciseErrors.integer value")
        );
      }
      if (
        FormCreateUserExerciseIsValidVariable === state.reducer["TrueCheckbox"]
      ) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["exercise_id"] =
          state.reducer["FormCreateUserExercise"]["exercise id"];
        payload.body_variables["integer_value"] =
          state.reducer["FormCreateUserExercise"]["integer value"];
        payload.body_variables["date"] = DateStringVariable;
        const response = yield call(
          serverApi.CreateUserExerciseEndpoint,
          payload
        );
        const CreateUserExerciseResponse = response.data;
        const CreateUserExerciseResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 3911,
            options: {
              body_variables: {
                "8daa28dc-a727-40b4-bac0-89b44ebf0c63.4885":
                  state.reducer["FormCreateUserExercise"]["exercise id"],
                "8daa28dc-a727-40b4-bac0-89b44ebf0c63.4887":
                  state.reducer["FormCreateUserExercise"]["integer value"],
                "8daa28dc-a727-40b4-bac0-89b44ebf0c63.4888": DateStringVariable,
              },
              response_as: CreateUserExerciseResponse,
              response_code_as: CreateUserExerciseResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (CreateUserExerciseResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3913,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_USER_EXERCISES", { inputVariables: {} })
          );

          yield put(
            actions.logEvent({
              id: 3914,
              options: {
                field_key: "NewUserExercise",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("NewUserExercise"));

          yield put(
            actions.logEvent({
              id: 4582,
              options: {
                parameter_mapping: {
                  "8caa3ef6-671b-4bb5-a15c-a0fb85f9d244":
                    state.reducer["CurrentUser"]["id"],
                },
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_EXERCISES_GRAPH_USER", {
              inputVariables: {
                "userid input": state.reducer["CurrentUser"]["id"],
              },
            })
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CREATE_USER_GRADE() {
  while (true) {
    let { inputVariables } = yield take(actions.CREATE_USER_GRADE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputDepthChartidVariable = inputVariables["input depth chartid"];

      let InputIntangibleidVariable = inputVariables["input intangibleid"];

      let InputUseridVariable = inputVariables["input userid"];

      let RowId = inputVariables?.["row data"]?.id;

      var ValuenullVariable = "NULL";

      let GetSeasonYearResponse;
      if (state.reducer["currentuserintegervalue"] === ValuenullVariable) {
        if (state.reducer?.selectedSeasonYear === "") {
          const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
          GetSeasonYearResponse = response.data;
        }
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["row_id"] = state.reducer["CurrentPlayId"];
        payload.query_variables["intangible_id"] = InputIntangibleidVariable;
        payload.query_variables["user_id"] = InputUseridVariable;
        payload.query_variables["year"] =
          state.reducer?.selectedSeasonYear === ""
            ? GetSeasonYearResponse?.[0]?.year
            : state.reducer?.selectedSeasonYear;
        const response = yield call(serverApi.DeleteUserGradeEndpoint, payload);

        const DeleteUserGradeResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 13153,
            options: {
              query_variables: {
                "958bef5f-8d0d-43e9-a46f-eb9a0d2d975d":
                  state.reducer["CurrentPlayId"],
                "ca501d0f-7145-44a3-8bad-f94c65068a7a":
                  InputIntangibleidVariable,
                "f097fb7f-d057-402a-b7bf-437ac7ff26c3": InputUseridVariable,
              },
              response_code_as: DeleteUserGradeResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (DeleteUserGradeResponseStatus === state.reducer["TwoHundred"]) {
        }
      }
      if (state.reducer["currentuserintegervalue"] !== ValuenullVariable) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["year"] = state.page?.year
          ? state.page?.year
          : state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
        payload["body_variables"] = {};
        payload.body_variables["Id"] = InputDepthChartidVariable;
        payload.body_variables["row_id"] = state.reducer["CurrentPlayId"];
        payload.body_variables["user_id"] = InputUseridVariable;
        payload.body_variables["intangible_id"] = InputIntangibleidVariable;
        payload.body_variables["depth_chart_id"] =
          state.reducer["CurrentPackage"];
        payload.body_variables["old_integer_value"] =
          state.reducer["demoGrade"];
        payload.body_variables["game_id"] = state.reducer["CurrentGame"]["Id"];
        payload.body_variables["integer_value"] =
          state.reducer["currentuserintegervalue"];
        const response = yield call(
          serverApi.CreateUserGradesEndpoint,
          payload
        );

        const CreateUserGradesResponseStatus = response.status;
        if (CreateUserGradesResponseStatus !== state.reducer["TwoHundred"]) {
          yield put(
            actions.changeInput("userGradingError", response?.data?.message)
          );
        } else {
          let newValue = updateValueById(
            state.reducer?.UserGrades,
            RowId,
            InputIntangibleidVariable,
            state.reducer["currentuserintegervalue"],
            state.reducer["CurrentPlayId"],
            state.reducer?.Intangibles
          );
          yield put(actions.changeInput("UserGrades", newValue));
          yield put(actions.changeInput("userGradingError", ""));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_DEPTH_CHART() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DepthChartIdToDeleteVariable =
        inputVariables["depth chart id to delete"];
      let DeleteDepthChartVarible = inputVariables["Depth Chart Id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = DepthChartIdToDeleteVariable;
      const response = yield call(serverApi.DeleteDepthChartEndpoint, payload);

      const DeleteDepthChartResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3473,
          options: {
            query_variables: {
              "17832508-9e4b-419e-8eb5-b336a81474b5":
                DepthChartIdToDeleteVariable,
            },
            response_code_as: DeleteDepthChartResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (DeleteDepthChartResponseStatus === state.reducer["TwoHundred"]) {
        yield put(push(`${state.reducer["PageDepthChartsList"]}`));

        yield put(
          actions.logEvent({
            id: 3476,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_GROUPED_CHARTS", { inputVariables: {} })
        );

        yield put(
          actions.logEvent({
            id: 3477,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_BASE_DEPTH_CHARTS", { inputVariables: {} })
        );
        yield put(
          actions.logEvent({
            id: 12256,
            options: {
              field_key: "DepthChartToDelete",
              field_value: DeleteDepthChartVarible,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("DepthChartToDelete", DeleteDepthChartVarible)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_DEPTH_CHART_ROW() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_DEPTH_CHART_ROW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DepthChartRowIdVariable = inputVariables["Depth Chart row id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = DepthChartRowIdVariable;
      const response = yield call(
        serverApi.DeleteDepthChartRowEndpoint,
        payload
      );

      const DeleteDepthChartRowResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 4531,
          options: {
            query_variables: {
              "38312c1c-360b-4b7e-851f-055308ab0959": DepthChartRowIdVariable,
            },
            response_code_as: DeleteDepthChartRowResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (DeleteDepthChartRowResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 8226,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_DEPTH_CHART_USERS", { inputVariables: {} })
        );

        yield put(
          actions.logEvent({
            id: 8230,
            options: {
              parameter_mapping: {
                "14d1545a-2298-41f1-a017-adbc52169c60":
                  state.reducer["FalseCheckbox"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("SET_DELETE_DEPTH_CHART_ROW", {
            inputVariables: {
              "Delete Depth Chart Row Id": state.reducer["FalseCheckbox"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_DEPTH_CHART_ROW_USER() {
  while (true) {
    yield take(actions.DELETE_DEPTH_CHART_ROW_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] =
        state.reducer["DepthChartPositionUsers"]["row_id"];
      const response8231 = yield call(
        serverApi.DeleteDepthChartRowEndpoint,
        payload
      );

      const DeleteDepthChartRowResponseCodeVariable = response8231.status;
      yield put(
        actions.logEvent({
          id: 8231,
          options: {
            query_variables: {
              "38312c1c-360b-4b7e-851f-055308ab0959":
                state.reducer["DepthChartPositionUsers"]["row_id"],
            },
            response_code_as: DeleteDepthChartRowResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        DeleteDepthChartRowResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 8233,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_DEPTH_CHART_POSITION_USERS", {
            inputVariables: {},
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_DEPTH_CHART_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_DEPTH_CHART_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputDepthChartUserIdVariable =
        inputVariables["input depth chart user id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = InputDepthChartUserIdVariable;
      const response = yield call(
        serverApi.DeleteDepthChartUserEndpoint,
        payload
      );

      const DeleteDepthChartUserResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 4491,
          options: {
            query_variables: {
              "6c3dc1e2-4863-44d8-a55f-406ccddd1f7f":
                InputDepthChartUserIdVariable,
            },
            response_code_as: DeleteDepthChartUserResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (DeleteDepthChartUserResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 4493,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_DEPTH_CHART_USERS", { inputVariables: {} })
        );

        yield put(
          actions.logEvent({
            id: 6923,
            options: {
              field_key: "CurrentDepthChartPosition",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("CurrentDepthChartPosition"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_DEPTH_CHARTS_USERS() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_DEPTH_CHARTS_USERS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DepthchartuidVariable = inputVariables["depthchartuid"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["depthchartuid"] = DepthchartuidVariable;
      const response9163 = yield call(
        serverApi.DeleteDepthChartUsersEndpoint,
        payload
      );

      const DeleteDepthChartUserCodeVariable = response9163.status;
      yield put(
        actions.logEvent({
          id: 9163,
          options: {
            query_variables: {
              "6759aae8-6a6a-4975-a5bb-13b86e9b4e9c": DepthchartuidVariable,
            },
            response_code_as: DeleteDepthChartUserCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_EXERCISE() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_EXERCISE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputDeleteExerciseIdVariable =
        inputVariables["input delete exercise id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = InputDeleteExerciseIdVariable;
      const response = yield call(serverApi.DeleteExerciseEndpoint, payload);

      const DeleteExerciseResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3235,
          options: {
            query_variables: {
              "29a232e2-7eac-4144-8bf6-9de10548d666":
                InputDeleteExerciseIdVariable,
            },
            response_code_as: DeleteExerciseResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (DeleteExerciseResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 13854,
            options: {
              field_key: "ExerciseToDelete",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("ExerciseToDelete"));

        yield put(
          actions.logEvent({
            id: 3243,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.runAction("GET_EXERCISES", { inputVariables: {} }));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_GAME() {
  while (true) {
    yield take(actions.DELETE_GAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = state.reducer["GameToDelete"];
      const response = yield call(serverApi.DeleteGameEndpoint, payload);

      const DeleteGameResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 10904,
          options: {
            query_variables: {
              "2b6d850d-bb7f-4a01-aba9-35dc16307e23":
                state.reducer["GameToDelete"],
            },
            response_code_as: DeleteGameResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (DeleteGameResponseStatus === state.reducer["TwoHundred"]) {
        yield put(push(`${state.reducer["PageGameGrades"]}`));

        yield put(
          actions.logEvent({
            id: 11254,
            options: {
              parameter_mapping: {
                "1f627adc-2f7e-4b41-91c2-97ee1ab16025":
                  state.reducer["Emptystring"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("SET_EDIT_GAME", {
            inputVariables: { "Edit Game": state.reducer["Emptystring"] },
          })
        );
      }

      yield put(
        actions.logEvent({
          id: 12115,
          options: {
            field_key: "ToggleGameDelete",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ToggleGameDelete", false));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_GAME_FILE() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_GAME_FILE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputGameFileIdVariable = inputVariables["input game file id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = InputGameFileIdVariable;
      const response = yield call(serverApi.DeleteGameFileEndpoint, payload);

      const DeleteGameFileResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3551,
          options: {
            query_variables: {
              "4ff8d649-7437-48f9-80c3-05390156c200": InputGameFileIdVariable,
            },
            response_code_as: DeleteGameFileResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (DeleteGameFileResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 13853,
            options: {
              field_key: "FileToDelete",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("FileToDelete"));

        yield put(
          actions.logEvent({
            id: 3553,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.runAction("GET_GAME_FILES", { inputVariables: {} }));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_INJURED_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_INJURED_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputDeleteInjuredPlayerIdVariable =
        inputVariables["input delete injured player id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = InputDeleteInjuredPlayerIdVariable;
      const response3217 = yield call(
        serverApi.DeleteInjuredPlayerEndpoint,
        payload
      );

      const DeleteInjuredPlayerResponseCodeVariable = response3217.status;
      yield put(
        actions.logEvent({
          id: 3217,
          options: {
            query_variables: {
              "8e59206e-d484-43e2-9c85-9a29cdaa84bf":
                InputDeleteInjuredPlayerIdVariable,
            },
            response_code_as: DeleteInjuredPlayerResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        DeleteInjuredPlayerResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 3220,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_INJURED_PLAYERS", { inputVariables: {} })
        );

        yield put(
          actions.logEvent({
            id: 3522,
            options: {
              parameter_mapping: {
                "07d7f5ae-8efc-46f6-b7eb-f8207ed35f1b": state.reducer["Zero"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_USERS", {
            inputVariables: {},
          })
        );

        yield put(
          actions.logEvent({
            id: 14664,
            options: {
              field_key: "InjuredPlayerToDelete",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("InjuredPlayerToDelete"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_INTANGIBLE() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_INTANGIBLE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputDeleteIntangibleIdVariable =
        inputVariables["input delete intangible id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = InputDeleteIntangibleIdVariable;
      const response = yield call(serverApi.DeleteIntangibleEndpoint, payload);

      const DeleteIntangibleResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3226,
          options: {
            query_variables: {
              "45f75cbb-2f47-40c9-bcf8-b94d6bef50e6":
                InputDeleteIntangibleIdVariable,
            },
            response_code_as: DeleteIntangibleResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (DeleteIntangibleResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 13852,
            options: {
              field_key: "IntangibleToDelete",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("IntangibleToDelete"));

        yield put(
          actions.logEvent({
            id: 3230,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.runAction("GET_INTANGIBLES", { inputVariables: {} }));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_PLAY_ROW() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_PLAY_ROW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputPlayRowIdVariable = inputVariables["input play row id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = InputPlayRowIdVariable;
      payload.query_variables["play_id"] = state.page?.playgradeid;
      const response = yield call(serverApi.DeletePlayRowEndpoint, payload);

      const DeletePlayRowResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 11649,
          options: {
            query_variables: {
              "f3d42e3c-bb91-411a-80be-4acf61c8f5d8": InputPlayRowIdVariable,
            },
            response_code_as: DeletePlayRowResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (DeletePlayRowResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 11751,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_AFTER_REFRESH", {
            inputVariables: {},
          })
        );

        yield put(
          actions.logEvent({
            id: 12272,
            options: {
              field_key: "PlayRowToDelete",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("PlayRowToDelete"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_POSITION() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_POSITION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputPositionIdVariable = inputVariables["input position id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = InputPositionIdVariable;
      const response3158 = yield call(
        serverApi.DeletePositionEndpoint,
        payload
      );

      const DeletePositionResponseCodeVariable = response3158.status;
      yield put(
        actions.logEvent({
          id: 3158,
          options: {
            query_variables: {
              "17aa3f02-f2f7-4a99-b383-288b1a048876": InputPositionIdVariable,
            },
            response_code_as: DeletePositionResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (DeletePositionResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3162,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.runAction("GET_POSITIONS", { inputVariables: {} }));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_POSITION_COACH() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_POSITION_COACH);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputDeletePositionCoachIdVariable =
        inputVariables["input delete position coach id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = InputDeletePositionCoachIdVariable;
      const response7515 = yield call(
        serverApi.DeletePositionCoachEndpoint,
        payload
      );

      const DeletePositionCoachResponseCodeVariable = response7515.status;
      yield put(
        actions.logEvent({
          id: 7515,
          options: {
            query_variables: {
              "20dfd56e-4b85-4dbe-a19d-2b18a6c48554":
                InputDeletePositionCoachIdVariable,
            },
            response_code_as: DeletePositionCoachResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        DeletePositionCoachResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 13959,
            options: {
              field_key: "CoachToDelete",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("CoachToDelete"));

        yield put(
          actions.logEvent({
            id: 7517,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_POSITION_COACHES", { inputVariables: {} })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_QUIZ() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_QUIZ);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputDeleteQuizIdVariable = inputVariables["input delete quiz id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = InputDeleteQuizIdVariable;
      const response3248 = yield call(serverApi.DeleteQuizEndpoint, payload);

      const DeleteQuizResponseCodeVariable = response3248.status;
      yield put(
        actions.logEvent({
          id: 3248,
          options: {
            query_variables: {
              "473f3787-b8fc-4977-aaf0-65550b6a8fc1": InputDeleteQuizIdVariable,
            },
            response_code_as: DeleteQuizResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (DeleteQuizResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3259,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.runAction("GET_QUIZZES", { inputVariables: {} }));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_QUIZ_QUESTION() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_QUIZ_QUESTION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IdVariable = inputVariables["id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = IdVariable;
      const response3267 = yield call(
        serverApi.DeleteQuizQuestionEndpoint,
        payload
      );

      const DeleteQuizQuestionResponseCodeVariable = response3267.status;
      yield put(
        actions.logEvent({
          id: 3267,
          options: {
            query_variables: {
              "4de37f62-6793-4a1b-811f-0e9900d68e7f": IdVariable,
            },
            response_code_as: DeleteQuizQuestionResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        DeleteQuizQuestionResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 3269,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_QUIZ_QUESTIONS", { inputVariables: {} })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_TAG() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_TAG);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputDeleteTagIdVariable = inputVariables["input delete tag id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = InputDeleteTagIdVariable;
      const response = yield call(serverApi.DeleteTagEndpoint, payload);

      const DeleteTagResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 12273,
          options: {
            query_variables: {
              "dee531b8-61f0-4766-be73-1723046c9d5b": InputDeleteTagIdVariable,
            },
            response_code_as: DeleteTagResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (DeleteTagResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 12275,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.runAction("GET_TAGS", { inputVariables: {} }));

        yield put(
          actions.logEvent({
            id: 12277,
            options: {
              field_key: "TagToDelete",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("TagToDelete"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("isLoaded", true));
      let InputDeleteUserVariable = inputVariables["input delete user"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = InputDeleteUserVariable;
      const response3110 = yield call(serverApi.DeleteUserEndpoint, payload);

      const DeleteUserResponseCodeVariable = response3110.status;
      yield put(
        actions.logEvent({
          id: 3110,
          options: {
            path_variables: {
              "4c415de8-d6f6-45c3-a288-a8798d78b851": InputDeleteUserVariable,
            },
            response_code_as: DeleteUserResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (state.reducer["TwoHundred"] === DeleteUserResponseCodeVariable) {
        yield put(
          actions.logEvent({
            id: 13538,
            options: {
              field_key: "UserToDelete",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("UserToDelete"));

        yield put(
          actions.logEvent({
            id: 3113,
            options: {
              parameter_mapping: {
                "07d7f5ae-8efc-46f6-b7eb-f8207ed35f1b": state.reducer["Zero"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.runAction("GET_USERS"));
      } else {
        yield put(actions.changeInput("isLoaded", false));
      }
    } catch (error) {
      yield put(actions.changeInput("isLoaded", false));
      console.warn(error);
    }
  }
}

function* DELETE_VIEW_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_VIEW_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var PageRedirectVariable = "/roster";

      let InputDeleteUserVariable = inputVariables["input delete user"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = InputDeleteUserVariable;
      const response = yield call(serverApi.DeleteUserEndpoint, payload);

      const DeleteUserResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3110,
          options: {
            path_variables: {
              "4c415de8-d6f6-45c3-a288-a8798d78b851": InputDeleteUserVariable,
            },
            response_code_as: DeleteUserResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (state.reducer["TwoHundred"] === DeleteUserResponseStatus) {
        yield put(
          actions.logEvent({
            id: 13538,
            options: {
              field_key: "UserToDelete",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("UserToDelete"));

        yield put(
          actions.logEvent({
            id: 3113,
            options: {
              parameter_mapping: {
                "07d7f5ae-8efc-46f6-b7eb-f8207ed35f1b": state.reducer["Zero"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.runAction("GET_USERS"));
        yield put(push(`${PageRedirectVariable}`));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* DELETE_USER_DEPTH_CHART() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_USER_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let UserDepthChartIdVariable = inputVariables["user depth chart id"];
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = UserDepthChartIdVariable;
      const response = yield call(
        serverApi.DeleteUserDepthChartEndpoint,
        payload
      );

      const DeleteUserDepthChartResponseStatus = response.status;
      if (DeleteUserDepthChartResponseStatus === state.reducer["TwoHundred"]) {
        yield put(actions.changeInput("toggleUserGradeLoader", true));
        yield put(actions.changeInput("ToggleDeletePlayDepthChartUser", false));
        yield put(actions.changeInput("toggleUserGradeLoader", true));
        yield put(
          actions.runAction("GET_ALL_PLAY_DEPTH_CHART_USERS", {
            inputVariables: {
              "input depth chart rowid": state.reducer["CurrentPackage"],
              "input play rowid": state.reducer["Playid"],
            },
          })
        );

        yield put(
          actions.logEvent({
            id: 11989,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_USER_GRADES", {
            inputVariables: {
              depthChartId: state.reducer["CurrentPackage"],
              playRowId: state.reducer["Playid"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_USER_EXERCISE() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_USER_EXERCISE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let UserExerciseIdVariable = inputVariables["user exercise id"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = UserExerciseIdVariable;
      const response = yield call(
        serverApi.DeleteUserExerciseEndpoint,
        payload
      );

      const DeleteUserExerciseResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3892,
          options: {
            path_variables: {
              "e995cfb4-0694-4cc1-93ad-bf5e3e942df9": UserExerciseIdVariable,
            },
            response_code_as: DeleteUserExerciseResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (DeleteUserExerciseResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3895,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_USER_EXERCISES", { inputVariables: {} })
        );

        yield put(
          actions.logEvent({
            id: 4584,
            options: {
              parameter_mapping: {
                "8caa3ef6-671b-4bb5-a15c-a0fb85f9d244":
                  state.reducer["CurrentUser"]["id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_EXERCISES_GRAPH_USER", {
            inputVariables: {
              "userid input": state.reducer["CurrentUser"]["id"],
            },
          })
        );

        yield put(
          actions.logEvent({
            id: 14663,
            options: {
              field_key: "PlayerExerciseToDelete",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("PlayerExerciseToDelete"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DEPTH_CHART_USERS_WORKFLOWS() {
  while (true) {
    let { inputVariables } = yield take(actions.DEPTH_CHART_USERS_WORKFLOWS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DepthchartusersidVariable = inputVariables["depthchartusersid"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = DepthchartusersidVariable;
      const response9061 = yield call(
        serverApi.GetDepthChartUsersEndpoint,
        payload
      );
      const GetDepthChartUsersResponseVariable = response9061.data;
      const GetDepthChartUsersResponseCodeVariable = response9061.status;
      yield put(
        actions.logEvent({
          id: 9061,
          options: {
            path_variables: {
              "6459786d-27dd-4738-9ada-7f7dc3a4a8be": DepthchartusersidVariable,
            },
            response_as: GetDepthChartUsersResponseVariable,
            response_code_as: GetDepthChartUsersResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetDepthChartUsersResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 9063,
            options: {
              field_key: "DepthChartUsers",
              field_value: GetDepthChartUsersResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "DepthChartUsers",
            GetDepthChartUsersResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DEPTH_CHART_WORKFLOW() {
  while (true) {
    let { inputVariables } = yield take(actions.DEPTH_CHART_WORKFLOW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let BasedepthchartidVariable = inputVariables["Basedepthchartid"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = BasedepthchartidVariable;
      const response = yield call(serverApi.GetDepthChartEndpoint, payload);
      const GetDepthChartResponse = response.data;
      const GetDepthChartResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 9058,
          options: {
            path_variables: {
              "b731f609-048e-4916-b7fc-418c0979b9e4": BasedepthchartidVariable,
            },
            response_as: GetDepthChartResponse,
            response_code_as: GetDepthChartResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetDepthChartResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 9060,
            options: {
              field_key: "CurrentDepthChart",
              field_value: GetDepthChartResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("CurrentDepthChart", GetDepthChartResponse)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* EDIT_COACH_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.EDIT_COACH_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var RosterPageOneVariable = "";
      var err4393;
      var FormEditCoachIsValidVariable = true;
      err4393 = yield call(
        _getValidationErrors,
        Yup.string().min(0, "Please enter a name"),
        state.reducer["FormEditCoach"]["name"]
      );
      FormEditCoachIsValidVariable = !FormEditCoachIsValidVariable
        ? false
        : err4393.name !== "ValidationError";
      if (err4393.name === "ValidationError") {
        console.warn(err4393.errors);
        yield put(
          actions.changeInput("FormEditCoachErrors.name", err4393.errors)
        );
      } else {
        yield put(actions.removeField("FormEditCoachErrors.name"));
      }
      if (FormEditCoachIsValidVariable === state.reducer["TrueCheckbox"]) {
        yield put(
          actions.changeInput("editCoachLoader", true)
        );
        let formData = new FormData();
        formData.append("email", state.reducer["FormEditCoach"]["email"]);
        formData.append("name", state.reducer["FormEditCoach"]["name"]);
        if (inputVariables?.["image"] !== "") {
          formData.append(
            "uploadfile",
            inputVariables?.["image"] ? inputVariables?.["image"] : ""
          );
        }
        if(inputVariables?.['schoolLogoImage']){
          formData.append('school_logo', inputVariables?.['schoolLogoImage']?inputVariables?.['schoolLogoImage'] :'' )
        }
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables = formData;

        const response4395 = yield call(serverApi.EditCoachEndpoint, payload);

        const EditCoachResponseCodeVariable = response4395.status;

        if (EditCoachResponseCodeVariable === state.reducer["TwoHundred"]) {
          yield put(
            actions.runAction("GET_CURRENT_USER", { inputVariables: {} })
          );
          yield put(
            actions.changeInput("editCoachLoader", false)
          );
          yield put(push(`${RosterPageOneVariable}`));
        } else {
          yield put(
            actions.changeInput("editCoachLoader", false)
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* EDIT_DEPTH_CHART() {
  while (true) {
    yield take(actions.EDIT_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let TagsCreatedVariable;
      try {
        TagsCreatedVariable = window.CreateTags({
          tagsstring: state.reducer["FormEditDepthChart"]["Tags"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 4463,
          options: {
            parameter_mapping: {
              "c0ac754c-ace8-4a3a-961e-96c1098756b8":
                state.reducer["FormEditDepthChart"]["Tags"],
            },
            return_as: TagsCreatedVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      var err3508;
      var FormEditDepthChartIsValidVariable = true;
      err3508 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a name"),
        state.reducer["FormEditDepthChart"]["title"]
      );
      FormEditDepthChartIsValidVariable = !FormEditDepthChartIsValidVariable
        ? false
        : err3508.name !== "ValidationError";
      if (err3508.name === "ValidationError") {
        console.warn(err3508.errors);
        yield put(
          actions.changeInput("FormEditDepthChartErrors.title", err3508.errors)
        );
      } else {
        yield put(actions.removeField("FormEditDepthChartErrors.title"));
      }
      if (FormEditDepthChartIsValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] = state.reducer["EditDepthChart"]["Id"];
        payload["body_variables"] = {};
        payload.body_variables["title"] =
          state.reducer["FormEditDepthChart"]["title"];
        payload.body_variables["week"] =
          state.reducer["FormEditDepthChart"]["week"];
        payload.body_variables["category"] =
          state.reducer["FormEditDepthChart"]["category"];
        payload.body_variables["Tags"] = TagsCreatedVariable;
        const response = yield call(serverApi.EditDepthChartEndpoint, payload);

        const EditDepthChartResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 3510,
            options: {
              body_variables: {
                "b3f0233c-dc3d-4155-a22d-e6d028fb4001.6023":
                  state.reducer["FormEditDepthChart"]["title"],
                "b3f0233c-dc3d-4155-a22d-e6d028fb4001.6024":
                  state.reducer["FormEditDepthChart"]["week"],
                "b3f0233c-dc3d-4155-a22d-e6d028fb4001.6025":
                  state.reducer["FormEditDepthChart"]["category"],
                "b3f0233c-dc3d-4155-a22d-e6d028fb4001.6028":
                  TagsCreatedVariable,
              },
              path_variables: {
                "89e24fde-9fc3-4ac7-91c4-c52b1ec47d5e":
                  state.reducer["EditDepthChart"]["Id"],
              },
              response_code_as: EditDepthChartResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (EditDepthChartResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3512,
              options: {
                field_key: "EditDepthChart",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("EditDepthChart"));

          yield put(
            actions.logEvent({
              id: 3513,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_DEPTH_CHART", { inputVariables: {} })
          );

          yield put(
            actions.logEvent({
              id: 3514,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_BASE_DEPTH_CHARTS", { inputVariables: {} })
          );
          yield put(
            actions.runAction("GET_DEPTH_CHART_USERS", { inputVariables: {} })
          );

          yield put(
            actions.logEvent({
              id: 12427,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.runAction("GET_TAGS", { inputVariables: {} }));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* EDIT_GAME_FILE() {
  while (true) {
    let { inputVariables } = yield take(actions.EDIT_GAME_FILE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputEditGameFileVariable = inputVariables["input edit game file"];

      yield put(
        actions.logEvent({
          id: 3554,
          options: {
            field_key: "EditGameFile",
            field_value: InputEditGameFileVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("EditGameFile", InputEditGameFileVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}

function* EDIT_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.EDIT_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var HomePageOneVariable = "";
      var err;
      var FormEditPlayerIsValidVariable = true;
      err = yield call(
        _getValidationErrors,
        Yup.string().min(0, "Please enter a name"),
        state.reducer["FormEditPlayer"]["name"]
      );
      FormEditPlayerIsValidVariable = !FormEditPlayerIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(actions.changeInput("FormEditPlayerErrors.name", err.errors));
      } else {
        yield put(actions.removeField("FormEditPlayerErrors.name"));
      }
      err = yield call(
        _getValidationErrors,
        Yup.string().email("Please enter a valid email"),
        state.reducer["FormEditPlayer"]["email"]
      );
      FormEditPlayerIsValidVariable = !FormEditPlayerIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput("FormEditPlayerErrors.email", err.errors)
        );
      } else {
        yield put(actions.removeField("FormEditPlayerErrors.email"));
      }
      if (FormEditPlayerIsValidVariable === state.reducer["TrueCheckbox"]) {
        let formData = new FormData();
        formData.append("email", state.reducer["FormEditPlayer"]["email"]);
        formData.append("name", state.reducer["FormEditPlayer"]["name"]);
        if (inputVariables?.["image"] !== "") {
          formData.append(
            "uploadfile",
            inputVariables?.["image"] ? inputVariables?.["image"] : ""
          );
        }
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables = formData;

        const response = yield call(serverApi.EditPlayerEndpoint, payload);

        const EditPlayerResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 4408,
            options: {
              body_variables: {
                "47d21393-c0d5-4911-a431-844431e17b75.4553":
                  state.reducer["FormEditPlayer"]["email"],
                "47d21393-c0d5-4911-a431-844431e17b75.4557":
                  state.reducer["FormEditPlayer"]["name"],
                "47d21393-c0d5-4911-a431-844431e17b75.5138":
                  state.reducer["FormEditPlayer"]["scale type"],
              },
              response_code_as: EditPlayerResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (EditPlayerResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 4410,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_CURRENT_USER", { inputVariables: {} })
          );

          yield put(push(`${HomePageOneVariable}`));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* EDIT_PLAYER_PASSWORD() {
  while (true) {
    yield take(actions.EDIT_PLAYER_PASSWORD);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var HomePageVariable = "";

      var schema4411;
      var err4411;
      var FormEditPlayerPasswordIsValidVariable = true;
      err4411 = yield call(
        _getValidationErrors,
        Yup.string().min(7, "Password must be 7 characters"),
        state.reducer["FormEditPlayer"]["password"]
      );
      FormEditPlayerPasswordIsValidVariable =
        !FormEditPlayerPasswordIsValidVariable
          ? false
          : err4411.name !== "ValidationError";
      if (err4411.name === "ValidationError") {
        console.warn(err4411.errors);
        yield put(
          actions.changeInput("FormEditPlayerErrors.password", err4411.errors)
        );
      } else {
        yield put(actions.removeField("FormEditPlayerErrors.password"));
      }
      if (
        FormEditPlayerPasswordIsValidVariable === state.reducer["TrueCheckbox"]
      ) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["password"] =
          state.reducer["FormEditPlayer"]["password"];
        const response4413 = yield call(
          serverApi.EditPasswordEndpoint,
          payload
        );

        const EditPlayerPasswordResponseCodeVariable = response4413.status;
        yield put(
          actions.logEvent({
            id: 4413,
            options: {
              body_variables: {
                "6e9b8182-3832-45f9-876c-d19dd6594a97.4554":
                  state.reducer["FormEditPlayer"]["password"],
              },
              response_code_as: EditPlayerPasswordResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          EditPlayerPasswordResponseCodeVariable === state.reducer["TwoHundred"]
        ) {
          yield put(push(`${HomePageVariable}`));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* EDIT_POSITION() {
  while (true) {
    let { inputVariables } = yield take(actions.EDIT_POSITION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputPositionVariable = inputVariables["input position"];

      yield put(
        actions.logEvent({
          id: 3163,
          options: {
            field_key: "EditPosition",
            field_value: InputPositionVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("EditPosition", InputPositionVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* EDIT_USER_EXERCISE() {
  while (true) {
    let { inputVariables } = yield take(actions.EDIT_USER_EXERCISE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let UserExerciseVariable = inputVariables["user exercise"];

      yield put(
        actions.logEvent({
          id: 3897,
          options: {
            field_key: "EditUserExercise",
            field_value: UserExerciseVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("EditUserExercise", UserExerciseVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* EDIT_USER_PASSWORD() {
  while (true) {
    yield take(actions.EDIT_USER_PASSWORD);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var RosterPageVariable = "";

      var schema4399;
      var err4399;
      var FormEditCoachPasswordIsValidVariable = true;
      err4399 = yield call(
        _getValidationErrors,
        Yup.string().min(7, "Password must be 7 characters"),
        state.reducer["FormEditCoach"]["password"]
      );
      FormEditCoachPasswordIsValidVariable =
        !FormEditCoachPasswordIsValidVariable
          ? false
          : err4399.name !== "ValidationError";
      if (err4399.name === "ValidationError") {
        console.warn(err4399.errors);
        yield put(
          actions.changeInput("FormEditCoachErrors.password", err4399.errors)
        );
      } else {
        yield put(actions.removeField("FormEditCoachErrors.password"));
      }
      if (
        FormEditCoachPasswordIsValidVariable === state.reducer["TrueCheckbox"]
      ) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["password"] =
          state.reducer["FormEditCoach"]["password"];
        const response = yield call(serverApi.EditPasswordEndpoint, payload);

        const EditPasswordResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 4401,
            options: {
              body_variables: {
                "6e9b8182-3832-45f9-876c-d19dd6594a97.4554":
                  state.reducer["FormEditCoach"]["password"],
              },
              response_code_as: EditPasswordResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (EditPasswordResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 4403,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_CURRENT_USER", { inputVariables: {} })
          );

          yield put(push(`${RosterPageVariable}`));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* EDITPLAYERPOSITION() {
  while (true) {
    let { inputVariables } = yield take(actions.EDITPLAYERPOSITION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputEditPlayerPositionVariable =
        inputVariables["input edit player position"];

      yield put(
        actions.logEvent({
          id: 3184,
          options: {
            field_key: "AddPlayer",
            field_value: InputEditPlayerPositionVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("AddPlayer", InputEditPlayerPositionVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_ALL_PLAY_DEPTH_CHART_USERS() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_ALL_PLAY_DEPTH_CHART_USERS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputDepthChartRowidVariable =
        inputVariables["input depth chart rowid"];

      let InputPlayRowidVariable = inputVariables["input play rowid"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["play_id"] = InputPlayRowidVariable;
      payload.query_variables["depth_chart_id"] = InputDepthChartRowidVariable;
      const response = yield call(
        serverApi.GetPlayDepthChartUsersEndpoint,
        payload
      );
      const GetPlayDepthChartUsersResponse = response.data;
      const GetPlayDepthChartUsersResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 11928,
          options: {
            query_variables: {
              "0165aa64-9a21-40e4-acc4-eca9e9ee9c7d": InputPlayRowidVariable,
              "9b8ad81f-2f0a-47f3-8eab-59426ac82077":
                InputDepthChartRowidVariable,
            },
            response_as: GetPlayDepthChartUsersResponse,
            response_code_as: GetPlayDepthChartUsersResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayDepthChartUsersResponseStatus === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 11930,
            options: {
              field_key: "AllPlayDepthCharts",
              field_value: GetPlayDepthChartUsersResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "AllPlayDepthCharts",
            GetPlayDepthChartUsersResponse
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_AVERAGE_GAME_GRADE_SORTING() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_AVERAGE_GAME_GRADE_SORTING);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleIddVariable = inputVariables["intangible idd"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleIddVariable;
      const response8165 = yield call(
        serverApi.GetAverageGameGradeSortingEndpoint,
        payload
      );
      const GetAverageGameGradeSortingResponseVariable = response8165.data;
      const GetAverageGameGradeSortingResponseCodeVariable =
        response8165.status;
      yield put(
        actions.logEvent({
          id: 8165,
          options: {
            query_variables: {
              "593690f4-f891-4819-a224-26d9649c37fb": IntangibleIddVariable,
            },
            response_as: GetAverageGameGradeSortingResponseVariable,
            response_code_as: GetAverageGameGradeSortingResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        state.reducer["TwoHundred"] ===
        GetAverageGameGradeSortingResponseCodeVariable
      ) {
        let UserswithcoloVariable;
        try {
          UserswithcoloVariable = window.GenerateColor({
            somelist: GetAverageGameGradeSortingResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 8167,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetAverageGameGradeSortingResponseVariable,
              },
              return_as: UserswithcoloVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 8168,
            options: {
              field_key: "Users",
              field_value: UserswithcoloVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", UserswithcoloVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_AVERAGE_GAME_GRADES() {
  while (true) {
    yield take(actions.GET_AVERAGE_GAME_GRADES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let FormateDateVariable;
      try {
        FormateDateVariable = window.FormatDate({
          datestring: state.reducer["FormSearchGradesDate"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 8192,
          options: {
            parameter_mapping: {
              "1eb12004-d380-4086-9731-feecf4591dc6":
                state.reducer["FormSearchGradesDate"],
            },
            return_as: FormateDateVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["date"] = FormateDateVariable;
      payload.path_variables["position_id"] =
        state.reducer["FormSearchGradesPosition"];
      payload.path_variables["type"] = state.reducer["FormSearchGradesType"];
      const response3627 = yield call(
        serverApi.GetAverageGameGradesEndpoint,
        payload
      );
      const GetAverageGameGradesResponseVariable = response3627.data;
      const GetAverageGameGradesResponseCodeVariable = response3627.status;
      yield put(
        actions.logEvent({
          id: 3627,
          options: {
            path_variables: {
              "0e81d2f3-9f02-4ef6-92ba-44a19b808ff6": FormateDateVariable,
              "9093e61e-9fb8-4f8d-971d-e2f4442049a6":
                state.reducer["FormSearchGradesPosition"],
              "eae9eae2-c99b-475d-bf76-8659dc56fdfb":
                state.reducer["FormSearchGradesType"],
            },
            response_as: GetAverageGameGradesResponseVariable,
            response_code_as: GetAverageGameGradesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetAverageGameGradesResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 3629,
            options: {
              field_key: "AverageGrades",
              field_value: GetAverageGameGradesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "AverageGrades",
            GetAverageGameGradesResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_AVERAGE_GAME_GRADES_DES() {
  while (true) {
    yield take(actions.GET_AVERAGE_GAME_GRADES_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DateVariable;
      try {
        DateVariable = window.FormatDate({
          datestring: state.reducer["FormSearchGradesDate"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 8220,
          options: {
            parameter_mapping: {
              "1eb12004-d380-4086-9731-feecf4591dc6":
                state.reducer["FormSearchGradesDate"],
            },
            return_as: DateVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["type"] = state.reducer["FormSearchGradesType"];
      payload.path_variables["position_id"] =
        state.reducer["FormSearchGradesPosition"];
      payload.path_variables["date"] = DateVariable;
      const response7819 = yield call(
        serverApi.GetAverageGameGradesDesEndpoint,
        payload
      );
      const GetAveragesGameGradesDesResponseVariable = response7819.data;
      const GetAveragesGameGradesDesResponseCodeVariable = response7819.status;
      yield put(
        actions.logEvent({
          id: 7819,
          options: {
            path_variables: {
              "60fc53ab-4591-4e0b-bc89-3ddad562e5c3":
                state.reducer["FormSearchGradesType"],
              "c8cf5b8c-84b2-496f-bf38-ef66e285e0c3":
                state.reducer["FormSearchGradesPosition"],
              "de9de400-2fa5-47c3-a470-49b6a4b21319": DateVariable,
            },
            response_as: GetAveragesGameGradesDesResponseVariable,
            response_code_as: GetAveragesGameGradesDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetAveragesGameGradesDesResponseCodeVariable ===
        state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 7821,
            options: {
              field_key: "AverageGrades",
              field_value: GetAveragesGameGradesDesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "AverageGrades",
            GetAveragesGameGradesDesResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_AVERAGE_GAME_GRADES_REPORTS() {
  while (true) {
    let { inputVariables } = yield take(
      actions.GET_AVERAGE_GAME_GRADES_REPORTS
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputAverageGradesVariable = inputVariables["input average grades"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["type"] = state.reducer["FormSearchGradesType"];
      payload.path_variables["position_id"] =
        state.reducer["FormSearchGradesPosition"];
      payload.path_variables["date"] = state.reducer["ReportsDate"];
      const response10355 = yield call(
        serverApi.GetAverageGameGradesReportsEndpoint,
        payload
      );
      const GetAverageGameGradesReportsResponseVariable = response10355.data;
      const GetAverageGameGradesReportsResponseCodeVariable =
        response10355.status;
      yield put(
        actions.logEvent({
          id: 10355,
          options: {
            path_variables: {
              "3d04744b-6ddd-456d-b40b-9c0fcfc3a84d":
                state.reducer["FormSearchGradesType"],
              "5553af42-e1c5-49b8-8b67-c5bb388f1612":
                state.reducer["FormSearchGradesPosition"],
              "5f4082c0-481c-4dab-aafe-508806c7dde6":
                state.reducer["ReportsDate"],
            },
            response_as: GetAverageGameGradesReportsResponseVariable,
            response_code_as: GetAverageGameGradesReportsResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetAverageGameGradesReportsResponseCodeVariable ===
        state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 10358,
            options: {
              field_key: "AverageGrades",
              field_value: GetAverageGameGradesReportsResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "AverageGrades",
            GetAverageGameGradesReportsResponseVariable
          )
        );

        yield put(
          actions.logEvent({
            id: 10456,
            options: {
              field_key: "AverageGradesMsg",
              field_value: InputAverageGradesVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("AverageGradesMsg", InputAverageGradesVariable)
        );

        yield put(
          actions.logEvent({
            id: 10492,
            options: {
              field_key: "ReportsDate",
              field_value: state.reducer["Emptystring"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("ReportsDate", state.reducer["Emptystring"])
        );

        yield put(
          actions.logEvent({
            id: 10493,
            options: {
              field_key: "FormSearchGradesType",
              field_value: state.reducer["Emptystring"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "FormSearchGradesType",
            state.reducer["Emptystring"]
          )
        );

        yield put(
          actions.logEvent({
            id: 12395,
            options: {
              field_key: "GameTypeMsg",
              field_value: state.reducer["Emptystring"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("GameTypeMsg", state.reducer["Emptystring"])
        );

        yield put(
          actions.logEvent({
            id: 10494,
            options: {
              field_key: "FormSearchGradesPosition",
              field_value: state.reducer["Emptystring"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "FormSearchGradesPosition",
            state.reducer["Emptystring"]
          )
        );

        yield put(
          actions.logEvent({
            id: 10507,
            options: {
              field_key: "position",
              field_value: state.reducer["Emptystring"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("position", state.reducer["Emptystring"])
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_AVERAGE_GAME_GRADES_SORTING_DES() {
  while (true) {
    let { inputVariables } = yield take(
      actions.GET_AVERAGE_GAME_GRADES_SORTING_DES
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleIiidVariable = inputVariables["Intangible iiid"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleIiidVariable;
      const response8169 = yield call(
        serverApi.GetAverageGameGradeSortingDesEndpoint,
        payload
      );
      const GetAverageGameGradeSortingDesResponseVariable = response8169.data;
      const GetAverageGameGradeSortingDesResponseCodeVariable =
        response8169.status;
      yield put(
        actions.logEvent({
          id: 8169,
          options: {
            query_variables: {
              "6621dab1-0880-4369-91f6-bfcefaf5e7ec": IntangibleIiidVariable,
            },
            response_as: GetAverageGameGradeSortingDesResponseVariable,
            response_code_as: GetAverageGameGradeSortingDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        state.reducer["TwoHundred"] ===
        GetAverageGameGradeSortingDesResponseCodeVariable
      ) {
        let UserswcolorrVariable;
        try {
          UserswcolorrVariable = window.GenerateColor({
            somelist: GetAverageGameGradeSortingDesResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 8171,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetAverageGameGradeSortingDesResponseVariable,
              },
              return_as: UserswcolorrVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 8172,
            options: {
              field_key: "Users",
              field_value: UserswcolorrVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", UserswcolorrVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_AVERAGE_PRACTICE_GRADE_SORTING() {
  while (true) {
    let { inputVariables } = yield take(
      actions.GET_AVERAGE_PRACTICE_GRADE_SORTING
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleIdddVariable = inputVariables["intangible iddd"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleIdddVariable;
      const response8179 = yield call(
        serverApi.GetAveragePracticeGradeSortingEndpoint,
        payload
      );
      const GetAveragePracticeGradeSortingResponseVariable = response8179.data;
      const GetAveragePracticeGradeSortingResponseCodeVariable =
        response8179.status;
      yield put(
        actions.logEvent({
          id: 8179,
          options: {
            query_variables: {
              "aecf6a32-16a0-43f6-a578-beab3196b9f5": IntangibleIdddVariable,
            },
            response_as: GetAveragePracticeGradeSortingResponseVariable,
            response_code_as:
              GetAveragePracticeGradeSortingResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        state.reducer["TwoHundred"] ===
        GetAveragePracticeGradeSortingResponseCodeVariable
      ) {
        let UsercolorVariable;
        try {
          UsercolorVariable = window.GenerateColor({
            somelist: GetAveragePracticeGradeSortingResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 8181,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetAveragePracticeGradeSortingResponseVariable,
              },
              return_as: UsercolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 8182,
            options: {
              field_key: "Users",
              field_value: UsercolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", UsercolorVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_AVERAGE_PRACTICE_GRADES_SORTING_DES() {
  while (true) {
    let { inputVariables } = yield take(
      actions.GET_AVERAGE_PRACTICE_GRADES_SORTING_DES
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleIdVariable = inputVariables["intangible  id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleIdVariable;
      const response8183 = yield call(
        serverApi.GetAveragePracticeGradeSortingDesEndpoint,
        payload
      );
      const GetAveragePracticeGradeSortingDesResponseVariable =
        response8183.data;
      const GetAveragePracticeGradeSortingDesResponseCodeVariable =
        response8183.status;
      yield put(
        actions.logEvent({
          id: 8183,
          options: {
            query_variables: {
              "0669489a-e512-4c7d-9358-62de5ec519cd": IntangibleIdVariable,
            },
            response_as: GetAveragePracticeGradeSortingDesResponseVariable,
            response_code_as:
              GetAveragePracticeGradeSortingDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        state.reducer["TwoHundred"] ===
        GetAveragePracticeGradeSortingDesResponseCodeVariable
      ) {
        let UserssVariable;
        try {
          UserssVariable = window.GenerateColor({
            somelist: GetAveragePracticeGradeSortingDesResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 8185,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetAveragePracticeGradeSortingDesResponseVariable,
              },
              return_as: UserssVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 8186,
            options: {
              field_key: "Users",
              field_value: UserssVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", UserssVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_AVERAGE_USER_EXERCISES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_AVERAGE_USER_EXERCISES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      if (inputVariables?.isDefault) {
        yield put(actions.changeInput("isLoaded", true));
        yield put(actions.changeInput("isDefaultLoaded", true));
      }
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const seasonYearResponse = yield call(
          serverApi.GetSeasonYearEndpoint,
          payload
        );
        GetSeasonYearResponse = seasonYearResponse.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["text_search"] = inputVariables?.rosterValue
        ? inputVariables?.rosterValue
        : "";
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response = yield call(
        serverApi.GetAverageUserExercisesEndpoint,
        payload
      );
      const GetAverageUserExercisesResponse = response.data;
      const GetAverageUserExercisesResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3923,
          options: {
            response_as: GetAverageUserExercisesResponse,
            response_code_as: GetAverageUserExercisesResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetAverageUserExercisesResponseStatus === state.reducer["TwoHundred"]
      ) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["year"] =
          state.reducer?.selectedSeasonYear === ""
            ? GetSeasonYearResponse?.[0]?.year
            : state.reducer?.selectedSeasonYear;
        const response = yield call(serverApi.GetExercisesEndpoint, payload);
        const GetExercisesResponse = response.data;
        const GetExercisesResponseStatus = response.status;
        if (GetExercisesResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 4581,
              options: {
                field_key: "Exercises",
                field_value: GetExercisesResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.changeInput("Exercises", GetExercisesResponse));
        }

        yield put(
          actions.logEvent({
            id: 3925,
            options: {
              field_key: "AverageUserExercises",
              field_value: GetAverageUserExercisesResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "AverageUserExercises",
            GetAverageUserExercisesResponse
          )
        );
        if (inputVariables?.isDefault) {
          yield put(actions.changeInput("isDefaultLoaded", false));
        }
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
      } else {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}
function* GET_AVERAGE_USER_EXERCISES_DES() {
  while (true) {
    yield take(actions.GET_AVERAGE_USER_EXERCISES_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      const response7579 = yield call(
        serverApi.GetAverageUserExercisesDesEndpoint,
        null
      );
      const GetAverageUserExercisesDesResponseVariable = response7579.data;
      const GetAverageUserExercisesDesResponseCodeVariable =
        response7579.status;
      yield put(
        actions.logEvent({
          id: 7579,
          options: {
            response_as: GetAverageUserExercisesDesResponseVariable,
            response_code_as: GetAverageUserExercisesDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetAverageUserExercisesDesResponseCodeVariable ===
        state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 7582,
            options: {
              field_key: "AverageUserExercises",
              field_value: GetAverageUserExercisesDesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "AverageUserExercises",
            GetAverageUserExercisesDesResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_BASE_DEPTH_CHARTS() {
  while (true) {
    yield take(actions.GET_BASE_DEPTH_CHARTS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const seasonYearlyResponse = yield call(
          serverApi.GetSeasonYearEndpoint,
          payload
        );
        GetSeasonYearResponse = seasonYearlyResponse.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === ""
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;
      const response = yield call(
        serverApi.GetBaseDepthChartsEndpoint,
        payload
      );
      const GetDepthChartsResponse = response.data;
      const GetDepthChartsResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3120,
          options: {
            response_as: GetDepthChartsResponse,
            response_code_as: GetDepthChartsResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetDepthChartsResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3122,
            options: {
              field_key: "BaseDepthCharts",
              field_value: GetDepthChartsResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("BaseDepthCharts", GetDepthChartsResponse)
        );
        yield put(actions.removeField("selectedDepthChartDropDownData"));
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}
function* TOGGLE_SEASON_YEAR_DEPTH_CHART() {
  while (true) {
    yield take(actions.TOGGLE_SEASON_YEAR_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    try {
      yield put(push("/depth_chart_lists"));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SEASON_YEAR_GRADING() {
  while (true) {
    yield take(actions.TOGGLE_SEASON_YEAR_GRADING);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    try {
      yield put(push("/play_grades"));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SEASON_YEAR_GAME_AND_PRACTICE_REPORT() {
  while (true) {
    yield take(actions.TOGGLE_SEASON_YEAR_GAME_AND_PRACTICE_REPORT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    try {
      yield put(push("/play_reports"));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_CURRENT_USER() {
  while (true) {
    yield take(actions.GET_CURRENT_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      const response = yield call(serverApi.GetCurrentUserEndpoint, null);
      const GetCurrentUserResponse = response.data;
      const GetCurrentUserResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3078,
          options: {
            response_as: GetCurrentUserResponse,
            response_code_as: GetCurrentUserResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetCurrentUserResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3080,
            options: {
              field_key: "CurrentUser",
              field_value: GetCurrentUserResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("CurrentUser", GetCurrentUserResponse));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_CUSTOM_FIELD_TITLES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_CUSTOM_FIELD_TITLES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GameIdVariable = inputVariables["game id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] = GameIdVariable;
      const response = yield call(
        serverApi.GetCustomFieldTitlesEndpoint,
        payload
      );
      const GetCustomFieldTitlesResponse = response.data;
      const GetCustomFieldTitlesResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 12305,
          options: {
            query_variables: {
              "dd2d1aea-06b9-4d3c-b247-5ed2a7ed3d54": GameIdVariable,
            },
            response_as: GetCustomFieldTitlesResponse,
            response_code_as: GetCustomFieldTitlesResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetCustomFieldTitlesResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 12307,
            options: {
              field_key: "CustomFieldTitles",
              field_value: GetCustomFieldTitlesResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("CustomFieldTitles", GetCustomFieldTitlesResponse)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_DEPTH_CHART() {
  while (true) {
    yield take(actions.GET_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      const userResponse = yield call(serverApi.GetCurrentUserEndpoint, null);
      const GetCurrentUserResponse = userResponse.data;
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const seasonYearResponse = yield call(
          serverApi.GetSeasonYearEndpoint,
          payload
        );
        GetSeasonYearResponse = seasonYearResponse.data;
      }
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.page["depthchartid"];
      const response = yield call(serverApi.GetDepthChartEndpoint, payload);
      const GetDepthChartResponseVariable = response.data;
      const GetDepthChartResponseCodeVariable = response.status;
      yield put(
        actions.logEvent({
          id: 3465,
          options: {
            path_variables: {
              "b731f609-048e-4916-b7fc-418c0979b9e4":
                state.page["depthchartid"],
            },
            response_as: GetDepthChartResponseVariable,
            response_code_as: GetDepthChartResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetDepthChartResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3467,
            options: {
              field_key: "CurrentDepthChart",
              field_value: GetDepthChartResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "CurrentDepthChart",
            GetDepthChartResponseVariable
          )
        );
        if (
          GetCurrentUserResponse?.["user_type"] === "Coach" ||
          GetCurrentUserResponse?.["user_type"] === "Position Coach"
        ) {
          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["week"] = GetDepthChartResponseVariable?.week;
          payload.query_variables["year"] = state.page?.year
            ? state.page?.year
            : state.reducer?.selectedSeasonYear === ""
            ? GetSeasonYearResponse?.[0]?.year
            : state.reducer?.selectedSeasonYear;
          const response = yield call(
            serverApi.GetDepthChartWeeksEndpoint,
            payload
          );
          const GetDepthChartWeeksResponse = response.data;
          const GetDepthChartWeeksResponseStatus = response.status;
          yield put(
            actions.logEvent({
              id: 3465,
              options: {
                path_variables: {
                  "b731f609-048e-4916-b7fc-418c0979b9e4":
                    state.page["depthchartid"],
                },
                response_as: GetDepthChartWeeksResponse,
                response_code_as: GetDepthChartWeeksResponseStatus,
              },
              type: "event",
              time: Date.now(),
            })
          );
          if (
            GetDepthChartWeeksResponseStatus === state.reducer["TwoHundred"]
          ) {
            yield put(
              actions.logEvent({
                id: 3467,
                options: {
                  field_key: "depthChartDropDownData",
                  field_value: GetDepthChartWeeksResponse,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.changeInput(
                "depthChartDropDownData",
                GetDepthChartWeeksResponse
              )
            );
          }
        }
      } else if (
        GetDepthChartResponseCodeVariable === state.reducer["FiveHundred"]
      ) {
        if (state.reducer["CurrentUser"]["user_type"] === "Player") {
          window.location.href = `${window.location.origin}/#/player_home`;
        } else {
          window.location.href = `${window.location.origin}/#/roster`;
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SELECT_DEPTH_CHART_VIEW() {
  while (true) {
    let { inputVariables } = yield take(actions.SELECT_DEPTH_CHART_VIEW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      if (state.reducer?.tableView === "table_view") {
        yield put(
          actions.runAction("GET_DEPTH_CHART_USERS", { inputVariables: {} })
        );
        yield put(
          actions.runAction("GET_DEPTH_CHART_FIELD_VIEW", {
            inputVariables: {},
          })
        );
      } else {
        yield put(
          actions.runAction("GET_DEPTH_CHART_FIELD_VIEW", {
            inputVariables: {},
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_DEPTH_CHART_FIELD_VIEW() {
  while (true) {
    yield take(actions.GET_DEPTH_CHART_FIELD_VIEW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.page["depthchartid"];

      const response = yield call(
        serverApi.GetDepthChartFieldViewEndpoint,
        payload
      );
      const GetDepthChartFieldViewResponse = response.data;
      const GetDepthChartFieldViewResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3465,
          options: {
            path_variables: {
              "b731f609-048e-4916-b7fc-418c0979b9e4":
                state.page["depthchartid"],
            },
            response_as: GetDepthChartFieldViewResponse,
            response_code_as: GetDepthChartFieldViewResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetDepthChartFieldViewResponseStatus === state.reducer["TwoHundred"]
      ) {
        const groundWidth = 1230 - 20;
        const groundHeight = 1230 - 140;
        const boxWidth = 100;
        const padding = 10;
        let tempData = [];
        var possitionData = {
          left: 0,
          top: 140,
        };

        for (let i = 0; i < GetDepthChartFieldViewResponse.length; i++) {
          if (GetDepthChartFieldViewResponse[i]?.field_position === null) {
            let objectData = {
              ...GetDepthChartFieldViewResponse[i],
              field_position: {
                left: possitionData.left,
                top: possitionData.top,
              },
            };

            if (possitionData.left + boxWidth + padding >= groundWidth) {
              objectData.field_position.top =
                possitionData.top + boxWidth + padding;
              objectData.field_position.left = 0;
              possitionData.top = possitionData.top + boxWidth + padding;
              possitionData.left = boxWidth + padding;
            } else {
              possitionData.left = possitionData.left + boxWidth + padding;
              // possitionData.top = possitionData.top + boxWidth + padding
            }
            tempData.push(objectData);
          } else {
            if (possitionData.left + boxWidth + padding >= groundWidth) {
              possitionData.top = possitionData.top + boxWidth + padding;
              possitionData.left = boxWidth + padding;
            } else {
              possitionData.left = possitionData.left + boxWidth + padding;
            }
            tempData.push(GetDepthChartFieldViewResponse[i]);
          }
        }

        yield put(
          actions.logEvent({
            id: 3467,
            options: {
              field_key: "DepthChartFieldView",
              field_value: tempData,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("DepthChartFieldView", tempData));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_DEPTH_CHART_FIELD_VIEW() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_DEPTH_CHART_FIELD_VIEW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let { depth_chart_id, field_position, rank, row_id, user_id } =
        inputVariables;

      payload = {};
      payload["body_variables"] = {};
      payload.body_variables["depth_chart_id"] = depth_chart_id;
      payload.body_variables["row_id"] = row_id;
      payload.body_variables["rank"] = rank;
      payload.body_variables["user_id"] = user_id;
      payload.body_variables["field_position"] = field_position;

      const response = yield call(
        serverApi.UpdateDepthChartFieldViewEndpoint,
        payload
      );
      const updateFieldViewResponseStatus = response.status;

      if (updateFieldViewResponseStatus === state.reducer["TwoHundred"]) {
        // yield put(
        //   actions.runAction("GET_DEPTH_CHART_FIELD_VIEW", { inputVariables: {} })
        // );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_FIELD_VIEW_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_FIELD_VIEW_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    try {
      let value = {
        user_id: inputVariables?.data?.user_id,
        "position id": inputVariables?.data?.position_id,
        rank: inputVariables?.data?.rank,
        "row id": inputVariables?.data?.row_id,
      };

      yield put(
        actions.logEvent({
          id: 3467,
          options: {
            field_key: "ToggleDepthFieldView",
            field_value: !state.reducer?.ToggleDepthFieldView,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "ToggleDepthFieldView",
          !state.reducer?.ToggleDepthFieldView
        )
      );
      yield put(
        actions.logEvent({
          id: 3467,
          options: {
            field_key: "currentSelectedPositionData",
            field_value: inputVariables?.data,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("currentSelectedPositionData", inputVariables?.data)
      );
      yield put(
        actions.logEvent({
          id: 3467,
          options: {
            field_key: "UpdateFiewViewPlayer",
            field_value: value,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("UpdateFiewViewPlayer", value));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_FIELD_VIEW_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_FIELD_VIEW_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.reducer["CurrentDepthChart"]["Id"];
      payload["body_variables"] = {};
      payload.body_variables["position_id"] =
        state.reducer["UpdateFiewViewPlayer"]["position id"];
      payload.body_variables["user_id"] = inputVariables?.["Id"];
      payload.body_variables["depth_chart_id"] =
        state.reducer["CurrentDepthChart"]["Id"];
      payload.body_variables["rank"] =
        state.reducer["UpdateFiewViewPlayer"]["rank"];
      payload.body_variables["row_id"] =
        state.reducer["UpdateFiewViewPlayer"]["row id"];
      const response = yield call(serverApi.SetDepthChartUserEndpoint, payload);

      const SetDepthChartUserResponseCodesVariable = response.status;

      if (
        SetDepthChartUserResponseCodesVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.runAction("GET_DEPTH_CHART_USERS", { inputVariables: {} })
        );

        yield put(
          actions.runAction("GET_DEPTH_CHART_FIELD_VIEW", {
            inputVariables: {},
          })
        );

        yield put(
          actions.logEvent({
            id: 3528,
            options: {
              field_key: "UpdateFiewViewPlayer",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("UpdateFiewViewPlayer", {}));

        yield put(
          actions.logEvent({
            id: 3467,
            options: {
              field_key: "ToggleDepthFieldView",
              field_value: !state.reducer?.ToggleDepthFieldView,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "ToggleDepthFieldView",
            !state.reducer?.ToggleDepthFieldView
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CLEAR_DEPTH_CHART_VALUES() {
  while (true) {
    yield take(actions.CLEAR_DEPTH_CHART_VALUES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      yield put(
        actions.logEvent({
          id: 3467,
          options: {
            field_key: "tableView",
            field_value: "table_view",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("tableView", "table_view"));
      yield put(
        actions.logEvent({
          id: 3467,
          options: {
            field_key: "DepthChartUsers",
            field_value: [],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("DepthChartUsers", []));
      yield put(
        actions.logEvent({
          id: 3467,
          options: {
            field_key: "selectedDepthChartDropDownData",
            field_value: "",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("selectedDepthChartDropDownData", ""));
      yield put(
        actions.logEvent({
          id: 3467,
          options: {
            field_key: "CurrentDepthChart",
            field_value: {},
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("CurrentDepthChart", {}));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DEPTH_CHART_WEEK_FILTER() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DEPTH_CHART_WEEK_FILTER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] =
        state.reducer?.["selectedDepthChartDropDownData"];
      const response = yield call(serverApi.GetDepthChartEndpoint, payload);
      const GetDepthChartResponseVariable = response.data;
      if (
        GetDepthChartResponseVariable?.base &&
        GetDepthChartResponseVariable?.week === 0
      ) {
        yield put(
          push(
            `/depth_chart?depthchartid=${state.reducer?.selectedDepthChartDropDownData}&base=true&year=${state.reducer?.selectedSeasonYear}`
          )
        );
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] =
          state.reducer?.["selectedDepthChartDropDownData"];
        const response = yield call(serverApi.GetDepthChartEndpoint, payload);
        const GetDepthChartResponseVariable = response.data;
        const GetDepthChartResponseCodeVariable = response.status;
        yield put(
          actions.logEvent({
            id: 3465,
            options: {
              path_variables: {
                "b731f609-048e-4916-b7fc-418c0979b9e4":
                  state.page["depthchartid"],
              },
              response_as: GetDepthChartResponseVariable,
              response_code_as: GetDepthChartResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (GetDepthChartResponseCodeVariable === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3467,
              options: {
                field_key: "CurrentDepthChart",
                field_value: GetDepthChartResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "CurrentDepthChart",
              GetDepthChartResponseVariable
            )
          );
        }
        const baseDepthChartResponse = yield call(
          serverApi.GetBaseDepthChartUsersEndpoint,
          payload
        );
        const GetDepthChartUsersResponse = baseDepthChartResponse.data;
        const GetDepthChartUsersResponseStatus = baseDepthChartResponse.status;
        yield put(
          actions.logEvent({
            id: 3208,
            options: {
              path_variables: {
                "6459786d-27dd-4738-9ada-7f7dc3a4a8be":
                  state.page["depthchartid"],
              },
              response_as: GetDepthChartUsersResponse,
              response_code_as: GetDepthChartUsersResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );

        if (GetDepthChartUsersResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3997,
              options: {
                field_key: "DepthChartUsers",
                field_value: GetDepthChartUsersResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("DepthChartUsers", GetDepthChartUsersResponse)
          );
          yield put(
            actions.runAction("GET_DEPTH_CHART_FIELD_VIEW", {
              inputVariables: {},
            })
          );
        }
      } else {
        yield put(
          push(
            `/depth_chart?depthchartid=${state.reducer?.selectedDepthChartDropDownData}&year=${state.reducer?.selectedSeasonYear}`
          )
        );
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] =
          state.reducer?.["selectedDepthChartDropDownData"];
        const response = yield call(serverApi.GetDepthChartEndpoint, payload);
        const GetDepthChartResponseVariable = response.data;
        const GetDepthChartResponseCodeVariable = response.status;
        yield put(
          actions.logEvent({
            id: 3465,
            options: {
              path_variables: {
                "b731f609-048e-4916-b7fc-418c0979b9e4":
                  state.page["depthchartid"],
              },
              response_as: GetDepthChartResponseVariable,
              response_code_as: GetDepthChartResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (GetDepthChartResponseCodeVariable === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3467,
              options: {
                field_key: "CurrentDepthChart",
                field_value: GetDepthChartResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "CurrentDepthChart",
              GetDepthChartResponseVariable
            )
          );
        }
        const depthChartUserresponse = yield call(
          serverApi.GetDepthChartUsersEndpoint,
          payload
        );
        const GetDepthChartUsersResponse = depthChartUserresponse.data;
        const GetDepthChartUsersResponseStatus = depthChartUserresponse.status;
        yield put(
          actions.logEvent({
            id: 3208,
            options: {
              path_variables: {
                "6459786d-27dd-4738-9ada-7f7dc3a4a8be":
                  state.page["depthchartid"],
              },
              response_as: GetDepthChartUsersResponse,
              response_code_as: GetDepthChartUsersResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );

        if (GetDepthChartUsersResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3997,
              options: {
                field_key: "DepthChartUsers",
                field_value: GetDepthChartUsersResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("DepthChartUsers", GetDepthChartUsersResponse)
          );
          yield put(
            actions.runAction("GET_DEPTH_CHART_FIELD_VIEW", {
              inputVariables: {},
            })
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_DEPTH_CHART_ID() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_DEPTH_CHART_ID);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DepthChartInputVariable = inputVariables["depth chart input"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = DepthChartInputVariable["depth chart id"];
      const response11449 = yield call(
        serverApi.GetDepthChartEndpoint,
        payload
      );
      const GetDepthChartResponseVariable = response11449.data;
      const GetDepthChartResponseCodeVariable = response11449.status;
      yield put(
        actions.logEvent({
          id: 11449,
          options: {
            path_variables: {
              "b731f609-048e-4916-b7fc-418c0979b9e4":
                DepthChartInputVariable["depth chart id"],
            },
            response_as: GetDepthChartResponseVariable,
            response_code_as: GetDepthChartResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetDepthChartResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 11451,
            options: {
              field_key: "DepthChartTitle",
              field_value: GetDepthChartResponseVariable["title"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "DepthChartTitle",
            GetDepthChartResponseVariable["title"]
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_DEPTH_CHART_POSITION_USERS() {
  while (true) {
    yield take(actions.GET_DEPTH_CHART_POSITION_USERS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["depth_chart_id"] = state.page["depthchartid"];
      payload.path_variables["position_id"] = state.page["positionid"];
      payload["query_variables"] = {};
      payload.query_variables["rowid"] = state.page["rowid"];
      const response = yield call(
        serverApi.GetDepthChartPositionUsersEndpoint,
        payload
      );
      const GetDepthChartPositionUsersResponse = response.data;
      const GetDepthChartPositionUsersResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 4494,
          options: {
            path_variables: {
              "89518286-330e-40d4-81ba-8a6f884faa1e":
                state.page["depthchartid"],
              "d32b3276-bf99-4bfe-8ae5-1ea52ee1d266": state.page["positionid"],
            },
            query_variables: {
              "9b953d4f-a176-40c3-8dc6-a58c90900f73": state.page["rowid"],
            },
            response_as: GetDepthChartPositionUsersResponse,
            response_code_as: GetDepthChartPositionUsersResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetDepthChartPositionUsersResponseStatus === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 4496,
            options: {
              field_key: "DepthChartPositionUsers",
              field_value: GetDepthChartPositionUsersResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "DepthChartPositionUsers",
            GetDepthChartPositionUsersResponse
          )
        );
      }
      if (
        GetDepthChartPositionUsersResponseStatus ===
        state.reducer["FiveHundred"]
      ) {
        window.location.href = `${window.location.origin}/#/roster`;
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_DEPTH_CHART_USERS() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_DEPTH_CHART_USERS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      if (inputVariables?.isDefault) {
        yield put(actions.changeInput("isLoaded", true));
        yield put(actions.changeInput("isDefaultLoaded", true));
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["text_search"] = inputVariables?.rosterValue
          ? inputVariables?.rosterValue
          : "";
        const response = yield call(serverApi.GetRosterEndpoint, payload);
        const GetUsersResponseVariable = response.data;
        const GetUsersResponseCodeVariable = response.status;
        if (state.reducer["TwoHundred"] === GetUsersResponseCodeVariable) {
          yield put(
            actions.logEvent({
              id: 4702,
              options: {
                field_key: "Users",
                field_value: GetUsersResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.changeInput("Users", GetUsersResponseVariable));
        }
      }
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.page["depthchartid"];

      if (state.page["base"]) {
        const response = yield call(
          serverApi.GetBaseDepthChartUsersEndpoint,
          payload
        );
        const GetDepthChartUsersResponse = response.data;
        const GetDepthChartUsersResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 3208,
            options: {
              path_variables: {
                "6459786d-27dd-4738-9ada-7f7dc3a4a8be":
                  state.page["depthchartid"],
              },
              response_as: GetDepthChartUsersResponse,
              response_code_as: GetDepthChartUsersResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );

        if (GetDepthChartUsersResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3997,
              options: {
                field_key: "DepthChartUsers",
                field_value: GetDepthChartUsersResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("DepthChartUsers", GetDepthChartUsersResponse)
          );
        }
      } else {
        const response = yield call(
          serverApi.GetDepthChartUsersEndpoint,
          payload
        );
        const GetDepthChartUsersResponse = response.data;
        const GetDepthChartUsersResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 3208,
            options: {
              path_variables: {
                "6459786d-27dd-4738-9ada-7f7dc3a4a8be":
                  state.page["depthchartid"],
              },
              response_as: GetDepthChartUsersResponse,
              response_code_as: GetDepthChartUsersResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );

        if (GetDepthChartUsersResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3997,
              options: {
                field_key: "DepthChartUsers",
                field_value: GetDepthChartUsersResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("DepthChartUsers", GetDepthChartUsersResponse)
          );
        } else if (
          GetDepthChartUsersResponseStatus === state.reducer["FiveHundred"]
        ) {
          window.location.href = `${window.location.origin}/#/player_home`;
        }
      }
      if (inputVariables?.isDefault) {
        yield put(actions.changeInput("isLoaded", false));
        yield put(actions.changeInput("isDefaultLoaded", false));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_DRAG_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_DRAG_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      const { user_id, row_id, rank, position_id, id } = inputVariables;

      payload = {};
      payload["body_variables"] = {};
      payload.body_variables["user_id"] = user_id;
      payload.body_variables["row_id"] = row_id;
      payload.body_variables["rank"] = rank;
      payload.body_variables["position_id"] = position_id;
      payload.body_variables["depth_chart_id"] = state.page?.["depthchartid"];
      payload.body_variables["id"] = id;

      yield call(serverApi.UpdateDragAndDropUserEndpoint, payload);
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_DROP_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_DROP_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      const { user_id, row_id, rank, position_id, id } = inputVariables;

      payload = {};
      payload["body_variables"] = {};
      payload.body_variables["user_id"] = user_id;
      payload.body_variables["row_id"] = row_id;
      payload.body_variables["rank"] = rank;
      payload.body_variables["position_id"] = position_id;
      payload.body_variables["depth_chart_id"] = state.page?.["depthchartid"];
      payload.body_variables["id"] = id;

      const response = yield call(
        serverApi.UpdateDragAndDropUserEndpoint,
        payload
      );
      const UpdateDragResponseStatus = response.status;
      if (UpdateDragResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.runAction("GET_DEPTH_CHART_USERS", { inputVariables: {} })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAYER_DEPTH_CHART_USERS() {
  while (true) {
    yield take(actions.GET_PLAYER_DEPTH_CHART_USERS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.page["depthchartid"];

      if (state.page["base"]) {
        const response = yield call(
          serverApi.GetBaseDepthChartUsersEndpoint,
          payload
        );
        const GetDepthChartUsersResponse = response.data;
        const GetDepthChartUsersResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 3208,
            options: {
              path_variables: {
                "6459786d-27dd-4738-9ada-7f7dc3a4a8be":
                  state.page["depthchartid"],
              },
              response_as: GetDepthChartUsersResponse,
              response_code_as: GetDepthChartUsersResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );

        if (GetDepthChartUsersResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3997,
              options: {
                field_key: "DepthChartUsers",
                field_value: GetDepthChartUsersResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("DepthChartUsers", GetDepthChartUsersResponse)
          );
        }
      } else {
        const response = yield call(
          serverApi.GetPlayerDepthChartUsersEndpoint,
          payload
        );
        const GetDepthChartUsersResponse = response.data;
        const GetDepthChartUsersResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 3208,
            options: {
              path_variables: {
                "6459786d-27dd-4738-9ada-7f7dc3a4a8be":
                  state.page["depthchartid"],
              },
              response_as: GetDepthChartUsersResponse,
              response_code_as: GetDepthChartUsersResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );

        if (GetDepthChartUsersResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3997,
              options: {
                field_key: "DepthChartUsers",
                field_value: GetDepthChartUsersResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("DepthChartUsers", GetDepthChartUsersResponse)
          );
          if (GetDepthChartUsersResponseStatus?.data === null) {
            window.location.href = `${window.location.origin}/#/player_home`;
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_DEPTH_CHART_BY_SCHOOL() {
  while (true) {
    yield take(actions.GET_DEPTH_CHART_BY_SCHOOL);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.reducer["CurrentUser"]["school_id"];
      const response11408 = yield call(
        serverApi.GetDepthChartBySchoolEndpoint,
        payload
      );
      const GetDepthChartBySchoolResponseVariable = response11408.data;
      const GetDepthChartBySchoolResponseCodeVariable = response11408.status;
      yield put(
        actions.logEvent({
          id: 11408,
          options: {
            path_variables: {
              "93fc4fcf-2336-4399-8fa6-a0c8e014cdc7":
                state.reducer["CurrentUser"]["school_id"],
            },
            response_as: GetDepthChartBySchoolResponseVariable,
            response_code_as: GetDepthChartBySchoolResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetDepthChartBySchoolResponseCodeVariable ===
        state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 11410,
            options: {
              field_key: "DepthChartsSchool",
              field_value: GetDepthChartBySchoolResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "DepthChartsSchool",
            GetDepthChartBySchoolResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_EXERCISES() {
  while (true) {
    yield take(actions.GET_EXERCISES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
        const GetSeasonYearResponseStatus = response.status;
        if (GetSeasonYearResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 4702,
              options: {
                field_key: "SeasonYearData",
                field_value: GetSeasonYearResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("SeasonYearData", GetSeasonYearResponse)
          );
        }
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.GetExercisesEndpoint, payload);
      const GetExercisesResponse = response.data;
      const GetExercisesResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3231,
          options: {
            response_as: GetExercisesResponse,
            response_code_as: GetExercisesResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetExercisesResponseStatus === state.reducer["TwoHundred"]) {
        let ExerciseswithcolorVariable;
        try {
          ExerciseswithcolorVariable = window.GenerateColor({
            somelist: GetExercisesResponse,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 3233,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade": GetExercisesResponse,
              },
              return_as: ExerciseswithcolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 4581,
            options: {
              field_key: "Exercises",
              field_value: ExerciseswithcolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Exercises", ExerciseswithcolorVariable));
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
      } else {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}
function* GET_EXERCISES_GRAPH() {
  while (true) {
    yield take(actions.GET_EXERCISES_GRAPH);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.page["userid"];
      const response = yield call(serverApi.GetExercisesGraphEndpoint, payload);
      const GetExercisesGraphResponse = response.data;
      const GetExercisesGraphResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 4568,
          options: {
            path_variables: {
              "ca43bc76-de4b-41c6-8726-f3613cb53177": state.page["userid"],
            },
            response_as: GetExercisesGraphResponse,
            response_code_as: GetExercisesGraphResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetExercisesGraphResponseStatus === state.reducer["TwoHundred"]) {
        let JsonDataVariable;
        try {
          JsonDataVariable = window.JsonParse({
            jsonString: GetExercisesGraphResponse,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 4570,
            options: {
              parameter_mapping: {
                "53a4f177-45d8-4547-bfb2-e7495a41c012":
                  GetExercisesGraphResponse,
              },
              return_as: JsonDataVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 4571,
            options: {
              field_key: "ExerciseData",
              field_value: JsonDataVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("ExerciseData", JsonDataVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_EXERCISES_GRAPH_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_EXERCISES_GRAPH_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let UseridInputVariable = inputVariables["userid input"];

      yield put(
        actions.logEvent({
          id: 4578,
          options: {
            field_key: "ShowUserGraph",
            field_value: UseridInputVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ShowUserGraph", UseridInputVariable));
      let GetCurrentUserResponse;
      if (UseridInputVariable === undefined) {
        const CurrentUserResponse = yield call(
          serverApi.GetCurrentUserEndpoint,
          null
        );
        GetCurrentUserResponse = CurrentUserResponse.data;
        const GetCurrentUserResponseStatus = CurrentUserResponse.status;
        yield put(
          actions.logEvent({
            id: 3078,
            options: {
              response_as: GetCurrentUserResponse,
              response_code_as: GetCurrentUserResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (GetCurrentUserResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3080,
              options: {
                field_key: "CurrentUser",
                field_value: GetCurrentUserResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.changeInput("CurrentUser", GetCurrentUserResponse));
        }
      }

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = UseridInputVariable
        ? UseridInputVariable
        : GetCurrentUserResponse?.id;
      const response = yield call(serverApi.GetExercisesGraphEndpoint, payload);
      const GetExercisesGraphResponse = response.data;
      const GetExercisesGraphResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 4574,
          options: {
            path_variables: {
              "ca43bc76-de4b-41c6-8726-f3613cb53177": UseridInputVariable,
            },
            response_as: GetExercisesGraphResponse,
            response_code_as: GetExercisesGraphResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetExercisesGraphResponseStatus === state.reducer["TwoHundred"]) {
        let JsonRespVariable;
        try {
          JsonRespVariable = window.JsonParse({
            jsonString: GetExercisesGraphResponse,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 4576,
            options: {
              parameter_mapping: {
                "53a4f177-45d8-4547-bfb2-e7495a41c012":
                  GetExercisesGraphResponse,
              },
              return_as: JsonRespVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 4577,
            options: {
              field_key: "ExerciseData",
              field_value: JsonRespVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("ExerciseData", JsonRespVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* GET_GAME_BY_ID() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_GAME_BY_ID);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.page["playgradeid"];
      const playResponse = yield call(serverApi.GetGameEndpoint, payload);
      const GetGameResponse = playResponse.data;
      const GetGameResponseStatus = playResponse.status;
      if (inputVariables?.callback) {
        inputVariables?.callback(playResponse);
      }
      if (GetGameResponseStatus === state.reducer["TwoHundred"]) {
        yield put(actions.changeInput("CurrentGame", GetGameResponse));

        let GameDateeVariable;
        try {
          GameDateeVariable = window.FormatDate({
            datestring: GetGameResponse["date"],
          });
        } catch (e) {}
        yield put(actions.changeInput("GameDateResult", GameDateeVariable));
      } else {
        yield put(
          actions.changeInput(
            "playByPlayGradingMessage",
            GetGameResponse?.message
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_GAME() {
  while (true) {
    yield take(actions.GET_GAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let gameId = state.page["playgradeid"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] = gameId;
      payload.query_variables["user_id"] = state.reducer?.["CurrentUser"]?.id;

      const gradingIndexResponse = yield call(
        serverApi.getGradingTableColumns,
        payload
      );
      const getGradingTableColumnResponse = gradingIndexResponse.data;
      const getGradingTableColumnStatus = gradingIndexResponse.status;

      if (getGradingTableColumnStatus === state.reducer?.["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 11930,
            options: {
              field_key: "gradingIndexData",
              field_value: getGradingTableColumnResponse?.index,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "gradingIndexData",
            getGradingTableColumnResponse?.index
          )
        );
      }

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] = gameId;
      payload.query_variables["user_id"] = state.reducer?.["CurrentUser"]?.id;

      const columnResponse = yield call(
        serverApi.getConfigurableTableColumns,
        payload
      );
      const getSelectedTableColumn = columnResponse.status;

      const fieldTitleResponse = yield call(
        serverApi.GetCustomFieldTitlesEndpoint,
        {
          query_variables: { game_id: gameId },
        }
      );
      const GetCustomFieldTitlesResponse = fieldTitleResponse.data;
      yield put(
        actions.changeInput("CustomFieldTitles", GetCustomFieldTitlesResponse)
      );
      let obj = {};
      GetCustomFieldTitlesResponse &&
        GetCustomFieldTitlesResponse?.forEach((data) => {
          obj[data?.title] = true;
        });

      const getCheckboxConfigStatus = columnResponse.status;
      const columns = columnResponse?.data?.columns
        ? columnResponse?.data?.columns
        : {
            "#": true,
            play: true,
            qtr: true,
            series: true,
            down: true,
            distance: true,
            type: true,
            package: true,
            ...obj,
          };
      yield put(
        actions.logEvent({
          id: 11928,
          options: {
            query_variables: {
              "0165aa64-9a21-40e4-acc4-eca9e9ee9c7d": columns,
              "9b8ad81f-2f0a-47f3-8eab-59426ac82077": columns,
            },
            response_as: getCheckboxConfigStatus,
            response_code_as: getCheckboxConfigStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );

      yield put(
        actions.logEvent({
          id: 11930,
          options: {
            field_key: "initialCheckbox",
            field_value: columns,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("initialCheckbox", columns));

      if (getSelectedTableColumn === state.reducer?.["FiveHundred"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game_id"] = gameId;
        payload.query_variables["user_id"] = state.reducer?.["CurrentUser"]?.id;
        payload["body_variables"] = {};
        payload.body_variables["columns"] = columns;
        payload.body_variables["name"] = "Default View";

        const saveViewresponse = yield call(
          serverApi.CreateSavedViewsEndpoint,
          payload
        );

        const saveViewsResponse = saveViewresponse.data;
        const saveViewsResponseStatus = saveViewresponse.status;

        if (saveViewsResponseStatus === state.reducer["TwoHundred"]) {
          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["game_id"] = gameId;
          payload.query_variables["user_id"] =
            state.reducer?.["CurrentUser"]?.id;

          const response = yield call(
            serverApi.GetDefaultViewEndpoint,
            payload
          );
          const defaultViewResponse = response.data;
          const defaultViewResponseStatus = response.status;

          if (defaultViewResponseStatus === state.reducer["TwoHundred"]) {
            yield put(
              actions.changeInput("defaultViewData", defaultViewResponse)
            );
          }
        }
      } else {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game_id"] = gameId;
        payload.query_variables["user_id"] = state.reducer?.["CurrentUser"]?.id;

        const response = yield call(serverApi.GetDefaultViewEndpoint, payload);

        const defaultViewResponse = response.data;
        const defaultViewResponseStatus = response.status;

        if (defaultViewResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.changeInput("defaultViewData", defaultViewResponse)
          );

          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["id"] = defaultViewResponse?.[0]?.id;

          const getColumnsResponse = yield call(
            serverApi.GetSavedViewEndpoint,
            payload
          );

          const getColumnsNameResponse = getColumnsResponse.data;
          const getColumnsNameResponseStatus = getColumnsResponse.status;
          if (getColumnsNameResponseStatus === state.reducer["TwoHundred"]) {
            yield put(
              actions.logEvent({
                id: 11930,
                options: {
                  field_key: "initialCheckbox",
                  field_value: getColumnsNameResponse?.[0]?.columns,
                },
                type: "event",
                time: Date.now(),
              })
            );

            yield put(
              actions.changeInput(
                "initialCheckbox",
                getColumnsNameResponse?.[0]?.columns
              )
            );
          }
        }
      }
      if (getGradingTableColumnResponse?.index) {
      } else {
        yield put(actions.changeInput("playByPlayLoader", false));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* GET_GAME_PRACTICE_GAME() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_GAME_PRACTICE_GAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload, payloadQuery;

    try {
      if (inputVariables?.isDefault) {
        yield put(actions.changeInput("isDefaultLoaded", true));
        yield put(actions.changeInput("isLoaded", true));
      }
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.page["playgradeid"];
      const playResponse = yield call(serverApi.GetGameEndpoint, payload);
      const GetGameResponse = playResponse.data;
      const GetGameResponseStatus = playResponse.status;
      yield put(
        actions.logEvent({
          id: 10878,
          options: {
            path_variables: {
              "88323176-c990-4487-9768-84e2ecd7ba1a": state.page["playgradeid"],
            },
            response_as: GetGameResponse,
            response_code_as: GetGameResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );

      if (GetGameResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 10880,
            options: {
              field_key: "CurrentGame",
              field_value: GetGameResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("CurrentGame", GetGameResponse));

        let GameDateeVariable;
        try {
          GameDateeVariable = window.FormatDate({
            datestring: GetGameResponse["date"],
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 10883,
            options: {
              parameter_mapping: {
                "1eb12004-d380-4086-9731-feecf4591dc6": GetGameResponse["date"],
              },
              return_as: GameDateeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 10884,
            options: {
              field_key: "GameDateResult",
              field_value: GameDateeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("GameDateResult", GameDateeVariable));

        yield put(
          actions.runAction("GET_CUSTOM_FIELD_TITLES", {
            inputVariables: { "game id": GetGameResponse["Id"] },
          })
        );

        const response = yield call(serverApi.GetAllSeries, null);
        let GetAllSeriesResponseVariable = response.data;
        yield (GetAllSeriesResponseVariable =
          GetAllSeriesResponseVariable?.filter((item) => item.series != null));
        yield (GetAllSeriesResponseVariable =
          GetAllSeriesResponseVariable &&
          isArray(GetAllSeriesResponseVariable) &&
          GetAllSeriesResponseVariable &&
          GetAllSeriesResponseVariable.map(
            ({ series: label, series: value }) => ({ label, value })
          ));
        GetAllSeriesResponseVariable.unshift({ label: "All", value: -1 });
        const GetAllSeriesCodeVariable = response.status;

        if (GetAllSeriesCodeVariable === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3542,
              options: {
                field_key: "SeriesTypeArray",
                field_value: GetAllSeriesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("SeriesTypeArray", GetAllSeriesResponseVariable)
          );
        }
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["year"] = state.page?.year
          ? state.page?.year
          : state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
        const GetScaleResponse = yield call(serverApi.GetScaleType, payload);
        const GetScaleTypeResponse = GetScaleResponse.data;
        const GetScaleTypeStatus = GetScaleResponse.status;

        if (GetScaleTypeStatus === state.reducer["TwoHundred"]) {
          yield put(actions.setField("GetScaleTypeData", GetScaleTypeResponse));
        }
        // yield put(
        //   actions.runAction("GET_PLAY_REPORTS_DATA", {
        //     inputVariables: { gameidd: GetGameResponse["Id"] },
        //   })
        // );

        yield put(
          actions.runAction("GET_TOTAL_PLAYS", {
            inputVariables: { "input game id": GetGameResponse["Id"] },
          })
        );
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game_id"] = GetGameResponse["Id"];
        payload.query_variables["position_id"] = -1;
        payload.query_variables["qtr"] = state.reducer["QuarterData"][0]?.value;
        payload.query_variables["type"] =
          state.reducer["PlayTypeArray"][0]?.value;
        payload.query_variables["series"] =
          GetAllSeriesResponseVariable?.[0]?.value;
        payload.query_variables["down"] =
          state.reducer["SelectedDownId"] === "" ||
          state.reducer["SelectedDownId"] === null
            ? state?.reducer?.DownDropDownData[0]?.value
            : state.reducer["SelectedDownId"];
        payload.query_variables["distance"] = -1;
        payload.query_variables["year"] = state.page?.year
          ? state.page?.year
          : state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
        payload.query_variables["column_data"] = JSON.stringify({});

        const AverageIntangibleUserResponse = yield call(
          serverApi.GetAverageIntangibleUsersEndpoint,
          payload
        );
        const GetAverageIntangibleUsersResponse =
          AverageIntangibleUserResponse.data;
        const GetAverageIntangibleUsersResponseStatus =
          AverageIntangibleUserResponse.status;
        yield put(
          actions.logEvent({
            id: 12651,
            options: {
              query_variables: {
                "0158728a-1919-47cd-9e87-e5d3898efcc5": GetGameResponse["Id"],
              },
              response_as: GetAverageIntangibleUsersResponse,
              response_code_as: GetAverageIntangibleUsersResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetAverageIntangibleUsersResponseStatus ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 12653,
              options: {
                field_key: "PlayReportsData",
                field_value: GetAverageIntangibleUsersResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayReportsData",
              GetAverageIntangibleUsersResponse
            )
          );
          const averagePlay = GetAverageIntangibleUsersResponse?.filter(
            (data) => data["package"] !== null
          )?.map((val) => val.index);
          yield put(
            actions.logEvent({
              id: 12243,
              options: {
                field_key: "AverageByPlay",
                field_value: averagePlay,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.changeInput("AverageByPlay", averagePlay));
          if (
            state.reducer?.SelectedPositionId === "" ||
            state.reducer?.SelectedPositionId === "-1" ||
            state.reducer?.SelectedPositionId === null
          ) {
            if (
              state.reducer?.SelectedPositionId === "" ||
              state.reducer?.SelectedPositionId === "-1" ||
              state.reducer?.SelectedPositionId === null
            ) {
              yield put(
                actions.logEvent({
                  id: 12243,
                  options: {
                    field_key: "Intangibles",
                    field_value: state.reducer?.IntangibleValueName,
                  },
                  type: "event",
                  time: Date.now(),
                })
              );
              yield put(
                actions.changeInput(
                  "Intangibles",
                  state.reducer?.IntangibleValueName
                )
              );
            }
            if (
              GetAverageIntangibleUsersResponse &&
              isArray(GetAverageIntangibleUsersResponse)
            ) {
              let averageArray = [];
              GetAverageIntangibleUsersResponse?.filter(
                (data) => data["package"] !== null
              )?.forEach((value) => {
                let average = averageCalculate(
                  value["position_averages"],
                  "position_averages"
                );
                average === "None"
                  ? averageArray.push(0.0)
                  : averageArray.push(Number(average?.replace(/.$/, "")));
              });
              yield put(
                actions.logEvent({
                  id: 12243,
                  options: {
                    field_key: "PlayAverages",
                    field_value: averageArray,
                  },
                  type: "event",
                  time: Date.now(),
                })
              );
              yield put(actions.changeInput("PlayAverages", averageArray));
            } else return [];
          }
        }

        payloadQuery = {};
        payloadQuery["query_variables"] = {};
        payloadQuery.query_variables["game_id"] = state.page["playgradeid"];
        payloadQuery.query_variables["year"] = state.page?.year
          ? state.page?.year
          : state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
        const AveragePlayResponse = yield call(
          serverApi.GetAverageByIntangiblePlay,
          payloadQuery
        );
        const GetAverageIntangiblePlay = AveragePlayResponse.data;
        const GetAverageIntangiblePlayCodeVariable = AveragePlayResponse.status;
        if (
          GetAverageIntangiblePlayCodeVariable === state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 12243,
              options: {
                field_key: "AverageByIntangible",
                field_value: GetAverageIntangiblePlay,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("AverageByIntangible", GetAverageIntangiblePlay)
          );
        }
        if (inputVariables?.isDefault) {
          yield put(actions.changeInput("isDefaultLoaded", false));
          yield put(actions.changeInput("isLoaded", false));
        }
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}

function* CLEAR_GRADING_INDEX() {
  while (true) {
    yield take(actions.CLEAR_GRADING_INDEX);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      yield put(
        actions.logEvent({
          id: 13376,
          options: {
            field_key: "PlayByPlayInfo",
            field_value: state.reducer["Nullvalue"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("PlayByPlayInfo", state.reducer["Nullvalue"])
      );
      yield put(actions.changeInput("checkAll", false));
      yield put(actions.changeInput("selectedPlayDataArray", []));
      yield put(actions.changeInput("gradingIndexData", []));
      yield put(actions.changeInput("SelectedBulkType", ""));
      yield put(actions.changeInput("CustomFieldTitles", []));
      yield put(actions.changeInput("AverageIntangible", {}));
      yield put(actions.removeField("PlayerReportDataUser"));
      yield put(
        actions.removeField("playByPlayGradingMessage")
      );
      yield put(
        actions.removeField("CsvPlayMappingPackage")
      );
      yield put(
        actions.removeField("PlayCheckboxError")
      );

    }
    catch (error) {
      console.warn(error);
    }
  }
}

function* GET_PLAYER_GAME() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAYER_GAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let fonndPlayerObject = {};
      let foundGameAndPracticeObject = {};
      if (inputVariables?.isDefault) {
        yield put(actions.changeInput("isLoaded", true));
        yield put(actions.changeInput("isDefaultLoaded", true));
      }
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const seasonYearResponse = yield call(
          serverApi.GetSeasonYearEndpoint,
          payload
        );
        GetSeasonYearResponse = seasonYearResponse.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.GetAllGamesEndpoint, payload);
      const GetGamesResponseVariable = response.data;
      const GetGamesResponseCodeVariable = response.status;
      if (GetGamesResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(actions.changeInput("Games", GetGamesResponseVariable));
        if (state.reducer?.SelectedPlayerGameAndPracticeId) {
          foundGameAndPracticeObject = GetGamesResponseVariable?.find(
            (item) =>
              item?.Id === state.reducer?.SelectedPlayerGameAndPracticeId
          );
          if (foundGameAndPracticeObject?.Id) {
            yield put(
              actions.changeInput(
                "SelectedPlayerGameAndPracticeId",
                foundGameAndPracticeObject?.Id
              )
            );
          } else {
            yield put(
              actions.changeInput("SelectedPlayerGameAndPracticeId", -1)
            );
          }
        }
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const PracticeResponse = yield call(
        serverApi.GetPracticesEndpoint,
        payload
      );
      const GetPracticesResponse = PracticeResponse.data;
      const GetPracticesResponseStatus = PracticeResponse.status;
      if (GetPracticesResponseStatus === state.reducer["TwoHundred"]) {
        yield put(actions.changeInput("Practices", GetPracticesResponse));
        if (state.reducer?.SelectedPlayerGameAndPracticeId) {
          foundGameAndPracticeObject = GetPracticesResponse?.find(
            (item) =>
              item?.Id === state.reducer?.SelectedPlayerGameAndPracticeId
          );
          if (foundGameAndPracticeObject?.Id) {
            yield put(
              actions.changeInput(
                "SelectedPlayerGameAndPracticeId",
                foundGameAndPracticeObject?.Id
              )
            );
          } else {
            yield put(
              actions.changeInput("SelectedPlayerGameAndPracticeId", -1)
            );
          }
        }
      }

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] = foundGameAndPracticeObject?.Id
        ? foundGameAndPracticeObject?.Id
        : -1;
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === ""
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;
      const response12512 = yield call(
        serverApi.GetTotalPlaysEndpoint,
        payload
      );
      const GetTotalPlaysResponseVariable = response12512.data;
      yield put(
        actions.changeInput(
          "TotalPlays",
          GetTotalPlaysResponseVariable["count"]
        )
      );

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["text_search"] = "";
      const response3106 = yield call(serverApi.GetRosterEndpoint, payload);
      const GetUsersResponseVariable = response3106.data;
      const GetUsersResponseCodeVariable = response3106.status;
      if (state.reducer["TwoHundred"] === GetUsersResponseCodeVariable) {
        yield put(actions.changeInput("Users", GetUsersResponseVariable));
        if (state.reducer?.SelectedPlayerGameAndPracticeId) {
          fonndPlayerObject = GetUsersResponseVariable?.find(
            (item) => item?.Id === state.reducer?.PlayerReportDataUser
          );
          if (fonndPlayerObject?.Id) {
            yield put(
              actions.changeInput("PlayerReportDataUser", fonndPlayerObject?.Id)
            );
          } else {
            yield put(actions.changeInput("PlayerReportDataUser", -1));
          }
        }
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === "" ||
        state.reducer?.selectedSeasonYear === 0
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const ScaleTypeResponse = yield call(serverApi.GetScaleType, payload);
      const GetScaleTypeResponse = ScaleTypeResponse.data;
      const GetScaleTypeStatus = ScaleTypeResponse.status;

      if (GetScaleTypeStatus === state.reducer["TwoHundred"]) {
        yield put(actions.setField("GetScaleTypeData", GetScaleTypeResponse));
      }
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = GetGamesResponseVariable[0]?.Id;
      const response10878 = yield call(serverApi.GetGameEndpoint, payload);
      const GetGameResponseVariable = response10878.data;

      const GetGameResponseCodeVariable = response10878.status;
      if (GetGameResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(actions.changeInput("CurrentGame", GetGameResponseVariable));

        const response12753 = yield call(serverApi.GetFirstUserEndpoint, null);
        const GetFirstUserResponseVariable = response12753.data;
        const GetFirstUserResponseCodeVariable = response12753.status;

        if (GetFirstUserResponseCodeVariable === state.reducer["TwoHundred"]) {
          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["game_id"] = foundGameAndPracticeObject?.Id
            ? foundGameAndPracticeObject?.Id
            : -1;
          payload.query_variables["user_id"] = fonndPlayerObject?.Id
            ? fonndPlayerObject?.Id
            : -1;
          payload.query_variables["year"] =
            state.reducer?.selectedSeasonYear === ""
              ? GetSeasonYearResponse?.[0]?.year
              : state.reducer?.selectedSeasonYear;

          const response12755 = yield call(
            serverApi.PlayerFilterIntangibles,
            payload
          );

          const GetPlayerReportsDataResVariable = response12755.data;
          const GetPlayerReportsDataResCodeVariable = response12755.status;
          if (
            GetPlayerReportsDataResCodeVariable === state.reducer["TwoHundred"]
          ) {
            yield put(
              actions.logEvent({
                id: 12766,
                options: {
                  field_key: "PlayerReportsData",
                  field_value: GetPlayerReportsDataResVariable,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.changeInput(
                "PlayerReportsData",
                GetPlayerReportsDataResVariable
              )
            );
          }

          // Remove ALL Option in Choose then uncomment the code

          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["game_id"] = foundGameAndPracticeObject?.Id
            ? foundGameAndPracticeObject?.Id
            : -1;
          payload.query_variables["user_id"] = fonndPlayerObject?.Id
            ? fonndPlayerObject?.Id
            : -1;
          payload.query_variables["year"] =
            state.reducer?.selectedSeasonYear === ""
              ? GetSeasonYearResponse?.[0]?.year
              : state.reducer?.selectedSeasonYear;
          const response12767 = yield call(
            serverApi.GetPlayerReportsCountEndpoint,
            payload
          );
          const GetPlayerReportsCountResponseVariable = response12767.data;
          const GetPlayerReportsCountResponseCodeVariable =
            response12767.status;
          if (
            GetPlayerReportsCountResponseCodeVariable ===
            state.reducer["TwoHundred"]
          ) {
            yield put(
              actions.changeInput(
                "PlayerReportUsersCount",
                GetPlayerReportsCountResponseVariable["count"]
              )
            );

            payload = {};
            payload["query_variables"] = {};
            payload.query_variables["game_id"] = foundGameAndPracticeObject?.Id
              ? foundGameAndPracticeObject?.Id
              : -1;
            payload.query_variables["user_id"] = fonndPlayerObject?.Id
              ? fonndPlayerObject?.Id
              : -1;
            payload.query_variables["year"] =
              state.reducer?.selectedSeasonYear === ""
                ? GetSeasonYearResponse?.[0]?.year
                : state.reducer?.selectedSeasonYear;

            const response = yield call(
              serverApi.GetPlayerProductionPointEndpoint,
              payload
            );
            const GetPlayerProductionPointResponse = response.data;
            const GetPlayerProductionPointResponseStatus = response.status;

            if (
              GetPlayerProductionPointResponseStatus ===
              state.reducer["TwoHundred"]
            ) {
              yield put(
                actions.changeInput(
                  "PlayerProductionPointData",
                  GetPlayerProductionPointResponse
                )
              );
            }

            payload = {};
            payload["query_variables"] = {};
            payload.query_variables["game_id"] = foundGameAndPracticeObject?.Id
              ? foundGameAndPracticeObject?.Id
              : -1;
            payload.query_variables["user_id"] = fonndPlayerObject?.Id
              ? fonndPlayerObject?.Id
              : -1;
            payload.query_variables["year"] =
              state.reducer?.selectedSeasonYear === ""
                ? GetSeasonYearResponse?.[0]?.year
                : state.reducer?.selectedSeasonYear;

            const response12893 = yield call(
              serverApi.GetPlayerAverageIntangibleEndpoint,
              payload
            );
            const GetAverageIntangibleResVariable = response12893.data;

            const GetAverageIntangibleResCodeVariable = response12893.status;
            if (
              GetAverageIntangibleResCodeVariable ===
              state.reducer["TwoHundred"]
            ) {
              yield put(
                actions.changeInput(
                  "AverageIntangible",
                  GetAverageIntangibleResVariable
                )
              );
              if (inputVariables?.isDefault) {
                yield put(actions.changeInput("isLoaded", false));
                yield put(actions.changeInput("isDefaultLoaded", false));
              }
            }
          }
          // }
        }
      } else {
        yield put(actions.changeInput("isLoaded", false));
        yield put(actions.changeInput("isDefaultLoaded", false));
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}
function* GET_GAME_FILES() {
  while (true) {
    yield take(actions.GET_GAME_FILES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      const response = yield call(serverApi.GetGameFilesEndpoint, null);
      const GetGameFilesResponse = response.data;
      const GetGameFilesResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3543,
          options: {
            response_as: GetGameFilesResponse,
            response_code_as: GetGameFilesResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetGameFilesResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3545,
            options: {
              field_key: "GameFiles",
              field_value: GetGameFilesResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("GameFiles", GetGameFilesResponse));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_GAME_FOR_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_GAME_FOR_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      if (inputVariables?.isDefault) {
        yield put(actions.changeInput("isLoaded", true));
        yield put(actions.changeInput("isDefaultLoaded", true));
      }
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === "" ||
          state.reducer?.selectedSeasonYear === 0
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.GetScaleType, payload);
      const GetScaleTypeResponse = response.data;
      const GetScaleTypeStatus = response.status;

      if (GetScaleTypeStatus === state.reducer["TwoHundred"]) {
        yield put(actions.setField("GetScaleTypeData", GetScaleTypeResponse));
      }

      const currentUserResponse = yield call(
        serverApi.GetCurrentUserEndpoint,
        null
      );
      const GetCurrentUserResponse = currentUserResponse.data;
      const GetCurrentUserResponseStatus = currentUserResponse.status;
      yield put(
        actions.logEvent({
          id: 3078,
          options: {
            response_as: GetCurrentUserResponse,
            response_code_as: GetCurrentUserResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetCurrentUserResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3080,
            options: {
              field_key: "CurrentUser",
              field_value: GetCurrentUserResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("CurrentUser", GetCurrentUserResponse));

        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] = state.page["playgradeid"];
        const response = yield call(serverApi.GetGameEndpoint, payload);
        const GetGameResponse = response.data;
        const GetGameResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 17320,
            options: {
              path_variables: {
                "88323176-c990-4487-9768-84e2ecd7ba1a":
                  state.page["playgradeid"],
              },
              response_as: GetGameResponse,
              response_code_as: GetGameResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (GetGameResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 17322,
              options: {
                field_key: "CurrentGame",
                field_value: GetGameResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.changeInput("CurrentGame", GetGameResponse));

          let GameDatePlayerVariable;
          try {
            GameDatePlayerVariable = window.FormatDate({
              datestring: GetGameResponse["date"],
            });
          } catch (e) {}
          yield put(
            actions.logEvent({
              id: 17324,
              options: {
                parameter_mapping: {
                  "1eb12004-d380-4086-9731-feecf4591dc6":
                    GetGameResponse["date"],
                },
                return_as: GameDatePlayerVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );

          yield put(
            actions.logEvent({
              id: 17325,
              options: {
                field_key: "GameDateResult",
                field_value: GameDatePlayerVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("GameDateResult", GameDatePlayerVariable)
          );

          yield put(
            actions.logEvent({
              id: 17326,
              options: {
                parameter_mapping: {
                  "40008d49-5573-453a-bf78-f19d7954dd0c": GetGameResponse["Id"],
                },
              },
              type: "event",
              time: Date.now(),
            })
          );

          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["game_id"] = state.page?.playgradeid;
          const CustomFieldResponse = yield call(
            serverApi.GetCustomFieldTitlesEndpoint,
            payload
          );
          const GetCustomFieldTitlesResponse = CustomFieldResponse.data;
          const GetCustomFieldTitlesResponseStatus = CustomFieldResponse.status;
          yield put(
            actions.logEvent({
              id: 12305,
              options: {
                query_variables: {
                  "dd2d1aea-06b9-4d3c-b247-5ed2a7ed3d54":
                    state.page?.playgradeid,
                },
                response_as: GetCustomFieldTitlesResponse,
                response_code_as: GetCustomFieldTitlesResponseStatus,
              },
              type: "event",
              time: Date.now(),
            })
          );
          if (
            GetCustomFieldTitlesResponseStatus === state.reducer["TwoHundred"]
          ) {
            yield put(
              actions.logEvent({
                id: 12307,
                options: {
                  field_key: "CustomFieldTitles",
                  field_value: GetCustomFieldTitlesResponse,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.changeInput(
                "CustomFieldTitles",
                GetCustomFieldTitlesResponse
              )
            );
          }

          yield put(
            actions.runAction("GET_TOTAL_PLAYS", {
              inputVariables: { "input game id": GetGameResponse["Id"] },
            })
          );

          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["game_id"] = state.page?.playgradeid;
          payload.query_variables["user_id"] = GetCurrentUserResponse?.id;
          payload.query_variables["year"] = state.page?.year
            ? state.page?.year
            : state.reducer?.selectedSeasonYear === ""
            ? GetSeasonYearResponse?.[0]?.year
            : state.reducer?.selectedSeasonYear;

          const ProductionPointResponse = yield call(
            serverApi.GetPlayerProductionPointEndpoint,
            payload
          );
          const GetPlayerProductionPointResponse = ProductionPointResponse.data;
          const GetPlayerProductionPointResponseStatus =
            ProductionPointResponse.status;

          if (
            GetPlayerProductionPointResponseStatus ===
            state.reducer["TwoHundred"]
          ) {
            yield put(
              actions.logEvent({
                id: 12895,
                options: {
                  field_key: "PlayerProductionPointData",
                  field_value: GetPlayerProductionPointResponse,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.changeInput(
                "PlayerProductionPointData",
                GetPlayerProductionPointResponse
              )
            );
          }

          yield put(
            actions.logEvent({
              id: 17323,
              options: {
                parameter_mapping: {
                  "1e234ba2-e384-46ae-b0da-71869cdf7b1d": GetGameResponse["Id"],
                  "50a0420c-dfcb-4cef-a04d-0d14aea71049":
                    state.reducer["CurrentUser"]["id"],
                },
              },
              type: "event",
              time: Date.now(),
            })
          );

          yield put(
            actions.runAction("GET_PLAYER_GRADES_DATA", {
              inputVariables: {
                "grade game id": GetGameResponse["Id"],
                "grade user id": GetCurrentUserResponse?.["id"],
              },
            })
          );

          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["user_id"] = GetCurrentUserResponse?.["id"];
          payload.query_variables["game_id"] = GetGameResponse["Id"];
          payload.query_variables["year"] = state.page?.year
            ? state.page?.year
            : state.reducer?.selectedSeasonYear === ""
            ? GetSeasonYearResponse?.[0]?.year
            : state.reducer?.selectedSeasonYear;

          const response = yield call(
            serverApi.PlayerFilterIntangibles,
            payload
          );
          const GetPlayerDataResVariable = response.data;
          const GetPlayerDataResCodeVariable = response.status;
          yield put(
            actions.logEvent({
              id: 17328,
              options: {
                query_variables: {
                  "8d5c47a0-5ec8-42d6-99f6-38e127e9e6d9":
                    state.reducer["CurrentUser"]["id"],
                  "995112b7-1670-43ca-b038-6322b0877b33": GetGameResponse["Id"],
                },
                response_as: GetPlayerDataResVariable,
                response_code_as: GetPlayerDataResCodeVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          if (GetPlayerDataResCodeVariable === state.reducer["TwoHundred"]) {
            yield put(
              actions.logEvent({
                id: 17330,
                options: {
                  field_key: "PlayerReportsData",
                  field_value: GetPlayerDataResVariable,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.changeInput("PlayerReportsData", GetPlayerDataResVariable)
            );

            payload = {};
            payload["query_variables"] = {};
            payload.query_variables["user_id"] = GetCurrentUserResponse?.id;
            payload.query_variables["game_id"] = GetGameResponse["Id"];
            payload.query_variables["year"] = state.page?.year
              ? state.page?.year
              : state.reducer?.selectedSeasonYear === ""
              ? GetSeasonYearResponse?.[0]?.year
              : state.reducer?.selectedSeasonYear;
            const response = yield call(
              serverApi.GetPlayerAverageIntangibleEndpoint,
              payload
            );
            const GetAvgIntangibleResponse = response.data;
            const GetAvgIntangibleResponseStatus = response.status;
            yield put(
              actions.logEvent({
                id: 17331,
                options: {
                  query_variables: {
                    "07f07538-95a0-4013-8586-2968fa4cd632":
                      state.reducer["CurrentUser"]["id"],
                    "30e6e7d2-bd5c-4a0e-abba-8385ee7c6986":
                      GetGameResponse["Id"],
                  },
                  response_as: GetAvgIntangibleResponse,
                  response_code_as: GetAvgIntangibleResponseStatus,
                },
                type: "event",
                time: Date.now(),
              })
            );
            if (
              GetAvgIntangibleResponseStatus === state.reducer["TwoHundred"]
            ) {
              yield put(
                actions.logEvent({
                  id: 17333,
                  options: {
                    field_key: "AverageIntangible",
                    field_value: GetAvgIntangibleResponse,
                  },
                  type: "event",
                  time: Date.now(),
                })
              );
              yield put(
                actions.changeInput(
                  "AverageIntangible",
                  GetAvgIntangibleResponse
                )
              );
            }
          }
          // API for the table header intangible for player games grades

          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["user_id"] = GetCurrentUserResponse?.id;
          payload.query_variables["game_id"] = state?.page?.["playgradeid"];
          payload.query_variables["year"] = state.page?.year
            ? state.page?.year
            : state.reducer?.selectedSeasonYear === ""
            ? GetSeasonYearResponse?.[0]?.year
            : state.reducer?.selectedSeasonYear;
          const playerResponse = yield call(
            serverApi.PlayerIntangibles,
            payload
          );
          const GetIntagiblesResponse = playerResponse.data;
          const GetIntagiblesResponseStatus = playerResponse.status;

          yield put(
            actions.logEvent({
              id: 3140,
              options: {
                response_as: GetIntagiblesResponse,
                response_code_as: GetIntagiblesResponseStatus,
              },
              type: "event",
              time: Date.now(),
            })
          );
          if (GetIntagiblesResponseStatus === state.reducer["TwoHundred"]) {
            yield put(
              actions.logEvent({
                id: 12366,
                options: {
                  parameter_mapping: {
                    "2844db8a-960c-430b-987d-7003aa903ade":
                      GetIntagiblesResponse,
                  },
                  return_as: GetIntagiblesResponse,
                },
                type: "event",
                time: Date.now(),
              })
            );

            yield put(
              actions.logEvent({
                id: 3142,
                options: {
                  field_key: "Intangibles",
                  field_value: GetIntagiblesResponse,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.changeInput("Intangibles", GetIntagiblesResponse)
            );
            if (inputVariables?.isDefault) {
              yield put(actions.changeInput("isLoaded", false));
              yield put(actions.changeInput("isDefaultLoaded", false));
            }
          }
        } else if (GetGameResponseStatus === state.reducer["FiveHundred"]) {
          if (state.reducer["CurrentUser"]["user_type"] === "Player") {
            window.location.href = `${window.location.origin}/#/player_home`;
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_GAME_GRADES() {
  while (true) {
    yield take(actions.GET_GAME_GRADES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let FormattedDateVariable;
      try {
        FormattedDateVariable = window.FormatDate({
          datestring: state.reducer["FormSearchGradesDate"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 4389,
          options: {
            parameter_mapping: {
              "1eb12004-d380-4086-9731-feecf4591dc6":
                state.reducer["FormSearchGradesDate"],
            },
            return_as: FormattedDateVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["position_id"] =
        state.reducer["FormSearchGradesPosition"];
      payload.path_variables["date"] = FormattedDateVariable;
      payload.path_variables["type"] = state.reducer["FormSearchGradesType"];
      const response3563 = yield call(serverApi.GetGameGradesEndpoint, payload);
      const GetGameGradesResponseVariable = response3563.data;
      const GetGameGradesResponseCodeVariable = response3563.status;
      yield put(
        actions.logEvent({
          id: 3563,
          options: {
            path_variables: {
              "2a98f0e6-ee51-4b91-b7fc-36b3d171b8f6":
                state.reducer["FormSearchGradesPosition"],
              "2faf98ba-8add-4e51-8956-b47f8e6bb13c": FormattedDateVariable,
              "b67e40da-fc4e-4b4b-826d-e10518a79bf0":
                state.reducer["FormSearchGradesType"],
            },
            response_as: GetGameGradesResponseVariable,
            response_code_as: GetGameGradesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetGameGradesResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3565,
            options: {
              field_key: "Grades",
              field_value: GetGameGradesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Grades", GetGameGradesResponseVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_GAME_GRADES_DES() {
  while (true) {
    yield take(actions.GET_GAME_GRADES_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let FormattedDateResVariable;
      try {
        FormattedDateResVariable = window.FormatDate({
          datestring: state.reducer["FormSearchGradesDate"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 7574,
          options: {
            parameter_mapping: {
              "1eb12004-d380-4086-9731-feecf4591dc6":
                state.reducer["FormSearchGradesDate"],
            },
            return_as: FormattedDateResVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["date"] = FormattedDateResVariable;
      payload.path_variables["type"] = state.reducer["FormSearchGradesType"];
      payload.path_variables["position_id"] =
        state.reducer["FormSearchGradesPosition"];
      const response7577 = yield call(
        serverApi.GetGameGradesDesEndpoint,
        payload
      );
      const GetGameGradesDesResponseVariable = response7577.data;
      const GetGameGradesDesResponseCodeVariable = response7577.status;
      yield put(
        actions.logEvent({
          id: 7577,
          options: {
            path_variables: {
              "13a41265-20e0-48c5-bf42-a7297d00f77c": FormattedDateResVariable,
              "8214aad9-62ab-4f80-822b-0c1746d2dcaa":
                state.reducer["FormSearchGradesType"],
              "b686a1ba-fcb8-44cc-acb4-18497ea29a2c":
                state.reducer["FormSearchGradesPosition"],
            },
            response_as: GetGameGradesDesResponseVariable,
            response_code_as: GetGameGradesDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetGameGradesDesResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 7580,
            options: {
              field_key: "Grades",
              field_value: GetGameGradesDesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("Grades", GetGameGradesDesResponseVariable)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_GAMES() {
  while (true) {
    yield take(actions.GET_GAMES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response7209 = yield call(serverApi.GetAllGamesEndpoint, payload);
      const GetGamesResponseVariable = response7209.data;
      const GetGamesResponseCodeVariable = response7209.status;
      yield put(
        actions.logEvent({
          id: 7209,
          options: {
            response_as: GetGamesResponseVariable,
            response_code_as: GetGamesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetGamesResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
        yield put(
          actions.logEvent({
            id: 7211,
            options: {
              field_key: "Games",
              field_value: GetGamesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Games", GetGamesResponseVariable));
        yield put(actions.removeField("selectedDefaultView"));
        yield put(actions.removeField("currentSelectedView"));
        yield put(actions.removeField("defaultViewData"));
      } else {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}
function* GET_TEAMS() {
  while (true) {
    yield take(actions.GET_TEAMS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const seasonYearResponse = yield call(
          serverApi.GetSeasonYearEndpoint,
          payload
        );
        GetSeasonYearResponse = seasonYearResponse.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.GetTeams, payload);
      const GetTeamsResponse = response.data;
      const GetTeamsResponseStatus = response.status;
      if (GetTeamsResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 7211,
            options: {
              field_key: "Teams",
              field_value: GetTeamsResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Teams", GetTeamsResponse));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_GAMES_AND_PRACTICES() {
  while (true) {
    yield take(actions.GET_GAMES_AND_PRACTICES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.logEvent({
          id: 12191,
          options: {},
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.runAction("GET_PRACTICES", { inputVariables: {} }));

      yield put(
        actions.logEvent({
          id: 13159,
          options: {},
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.runAction("GET_GAMES", { inputVariables: {} }));
      // const clonePractices = state.reducer?.Games
      // const allData = clonePractices.concat(state.reducer?.Practices)
      //   yield put(
      //     actions.logEvent({
      //       id: 12772,
      //       options: {
      //         field_key: "getPlayGradesIds",
      //         field_value: allData?.map(data => data?.['school id']),
      //       },
      //       type: "event",
      //       time: Date.now(),
      //     })
      //   );
      //   yield put(actions.changeInput("getPlayGradesIds", allData?.map(data => data?.['school id'])));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_GRADES_GRAPH() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_GRADES_GRAPH);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleIdInputVariable = inputVariables["intangible id input"];

      let PositionIdInputVariable = inputVariables["position id input"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["position_id"] = PositionIdInputVariable;
      payload.query_variables["intangible_id"] = IntangibleIdInputVariable;
      const response = yield call(serverApi.GetGradesGraphEndpoint, payload);
      const GetGradesGraphResponse = response.data;
      const GetGradesGraphResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 4697,
          options: {
            query_variables: {
              "6d2e6eb1-54de-4232-88e0-d77e41b45d76": PositionIdInputVariable,
              "d53d5230-cef7-4e10-835a-c89219da9b83": IntangibleIdInputVariable,
            },
            response_as: GetGradesGraphResponse,
            response_code_as: GetGradesGraphResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetGradesGraphResponseStatus === state.reducer["TwoHundred"]) {
        let JsondataVariable;
        try {
          JsondataVariable = window.JsonParse({
            jsonString: GetGradesGraphResponse,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 4699,
            options: {
              parameter_mapping: {
                "53a4f177-45d8-4547-bfb2-e7495a41c012": GetGradesGraphResponse,
              },
              return_as: JsondataVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 4701,
            options: {
              field_key: "GraphGradesData",
              field_value: JsondataVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("GraphGradesData", JsondataVariable));

        yield put(
          actions.logEvent({
            id: 4710,
            options: {
              field_key: "PlayerComparisonFilter",
              field_value: PositionIdInputVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("PlayerComparisonFilter", PositionIdInputVariable)
        );

        yield put(
          actions.logEvent({
            id: 4711,
            options: {
              field_key: "PlayerComparisonIntangible",
              field_value: IntangibleIdInputVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayerComparisonIntangible",
            IntangibleIdInputVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAYER_USERS() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAYER_USERS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      if (inputVariables?.isDefault) {
        yield put(actions.changeInput("isLoaded", true));
        yield put(actions.changeInput("isDefaultLoaded", true));
      }
      let intangibleValue = inputVariables?.["intangible id input"];
      let positionsValue = inputVariables?.["position id input"];

      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["position_id"] = positionsValue;
      payload.query_variables["intangible_id"] = intangibleValue;
      payload.query_variables["text_search"] = inputVariables?.rosterValue
        ? inputVariables?.rosterValue
        : "";
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.GetComparisonReports, payload);
      const GetComparisonReportsResponse = response.data;
      const GetComparisonReportsStatus = response.status;
      if (state.reducer["TwoHundred"] === GetComparisonReportsStatus) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["year"] = state.page?.year
          ? state.page?.year
          : state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
        const response = yield call(serverApi.GetIntagiblesEndpoint, payload);
        const GetIntagiblesResponse = response.data;
        const GetIntagiblesResponseStatus = response.status;
        if (GetIntagiblesResponseStatus === state.reducer["TwoHundred"]) {
          yield put(actions.changeInput("Intangibles", GetIntagiblesResponse));
        }
        yield put(
          actions.changeInput("PlayerCompare", GetComparisonReportsResponse)
        );
        if (inputVariables?.isDefault) {
          yield put(actions.changeInput("isLoaded", false));
          yield put(actions.changeInput("isDefaultLoaded", false));
        }
      } else {
        if (inputVariables?.isDefault) {
          yield put(actions.changeInput("isLoaded", false));
          yield put(actions.changeInput("isDefaultLoaded", false));
        }
      }
    } catch (error) {
      yield put(actions.changeInput("isLoaded", false));
      console.warn(error);
    }
  }
}
function* PLAYER_POSITION_COLLECTION() {
  while (true) {
    let { inputVariables } = yield take(actions.PLAYER_POSITION_COLLECTION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["position_id"] =
        state.reducer?.filterPlayerPositions === 0 ||
        state.reducer?.filterPlayerPositions === undefined
          ? -1
          : state.reducer?.filterPlayerPositions;
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;

      const positionResponse = yield call(serverApi.FilterIntangibles, payload);
      const GetPlayerPositionResponseVariable = positionResponse.data;
      const GetPlayerPositionResponseCodeVariable = positionResponse.status;
      if (
        GetPlayerPositionResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 3461,
            options: {
              field_key: "PlayerComparisonIntangibleCollection",
              field_value: GetPlayerPositionResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.changeInput(
            "PlayerComparisonIntangibleCollection",
            GetPlayerPositionResponseVariable
          )
        );
        yield put(actions.changeInput("filterPlayerIntangible", -1));
        yield put(
          actions.runAction("GET_PLAYER_USERS", {
            inputVariables: {
              "intangible id input": -1,
              "position id input": state.reducer?.filterPlayerPositions,
              rosterValue: inputVariables?.searchValue
                ? inputVariables?.searchValue
                : "",
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* PLAYER_INTANGIBLE_COLLECTION() {
  while (true) {
    let { inputVariables } = yield take(actions.PLAYER_INTANGIBLE_COLLECTION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.runAction("GET_PLAYER_USERS", {
          inputVariables: {
            "intangible id input": state.reducer?.filterPlayerIntangible,
            "position id input": state.reducer?.filterPlayerPositions,
            rosterValue: inputVariables?.searchValue
              ? inputVariables?.searchValue
              : "",
          },
        })
      );
    } catch (error) {
      console.warn(error);
    }
  }
}

function* CLEAR_COMPARE_VALUE() {
  while (true) {
    let { inputVariables } = yield take(actions.CLEAR_COMPARE_VALUE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("filterPlayerIntangible", -1));
      yield put(actions.changeInput("filterPlayerPositions", -1));
    } catch (error) {
      console.warn(error);
    }
  }
}
// function* PLAYER_INTANGIBLE_COLLECTION(){
//   while (true) {
//     let { inputVariables } = yield take(actions.PLAYER_INTANGIBLE_COLLECTION);
//     let state = yield select();
//     state.page =
//       state.routing && state.routing.locationBeforeTransitions
//         ? state.routing.locationBeforeTransitions.query
//         : {};
//     let payload;

//     try {
//       const playerResponse = yield call(
//         serverApi.PlayerComparisonIntangible,
//         payload
//       );
//       const GetPlayerIntagibleResponseVariable = playerResponse.data;
//       const GetPlayerIntagibleResponseCodeVariable = playerResponse.status;
//       if (GetPlayerIntagibleResponseCodeVariable === state.reducer["TwoHundred"]) {
//         yield put(
//           actions.logEvent({
//             id: 3461,
//             options: {
//               field_key: "PlayerComparisonIntangibleCollection",
//               field_value: GetPlayerIntagibleResponseVariable,
//             },
//             type: "event",
//             time: Date.now(),
//           })
//         );
//         yield put(
//           actions.changeInput(
//             "PlayerComparisonIntangibleCollection",
//             GetPlayerIntagibleResponseVariable
//           )
//         );
//         yield put(
//           actions.runAction("GET_GRADES_GRAPH", {
//             inputVariables: { "intangible id input": inputVariables["intangible id input"],
//             "position id input": inputVariables["position id input"],},
//           })
//         );
//         }
//     }
//     catch (error) {
//       console.warn(error);
//     }
//   }
// }
function* GET_GROUPED_CHARTS() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_GROUPED_CHARTS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      if (inputVariables?.isDefault) {
        yield put(actions.changeInput("isLoaded", true));
        yield put(actions.changeInput("isDefaultLoaded", true));
      }
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.GetGroupedChartsEndpoint, payload);
      const GetGroupedChartsResponse = response.data;
      const GetGroupedChartsResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3459,
          options: {
            response_as: GetGroupedChartsResponse,
            response_code_as: GetGroupedChartsResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetGroupedChartsResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3461,
            options: {
              field_key: "GroupedDepthCharts",
              field_value: GetGroupedChartsResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("GroupedDepthCharts", GetGroupedChartsResponse)
        );
        if (inputVariables?.isDefault) {
          yield put(actions.changeInput("isLoaded", false));
          yield put(actions.changeInput("isDefaultLoaded", false));
          yield put(
            actions.runAction("LOADING_EVENT", {
              inputVariables: { loader: false },
            })
          );
        }
      } else {
        if (inputVariables?.isDefault) {
          yield put(actions.changeInput("isLoaded", false));
          yield put(actions.changeInput("isDefaultLoaded", false));
          yield put(
            actions.runAction("LOADING_EVENT", {
              inputVariables: { loader: false },
            })
          );
        }
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}
function* GET_INJURED_PLAYERS() {
  while (true) {
    yield take(actions.GET_INJURED_PLAYERS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      const response = yield call(serverApi.GetInjuredPlayersEndpoint, null);
      const GetInjuredPlayersResponse = response.data;
      const GetInjuredPlayersResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3209,
          options: {
            response_as: GetInjuredPlayersResponse,
            response_code_as: GetInjuredPlayersResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetInjuredPlayersResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3211,
            options: {
              field_key: "InjuredPlayers",
              field_value: GetInjuredPlayersResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("InjuredPlayers", GetInjuredPlayersResponse)
        );
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
      } else {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}
function* GET_INTANGIBLE_SORTING() {
  while (true) {
    yield take(actions.GET_INTANGIBLE_SORTING);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let FormatDateVariable;
      try {
        FormatDateVariable = window.FormatDate({
          datestring: state.reducer["FormSearchGradesDate"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 8213,
          options: {
            parameter_mapping: {
              "1eb12004-d380-4086-9731-feecf4591dc6":
                state.reducer["FormSearchGradesDate"],
            },
            return_as: FormatDateVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["position_id"] =
        state.reducer["FormSearchGradesPosition"];
      payload.query_variables["type"] = state.reducer["FormSearchGradesType"];
      payload.query_variables["date"] = FormatDateVariable;
      const response8214 = yield call(
        serverApi.GetIntangibleSortingEndpoint,
        payload
      );
      const GetIntangibleSortingResponseVariable = response8214.data;
      const GetIntangibleSortingResponseCodeVariable = response8214.status;
      yield put(
        actions.logEvent({
          id: 8214,
          options: {
            query_variables: {
              "64940b15-aea8-429f-8c2b-0ec0433d0daa":
                state.reducer["FormSearchGradesPosition"],
              "a06ed137-34e6-4215-b434-5ea5fa674b43":
                state.reducer["FormSearchGradesType"],
              "fcd95c95-1d29-4b1b-b79e-3f9774dd7b66": FormatDateVariable,
            },
            response_as: GetIntangibleSortingResponseVariable,
            response_code_as: GetIntangibleSortingResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetIntangibleSortingResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 8216,
            options: {
              field_key: "AverageGrades",
              field_value: GetIntangibleSortingResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "AverageGrades",
            GetIntangibleSortingResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_INTANGIBLES() {
  while (true) {
    yield take(actions.GET_INTANGIBLES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
        const GetSeasonYearResponseStatus = response.status;
        if (GetSeasonYearResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 4702,
              options: {
                field_key: "SeasonYearData",
                field_value: GetSeasonYearResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("SeasonYearData", GetSeasonYearResponse)
          );
        }
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === ""
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.GetIntagiblesEndpoint, payload);
      const GetIntagiblesResponse = response.data;
      const GetIntagiblesResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3140,
          options: {
            response_as: GetIntagiblesResponse,
            response_code_as: GetIntagiblesResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetIntagiblesResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
        yield put(
          actions.logEvent({
            id: 12366,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade": GetIntagiblesResponse,
              },
              return_as: GetIntagiblesResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 3142,
            options: {
              field_key: "Intangibles",
              field_value: GetIntagiblesResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Intangibles", GetIntagiblesResponse));
      } else {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}
function* GET_INTANGIBLES_FOR_REPORT() {
  while (true) {
    yield take(actions.GET_INTANGIBLES_FOR_REPORT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.logEvent({
          id: 3533,
          options: {},
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.runAction("GET_POSITIONS", {
          inputVariables: {},
        })
      );
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      const response3144 = yield call(serverApi.GetPositionsEndpoint, null);
      const GetPositionsResponseVariable = response3144.data;
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["position_id"] = -1;
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response3140 = yield call(serverApi.FilterIntangibles, payload);
      const GetIntagiblesResponseVariable = response3140.data;
      const GetIntagiblesResponseCodeVariable = response3140.status;
      yield put(
        actions.logEvent({
          id: 3140,
          options: {
            response_as: GetIntagiblesResponseVariable,
            response_code_as: GetIntagiblesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetIntagiblesResponseCodeVariable === state.reducer["TwoHundred"]) {
        let IntangibleswithcolorVariable;
        try {
          IntangibleswithcolorVariable = window.GenerateColor({
            somelist: GetIntagiblesResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 12366,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetIntagiblesResponseVariable,
              },
              return_as: IntangibleswithcolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 3142,
            options: {
              field_key: "Intangibles",
              field_value: IntangibleswithcolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("Intangibles", IntangibleswithcolorVariable)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_INTANGIBLES_FOR_VIEW_ROW() {
  while (true) {
    yield take(actions.GET_INTANGIBLES_FOR_VIEW_ROW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === ""
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;
      const response3140 = yield call(serverApi.GetIntagiblesEndpoint, payload);
      const GetIntagiblesResponseVariable = response3140.data;
      const GetIntagiblesResponseCodeVariable = response3140.status;
      yield put(
        actions.logEvent({
          id: 3140,
          options: {
            response_as: GetIntagiblesResponseVariable,
            response_code_as: GetIntagiblesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetIntagiblesResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 12366,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetIntagiblesResponseVariable,
              },
              return_as: GetIntagiblesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 3142,
            options: {
              field_key: "IntangiblesNew",
              field_value: GetIntagiblesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("IntangiblesNew", GetIntagiblesResponseVariable)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_INTANGIBLES_GRAPH() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_INTANGIBLES_GRAPH);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let UserIdVariable = inputVariables["user id "];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["userId"] = state.page["userid"];
      const response12362 = yield call(
        serverApi.GetIntangiblesGraphEndpoint,
        payload
      );
      const GetIntangiblesGraphResponseVariable = response12362.data;
      const GetIntangiblesGraphResponseCodeVariable = response12362.status;
      yield put(
        actions.logEvent({
          id: 12362,
          options: {
            query_variables: {
              "a0f23168-8e23-4c05-9fe2-f5a7ee0cc9d2": state.page["userid"],
            },
            response_as: GetIntangiblesGraphResponseVariable,
            response_code_as: GetIntangiblesGraphResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetIntangiblesGraphResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        let JsonDataaVariable;
        try {
          JsonDataaVariable = window.JsonParseUserId({
            jsonStringUserId: GetIntangiblesGraphResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 12364,
            options: {
              parameter_mapping: {
                "1738818c-d4ae-4410-a3ff-2eb0a970fd66":
                  GetIntangiblesGraphResponseVariable,
              },
              return_as: JsonDataaVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 12365,
            options: {
              field_key: "IntangibleData",
              field_value: JsonDataaVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("IntangibleData", JsonDataaVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_SCHOOL_REPORT() {
  while (true) {
    yield take(actions.GET_SCHOOL_REPORT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      yield put(actions.changeInput("isLoaded", true));
      const response = yield call(serverApi.GetSchoolReportEndpoint, null);
      const GetSchoolReportResponse = response.data;
      const GetSchoolReportResponseStatus = response.status;
      if (GetSchoolReportResponseStatus === state.reducer["TwoHundred"]) {
        yield put(actions.changeInput("schoolReport", GetSchoolReportResponse));
        yield put(actions.changeInput("isLoaded", false));
      } else {
        yield put(actions.changeInput("isLoaded", false));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PRODUCTION_POINT() {
  while (true) {
    yield take(actions.GET_PRODUCTION_POINT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      const response = yield call(serverApi.GetProductionEndpoint, null);
      const GetProductionPointResponse = response.data;
      const GetProductionPointResponseStatus = response.status;
      if (GetProductionPointResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.changeInput("productionPoints", GetProductionPointResponse)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_SCHOOL_PRODUCTION_POINT() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_SCHOOL_PRODUCTION_POINT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let positionId = "";
      if (inputVariables?.position_id) {
        positionId = state.reducer?.Positions?.find(
          (value) => value?.name === inputVariables?.position_id
        );
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["position_id"] = positionId?.id
        ? positionId?.id
        : positionId;
      const response = yield call(
        serverApi.GetSchoolProductionEndpoint,
        payload
      );
      const GetSchoolProductionPointResponse = response.data;
      const GetSchoolProductionPointResponseStatus = response.status;
      if (
        GetSchoolProductionPointResponseStatus === state.reducer["TwoHundred"]
      ) {
        let productionPointById = GetSchoolProductionPointResponse?.filter(
          (item) => item?.production_point && item?.production_point?.name
        );
        yield put(
          actions.changeInput("productionPointsData", productionPointById)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SEND_NEW_PRODUCTION_POINT_REQUEST() {
  while (true) {
    let { inputVariables } = yield take(
      actions.SEND_NEW_PRODUCTION_POINT_REQUEST
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let positionId = "";
      if (inputVariables?.position_id) {
        positionId = state.reducer?.Positions?.find(
          (value) => value?.name === inputVariables?.position_id
        );
      }
      payload = {};
      payload["body_variables"] = {};
      payload.body_variables["name"] = inputVariables?.name;
      payload.body_variables["position_id"] = positionId?.id;
      const response = yield call(serverApi.SendNewProductionEndpoint, payload);
      const SendNewProductionPointResponse = response.data;
      const SendNewProductionPointResponseStatus = response.status;
      if (
        SendNewProductionPointResponseStatus === state.reducer["TwoHundred"]
      ) {
        inputVariables?.callback({
          ...SendNewProductionPointResponse,
          status: SendNewProductionPointResponseStatus,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* ADD_SCHOOL_PRODUCTION_POINT() {
  while (true) {
    let { inputVariables } = yield take(actions.ADD_SCHOOL_PRODUCTION_POINT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.setField("AddedProductionData", ""));
      let finArr = inputVariables?.listPayload?.map((v) => ({
        position_id: v?.position_id,
        production_id: v?.production_id,
      }));
      payload = {};
      payload["body_variables"] = {};
      if (finArr && isArray(finArr)) {
        payload.body_variables = finArr[0];
      }
      const response = yield call(
        serverApi.CreateSchoolProductionEndpoint,
        payload
      );
      const CreateProductionPointResponse = response.data;
      const CreateProductionPointResponseStatus = response.status;

      if (CreateProductionPointResponseStatus === state.reducer["TwoHundred"]) {
        if (!state.reducer?.productionPointToDelete) {
          yield put(
            actions.setField("AddedProductionData", "Added Successfully")
          );
        } else {
          yield put(
            actions.setField("AddedProductionData", "Deleted Successfully")
          );
        }
        yield put(actions.changeInput("productionPointToDelete", false));

        // yield put(
        //   actions.runAction("GET_PRODUCTION_POINT", { inputVariables: {} })
        // );
        yield put(
          actions.runAction("GET_SCHOOL_PRODUCTION_POINT", {
            inputVariables: {},
          })
        );
      } else {
        yield put(
          actions.setField(
            "AddedProductionData",
            CreateProductionPointResponse?.message
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* ADD_PRODUCTION_POINT() {
  while (true) {
    let { inputVariables } = yield take(actions.ADD_PRODUCTION_POINT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;
    try {
      payload = {};
      payload["body_variables"] = {};
      payload.body_variables["name"] = inputVariables?.value;
      const response = yield call(serverApi.CreateProductionEndpoint, payload);
      const responseData = response.data;
      const AddProductionPointReponseStatus = response.status;

      if (AddProductionPointReponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.changeInput(
            "productionPointMessage",
            "New production point added."
          )
        );
        yield put(
          actions.runAction("GET_PRODUCTION_POINT", { inputVariables: {} })
        );
        yield call(actions.delay, 5000);
        yield put(actions.changeInput("productionPointMessage", ""));
      } else {
        yield put(
          actions.changeInput(
            "productionPointMessage",
            "Production point already exists."
          )
        );
        yield call(actions.delay, 5000);
        yield put(actions.changeInput("productionPointMessage", ""));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* PRODUCTION_POINT_ERROR_MESSAGE() {
  while (true) {
    let { inputVariables } = yield take(actions.PRODUCTION_POINT_ERROR_MESSAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    try {
      if (state.reducer?.productionPointError) {
        yield put(
          actions.changeInput(
            "productionPointErrorMessage",
            inputVariables?.message
          )
        );
        yield put(
          actions.changeInput("productionPointErrorId", inputVariables?.id)
        );
      } else {
        yield put(actions.changeInput("productionPointErrorMessage", ""));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_NUMBER_ROSTER() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_NUMBER_ROSTER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleiiidVariable = inputVariables["intangibleiiid"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleiiidVariable;
      const response7923 = yield call(
        serverApi.GetNumberRosterEndpoint,
        payload
      );
      const GetNumberRosterResponseVariable = response7923.data;
      const GetNumberRosterResponseCodeVariable = response7923.status;
      yield put(
        actions.logEvent({
          id: 7923,
          options: {
            query_variables: {
              "46679213-a841-429d-b203-7dce7d39fdfc": IntangibleiiidVariable,
            },
            response_as: GetNumberRosterResponseVariable,
            response_code_as: GetNumberRosterResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetNumberRosterResponseCodeVariable === state.reducer["TwoHundred"]) {
        let UserswcolorVariable;
        try {
          UserswcolorVariable = window.GenerateColor({
            somelist: GetNumberRosterResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 7925,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetNumberRosterResponseVariable,
              },
              return_as: UserswcolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 7926,
            options: {
              field_key: "Users",
              field_value: UserswcolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", UserswcolorVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_NUMBER_ROSTER_DES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_NUMBER_ROSTER_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleiddddVariable = inputVariables["intangibleidddd"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleiddddVariable;
      const response7927 = yield call(
        serverApi.GetNumberRosterDesEndpoint,
        payload
      );
      const GetNumberRosterDesResponseVariable = response7927.data;
      const GetNumberRosterDesResponseCodeVariable = response7927.status;
      yield put(
        actions.logEvent({
          id: 7927,
          options: {
            query_variables: {
              "3d0aa35d-4a89-4bf1-9800-37881d483e59": IntangibleiddddVariable,
            },
            response_as: GetNumberRosterDesResponseVariable,
            response_code_as: GetNumberRosterDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetNumberRosterDesResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        let UserswicolorVariable;
        try {
          UserswicolorVariable = window.GenerateColor({
            somelist: GetNumberRosterDesResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 7929,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetNumberRosterDesResponseVariable,
              },
              return_as: UserswicolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 7930,
            options: {
              field_key: "Users",
              field_value: UserswicolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", UserswicolorVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PACKAGES() {
  while (true) {
    yield take(actions.GET_PACKAGES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      const response = yield call(serverApi.GetCurrentUserEndpoint, null);
      const GetCurrentUserResponse = response.data;
      const GetCurrentUserResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3078,
          options: {
            response_as: GetCurrentUserResponse,
            response_code_as: GetCurrentUserResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetCurrentUserResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3080,
            options: {
              field_key: "CurrentUser",
              field_value: GetCurrentUserResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("CurrentUser", GetCurrentUserResponse));
      }
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const seasonYearResponse = yield call(
          serverApi.GetSeasonYearEndpoint,
          payload
        );
        GetSeasonYearResponse = seasonYearResponse.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["schoolid"] = GetCurrentUserResponse?.school_id;
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === ""
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;
      const packagesResponse = yield call(
        serverApi.GetPackagesEndpoint,
        payload
      );
      const GetPackagesResponse = packagesResponse.data;
      const GetPackagesResponseStatus = packagesResponse.status;
      yield put(
        actions.logEvent({
          id: 10899,
          options: {
            query_variables: {
              "737c6d7d-c463-4ac6-9005-95ab18751468":
                state.reducer["CurrentUser"]["school_id"],
            },
            response_as: GetPackagesResponse,
            response_code_as: GetPackagesResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetPackagesResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 10901,
            options: {
              field_key: "Packages",
              field_value: GetPackagesResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Packages", GetPackagesResponse));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_BY_PLAY_INFO() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_BY_PLAY_INFO);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GameidVariable = inputVariables["gameid"];
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = GameidVariable;
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : null;
      const response = yield call(serverApi.GetPlayByPlayInfoEndpoint, payload);
      const GetPlayResponse = response.data;
      const GetPlayResponseStatus = response.status;
      if (inputVariables?.callback) {
        inputVariables?.callback(response);
      }
      yield put(
        actions.logEvent({
          id: 11823,
          options: {
            query_variables: {
              "627a4f98-f404-4952-86f6-bb1a5ad09258": GameidVariable,
            },
            response_as: GetPlayResponse,
            response_code_as: GetPlayResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetPlayResponseStatus === state.reducer["TwoHundred"]) {
        let playInfoData =
          GetPlayResponse &&
          isArray(GetPlayResponse) &&
          GetPlayResponse?.map((v) => ({ ...v, isChecked: false }));
        yield put(
          actions.logEvent({
            id: 11824,
            options: {
              field_key: "PlayByPlayInfo",
              field_value: playInfoData,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("PlayByPlayInfo", playInfoData));
        yield put(actions.removeField("playByPlayGradingMessage"));
      } else {
        yield put(
          actions.logEvent({
            id: 11824,
            options: {
              field_key: "playByPlayGradingMessage",
              field_value: GetPlayResponse?.message,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "playByPlayGradingMessage",
            GetPlayResponse?.message
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_AFTER_REFRESH() {
  while (true) {
    yield take(actions.GET_PLAY_INFO_AFTER_REFRESH);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.logEvent({
          id: 14229,
          options: {
            field_key: "PlayByPlayInfo",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.removeField("PlayByPlayInfo"));

      yield put(
        actions.logEvent({
          id: 14230,
          options: {
            parameter_mapping: {
              "e556c934-6791-465b-99d5-4ccad85c63f3":
                state.reducer["CurrentGame"]["Id"],
            },
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.runAction("GET_PLAY_BY_PLAY_INFO", {
          inputVariables: { gameid: state.reducer["CurrentGame"]["Id"] },
        })
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_DOWN() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_DOWN);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DownGameIdVariable = inputVariables["down_game_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = DownGameIdVariable;
      const response12700 = yield call(
        serverApi.GetPlayInfoByDownEndpoint,
        payload
      );
      const GetPlayInfoByDownResponseVariable = response12700.data;
      const GetPlayInfoByDownResponseCodeVariable = response12700.status;
      yield put(
        actions.logEvent({
          id: 12700,
          options: {
            query_variables: {
              "c733915c-88b4-405c-b0ca-213ba2651029": DownGameIdVariable,
            },
            response_as: GetPlayInfoByDownResponseVariable,
            response_code_as: GetPlayInfoByDownResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoByDownResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12702,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoByDownResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoByDownResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_DOWN_DES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_DOWN_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DownDesGameIdVariable = inputVariables["down_des_game_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = DownDesGameIdVariable;
      const response12703 = yield call(
        serverApi.GetPlayInfoByDownDesEndpoint,
        payload
      );
      const GetPlayInfoByDownDesResponseVariable = response12703.data;
      const GetPlayInfoByDownDesResponseCodeVariable = response12703.status;
      yield put(
        actions.logEvent({
          id: 12703,
          options: {
            query_variables: {
              "4b3a9a5c-46f4-4b24-878e-7de01b46a396": DownDesGameIdVariable,
            },
            response_as: GetPlayInfoByDownDesResponseVariable,
            response_code_as: GetPlayInfoByDownDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoByDownDesResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12705,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoByDownDesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoByDownDesResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_INTANGIBLE() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_INTANGIBLE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleGameIdVariable = inputVariables["intangible_game_id"];

      let IntangibleIdVariable = inputVariables["intangible_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleIdVariable;
      payload.query_variables["game id"] = IntangibleGameIdVariable;
      const response12722 = yield call(
        serverApi.GetPlayInfoByIntangibleEndpoint,
        payload
      );
      const GetPlayInfoByIntangibleResponseVariable = response12722.data;
      const GetPlayInfoByIntangibleResponseCodeVariable = response12722.status;
      yield put(
        actions.logEvent({
          id: 12722,
          options: {
            query_variables: {
              "54733c15-5725-4970-8b29-a181b740fff6": IntangibleIdVariable,
              "f6f33dba-439d-4f76-bc67-8fc6091f79a2": IntangibleGameIdVariable,
            },
            response_as: GetPlayInfoByIntangibleResponseVariable,
            response_code_as: GetPlayInfoByIntangibleResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoByIntangibleResponseCodeVariable ===
        state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12724,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoByIntangibleResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoByIntangibleResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_NAME() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_NAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let NameGameIdVariable = inputVariables["name_game_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = NameGameIdVariable;
      const response12655 = yield call(
        serverApi.GetPlayInfoByNameEndpoint,
        payload
      );
      const GetPlayInfoByNameResponseVariable = response12655.data;
      const GetPlayInfoByNameResponseCodeVariable = response12655.status;
      yield put(
        actions.logEvent({
          id: 12655,
          options: {
            query_variables: {
              "c834b35f-57ae-42a4-9486-6c5a1af47820": NameGameIdVariable,
            },
            response_as: GetPlayInfoByNameResponseVariable,
            response_code_as: GetPlayInfoByNameResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoByNameResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12657,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoByNameResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoByNameResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_NAME_DES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_NAME_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let NameDesGameIdVariable = inputVariables["name_des_game_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = NameDesGameIdVariable;
      const response12658 = yield call(
        serverApi.GetPlayInfoByNameDesEndpoint,
        payload
      );
      const GetPlayInfoByNameDesResponseVariable = response12658.data;
      const GetPlayInfoByNameDesResponseCodeVariable = response12658.status;
      yield put(
        actions.logEvent({
          id: 12658,
          options: {
            query_variables: {
              "bf9978cf-650c-43e0-9b4a-46afebd408dc": NameDesGameIdVariable,
            },
            response_as: GetPlayInfoByNameDesResponseVariable,
            response_code_as: GetPlayInfoByNameDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoByNameDesResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12660,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoByNameDesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoByNameDesResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_PACKAGE() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_PACKAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PackageGameIdVariable = inputVariables["package_game_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = PackageGameIdVariable;
      const response12711 = yield call(
        serverApi.GetPlayInfoByPackageEndpoint,
        payload
      );
      const GetPlayInfoByPackageResponseVariable = response12711.data;
      const GetPlayInfoByPackageResponseCodeVariable = response12711.status;
      yield put(
        actions.logEvent({
          id: 12711,
          options: {
            query_variables: {
              "63f74e42-56ed-4c70-97af-1cada58621c8": PackageGameIdVariable,
            },
            response_as: GetPlayInfoByPackageResponseVariable,
            response_code_as: GetPlayInfoByPackageResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoByPackageResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12713,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoByPackageResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoByPackageResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_PACKAGE_DES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_PACKAGE_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PackageDesGameIdVariable = inputVariables["package_des_game_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = PackageDesGameIdVariable;
      const response12714 = yield call(
        serverApi.GetPlayInfoByPackageDesEndpoint,
        payload
      );
      const GetPlayInfoByPackageDesResponseVariable = response12714.data;
      const GetPlayInfoByPackageDesResponseCodeVariable = response12714.status;
      yield put(
        actions.logEvent({
          id: 12714,
          options: {
            query_variables: {
              "1cd3f3f0-cfb2-4ecf-a8bf-fc89f7d8bf95": PackageDesGameIdVariable,
            },
            response_as: GetPlayInfoByPackageDesResponseVariable,
            response_code_as: GetPlayInfoByPackageDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoByPackageDesResponseCodeVariable ===
        state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12716,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoByPackageDesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoByPackageDesResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_QTR() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_QTR);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let QtrGameIdVariable = inputVariables["qtr_game_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = QtrGameIdVariable;
      const response12678 = yield call(
        serverApi.GetPlayInfoByQtrEndpoint,
        payload
      );
      const GetPlayInfoByQtrResponseVariable = response12678.data;
      const GetPlayInfoByQtrResponseCodeVariable = response12678.status;
      yield put(
        actions.logEvent({
          id: 12678,
          options: {
            query_variables: {
              "288d455b-a9c8-4ca3-afa8-8b4a67794f7d": QtrGameIdVariable,
            },
            response_as: GetPlayInfoByQtrResponseVariable,
            response_code_as: GetPlayInfoByQtrResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoByQtrResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12680,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoByQtrResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoByQtrResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_QTR_DES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_QTR_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let QtrDesGameIdVariable = inputVariables["qtr_des_game_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = QtrDesGameIdVariable;
      const response12681 = yield call(
        serverApi.GetPlayInfoByQtrDesEndpoint,
        payload
      );
      const GetPlayInfoByQtrDesResponseVariable = response12681.data;
      const GetPlayInfoByQtrDesResponseCodeVariable = response12681.status;
      yield put(
        actions.logEvent({
          id: 12681,
          options: {
            query_variables: {
              "20e7159d-7ca7-42f3-b1e6-e608accccb62": QtrDesGameIdVariable,
            },
            response_as: GetPlayInfoByQtrDesResponseVariable,
            response_code_as: GetPlayInfoByQtrDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoByQtrDesResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12683,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoByQtrDesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoByQtrDesResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_SERIES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_SERIES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let SeriesGameIdVariable = inputVariables["series_game_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = SeriesGameIdVariable;
      const response12689 = yield call(
        serverApi.GetPlayInfoBySeriesEndpoint,
        payload
      );
      const GetPlayInfoBySeriesResponseVariable = response12689.data;
      const GetPlayInfoBySeriesResponseCodeVariable = response12689.status;
      yield put(
        actions.logEvent({
          id: 12689,
          options: {
            query_variables: {
              "2b2514f2-b8ae-4427-a268-2b376831b128": SeriesGameIdVariable,
            },
            response_as: GetPlayInfoBySeriesResponseVariable,
            response_code_as: GetPlayInfoBySeriesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoBySeriesResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12691,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoBySeriesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoBySeriesResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_SERIES_DES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_SERIES_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let SeresDesGameIdVariable = inputVariables["seres_des_game_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = SeresDesGameIdVariable;
      const response12692 = yield call(
        serverApi.GetPlayInfoBySeriesDesEndpoint,
        payload
      );
      const GetPlayInfoBySeriesDesResponseVariable = response12692.data;
      const GetPlayInfoBySeriesDesResponseCodeVariable = response12692.status;
      yield put(
        actions.logEvent({
          id: 12692,
          options: {
            query_variables: {
              "a1f43209-7a5f-43e0-a8dd-7234a219739b": SeresDesGameIdVariable,
            },
            response_as: GetPlayInfoBySeriesDesResponseVariable,
            response_code_as: GetPlayInfoBySeriesDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoBySeriesDesResponseCodeVariable ===
        state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12694,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoBySeriesDesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoBySeriesDesResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_TYPE() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_TYPE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let TypeGameIdVariable = inputVariables["type_game_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = TypeGameIdVariable;
      const response12667 = yield call(
        serverApi.GetPlayInfoByTypeEndpoint,
        payload
      );
      const GetPlayInfoByTypeResponseVariable = response12667.data;
      const GetPlayInfoByTypeResponseCodeVariable = response12667.status;
      yield put(
        actions.logEvent({
          id: 12667,
          options: {
            query_variables: {
              "90fedffc-3217-4528-ae29-10eeee9213e9": TypeGameIdVariable,
            },
            response_as: GetPlayInfoByTypeResponseVariable,
            response_code_as: GetPlayInfoByTypeResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoByTypeResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12669,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoByTypeResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoByTypeResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_INFO_BY_TYPE_DES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_INFO_BY_TYPE_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let TypeDesGameIdVariable = inputVariables["type_des_game_id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = TypeDesGameIdVariable;
      const response12670 = yield call(
        serverApi.GetPlayInfoByTypeDesEndpoint,
        payload
      );
      const GetPlayInfoByTypeDesResponseVariable = response12670.data;
      const GetPlayInfoByTypeDesResponseCodeVariable = response12670.status;
      yield put(
        actions.logEvent({
          id: 12670,
          options: {
            query_variables: {
              "0af920a6-028f-40da-bf96-e991b0b8c37c": TypeDesGameIdVariable,
            },
            response_as: GetPlayInfoByTypeDesResponseVariable,
            response_code_as: GetPlayInfoByTypeDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayInfoByTypeDesResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12672,
            options: {
              field_key: "PlayReportsData",
              field_value: GetPlayInfoByTypeDesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetPlayInfoByTypeDesResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAY_REPORTS_DATA() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAY_REPORTS_DATA);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GameiddVariable = inputVariables["gameidd"];
      yield put(
        actions.runAction("GET_TOTAL_PLAYS", {
          inputVariables: { "input game id": GameiddVariable },
        })
      );
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] = GameiddVariable;
      payload.query_variables["position_id"] = -1;
      payload.query_variables["qtr"] = state.reducer["QuarterData"][0]?.value;
      payload.query_variables["type"] =
        state.reducer["PlayTypeArray"][0]?.value;
      payload.query_variables["series"] =
        state.reducer["SeriesTypeArray"][0]?.value;
      payload.query_variables["down"] =
        state.reducer["SelectedDownId"] === "" ||
        state.reducer["SelectedDownId"] === null
          ? state?.reducer?.DownDropDownData[0]?.value
          : state.reducer["SelectedDownId"];
      payload.query_variables["distance"] = -1;
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === ""
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;

      const response = yield call(
        serverApi.GetAverageIntangibleUsersEndpoint,
        payload
      );
      const GetAverageIntangibleUsersResponse = response.data;
      const GetAverageIntangibleUsersResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 12651,
          options: {
            query_variables: {
              "0158728a-1919-47cd-9e87-e5d3898efcc5": GameiddVariable,
            },
            response_as: GetAverageIntangibleUsersResponse,
            response_code_as: GetAverageIntangibleUsersResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetAverageIntangibleUsersResponseStatus === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12653,
            options: {
              field_key: "PlayReportsData",
              field_value: GetAverageIntangibleUsersResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayReportsData",
            GetAverageIntangibleUsersResponse
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAYER_GRADES() {
  while (true) {
    yield take(actions.GET_PLAYER_GRADES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      const response3879 = yield call(serverApi.GetPlayerGradesEndpoint, null);
      const GetPlayerGradesResponseVariable = response3879.data;
      const GetPlayerGradesResponseCodeVariable = response3879.status;
      yield put(
        actions.logEvent({
          id: 3879,
          options: {
            response_as: GetPlayerGradesResponseVariable,
            response_code_as: GetPlayerGradesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetPlayerGradesResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3881,
            options: {
              field_key: "PlayerGrades",
              field_value: GetPlayerGradesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("PlayerGrades", GetPlayerGradesResponseVariable)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAYER_GRADES_DATA() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_PLAYER_GRADES_DATA);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GradeGameIdVariable = inputVariables["grade game id"];

      let GradeUserIdVariable = inputVariables["grade user id"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] = GradeGameIdVariable;
      payload.query_variables["user_id"] = GradeUserIdVariable;
      const response = yield call(
        serverApi.GetPlayerReportsCountEndpoint,
        payload
      );
      const GetPlayerReportsResponse = response.data;
      const GetPlayerReportsResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 17309,
          options: {
            query_variables: {
              "5f8aa922-f965-430f-b07f-2d477bc71ffc": GradeGameIdVariable,
              "f9688959-26fd-4a53-98d3-b8c68200eece": GradeUserIdVariable,
            },
            response_as: GetPlayerReportsResponse,
            response_code_as: GetPlayerReportsResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetPlayerReportsResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 17311,
            options: {
              field_key: "PlayerGradesUserCount",
              field_value: GetPlayerReportsResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("PlayerGradesUserCount", GetPlayerReportsResponse)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PLAYER_REPORTS_COUNT() {
  while (true) {
    yield take(actions.GET_PLAYER_REPORTS_COUNT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
      payload.query_variables["user id"] =
        state.reducer["PlayerReportDataUser"];
      const response12761 = yield call(
        serverApi.GetPlayerReportsCountEndpoint,
        payload
      );
      const GetPlayerReportsCountResVariable = response12761.data;
      const GetPlayerReportsCountResCodeVariable = response12761.status;
      yield put(
        actions.logEvent({
          id: 12761,
          options: {
            query_variables: {
              "5f8aa922-f965-430f-b07f-2d477bc71ffc":
                state.reducer["CurrentGame"]["Id"],
              "f9688959-26fd-4a53-98d3-b8c68200eece":
                state.reducer["PlayerReportDataUser"],
            },
            response_as: GetPlayerReportsCountResVariable,
            response_code_as: GetPlayerReportsCountResCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPlayerReportsCountResCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12763,
            options: {
              field_key: "PlayerReportUsersCount",
              field_value: GetPlayerReportsCountResVariable["count"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayerReportUsersCount",
            GetPlayerReportsCountResVariable["count"]
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* GET_POSITION() {
  while (true) {
    yield take(actions.GET_POSITION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = state.reducer["FormSearchGradesPosition"];
      const response10504 = yield call(serverApi.GetPositionEndpoint, payload);
      const GetPositionResponseVariable = response10504.data;
      const GetPositionResponseCodeVariable = response10504.status;
      yield put(
        actions.logEvent({
          id: 10504,
          options: {
            query_variables: {
              "10745069-0fcf-4667-9d80-8af70d20d6ce":
                state.reducer["FormSearchGradesPosition"],
            },
            response_as: GetPositionResponseVariable,
            response_code_as: GetPositionResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetPositionResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 10506,
            options: {
              field_key: "position",
              field_value: GetPositionResponseVariable["name"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("position", GetPositionResponseVariable["name"])
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_POSITION_COACHES() {
  while (true) {
    yield take(actions.GET_POSITION_COACHES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      const response = yield call(serverApi.GetPositionCoachesEndpoint, null);
      const GetPositionCoachesResponse = response.data;
      const GetPositionCoachesResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 7509,
          options: {
            response_as: GetPositionCoachesResponse,
            response_code_as: GetPositionCoachesResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetPositionCoachesResponseStatus === state.reducer["TwoHundred"]) {
        let PositionCoachwithColorVariable;
        try {
          PositionCoachwithColorVariable = window.GenerateColor({
            somelist: GetPositionCoachesResponse,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 7511,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetPositionCoachesResponse,
              },
              return_as: PositionCoachwithColorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 7691,
            options: {
              field_key: "PositionCoaches",
              field_value: PositionCoachwithColorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("PositionCoaches", PositionCoachwithColorVariable)
        );

        yield put(
          actions.logEvent({
            id: 13966,
            options: {
              field_key: "SuccessMessage",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("SuccessMessage"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_POSITIONS() {
  while (true) {
    yield take(actions.GET_POSITIONS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      const response = yield call(serverApi.GetPositionsEndpoint, null);
      const GetPositionsResponse = response.data;
      const GetPositionsResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3144,
          options: {
            response_as: GetPositionsResponse,
            response_code_as: GetPositionsResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetPositionsResponseStatus === state.reducer["TwoHundred"]) {
        let PositionsiwthcolorVariable;
        try {
          PositionsiwthcolorVariable = window.GenerateColor({
            somelist: GetPositionsResponse,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 3146,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade": GetPositionsResponse,
              },
              return_as: PositionsiwthcolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 4580,
            options: {
              field_key: "Positions",
              field_value: PositionsiwthcolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Positions", PositionsiwthcolorVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_POSITIONS_DES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_POSITIONS_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleidddVariable = inputVariables["intangibleiddd"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleidddVariable;
      const response7862 = yield call(
        serverApi.GetPositionsDesEndpoint,
        payload
      );
      const GetPositionsDesResponseVariable = response7862.data;
      const GetPositionsDesResponseCodeVariable = response7862.status;
      yield put(
        actions.logEvent({
          id: 7862,
          options: {
            query_variables: {
              "c4fedf27-6758-4629-962e-1486d6d077f3": IntangibleidddVariable,
            },
            response_as: GetPositionsDesResponseVariable,
            response_code_as: GetPositionsDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetPositionsDesResponseCodeVariable === state.reducer["TwoHundred"]) {
        let PositionsiwthcolordesVariable;
        try {
          PositionsiwthcolordesVariable = window.GenerateColor({
            somelist: GetPositionsDesResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 7864,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetPositionsDesResponseVariable,
              },
              return_as: PositionsiwthcolordesVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 7865,
            options: {
              field_key: "Users",
              field_value: PositionsiwthcolordesVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", PositionsiwthcolordesVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_POSITIONS_ROSTER() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_POSITIONS_ROSTER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleiddVariable = inputVariables["Intangibleidd"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleiddVariable;
      const response7906 = yield call(
        serverApi.GetPositionRosterEndpoint,
        payload
      );
      const GetPositionRosterResponseVariable = response7906.data;
      const GetPositionRosterResponseCodeVariable = response7906.status;
      yield put(
        actions.logEvent({
          id: 7906,
          options: {
            query_variables: {
              "edf80c60-841b-44d0-bbc9-663f6262cc9b": IntangibleiddVariable,
            },
            response_as: GetPositionRosterResponseVariable,
            response_code_as: GetPositionRosterResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetPositionRosterResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        let UserswithcolorrVariable;
        try {
          UserswithcolorrVariable = window.GenerateColor({
            somelist: GetPositionRosterResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 7908,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetPositionRosterResponseVariable,
              },
              return_as: UserswithcolorrVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 7909,
            options: {
              field_key: "Users",
              field_value: UserswithcolorrVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", UserswithcolorrVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_PRACTICES() {
  while (true) {
    yield take(actions.GET_PRACTICES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const seasonYeaResponse = yield call(
          serverApi.GetSeasonYearEndpoint,
          payload
        );
        GetSeasonYearResponse = seasonYeaResponse.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.GetPracticesEndpoint, payload);
      const GetPracticesResponse = response.data;
      const GetPracticesResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 12168,
          options: {
            response_as: GetPracticesResponse,
            response_code_as: GetPracticesResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetPracticesResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
        yield put(
          actions.logEvent({
            id: 12170,
            options: {
              field_key: "Practices",
              field_value: GetPracticesResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Practices", GetPracticesResponse));
      } else {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}
function* GET_QUIZ_QUESTIONS() {
  while (true) {
    yield take(actions.GET_QUIZ_QUESTIONS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      const response3261 = yield call(serverApi.GetQuizQuestionsEndpoint, null);
      const GetManyResponseVariable = response3261.data;
      const GetManyResponseCodeVariable = response3261.status;
      yield put(
        actions.logEvent({
          id: 3261,
          options: {
            response_as: GetManyResponseVariable,
            response_code_as: GetManyResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetManyResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3263,
            options: {
              field_key: "QuizQuestions",
              field_value: GetManyResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("QuizQuestions", GetManyResponseVariable)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_QUIZZES() {
  while (true) {
    yield take(actions.GET_QUIZZES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      const response3251 = yield call(serverApi.GetQuizzesEndpoint, null);
      const GetQuizzesResponseVariable = response3251.data;
      const GetQuizzesResponseCodeVariable = response3251.status;
      yield put(
        actions.logEvent({
          id: 3251,
          options: {
            response_as: GetQuizzesResponseVariable,
            response_code_as: GetQuizzesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetQuizzesResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3253,
            options: {
              field_key: "Quizzes",
              field_value: GetQuizzesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Quizzes", GetQuizzesResponseVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_REPORTS_POSITION_FIELD() {
  while (true) {
    yield take(actions.GET_REPORTS_POSITION_FIELD);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      if (
        state.reducer["PositionsModel"]["Id"] ===
        state.reducer["FormSearchGradesPosition"]
      ) {
        yield put(
          actions.logEvent({
            id: 10500,
            options: {
              field_key: "GameTypeMsg",
              field_value: state.reducer["PositionsModel"]["name"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "GameTypeMsg",
            state.reducer["PositionsModel"]["name"]
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
// function* GET_SCHOOLS() {
//   while (true) {
//     yield take(actions.GET_SCHOOLS);
//     let state = yield select();
//     state.page =
//       state.routing && state.routing.locationBeforeTransitions
//         ? state.routing.locationBeforeTransitions.query
//         : {};
//     let payload;

//     try {
//       const response3307 = yield call(serverApi.GetSchoolsEndpoint, null);
//       const GetSchoolsResponseVariable = response3307.data;
//       const GetSchoolsResponseCodeVariable = response3307.status;
//       yield put(
//         actions.logEvent({
//           id: 3307,
//           options: {
//             response_as: GetSchoolsResponseVariable,
//             response_code_as: GetSchoolsResponseCodeVariable,
//           },
//           type: "event",
//           time: Date.now(),
//         })
//       );
//       if (GetSchoolsResponseCodeVariable === state.reducer["TwoHundred"]) {
//         yield put(
//           actions.logEvent({
//             id: 3309,
//             options: {
//               field_key: "Schools",
//               field_value: GetSchoolsResponseVariable,
//             },
//             type: "event",
//             time: Date.now(),
//           })
//         );
//         yield put(actions.changeInput("Schools", GetSchoolsResponseVariable));
//       }
//     } catch (error) {
//       console.warn(error);
//     }
//   }
// }
function* GET_SECONDARY_ROSTER() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_SECONDARY_ROSTER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleVariable = inputVariables["intangible"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleVariable;
      const response7936 = yield call(
        serverApi.GetSecondaryRosterEndpoint,
        payload
      );
      const GetSecondaryRosterResponseVariable = response7936.data;
      const GetSecondaryRosterResponseCodeVariable = response7936.status;
      yield put(
        actions.logEvent({
          id: 7936,
          options: {
            query_variables: {
              "9eb52709-77db-46f0-a879-30756e9cbb37": IntangibleVariable,
            },
            response_as: GetSecondaryRosterResponseVariable,
            response_code_as: GetSecondaryRosterResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetSecondaryRosterResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        let UsersVariable;
        try {
          UsersVariable = window.GenerateColor({
            somelist: GetSecondaryRosterResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 7938,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetSecondaryRosterResponseVariable,
              },
              return_as: UsersVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 7939,
            options: {
              field_key: "Users",
              field_value: UsersVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", UsersVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_SECONDARY_ROSTER_DES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_SECONDARY_ROSTER_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleeVariable = inputVariables["intangiblee"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleeVariable;
      const response7940 = yield call(
        serverApi.GetSecondaryRosterDesEndpoint,
        payload
      );
      const GetSecondaryRosterDesResponseVariable = response7940.data;
      const GetSecondaryRosterDesResponseCodeVariable = response7940.status;
      yield put(
        actions.logEvent({
          id: 7940,
          options: {
            query_variables: {
              "9e980c4f-700c-4312-97b4-2b97ca64e623": IntangibleeVariable,
            },
            response_as: GetSecondaryRosterDesResponseVariable,
            response_code_as: GetSecondaryRosterDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetSecondaryRosterDesResponseCodeVariable ===
        state.reducer["TwoHundred"]
      ) {
        let UserscolorrVariable;
        try {
          UserscolorrVariable = window.GenerateColor({
            somelist: GetSecondaryRosterDesResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 7942,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetSecondaryRosterDesResponseVariable,
              },
              return_as: UserscolorrVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 7943,
            options: {
              field_key: "Users",
              field_value: UserscolorrVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", UserscolorrVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_SUPPORT_PAGE() {
  while (true) {
    yield take(actions.GET_SUPPORT_PAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let SupportPageVariable;
      try {
        SupportPageVariable = window.Support({});
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 19123,
          options: {
            parameter_mapping: {},
            return_as: SupportPageVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_TAGS() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_TAGS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      if (inputVariables?.isDefault) {
        yield put(actions.changeInput("isLoaded", true));
      }
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const seasonYearlyResponse = yield call(
          serverApi.GetSeasonYearEndpoint,
          payload
        );
        GetSeasonYearResponse = seasonYearlyResponse.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === ""
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.GetTagsEndpoint, payload);
      const GetTagsResponse = response.data;
      const GetTagsResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 4459,
          options: {
            response_as: GetTagsResponse,
            response_code_as: GetTagsResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetTagsResponseStatus === state.reducer["TwoHundred"]) {
        if (inputVariables?.isDefault) {
          yield put(actions.changeInput("isLoaded", false));
          yield put(
            actions.runAction("LOADING_EVENT", {
              inputVariables: { loader: false },
            })
          );
        }
        yield put(
          actions.logEvent({
            id: 4461,
            options: {
              field_key: "AllTags",
              field_value: GetTagsResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("AllTags", GetTagsResponse));
      } else {
        if (inputVariables?.isDefault) {
          yield put(actions.changeInput("isLoaded", true));
          yield put(
            actions.runAction("LOADING_EVENT", {
              inputVariables: { loader: false },
            })
          );
        }
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}
function* GET_TOTAL_PLAYS() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_TOTAL_PLAYS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputGameIdVariable = inputVariables?.["input game id"];
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] = InputGameIdVariable;
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === ""
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;
      const response12512 = yield call(
        serverApi.GetTotalPlaysEndpoint,
        payload
      );
      const GetTotalPlaysResponseVariable = response12512.data;
      const GetTotalPlaysResponseCodeVariable = response12512.status;
      yield put(
        actions.logEvent({
          id: 12512,
          options: {
            query_variables: {
              "972b6dd1-5fcf-4fa5-9f3c-469ff79f8996": InputGameIdVariable,
            },
            response_as: GetTotalPlaysResponseVariable,
            response_code_as: GetTotalPlaysResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetTotalPlaysResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 12514,
            options: {
              field_key: "TotalPlays",
              field_value: GetTotalPlaysResponseVariable["count"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "TotalPlays",
            GetTotalPlaysResponseVariable["count"]
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_USER() {
  while (true) {
    yield take(actions.GET_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state?.page["userid"];
      const response = yield call(serverApi.GetUserEndpoint, payload);
      const GetUserResponse = response.data;
      const GetUserResponseStatus = response.status;

      yield put(
        actions.logEvent({
          id: 4469,
          options: {
            path_variables: {
              "54b3e3e7-63cb-4cad-8e8a-e5dda71b8c07": state.page["userid"],
            },
            response_as: GetUserResponse,
            response_code_as: GetUserResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetUserResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 4471,
            options: {
              field_key: "ViewPlayer",
              field_value: GetUserResponse[0],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("ViewPlayer", GetUserResponse));
      } else if (GetUserResponse === state.reducer["FiveHundred"]) {
        window.location.href = `${window.location.origin}/#/roster`;
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_USER_EXERCISES() {
  while (true) {
    yield take(actions.GET_USER_EXERCISES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.GetUserExercisesEndpoint, payload);
      const GetUserExercisesResponse = response.data;
      const GetUserExercisesResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3883,
          options: {
            response_as: GetUserExercisesResponse,
            response_code_as: GetUserExercisesResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetUserExercisesResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3885,
            options: {
              field_key: "UserExercises",
              field_value: GetUserExercisesResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("UserExercises", GetUserExercisesResponse)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_USER_GRADES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_USER_GRADES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("UserGrades", state.reducer["Nullvalue"]));

      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["depth_chart_id"] = inputVariables?.depthChartId;
      payload.query_variables["play_id"] = inputVariables?.playRowId;
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === ""
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.GetUserGradesEndpoint, payload);

      const GetUserGradesResponse = response.data;
      const GetUserGradesResponseStatus = response.status;
      if (GetUserGradesResponseStatus === state.reducer["TwoHundred"]) {
        yield put(actions.changeInput("UserGrades", GetUserGradesResponse));
        // yield put(
        //   actions.runAction("GET_SCHOOL_PRODUCTION_POINT", {
        //     inputVariables: { position_id: GetUserGradesResponse?.[0]?.position_id},
        //   })
        // );
        yield put(actions.changeInput("toggleUserGradeLoader", false));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_USER_GRADES_FOR_FILTER() {
  while (true) {
    let { currentIndex, firstPosition } = yield take(
      actions.GET_USER_GRADES_FOR_FILTER
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield call(actions.wait, 500);
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["depth_chart_id"] =
        state.reducer["CurrentPackage"];
      payload.query_variables["play_id"] = state.reducer["CurrentPlayId"];
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === ""
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;

      const response11975 = yield call(
        serverApi.GetUserGradesEndpoint,
        payload
      );
      const GetUserGradesResponseVariable = response11975.data || [];
      const GetUserGradesResponseCodeVariable = response11975.status;
      yield put(
        actions.logEvent({
          id: 11975,
          options: {
            query_variables: {
              "1697741d-6ae6-48fc-8c72-4c24c1f992f6":
                state.reducer["CurrentPackage"],
              "882b92c8-3b8c-42a2-bfaa-2efac5b4f043":
                state.reducer["CurrentPlayId"],
            },
            response_as: GetUserGradesResponseVariable,
            response_code_as: GetUserGradesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetUserGradesResponseCodeVariable === state.reducer["TwoHundred"]) {
        let cloneData = [];
        if (firstPosition !== undefined) {
          cloneData = GetUserGradesResponseVariable.filter(
            (item) => item.position === firstPosition
          );
        } else if (
          GetUserGradesResponseVariable &&
          isArray(GetUserGradesResponseVariable)
        ) {
          cloneData = GetUserGradesResponseVariable;
        }
        yield put(
          actions.logEvent({
            id: 11977,
            options: {
              field_key: "collapse",
              field_value: {},
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("collapse", {
            ...state?.reducer?.["collapse"],
            [currentIndex]: !state?.reducer?.["collapse"][currentIndex],
          })
        );
        yield put(
          actions.logEvent({
            id: 11977,
            options: {
              field_key: "PlayReportsMultipleData",
              field_value: cloneData,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("PlayReportsMultipleData", {
            ...state?.reducer?.["PlayReportsMultipleData"],
            [currentIndex]: cloneData,
          })
        );
      }
      yield put(
        actions.logEvent({
          id: 11977,
          options: {
            field_key: "currentPosition",
            field_value: currentIndex,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("currentPosition", currentIndex));
      yield put(actions.changeInput("isLoaded", false));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_USER_GRADES_GROUPED() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_USER_GRADES_GROUPED);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let UserIdInputVariable = inputVariables["user id input"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["user_id"] = UserIdInputVariable;
      const response4481 = yield call(
        serverApi.GetUserGradesGroupedEndpoint,
        payload
      );
      const GetUserGradesGroupedResponseVariable = response4481.data;
      const GetUserGradesGroupedResponseCodeVariable = response4481.status;
      yield put(
        actions.logEvent({
          id: 4481,
          options: {
            path_variables: {
              "847d1988-a975-4dc8-8183-f7e3af43421c": UserIdInputVariable,
            },
            response_as: GetUserGradesGroupedResponseVariable,
            response_code_as: GetUserGradesGroupedResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetUserGradesGroupedResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 4483,
            options: {
              field_key: "GradesGrouped",
              field_value: GetUserGradesGroupedResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "GradesGrouped",
            GetUserGradesGroupedResponseVariable
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_SEASON_YEAR() {
  while (true) {
    yield take(actions.GET_SEASON_YEAR);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
      const GetSeasonYearResponse = response.data;
      const GetSeasonYearResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3106,
          options: {
            response_as: GetSeasonYearResponse,
            response_code_as: GetSeasonYearResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetSeasonYearResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 4702,
            options: {
              field_key: "SeasonYearData",
              field_value: GetSeasonYearResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("SeasonYearData", GetSeasonYearResponse));
        yield put(
          actions.logEvent({
            id: 4702,
            options: {
              field_key: "selectedSeasonYear",
              field_value: GetSeasonYearResponse?.[0]?.year,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "selectedSeasonYear",
            GetSeasonYearResponse?.[0]?.year
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SELECT_SEASON_YEAR() {
  while (true) {
    yield take(actions.SELECT_SEASON_YEAR);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      toggleClassInbody("setting-menu_item");
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_USERS() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_USERS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["text_search"] = inputVariables?.rosterValue
        ? inputVariables?.rosterValue
        : "";
      if (inputVariables?.isDefault) {
        yield put(actions.changeInput("isDefaultLoaded", true));
        yield put(actions.changeInput("isLoaded", true));
      }
      const response3106 = yield call(serverApi.GetRosterEndpoint, payload);
      const GetUsersResponseVariable = response3106.data;
      const GetUsersResponseCodeVariable = response3106.status;
      yield put(
        actions.logEvent({
          id: 3106,
          options: {
            response_as: GetUsersResponseVariable,
            response_code_as: GetUsersResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (state.reducer["TwoHundred"] === GetUsersResponseCodeVariable) {
        yield put(actions.changeInput("isLoaded", false));
        if (inputVariables?.isDefault) {
          yield put(actions.changeInput("isDefaultLoaded", false));
          // yield put(actions.changeInput("isLoaded", false));
        }

        yield put(
          actions.logEvent({
            id: 3108,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetUsersResponseVariable,
              },
              return_as: GetUsersResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 4702,
            options: {
              field_key: "Users",
              field_value: GetUsersResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", GetUsersResponseVariable));
      }
    } catch (error) {
      yield put(actions.changeInput("isLoaded", false));
      // if(inputVariables?.isDefault){
      //   yield put(actions.changeInput("isDefaultLoaded", false));
      // }
      // yield put(
      //   actions.runAction("LOADING_EVENT", { inputVariables: {loader:false} })
      // );
      console.warn(error);
    }
  }
}

function* LOADING_EVENT() {
  while (true) {
    let { inputVariables } = yield take(actions.LOADING_EVENT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("isLoaded", inputVariables?.loader));
    } catch (error) {
      console.warn(error);
    }
  }
}

// function* GET_USERS_DES() {
//   while (true) {
//     let { inputVariables } = yield take(actions.GET_USERS_DES);
//     let state = yield select();
//     state.page =
//       state.routing && state.routing.locationBeforeTransitions
//         ? state.routing.locationBeforeTransitions.query
//         : {};
//     let payload;

//     try {
//       let IntangibleIdVariable = inputVariables["IntangibleId"];

//       payload = {};
//       payload["query_variables"] = {};
//       payload.query_variables["intangible id"] = IntangibleIdVariable;
//       const response7526 = yield call(serverApi.GetRoastersEndpoint, payload);
//       const GetRoastersResponseVariable = response7526.data;
//       const GetRoastersResponseCodeVariable = response7526.status;
//       yield put(
//         actions.logEvent({
//           id: 7526,
//           options: {
//             query_variables: {
//               "4d1ce8aa-2903-4da5-addb-6a4fa30f39e2": IntangibleIdVariable,
//             },
//             response_as: GetRoastersResponseVariable,
//             response_code_as: GetRoastersResponseCodeVariable,
//           },
//           type: "event",
//           time: Date.now(),
//         })
//       );
//       if (state.reducer["TwoHundred"] === GetRoastersResponseCodeVariable) {
//         let UsersWithColorVariable;
//         try {
//           UsersWithColorVariable = window.GenerateColor({
//             somelist: GetRoastersResponseVariable,
//           });
//         } catch (e) {}
//         yield put(
//           actions.logEvent({
//             id: 7528,
//             options: {
//               parameter_mapping: {
//                 "2844db8a-960c-430b-987d-7003aa903ade":
//                   GetRoastersResponseVariable,
//               },
//               return_as: UsersWithColorVariable,
//             },
//             type: "event",
//             time: Date.now(),
//           })
//         );

//         yield put(
//           actions.logEvent({
//             id: 7529,
//             options: {
//               field_key: "Users",
//               field_value: UsersWithColorVariable,
//             },
//             type: "event",
//             time: Date.now(),
//           })
//         );
//         yield put(actions.changeInput("Users", UsersWithColorVariable));
//       }
//     } catch (error) {
//       console.warn(error);
//     }
//   }
// }
function* GET_YEAR_ROSTER() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_YEAR_ROSTER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleIidVariable = inputVariables["intangible iid"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleIidVariable;
      const response7910 = yield call(serverApi.GetYearRosterEndpoint, payload);
      const GetYearRosterResponseVariable = response7910.data;
      const GetYearRosterResponseCodeVariable = response7910.status;
      yield put(
        actions.logEvent({
          id: 7910,
          options: {
            query_variables: {
              "0c67d69c-e83f-4901-8f2a-988d9691b8d7": IntangibleIidVariable,
            },
            response_as: GetYearRosterResponseVariable,
            response_code_as: GetYearRosterResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetYearRosterResponseCodeVariable === state.reducer["TwoHundred"]) {
        let UserswithcolorrrVariable;
        try {
          UserswithcolorrrVariable = window.GenerateColor({
            somelist: GetYearRosterResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 7912,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetYearRosterResponseVariable,
              },
              return_as: UserswithcolorrrVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 7913,
            options: {
              field_key: "Users",
              field_value: UserswithcolorrrVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", UserswithcolorrrVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_YEAR_ROSTER_DES() {
  while (true) {
    let { inputVariables } = yield take(actions.GET_YEAR_ROSTER_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IntangibleiidVariable = inputVariables["intangibleiid"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["intangible id"] = IntangibleiidVariable;
      const response7914 = yield call(
        serverApi.GetYearRosterDesEndpoint,
        payload
      );
      const GetYearRosterDesResponseVariable = response7914.data;
      const GetYearRosterDesResponseCodeVariable = response7914.status;
      yield put(
        actions.logEvent({
          id: 7914,
          options: {
            query_variables: {
              "ef0c2b0c-eee3-40c4-ba33-1d18c4878634": IntangibleiidVariable,
            },
            response_as: GetYearRosterDesResponseVariable,
            response_code_as: GetYearRosterDesResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetYearRosterDesResponseCodeVariable === state.reducer["TwoHundred"]
      ) {
        let UserscolorVariable;
        try {
          UserscolorVariable = window.GenerateColor({
            somelist: GetYearRosterDesResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 7916,
            options: {
              parameter_mapping: {
                "2844db8a-960c-430b-987d-7003aa903ade":
                  GetYearRosterDesResponseVariable,
              },
              return_as: UserscolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 7917,
            options: {
              field_key: "Users",
              field_value: UserscolorVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("Users", UserscolorVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* GET_CSV_PLAY_MAPPING_TITLES_CACHE() {
  while (true) {
    let { inputVariables } = yield take(
      actions.GET_CSV_PLAY_MAPPING_TITLES_CACHE
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let CustomTitleVariable = inputVariables["custom_title"];
      let CustomTitleId = inputVariables["title_id"];

      let CsvPlayMappingTitlesCacheObjVariable;
      try {
        CsvPlayMappingTitlesCacheObjVariable =
          window.GetCsvPlayMappingTitlesCache({
            custom_title_name: CustomTitleVariable,
            cache: state.reducer["CustomFieldsCsvMappingCache"],
            custom_title_index:
              state.reducer["currentCustomTitleCsvPlayMappingIndex"],
            current_titles: state.reducer["CsvPlayMapping"]["title"],
            custom_title_id_input: CustomTitleId,
          });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 13073,
          options: {
            parameter_mapping: {
              "4be771d3-ca15-4ca3-a43c-2c454ce54fcc": CustomTitleVariable,
              "70a9a229-5edb-4669-82a1-6e03c42d1384":
                state.reducer["CustomFieldsCsvMappingCache"],
              "7e8902d8-e223-4fa4-b9d4-6adf81307cb2":
                state.reducer["currentCustomTitleCsvPlayMappingIndex"],
              "d8803d42-be9c-42bc-9c7f-1a80f0f9348c":
                state.reducer["CsvPlayMapping"]["title"],
            },
            return_as: CsvPlayMappingTitlesCacheObjVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      yield put(
        actions.logEvent({
          id: 13138,
          options: {
            field_key: "csv_play_mapping_titles_cache",
            field_value: CsvPlayMappingTitlesCacheObjVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "csv_play_mapping_titles_cache",
          CsvPlayMappingTitlesCacheObjVariable
        )
      );

      let CustomTitlesVariable;
      try {
        CustomTitlesVariable = window.GetCsvPlayMappingTitles({
          titles_cache_obj: CsvPlayMappingTitlesCacheObjVariable,
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 13148,
          options: {
            parameter_mapping: {
              "af200e10-a4f1-4327-a627-539be229abb7":
                CsvPlayMappingTitlesCacheObjVariable,
            },
            return_as: CustomTitlesVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      yield put(
        actions.logEvent({
          id: 13149,
          options: {
            field_key: "CsvPlayMapping.title",
            field_value: CustomTitlesVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CsvPlayMapping.title", CustomTitlesVariable)
      );

      let CustomTitlesCacheVariable;
      try {
        CustomTitlesCacheVariable = window.GetCsvPlayMappingCache({
          titles_cache_object: CsvPlayMappingTitlesCacheObjVariable,
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 13150,
          options: {
            parameter_mapping: {
              "7a8b8ad0-b9a2-447c-a2e1-5372ecf9624f":
                CsvPlayMappingTitlesCacheObjVariable,
            },
            return_as: CustomTitlesCacheVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      yield put(
        actions.logEvent({
          id: 13151,
          options: {
            field_key: "CustomFieldsCsvMappingCache",
            field_value: CustomTitlesCacheVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "CustomFieldsCsvMappingCache",
          CustomTitlesCacheVariable
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* HIDE_PLAY_MAPPING() {
  while (true) {
    let { inputVariables } = yield take(actions.HIDE_PLAY_MAPPING);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let NullvalVariable = inputVariables["nullval"];

      yield put(
        actions.logEvent({
          id: 12302,
          options: {
            field_key: "playmapping",
            field_value: NullvalVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("playmapping", NullvalVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* HIDE_IMPORT_MAPPING() {
  while (true) {
    let { inputVariables } = yield take(actions.HIDE_IMPORT_MAPPING);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let NullvalVariable = inputVariables["nullval"];
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = state.reducer?.CurrentGame?.Id;
      yield call(serverApi.DeleteGameEndpoint, payload);

      yield put(
        actions.logEvent({
          id: 12302,
          options: {
            field_key: "importMapping",
            field_value: NullvalVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("importMapping", NullvalVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* HIDE_ROSTER_MAPPING() {
  while (true) {
    let { inputVariables } = yield take(actions.HIDE_ROSTER_MAPPING);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let NullVariable = inputVariables["null"];

      yield put(
        actions.logEvent({
          id: 4451,
          options: {
            field_key: "rostermapping",
            field_value: NullVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("rostermapping", NullVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* HIDE_USER_GRAPH() {
  while (true) {
    yield take(actions.HIDE_USER_GRAPH);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.logEvent({
          id: 4579,
          options: {
            field_key: "ShowUserGraph",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.removeField("ShowUserGraph"));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* INIT_GAME_GRADES_PAGE() {
  while (true) {
    yield take(actions.INIT_GAME_GRADES_PAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var GameVariable = "Game";

      const response4498 = yield call(serverApi.GetPositionsEndpoint, null);
      const GetPositionsResponseVariable = response4498.data;
      const GetPositionsResponseCodeVariable = response4498.status;
      yield put(
        actions.logEvent({
          id: 4498,
          options: {
            response_as: GetPositionsResponseVariable,
            response_code_as: GetPositionsResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetPositionsResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 4500,
            options: {
              field_key: "Positions",
              field_value: GetPositionsResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("Positions", GetPositionsResponseVariable)
        );

        let FirstRecordVariable;
        try {
          FirstRecordVariable = window.GetFirstRecord({
            PositionsAll: GetPositionsResponseVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 4501,
            options: {
              parameter_mapping: {
                "b6e171c2-0991-4463-8901-98c567a7864d":
                  GetPositionsResponseVariable,
              },
              return_as: FirstRecordVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        let NowVariable;
        try {
          NowVariable = window.Now({});
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 4502,
            options: {
              parameter_mapping: {},
              return_as: NowVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 12346,
            options: {
              field_key: "ReportsDate",
              field_value: NowVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("ReportsDate", NowVariable));

        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["position_id"] = FirstRecordVariable;
        payload.path_variables["date"] = NowVariable;
        payload.path_variables["type"] = GameVariable;
        const response4503 = yield call(
          serverApi.GetGameGradesEndpoint,
          payload
        );
        const GetGameGradesResponseVariable = response4503.data;
        const GetGameGradesResponseCodeVariable = response4503.status;
        yield put(
          actions.logEvent({
            id: 4503,
            options: {
              path_variables: {
                "2a98f0e6-ee51-4b91-b7fc-36b3d171b8f6": FirstRecordVariable,
                "2faf98ba-8add-4e51-8956-b47f8e6bb13c": NowVariable,
                "b67e40da-fc4e-4b4b-826d-e10518a79bf0": GameVariable,
              },
              response_as: GetGameGradesResponseVariable,
              response_code_as: GetGameGradesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (GetGameGradesResponseCodeVariable === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 4505,
              options: {
                field_key: "Grades",
                field_value: GetGameGradesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("Grades", GetGameGradesResponseVariable)
          );
        }

        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["date"] = NowVariable;
        payload.path_variables["position_id"] = FirstRecordVariable;
        payload.path_variables["type"] = state.reducer["FormSearchGradesType"];
        const response4506 = yield call(
          serverApi.GetAverageGameGradesEndpoint,
          payload
        );
        const GetAverageGameGradesResponseVariable = response4506.data;
        const GetAverageGameGradesResponseCodeVariable = response4506.status;
        yield put(
          actions.logEvent({
            id: 4506,
            options: {
              path_variables: {
                "0e81d2f3-9f02-4ef6-92ba-44a19b808ff6": NowVariable,
                "9093e61e-9fb8-4f8d-971d-e2f4442049a6": FirstRecordVariable,
                "eae9eae2-c99b-475d-bf76-8659dc56fdfb":
                  state.reducer["FormSearchGradesType"],
              },
              response_as: GetAverageGameGradesResponseVariable,
              response_code_as: GetAverageGameGradesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetAverageGameGradesResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 4508,
              options: {
                field_key: "AverageGrades",
                field_value: GetAverageGameGradesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "AverageGrades",
              GetAverageGameGradesResponseVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* INIT_GAME_GRADES_PAGE_DES() {
  while (true) {
    yield take(actions.INIT_GAME_GRADES_PAGE_DES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var GameDesVariable = "";

      const response7621 = yield call(serverApi.GetPositionsEndpoint, null);
      const GetPositionsResponseDesVariable = response7621.data;
      const GetPositionsResponseCodeDesVariable = response7621.status;
      yield put(
        actions.logEvent({
          id: 7621,
          options: {
            response_as: GetPositionsResponseDesVariable,
            response_code_as: GetPositionsResponseCodeDesVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (GetPositionsResponseCodeDesVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 7623,
            options: {
              field_key: "Positions",
              field_value: GetPositionsResponseDesVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("Positions", GetPositionsResponseDesVariable)
        );

        let FirstRecordDesVariable;
        try {
          FirstRecordDesVariable = window.GetFirstRecord({
            PositionsAll: GetPositionsResponseDesVariable,
          });
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 7624,
            options: {
              parameter_mapping: {
                "b6e171c2-0991-4463-8901-98c567a7864d":
                  GetPositionsResponseDesVariable,
              },
              return_as: FirstRecordDesVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        let NowDesVariable;
        try {
          NowDesVariable = window.Now({});
        } catch (e) {}
        yield put(
          actions.logEvent({
            id: 7625,
            options: {
              parameter_mapping: {},
              return_as: NowDesVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["date"] = NowDesVariable;
        payload.path_variables["type"] = GameDesVariable;
        payload.path_variables["position_id"] = FirstRecordDesVariable;
        const response7626 = yield call(
          serverApi.GetGameGradesDesEndpoint,
          payload
        );
        const GetGameGradesDesResponseVariable = response7626.data;
        const GetGameGradesDesResponseCodeVariable = response7626.status;
        yield put(
          actions.logEvent({
            id: 7626,
            options: {
              path_variables: {
                "13a41265-20e0-48c5-bf42-a7297d00f77c": NowDesVariable,
                "8214aad9-62ab-4f80-822b-0c1746d2dcaa": GameDesVariable,
                "b686a1ba-fcb8-44cc-acb4-18497ea29a2c": FirstRecordDesVariable,
              },
              response_as: GetGameGradesDesResponseVariable,
              response_code_as: GetGameGradesDesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetGameGradesDesResponseCodeVariable === state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 7628,
              options: {
                field_key: "Grades",
                field_value: GetGameGradesDesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("Grades", GetGameGradesDesResponseVariable)
          );
        }

        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["date"] = NowDesVariable;
        payload.path_variables["position_id"] = FirstRecordDesVariable;
        payload.path_variables["type"] = state.reducer["FormSearchGradesType"];
        const response7629 = yield call(
          serverApi.GetAverageGameGradesEndpoint,
          payload
        );
        const GetAverageGameGradesDesResponseVariable = response7629.data;
        const GetAverageGameGradesDesResponseCodeVariable = response7629.status;
        yield put(
          actions.logEvent({
            id: 7629,
            options: {
              path_variables: {
                "0e81d2f3-9f02-4ef6-92ba-44a19b808ff6": NowDesVariable,
                "9093e61e-9fb8-4f8d-971d-e2f4442049a6": FirstRecordDesVariable,
                "eae9eae2-c99b-475d-bf76-8659dc56fdfb":
                  state.reducer["FormSearchGradesType"],
              },
              response_as: GetAverageGameGradesDesResponseVariable,
              response_code_as: GetAverageGameGradesDesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetAverageGameGradesDesResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 7631,
              options: {
                field_key: "AverageGrades",
                field_value: GetAverageGameGradesDesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "AverageGrades",
              GetAverageGameGradesDesResponseVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* LOGIN() {
  while (true) {
    yield take(actions.LOGIN);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      // let  PageRedirectVariable = '/support';
      var InvalidPasswordMessageVariable = "Invalid username or password";

      var err3417;
      var FormLoginValidVariable = true;
      err3417 = yield call(
        _getValidationErrors,
        Yup.string()
          // .email("Please enter a valid email")
          .min(1, "Please enter a valid email"),
        state.reducer["FormLogin"]["email"]
      );
      FormLoginValidVariable = !FormLoginValidVariable
        ? false
        : err3417.name !== "ValidationError";
      if (err3417.name === "ValidationError") {
        console.warn(err3417.errors);
        yield put(actions.changeInput("FormLoginErrors.email", err3417.errors));
      } else {
        yield put(actions.removeField("FormLoginErrors.email"));
      }
      err3417 = yield call(
        _getValidationErrors,
        Yup.string().min(1, "Please enter a password"),
        state.reducer["FormLogin"]["password"]
      );
      FormLoginValidVariable = !FormLoginValidVariable
        ? false
        : err3417.name !== "ValidationError";
      if (err3417.name === "ValidationError") {
        console.warn(err3417.errors);
        yield put(
          actions.changeInput("FormLoginErrors.password", err3417.errors)
        );
      } else {
        yield put(actions.removeField("FormLoginErrors.password"));
      }
      if (FormLoginValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["header_variables"] = {
          Authorization:
            "Basic " +
            Buffer.from(
              `${state?.reducer["FormLogin"]["email"]}:${state?.reducer["FormLogin"]["password"]}`,
              "binary"
            ).toString("base64"),
        };

        const response = yield fetch(
          `${process.env.REACT_APP_BASE_URL}/login`,
          {
            method: "POST",
            headers: {
              Authorization:
                "Basic " +
                Buffer.from(
                  `${state?.reducer["FormLogin"]["email"]}:${state?.reducer["FormLogin"]["password"]}`,
                  "binary"
                ).toString("base64"),
            },
          }
        )
          .then((response) =>
            response.json().then((text) => {
              return {
                data: text.data,
                isSuccess: response.ok,
                status: response.status,
                message: response?.message,
              };
            })
          )
          .catch((err) => {
            console.log("eer", err);
          });
        // yield call(serverApi.LoginEndpoint, payload);

        const LoginResponse = response.data;
        const LoginResponseStatus = response.status;

        if (LoginResponseStatus === state.reducer["TwoHundred"]) {
          const routeName = localStorage.getItem("previousRoute");
          if (response?.isSuccess) {
            localStorage.setItem("token", LoginResponse?.token);
          }
          loginEvent(LoginResponse);
          yield put(
            actions.logEvent({
              id: 3430,
              options: {
                field_key: "SignInData",
                field_value: LoginResponse,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.changeInput("SignInData", LoginResponse));
          yield put(
            actions.runAction("GET_CURRENT_USER", { inputVariables: {} })
          );
          yield put(
            actions.runAction("GET_SEASON_YEAR", { inputVariables: {} })
          );
          if (routeName) {
            const path = routeName;
            const extractedPath = path?.substring(1);
            yield put(push(`${extractedPath}`));
          }
        } else {
          yield put(
            actions.logEvent({
              id: 3430,
              options: {
                field_key: "Unauthorized",
                field_value: InvalidPasswordMessageVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("Unauthorized", InvalidPasswordMessageVariable)
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* GET_BASE_DEPTH_CHART_BY_PLAYER() {
  while (true) {
    yield take(actions.GET_BASE_DEPTH_CHART_BY_PLAYER);
    let state = yield select();
    let payload;

    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const getResponse = yield call(
        serverApi.GetBaseDepthChartByPlayer,
        payload
      );

      if (getResponse.status === state.reducer["TwoHundred"]) {
        yield put(actions.setField("BaseDepthChartsPlayer", getResponse.data));
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}

function* GET_DEPTH_CHART_BY_PLAYER() {
  while (true) {
    yield take(actions.GET_DEPTH_CHART_BY_PLAYER);
    let state = yield select();
    let payload;

    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const getResponse = yield call(serverApi.GetDepthChartByPlayer, payload);

      if (getResponse.status === state.reducer["TwoHundred"]) {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
        yield put(actions.setField("PlayerDepthChartData", getResponse.data));
      } else {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
      console.warn(error);
    }
  }
}

function* LOGOUT() {
  while (true) {
    yield take(actions.LOGOUT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      toggleClassInbody("setting-menu_item");
      localStorage.clear();
      yield put(push("/login"));
      yield put(
        actions.logEvent({
          id: 3077,
          options: {
            field_key: "CurrentUser",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("CurrentUser", {}));
      logoutEvent();
    } catch (error) {
      console.warn(error);
    }
  }
}
function* MAP_CSV() {
  while (true) {
    const { inputVariables } = yield take(actions.MAP_CSV);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var QqVariable = "true";

      var FfVariable = "false";

      // if (state.reducer["SendEmail"] === state.reducer["TrueCheckbox"]) {
      yield put(
        actions.logEvent({
          id: 20616,
          options: {
            field_key: "AverageGradesMsg",
            field_value: QqVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("AverageGradesMsg", QqVariable));
      // }
      // if (state.reducer["SendEmail"] === state.reducer["FalseCheckbox"]) {
      //   yield put(
      //     actions.logEvent({
      //       id: 20618,
      //       options: {
      //         field_key: "AverageGradesMsg",
      //         field_value: FfVariable,
      //       },
      //       type: "event",
      //       time: Date.now(),
      //     })
      //   );
      //   yield put(actions.changeInput("AverageGradesMsg", FfVariable));
      // }

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.reducer["rostermapping"]["id"];
      payload["body_variables"] = {};
      payload.body_variables["weight"] = state.reducer["CsvMapping"]["weight"];
      payload.body_variables["number"] = state.reducer["CsvMapping"]["number"];
      payload.body_variables["fullname"] =
        state.reducer["CsvMapping"]["fullname"];
      payload.body_variables["position_id"] =
        state.reducer["CsvMapping"]["position_id"];
      payload.body_variables["secondary_positions"] =
        state.reducer["CsvMapping"]["secondary"];
      payload.body_variables["graduation_year"] =
        state.reducer["CsvMapping"]["graduation_year"];
      payload.body_variables["height"] = state.reducer["CsvMapping"]["height"];
      payload.body_variables["email"] = state.reducer["CsvMapping"]["email"];
      let MapCsvResponse, MapCsvResponseStatus;
      if (inputVariables?.check === true) {
        const response = yield call(serverApi.MapCsvEndpoint, payload);
        MapCsvResponse = response.data;
        MapCsvResponseStatus = response.status;
      } else {
        const response = yield call(serverApi.MapCsvNoEmailEndpoint, payload);
        MapCsvResponse = response.data;
        MapCsvResponseStatus = response.status;
      }

      yield put(
        actions.logEvent({
          id: 4453,
          options: {
            body_variables: {
              "dc5dea42-5a8b-494a-ac70-8a49862c8c7d.19179":
                state.reducer["CsvMapping"]["weight"],
              "dc5dea42-5a8b-494a-ac70-8a49862c8c7d.5987":
                state.reducer["CsvMapping"]["number"],
              "dc5dea42-5a8b-494a-ac70-8a49862c8c7d.5988":
                state.reducer["CsvMapping"]["fullname"],
              "dc5dea42-5a8b-494a-ac70-8a49862c8c7d.5989":
                state.reducer["CsvMapping"]["position_id"],
              "dc5dea42-5a8b-494a-ac70-8a49862c8c7d.5990":
                state.reducer["CsvMapping"]["graduation_year"],
              "dc5dea42-5a8b-494a-ac70-8a49862c8c7d.5991":
                state.reducer["CsvMapping"]["height"],
              "dc5dea42-5a8b-494a-ac70-8a49862c8c7d.5993":
                state.reducer["CsvMapping"]["email"],
            },
            path_variables: {
              "20f6ee2f-6304-46c9-8ad8-484ad4f8effa":
                state.reducer["rostermapping"]["id"],
            },
            response_as: MapCsvResponse,
            response_code_as: MapCsvResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (MapCsvResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 4455,
            options: {
              field_key: "rostermapping",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("rostermapping"));
        yield call(actions.delay, 1000);
        yield put(
          actions.logEvent({
            id: 4456,
            options: {
              parameter_mapping: {
                "07d7f5ae-8efc-46f6-b7eb-f8207ed35f1b": state.reducer["Zero"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.runAction("GET_USERS", { inputVariables: {} }));

        yield put(
          actions.logEvent({
            id: 7143,
            options: {
              field_key: "SuccessMessage",
              field_value: `${state.reducer["RoasterUploadSuccessMessage"]} \n ${MapCsvResponse?.message}`,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "SuccessMessage",
            `${state.reducer["RoasterUploadSuccessMessage"]} \n ${MapCsvResponse?.message}`
          )
        );
      } else if (MapCsvResponseStatus === state.reducer?.FiveHundred) {
        yield put(
          actions.changeInput("RosterMappingError", MapCsvResponse?.message)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* MAP_PLAY_CSV() {
  while (true) {
    yield take(actions.MAP_PLAY_CSV);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("isLoaded", true));
      // payload = {};
      // payload["query_variables"] = {};
      // payload.query_variables["year"] = state.page?.year ?  state.page?.year : state.reducer?.selectedSeasonYear === '' ? state.reducer?.SeasonYearData?.[0]?.year : state.reducer?.selectedSeasonYear;
      // const response = yield call(
      //   serverApi.GetFirstDepthChartEndpoint,
      //   payload
      // );
      // const GetFirstDepthChartResVariable = response.data;
      // const GetFirstDepthChartResCodeVariable = response.status;

      // if (GetFirstDepthChartResCodeVariable === state.reducer["TwoHundred"]) {
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.reducer["playmapping"]["id"];
      payload["query_variables"] = {};
      payload.query_variables["game_id"] = state.reducer["CurrentGame"]["Id"];
      // payload.query_variables["depth_chart_id"] =
      //   GetFirstDepthChartResVariable["id"];
      payload["body_variables"] = {};
      payload.body_variables["index"] =
        state.reducer["CsvPlayMapping"]["index"];
      payload.body_variables["name"] = state.reducer["CsvPlayMapping"]["name"];
      payload.body_variables["qtr"] = state.reducer["CsvPlayMapping"]["qtr"];
      payload.body_variables["series"] =
        state.reducer["CsvPlayMapping"]["series"];
      payload.body_variables["down"] = state.reducer["CsvPlayMapping"]["down"];
      payload.body_variables["distance"] =
        state.reducer["CsvPlayMapping"]["distance"];
      payload.body_variables["type"] = state.reducer["CsvPlayMapping"]["type"];
      payload.body_variables["depth_chart_id"] =
        state.reducer?.CsvPlayMappingPackage === undefined ||
        state.reducer?.CsvPlayMappingPackage === "NULL"
          ? null
          : state.reducer?.CsvPlayMappingPackage;
      payload.body_variables["game_id"] = state.reducer["CurrentGame"]["Id"];
      payload.body_variables["data"] = state.reducer["CsvPlayMapping"]["data"];
      payload.body_variables["title"] =
        state.reducer["CsvPlayMapping"]["title"];
      const response = yield call(serverApi.MapPlayCsvEndpoint, payload);
      const MapPlayCsvResponse = response.data;
      const MapPlayCsvResponseStatus = response.status;
      if (MapPlayCsvResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 12330,
            options: {
              field_key: "playmapping",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("playmapping"));

        yield put(
          actions.logEvent({
            id: 12370,
            options: {
              field_key: "DemoTitles",
              field_value: MapPlayCsvResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("DemoTitles", MapPlayCsvResponse));

        yield put(
          actions.logEvent({
            id: 12448,
            options: {
              field_key: "CsvTitlesPrint",
              field_value: state.reducer["CsvPlayMapping"]["title"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "CsvTitlesPrint",
            state.reducer["CsvPlayMapping"]["title"]
          )
        );

        // let RefreshMapPlayCsvVariable;
        // try {
        //   RefreshMapPlayCsvVariable = window.RefreshPage({});
        // } catch (e) {}
        // yield put(
        //   actions.logEvent({
        //     id: 20441,
        //     options: {
        //       parameter_mapping: {},
        //       return_as: RefreshMapPlayCsvVariable,
        //     },
        //     type: "event",
        //     time: Date.now(),
        //   })
        // );

        yield put(
          actions.logEvent({
            id: 12331,
            options: {
              parameter_mapping: {
                "e556c934-6791-465b-99d5-4ccad85c63f3":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_BY_PLAY_INFO", {
            inputVariables: { gameid: state.reducer["CurrentGame"]["Id"] },
          })
        );
        yield put(actions.changeInput("isLoaded", false));
        window.RefreshPage();
      } else {
        yield put(
          actions.changeInput(
            "PlayUploadMappingError",
            MapPlayCsvResponse?.message
          )
        );
        yield put(actions.changeInput("isLoaded", false));
      }
      // }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* IMPORT_PLAY_CSV() {
  while (true) {
    yield take(actions.IMPORT_PLAY_CSV);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      if (state.reducer?.CurrentGame?.Id && state.reducer?.importMapping?.id) {
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] = state.reducer["importMapping"]["id"];
        payload["query_variables"] = {};
        payload.query_variables["game_id"] = state.reducer?.CurrentGame?.Id;
        payload["body_variables"] = {};
        payload.body_variables["index"] =
          state.reducer["CsvPlayMapping"]["index"];
        payload.body_variables["name"] =
          state.reducer["CsvPlayMapping"]["name"];
        payload.body_variables["qtr"] = state.reducer["CsvPlayMapping"]["qtr"];
        payload.body_variables["series"] =
          state.reducer["CsvPlayMapping"]["series"];
        payload.body_variables["down"] =
          state.reducer["CsvPlayMapping"]["down"];
        payload.body_variables["distance"] =
          state.reducer["CsvPlayMapping"]["distance"];
        payload.body_variables["type"] =
          state.reducer["CsvPlayMapping"]["type"];
        payload.body_variables["depth_chart_id"] =
          state.reducer?.CsvPlayMappingPackage === undefined ||
          state.reducer?.CsvPlayMappingPackage === "NULL"
            ? null
            : state.reducer?.CsvPlayMappingPackage;
        payload.body_variables["game_id"] = state.reducer?.CurrentGame?.Id;
        payload.body_variables["data"] =
          state.reducer["CsvPlayMapping"]["data"];
        payload.body_variables["title"] =
          state.reducer["CsvPlayMapping"]["title"];
        console.log("payload: ", payload);
        const response = yield call(serverApi.MapPlayCsvEndpoint, payload);
        const MapPlayCsvResponse = response.data;
        const MapPlayCsvResponseStatus = response.status;
        if (MapPlayCsvResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            push(
              `/play_by_play?playgradeid=${state.reducer?.CurrentGame?.Id}&year=${state.reducer?.selectedSeasonYear}`
            )
          );

          // yield put(actions.runAction("GET_GAMES", { inputVariables: {} }));

          // yield put(actions.runAction("GET_PRACTICES", { inputVariables: {} }));

          yield put(actions.removeField("importMapping"));

          yield put(actions.changeInput("DemoTitles", MapPlayCsvResponse));
          yield put(
            actions.changeInput(
              "CsvTitlesPrint",
              state.reducer["CsvPlayMapping"]["title"]
            )
          );
          yield put(actions.changeInput("NewGame", false));
        } else {
          yield put(
            actions.changeInput(
              "PlayUploadMappingError",
              MapPlayCsvResponse?.message
            )
          );
        }
      }
      // }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* NEW_EXERCISE() {
  while (true) {
    let { inputVariables } = yield take(actions.NEW_EXERCISE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputNewExerciseVariable = inputVariables["input new exercise"];

      yield put(
        actions.logEvent({
          id: 3244,
          options: {
            field_key: "NewExercise",
            field_value: InputNewExerciseVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("NewExercise", InputNewExerciseVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* NEW_GAME() {
  while (true) {
    let { inputVariables } = yield take(actions.NEW_GAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputNewGameVariable = inputVariables["input new Game"];

      yield put(
        actions.logEvent({
          id: 7346,
          options: {
            field_key: "NewGame",
            field_value: InputNewGameVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("NewGame", InputNewGameVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* NEW_INJURED_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.NEW_INJURED_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputNewInjuredVariable = inputVariables["input new injured"];

      yield put(
        actions.logEvent({
          id: 3165,
          options: {
            field_key: "NewInjuredPlayer",
            field_value: InputNewInjuredVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("NewInjuredPlayer", InputNewInjuredVariable)
      );

      yield put(
        actions.logEvent({
          id: 9486,
          options: {
            field_key: "NullPositionError",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.removeField("NullPositionError"));
    } catch (error) {
      console.warn(error);
    }
  }
}

// function* NEW_INTANGIBLE() {
//   while (true) {
//     let { inputVariables } = yield take(actions.NEW_INTANGIBLE);
//     let state = yield select();
//     state.page =
//       state.routing && state.routing.locationBeforeTransitions
//         ? state.routing.locationBeforeTransitions.query
//         : {};
//     let payload;
//     let newIntArray = [
//       { name: "Effort", value: false, positionName: "" },
//       { name: "Finish", value: false, positionName: "" },
//       { name: "Accuracy", value: false, positionName: "" },
//       { name: "Hand Placement", value: false, positionName: "" },
//       { name: "Impact", value: false, positionName: "" },
//       { name: "Fit", value: false, positionName: "" },
//       { name: "Read", value: false, positionName: "" },
//       { name: "Release", value: false, positionName: "" },
//       { name: "Decision", value: false, positionName: "" },
//       { name: "Footwork", value: false, positionName: "" },
//       { name: "Job", value: false, positionName: "" },
//       { name: "Production", value: false, positionName: "" },
//     ];
//     try {
//       let InputNewIntangibleVariable = inputVariables["input new intangible"];

//       yield put(
//         actions.logEvent({
//           id: 3221,
//           options: {
//             field_key: "NewIntangible",
//             field_value: InputNewIntangibleVariable,
//           },
//           type: "event",
//           time: Date.now(),
//         })
//       );
//       yield put(
//         actions.changeInput("NewIntangible", InputNewIntangibleVariable)
//       );
//       yield put(
//         actions.runAction("UPDATE_INTANGIBLE_ARRAY", {
//           inputVariables: { newIntArray },
//         })
//       );
//     } catch (error) {
//       console.warn(error);
//     }
//   }
// }
function* NEW_POSITION_COACH() {
  // debugger
  while (true) {
    let { inputVariables } = yield take(actions.NEW_POSITION_COACH);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    try {
      let InputNewPositionCoachVariable =
        inputVariables["input new position coach"];
      // debugger
      yield put(
        actions.logEvent({
          id: 7521,
          options: {
            field_key: "NewPositionCoach",
            field_value: InputNewPositionCoachVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("NewPositionCoach", InputNewPositionCoachVariable)
      );

      yield put(
        actions.logEvent({
          id: 13962,
          options: {
            field_key: "SuccessMessage",
            field_value: state.reducer["Emptystring"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SuccessMessage", state.reducer["Emptystring"])
      );
      yield put(actions.changeInput("FormPositionsCoach", undefined));
    } catch (error) {
      // debugger
      console.warn(error);
    }
  }
}
function* NEW_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.NEW_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let SecondaryPositionsOneVariable =
        inputVariables["secondary positions one"];

      var EmailUsedErrorVariable =
        "That email is already being used. Please use another email address.";

      var schema3447;
      var err3447;
      var FormNewUserIsValidVariable = true;
      err3447 = yield call(
        _getValidationErrors,
        Yup.string().email("Please enter a valid email"),
        state.reducer["FormNewUser"]["email"]
      );
      FormNewUserIsValidVariable = !FormNewUserIsValidVariable
        ? false
        : err3447.name !== "ValidationError";
      if (err3447.name === "ValidationError") {
        console.warn(err3447.errors);
        yield put(
          actions.changeInput("FormNewUserErrors.email", err3447.errors)
        );
      } else {
        yield put(actions.removeField("FormNewUserErrors.email"));
      }
      err3447 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a name"),
        state.reducer["FormNewUser"]["name"]
      );
      FormNewUserIsValidVariable = !FormNewUserIsValidVariable
        ? false
        : err3447.name !== "ValidationError";
      if (err3447.name === "ValidationError") {
        console.warn(err3447.errors);
        yield put(
          actions.changeInput("FormNewUserErrors.name", err3447.errors)
        );
      } else {
        yield put(actions.removeField("FormNewUserErrors.name"));
      }
      err3447 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(1, "Please select a position"),
        state.reducer["FormNewUser"]["position id"]
      );
      FormNewUserIsValidVariable = !FormNewUserIsValidVariable
        ? false
        : err3447.name !== "ValidationError";
      if (err3447.name === "ValidationError") {
        console.warn(err3447.errors);
        yield put(
          actions.changeInput("FormNewUserErrors.position id", err3447.errors)
        );
      } else {
        yield put(actions.removeField("FormNewUserErrors.position id"));
      }
      err3447 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormNewUser"]["number"]
      );
      FormNewUserIsValidVariable = !FormNewUserIsValidVariable
        ? false
        : err3447.name !== "ValidationError";
      if (err3447.name === "ValidationError") {
        console.warn(err3447.errors);
        yield put(
          actions.changeInput("FormNewUserErrors.number", err3447.errors)
        );
      } else {
        yield put(actions.removeField("FormNewUserErrors.number"));
      }
      err3447 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormNewUser"]["weight"]
      );
      FormNewUserIsValidVariable = !FormNewUserIsValidVariable
        ? false
        : err3447.name !== "ValidationError";
      if (err3447.name === "ValidationError") {
        console.warn(err3447.errors);
        yield put(
          actions.changeInput("FormNewUserErrors.weight", err3447.errors)
        );
      } else {
        yield put(actions.removeField("FormNewUserErrors.weight"));
      }
      err3447 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormNewUser"]["height"]
      );
      FormNewUserIsValidVariable = !FormNewUserIsValidVariable
        ? false
        : err3447.name !== "ValidationError";
      if (err3447.name === "ValidationError") {
        console.warn(err3447.errors);
        yield put(
          actions.changeInput("FormNewUserErrors.height", err3447.errors)
        );
      } else {
        yield put(actions.removeField("FormNewUserErrors.height"));
      }
      err3447 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormNewUser"]["grade"]
      );
      FormNewUserIsValidVariable = !FormNewUserIsValidVariable
        ? false
        : err3447.name !== "ValidationError";
      if (err3447.name === "ValidationError") {
        console.warn(err3447.errors);
        yield put(
          actions.changeInput("FormNewUserErrors.grade", err3447.errors)
        );
      } else {
        yield put(actions.removeField("FormNewUserErrors.grade"));
      }
      err3447 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormNewUser"]["graduation year"]
      );
      FormNewUserIsValidVariable = !FormNewUserIsValidVariable
        ? false
        : err3447.name !== "ValidationError";
      if (err3447.name === "ValidationError") {
        console.warn(err3447.errors);
        yield put(
          actions.changeInput(
            "FormNewUserErrors.graduation year",
            err3447.errors
          )
        );
      } else {
        yield put(actions.removeField("FormNewUserErrors.graduation year"));
      }
      if (FormNewUserIsValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["active"] =
          state.reducer["FormNewUser"]["active"];
        payload.body_variables["email"] = state.reducer["FormNewUser"]["email"];
        payload.body_variables["name"] = state.reducer["FormNewUser"]["name"];
        payload.body_variables["weight"] =
          state.reducer["FormNewUser"]["weight"];
        payload.body_variables["number"] =
          state.reducer["FormNewUser"]["number"];
        payload.body_variables["graduation_year"] =
          state.reducer["FormNewUser"]["graduation year"];
        payload.body_variables["grade"] = state.reducer["FormNewUser"]["grade"];
        payload.body_variables["height"] =
          state.reducer["FormNewUser"]["height"];
        payload.body_variables["position_id"] =
          state.reducer["FormNewUser"]["position id"];
        payload.body_variables["notes"] = state.reducer["FormNewUser"]["notes"];
        payload.body_variables["secondary_positions"] =
          SecondaryPositionsOneVariable;
        payload.body_variables["category"] =
          state.reducer["FormNewUser"]["category"];
        const response = yield call(serverApi.CreateUserEndpoint, payload);
        const CreateUserResponse = response.data;
        const CreateUserResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 20431,
            options: {
              body_variables: {
                "9eeb51e3-5fa0-4673-a2cc-9a9fbc1b4fe0.5754":
                  state.reducer["FormNewUser"]["active"],
                "9eeb51e3-5fa0-4673-a2cc-9a9fbc1b4fe0.5755":
                  state.reducer["FormNewUser"]["email"],
                "9eeb51e3-5fa0-4673-a2cc-9a9fbc1b4fe0.5756":
                  state.reducer["FormNewUser"]["name"],
                "9eeb51e3-5fa0-4673-a2cc-9a9fbc1b4fe0.5758":
                  state.reducer["FormNewUser"]["weight"],
                "9eeb51e3-5fa0-4673-a2cc-9a9fbc1b4fe0.5759":
                  state.reducer["FormNewUser"]["number"],
                "9eeb51e3-5fa0-4673-a2cc-9a9fbc1b4fe0.5760":
                  state.reducer["FormNewUser"]["graduation year"],
                "9eeb51e3-5fa0-4673-a2cc-9a9fbc1b4fe0.5761":
                  state.reducer["FormNewUser"]["grade"],
                "9eeb51e3-5fa0-4673-a2cc-9a9fbc1b4fe0.5762":
                  state.reducer["FormNewUser"]["height"],
                "9eeb51e3-5fa0-4673-a2cc-9a9fbc1b4fe0.5764":
                  state.reducer["FormNewUser"]["position id"],
                "9eeb51e3-5fa0-4673-a2cc-9a9fbc1b4fe0.5765":
                  state.reducer["FormNewUser"]["notes"],
                "9eeb51e3-5fa0-4673-a2cc-9a9fbc1b4fe0.5770":
                  SecondaryPositionsOneVariable,
                "9eeb51e3-5fa0-4673-a2cc-9a9fbc1b4fe0.9459":
                  state.reducer["FormNewUser"]["category"],
              },
              response_as: CreateUserResponse,
              response_code_as: CreateUserResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (CreateUserResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3451,
              options: {
                parameter_mapping: {
                  "07d7f5ae-8efc-46f6-b7eb-f8207ed35f1b": state.reducer["Zero"],
                },
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.runAction("GET_USERS", { inputVariables: {} }));

          yield put(
            actions.logEvent({
              id: 3458,
              options: {
                field_key: "NewUser",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("NewUser"));
        }
        if (CreateUserResponseStatus !== state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3453,
              options: {
                field_key: "FormNewUserErrors.email",
                field_value: EmailUsedErrorVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "FormNewUserErrors.email",
              EmailUsedErrorVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* NEW_USER_EXERCISE() {
  while (true) {
    let { inputVariables } = yield take(actions.NEW_USER_EXERCISE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let ToggleNewUserExerciseVariable =
        inputVariables["toggle new user exercise"];

      yield put(
        actions.logEvent({
          id: 3896,
          options: {
            field_key: "NewUserExercise",
            field_value: ToggleNewUserExerciseVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("NewUserExercise", ToggleNewUserExerciseVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* PLAY_BY_PLAY_POSITION_SORTING() {
  while (true) {
    yield take(actions.PLAY_BY_PLAY_POSITION_SORTING);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
      const response19149 = yield call(
        serverApi.PlayByPlaySortPositionEndpoint,
        payload
      );
      const PlayResVariable = response19149.data;
      const PlayResCodeVariable = response19149.status;
      yield put(
        actions.logEvent({
          id: 19149,
          options: {
            query_variables: {
              "40aef5ae-d210-445e-b8a4-aa383aa3dc74":
                state.reducer["CurrentGame"]["Id"],
            },
            response_as: PlayResVariable,
            response_code_as: PlayResCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (PlayResCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 19151,
            options: {
              field_key: "PlayReportsData",
              field_value: PlayResVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("PlayReportsData", PlayResVariable));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* PRINT_REPORTS_PARTICULAR() {
  while (true) {
    let { inputVariables } = yield take(actions.PRINT_REPORTS_PARTICULAR);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    try {
      let PrintReportsVariable;
      try {
        PrintReportsVariable = printDiv(inputVariables?.id);
      } catch (e) {
        console.warn(e);
      }
      yield put(
        actions.logEvent({
          id: 19468,
          options: {
            parameter_mapping: {},
            return_as: PrintReportsVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* PRINT_REPORTS() {
  while (true) {
    yield take(actions.PRINT_REPORTS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PrintReportsVariable;
      try {
        PrintReportsVariable = window.PrintReports();
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 19468,
          options: {
            parameter_mapping: {},
            return_as: PrintReportsVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* REFRESH_PAGE() {
  while (true) {
    yield take(actions.REFRESH_PAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let RefreshGamePageVariable;
      try {
        RefreshGamePageVariable = window.RefreshPage({});
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 12332,
          options: {
            parameter_mapping: {},
            return_as: RefreshGamePageVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* RELOADPAGE() {
  while (true) {
    yield take(actions.RELOADPAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(push(`${state.reducer["PageDepthChartsList"]}`));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* RELOADPLAYGRADES() {
  while (true) {
    yield take(actions.RELOADPLAYGRADES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(push(`${state.reducer["PlayGradesPage"]}`));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* REMOVE_ERRORS() {
  while (true) {
    yield take(actions.REMOVE_ERRORS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.logEvent({
          id: 15965,
          options: {
            field_key: "FormNewUserErrors",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.removeField("FormNewUserErrors"));

      yield put(
        actions.logEvent({
          id: 15966,
          options: {
            field_key: "FormPositionsCoachError",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.removeField("FormPositionsCoachError"));

      yield put(
        actions.logEvent({
          id: 15969,
          options: {
            field_key: "FormEditPositionCoachError",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.removeField("FormEditPositionCoachError"));

      yield put(
        actions.logEvent({
          id: 15970,
          options: {
            field_key: "FormEditUserErrors",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.removeField("FormEditUserErrors"));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* REMOVE_ADMIN_EDIT_USER_ERROR() {
  while (true) {
    yield take(actions.REMOVE_ADMIN_EDIT_USER_ERROR);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      yield put(actions.removeField("FormAdminEditUserErrors"));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* REMOVE_SUCCESSEMAILMESSAGE() {
  while (true) {
    yield take(actions.REMOVE_SUCCESSEMAILMESSAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.logEvent({
          id: 16401,
          options: {
            field_key: "SuccessMessage",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.removeField("SuccessMessage"));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* REMOVE_TOGGLEDEPTHCHART() {
  while (true) {
    yield take(actions.REMOVE_TOGGLEDEPTHCHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("ToggleDepthChart", false));
      yield put(
        actions.changeInput(
          "gradingBulkDataClear",
          !state.reducer?.gradingBulkDataClear
        )
      );
      yield put(actions.changeInput("DepthChartPackageId", ""));
      yield put(actions.changeInput("CurrentPackage", 0));
      yield put(actions.changeInput("CheckboxError", ""));
      yield put(actions.changeInput("productionPointErrorMessage", ""));
      yield put(actions.changeInput("GetUploadedVideo", ""));
      yield put(actions.setField("DepthChartIndex", 0));
      yield put(actions.changeInput("UserGrades", state.reducer["Nullvalue"]));
      yield put(actions.changeInput("toggleUserGradeLoader", false));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* ADD_GRADING_PLAY() {
  while (true) {
    let { inputVariables } = yield take(actions.ADD_GRADING_PLAY);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("toggleUserGradeLoader", true));
      const { position } = inputVariables;
      let foundObj = state.reducer?.Positions?.find(
        (item) => item?.name === position
      );
      let position_id = foundObj?.id;
      let depth_chart_id = state.reducer?.CurrentPackage;
      let game_id = state.page?.playgradeid;
      let play_id = state.reducer?.CurrentPlayId;
      payload = {};
      payload["body_variables"] = {};
      payload.body_variables["position_id"] = position_id;
      payload.body_variables["depth_chart_id"] = depth_chart_id;
      payload.body_variables["game_id"] = game_id;
      payload.body_variables["play_id"] = play_id;

      const response = yield call(serverApi.createGradeRow, payload);
      const gradeRowResponseStatus = response.status;

      if (gradeRowResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.runAction("GET_USER_GRADES", {
            inputVariables: {
              depthChartId: depth_chart_id,
              playRowId: play_id,
            },
          })
        );
      }
      yield put(actions.changeInput("CheckboxError", ""));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_NEXT_DEPTH_CHART() {
  while (true) {
    yield take(actions.SET_NEXT_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      yield put(actions.changeInput("toggleUserGradeLoader", true));
      yield put(actions.changeInput("productionPointsData", []));
      yield put(
        actions.changeInput(
          "gradingBulkDataClear",
          !state.reducer?.gradingBulkDataClear
        )
      );
      yield put(actions.changeInput("CheckboxError", ""));
      yield put(actions.changeInput("productionPointErrorMessage", ""));
      let objectData = {};
      if (
        state.reducer?.DepthChartIndex <=
        state.reducer?.PlayByPlayInfo.length - 1
      ) {
        objectData =
          state.reducer?.PlayByPlayInfo[state.reducer?.DepthChartIndex];
        yield put(
          actions.setField(
            "DepthChartIndex",
            state.reducer?.DepthChartIndex + 1
          )
        );

        yield put(
          actions.setField("CurrentPackage", objectData?.["depth_chart_id"])
        );

        yield put(
          actions.setField("CurrentPlayPackage", objectData?.["title"])
        );

        yield put(actions.setField("CurrentPlay", objectData?.["index"]));

        yield put(actions.setField("CurrentName", objectData?.["name"]));

        yield put(
          actions.setField(
            "GetUploadedVideo",
            JSON.parse(objectData?.["hudlVideoLink"])
          )
        );

        yield put(actions.setField("CurrentPlayId", objectData?.["id"]));

        yield put(
          actions.runAction("GET_USER_GRADES", {
            inputVariables: {
              depthChartId: objectData?.["depth_chart_id"],
              playRowId: objectData?.["id"],
            },
          })
        );

        yield put(
          actions.runAction("GET_ALL_PLAY_DEPTH_CHART_USERS", {
            inputVariables: {
              "input depth chart rowid": objectData?.["depth_chart_id"],
              "input play rowid": objectData?.["id"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* REMOVEVALUE() {
  while (true) {
    let { inputVariables } = yield take(actions.REMOVEVALUE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("filterPlayerPositions", -1));
      yield put(actions.changeInput("filterPlayerIntangible", -1));
      yield put(
        actions.runAction("GET_PLAYER_USERS", {
          inputVariables: {
            "intangible id input": -1,
            "position id input": -1,
            rosterValue: inputVariables?.searchValue
              ? inputVariables?.searchValue
              : "",
          },
        })
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* REQUEST_PASSWORD_RESET() {
  while (true) {
    yield take(actions.REQUEST_PASSWORD_RESET);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var AlertMessageVariable =
        "If the email address entered is associated with an account, then an email has been sent instructions on resetting your password";

      var schema4435;
      var err4435;
      var FormRequestPasswordResetIsValidVariable = true;
      err4435 = yield call(
        _getValidationErrors,
        Yup.string()
          .email("Please enter a valid email")
          .required("Please enter a valid email"),
        state.reducer["FormRequestPasswordReset"]["email"]
      );
      FormRequestPasswordResetIsValidVariable =
        !FormRequestPasswordResetIsValidVariable
          ? false
          : err4435.name !== "ValidationError";
      if (err4435.name === "ValidationError") {
        console.warn(err4435.errors);
        yield put(
          actions.changeInput(
            "FormRequestPasswordResetErrors.email",
            err4435.errors
          )
        );
      } else {
        yield put(actions.removeField("FormRequestPasswordResetErrors.email"));
      }
      if (
        FormRequestPasswordResetIsValidVariable ===
        state.reducer["TrueCheckbox"]
      ) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["email"] =
          state.reducer["FormRequestPasswordReset"]["email"];
        const response4437 = yield call(
          serverApi.RequestPasswordResetEndpoint,
          payload
        );

        const RequestPasswordResetResponseCodeVariable = response4437.status;
        yield put(
          actions.logEvent({
            id: 4437,
            options: {
              query_variables: {
                "48e90246-69d4-482c-b232-19d475ea5969":
                  state.reducer["FormRequestPasswordReset"]["email"],
              },
              response_code_as: RequestPasswordResetResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          RequestPasswordResetResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 4439,
              options: {
                field_key: "ResetPasswordRequestResponseText",
                field_value: AlertMessageVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "ResetPasswordRequestResponseText",
              AlertMessageVariable
            )
          );
        }
        if (
          RequestPasswordResetResponseCodeVariable !=
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 12176,
              options: {
                field_key: "ResetPasswordRequestResponseText",
                field_value: AlertMessageVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "ResetPasswordRequestResponseText",
              AlertMessageVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_EMAIL_ALL_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_EMAIL_ALL_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let ResendEmailModalVariable = inputVariables?.["resend email"];
      yield put(
        actions.logEvent({
          id: 13964,
          options: {
            field_key: "ResendEmailAll",
            field_value: ResendEmailModalVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("ResendEmailAll", ResendEmailModalVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* RESEND_EMAIL_ALL_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.RESEND_EMAIL_ALL_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.logEvent({
          id: 13964,
          options: {
            field_key: "SuccessMessage",
            field_value: state.reducer["Emptystring"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SuccessMessage", state.reducer["Emptystring"])
      );
      const response = yield call(serverApi.ResendEmailAllPlayer, null);

      yield put(
        actions.logEvent({
          id: 7781,
          options: {
            field_key: "SuccessMessage",
            field_value: state.reducer["SuccessResendEmail"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "SuccessMessage",
          state.reducer["SuccessResendEmail"]
        )
      );
      yield put(
        actions.logEvent({
          id: 13964,
          options: {
            field_key: "ResendEmailAll",
            field_value: false,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ResendEmailAll", false));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* RESEND_EMAIL() {
  while (true) {
    let { inputVariables } = yield take(actions.RESEND_EMAIL);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayerIdVariable = inputVariables?.["player id"];
      let PositioncoachidVariable = inputVariables["positioncoachid"];

      if (PlayerIdVariable) {
        yield put(
          actions.logEvent({
            id: 13964,
            options: {
              field_key: "SuccessMessage",
              field_value: state.reducer["Emptystring"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("SuccessMessage", state.reducer["Emptystring"])
        );

        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["user_id"] = PlayerIdVariable;

        const response = yield call(serverApi.ResendEmailEndpoint, payload);
        const ResendEmailResponse = response.data;
        const ResendEmailResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 7764,
            options: {
              path_variables: {
                "75eb3f0d-6886-4d80-81d5-a925a89433e6": PositioncoachidVariable,
              },
              response_as: ResendEmailResponse,
              response_code_as: ResendEmailResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 7781,
            options: {
              field_key: "SuccessMessage",
              field_value: state.reducer["SuccessResendEmail"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "SuccessMessage",
            state.reducer["SuccessResendEmail"]
          )
        );
      } else {
        yield put(
          actions.logEvent({
            id: 13964,
            options: {
              field_key: "SuccessMessage",
              field_value: state.reducer["Emptystring"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("SuccessMessage", state.reducer["Emptystring"])
        );

        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["user_id"] = PositioncoachidVariable;

        const response = yield call(serverApi.ResendEmailEndpoint, payload);
        const ResendEmailResponse = response.data;
        const ResendEmailResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 7764,
            options: {
              path_variables: {
                "75eb3f0d-6886-4d80-81d5-a925a89433e6": PositioncoachidVariable,
              },
              response_as: ResendEmailResponse,
              response_code_as: ResendEmailResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.logEvent({
            id: 7781,
            options: {
              field_key: "SuccessMessage",
              field_value: state.reducer["SuccessResendEmail"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "SuccessMessage",
            state.reducer["SuccessResendEmail"]
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* RESET_DEPTH_CHART() {
  while (true) {
    let { inputVariables } = yield take(actions.RESET_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputCategoryfilterVariable = inputVariables["input categoryfilter"];

      let InputDepthchartListWeekFilterVariable =
        inputVariables["input depthchart list week filter"];

      let InputTagfilterVariable = inputVariables["input tagfilter"];

      yield put(
        actions.logEvent({
          id: 9122,
          options: {
            field_key: "DepthChartListsTagFilter",
            field_value: InputTagfilterVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("DepthChartListsTagFilter", InputTagfilterVariable)
      );

      yield put(
        actions.logEvent({
          id: 9123,
          options: {
            field_key: "DepthChartListsCategoryFilter",
            field_value: InputCategoryfilterVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DepthChartListsCategoryFilter",
          InputCategoryfilterVariable
        )
      );

      yield put(
        actions.logEvent({
          id: 9125,
          options: {
            field_key: "DepthChartListWeekFilter",
            field_value: InputDepthchartListWeekFilterVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DepthChartListWeekFilter",
          InputDepthchartListWeekFilterVariable
        )
      );

      yield put(
        actions.changeInput("SelectedWeekId", inputVariables?.["input week"])
      );

      yield put(
        actions.changeInput("SelectedTypeId", inputVariables?.["input type"])
      );

      yield put(
        actions.changeInput("SelectedTagId", inputVariables?.["input tag"])
      );

      yield put(push(`${state.reducer["PageDepthChartsList"]}`));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* RESET_PASSWORD() {
  while (true) {
    yield take(actions.RESET_PASSWORD);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var AlertMessageTwoVariable = "";

      var schema4440;
      var err4440;
      var ResetPasswordIsValidVariable = true;
      err4440 = yield call(
        _getValidationErrors,
        Yup.string().min(7, "Password must be 7 characters"),
        state.reducer["FormResetPassword"]["password"]
      );
      ResetPasswordIsValidVariable = !ResetPasswordIsValidVariable
        ? false
        : err4440.name !== "ValidationError";
      if (err4440.name === "ValidationError") {
        console.warn(err4440.errors);
        yield put(
          actions.changeInput(
            "FormResetPasswordErrors.password",
            err4440.errors
          )
        );
      } else {
        yield put(actions.removeField("FormResetPasswordErrors.password"));
      }
      if (ResetPasswordIsValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["password"] =
          state.reducer["FormResetPassword"]["password"];
        payload.query_variables["token"] = state.page["token"];
        const response = yield call(serverApi.ResetPasswordEndpoint, payload);

        const ResetPasswordResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 4442,
            options: {
              query_variables: {
                "177aeee2-9218-4bbf-9fc0-8ff17267168b":
                  state.reducer["FormResetPassword"]["password"],
                "a32ffad9-d2fb-47b6-b0bd-564aa74ba4d5": state.page["token"],
              },
              response_code_as: ResetPasswordResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (ResetPasswordResponseStatus === state.reducer["TwoHundred"]) {
          yield put(push(`${state.reducer["loginPage"]}`));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_ACCORDION_INDEX() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_ACCORDION_INDEX);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputNewAccordionIndexVariable =
        inputVariables["input new accordion index"];

      yield put(
        actions.logEvent({
          id: 11885,
          options: {
            field_key: "AccordionIndex",
            field_value: InputNewAccordionIndexVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("AccordionIndex", InputNewAccordionIndexVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_AVERAGE_GRADES_FIELD() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_AVERAGE_GRADES_FIELD);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let AverageGradesMsgVariable = inputVariables["average grades msg"];

      yield put(
        actions.logEvent({
          id: 10374,
          options: {
            field_key: "AverageGradesMsg",
            field_value: AverageGradesMsgVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("AverageGradesMsg", AverageGradesMsgVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_ALIAS() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_ALIAS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let AliasIdInputVariable = inputVariables["alias id input"];

      let AliasNameVariable = inputVariables["alias name"];

      yield put(
        actions.logEvent({
          id: 4544,
          options: {
            field_key: "CurrentAlias",
            field_value: AliasIdInputVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("CurrentAlias", AliasIdInputVariable));

      yield put(
        actions.logEvent({
          id: 12425,
          options: {
            field_key: "AliasName",
            field_value: AliasNameVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("AliasName", AliasNameVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_DEPTH_CHART_POSITION() {
  while (true) {
    let { inputVariables } = yield take(
      actions.SET_CURRENT_DEPTH_CHART_POSITION
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let CurrentDepthChartPositionInputVariable =
        inputVariables["current depth chart position input"];

      let CurrentUserNameVariable = inputVariables["current user name"];

      let UserIdInputtVariable = inputVariables["user id inputt"];
      let currentSelectedPositionData = inputVariables["position_all_data"];

      yield put(
        actions.logEvent({
          id: 19481,
          options: {
            field_key: "CurrentUserName",
            field_value: state.reducer["Nullvalue"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentUserName", state.reducer["Nullvalue"])
      );

      yield put(
        actions.logEvent({
          id: 3486,
          options: {
            field_key: "CurrentDepthChartPosition",
            field_value: CurrentDepthChartPositionInputVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "CurrentDepthChartPosition",
          CurrentDepthChartPositionInputVariable
        )
      );

      yield put(
        actions.logEvent({
          id: 3486,
          options: {
            field_key: "currentSelectedPositionData",
            field_value: currentSelectedPositionData,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "currentSelectedPositionData",
          currentSelectedPositionData
        )
      );

      yield put(
        actions.logEvent({
          id: 19620,
          options: {
            field_key: "CurrentUserName",
            field_value: CurrentUserNameVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentUserName", CurrentUserNameVariable)
      );

      yield put(
        actions.logEvent({
          id: 19589,
          options: {
            field_key: "FormDepthChartUser.user id",
            field_value: UserIdInputtVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("FormDepthChartUser.user id", UserIdInputtVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_DOWN() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_DOWN);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DownIdInputVariable = inputVariables["down id input"];

      let ToggleDownVariable = inputVariables["toggle down"];

      yield put(
        actions.logEvent({
          id: 11460,
          options: {
            field_key: "ToggleDown",
            field_value: ToggleDownVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ToggleDown", ToggleDownVariable));

      yield put(
        actions.logEvent({
          id: 11515,
          options: {
            field_key: "EditDown",
            field_value: DownIdInputVariable["down"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("EditDown", DownIdInputVariable["down"]));

      yield put(
        actions.logEvent({
          id: 11516,
          options: {
            field_key: "CurrentPlayId",
            field_value: DownIdInputVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentPlayId", DownIdInputVariable["id"])
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_FIELD() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_FIELD);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let FieldIdInputVariable = inputVariables["field id input"];

      let ToggleFieldVariable = inputVariables["toggle field"];

      yield put(
        actions.logEvent({
          id: 11555,
          options: {
            field_key: "ToggleField",
            field_value: ToggleFieldVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ToggleField", ToggleFieldVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_INDEX() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_INDEX);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IndexIdInputVariable = inputVariables["index id input"];

      let ToggleIndexVariable = inputVariables["toggle index"];

      yield put(
        actions.logEvent({
          id: 12041,
          options: {
            field_key: "ToggleIndex",
            field_value: ToggleIndexVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ToggleIndex", ToggleIndexVariable));

      yield put(
        actions.logEvent({
          id: 12042,
          options: {
            field_key: "EditIndex",
            field_value: IndexIdInputVariable["index"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("EditIndex", IndexIdInputVariable["index"])
      );

      yield put(
        actions.logEvent({
          id: 12043,
          options: {
            field_key: "CurrentPlayId",
            field_value: IndexIdInputVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentPlayId", IndexIdInputVariable["id"])
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_INTANGIBLE() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_INTANGIBLE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let CurrentIntangibleVariable = inputVariables["current intangible"];

      yield put(
        actions.logEvent({
          id: 7695,
          options: {
            field_key: "CurrentIntangible",
            field_value: CurrentIntangibleVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentIntangible", CurrentIntangibleVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_NAME() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_NAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let NameIdInputVariable = inputVariables["name id input"];

      let ToggleNameVariable = inputVariables["toggle name"];

      yield put(
        actions.logEvent({
          id: 11389,
          options: {
            field_key: "ToggleName",
            field_value: ToggleNameVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ToggleName", ToggleNameVariable));

      yield put(
        actions.logEvent({
          id: 11390,
          options: {
            field_key: "EditName",
            field_value: NameIdInputVariable["name"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("EditName", NameIdInputVariable["name"]));

      yield put(
        actions.logEvent({
          id: 11393,
          options: {
            field_key: "CurrentPlayId",
            field_value: NameIdInputVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentPlayId", NameIdInputVariable["id"])
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_NOTES() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_NOTES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let NotesIdInputVariable = inputVariables["notes id input"];

      let ToggleNotesVariable = inputVariables["toggle notes"];

      yield put(
        actions.logEvent({
          id: 11932,
          options: {
            field_key: "ToggleNotes",
            field_value: ToggleNotesVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ToggleNotes", ToggleNotesVariable));

      yield put(
        actions.logEvent({
          id: 11933,
          options: {
            field_key: "EditNotes",
            field_value: NotesIdInputVariable["notes"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("EditNotes", NotesIdInputVariable["notes"])
      );

      yield put(
        actions.logEvent({
          id: 11934,
          options: {
            field_key: "CurrentPlayDepthChartId",
            field_value: NotesIdInputVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "CurrentPlayDepthChartId",
          NotesIdInputVariable["id"]
        )
      );

      yield put(
        actions.logEvent({
          id: 11940,
          options: {
            field_key: "Playid",
            field_value: state.reducer["CurrentPlayId"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("Playid", state.reducer["CurrentPlayId"]));

      yield put(
        actions.logEvent({
          id: 11964,
          options: {
            field_key: "CurrentDepthChartId",
            field_value: NotesIdInputVariable["depthchart_id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "CurrentDepthChartId",
          NotesIdInputVariable["depthchart_id"]
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_PACKAGE() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_PACKAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PackageIdInputVariable = inputVariables["package id input"];

      let TogglePackageVariable = inputVariables["toggle package"];

      yield put(
        actions.logEvent({
          id: 11418,
          options: {
            field_key: "TogglePackage",
            field_value: TogglePackageVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("TogglePackage", TogglePackageVariable));

      yield put(
        actions.logEvent({
          id: 11419,
          options: {
            field_key: "EditPackage",
            field_value: PackageIdInputVariable["depth_chart_id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "EditPackage",
          PackageIdInputVariable["depth_chart_id"]
        )
      );

      yield put(
        actions.logEvent({
          id: 11420,
          options: {
            field_key: "CurrentPlayId",
            field_value: PackageIdInputVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentPlayId", PackageIdInputVariable["id"])
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_POSITION() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_POSITION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let CurrentPositionVariable = inputVariables["current position"];

      yield put(
        actions.logEvent({
          id: 7694,
          options: {
            field_key: "CurrentPosition",
            field_value: CurrentPositionVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentPosition", CurrentPositionVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_PRODUCTION_POINT() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_PRODUCTION_POINT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let ProductionPointInputVariable =
        inputVariables["production point input"];

      let TogglePointVariable = inputVariables["toggle point"];

      yield put(
        actions.logEvent({
          id: 11996,
          options: {
            field_key: "ToggleProductionPoint",
            field_value: TogglePointVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("ToggleProductionPoint", TogglePointVariable)
      );

      yield put(
        actions.logEvent({
          id: 11997,
          options: {
            field_key: "EditProductionPoint",
            field_value: ProductionPointInputVariable["production_point"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "EditProductionPoint",
          ProductionPointInputVariable["production_point"]
        )
      );

      yield put(
        actions.logEvent({
          id: 11998,
          options: {
            field_key: "CurrentPlayDepthChartId",
            field_value: ProductionPointInputVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "CurrentPlayDepthChartId",
          ProductionPointInputVariable["id"]
        )
      );

      yield put(
        actions.logEvent({
          id: 11999,
          options: {
            field_key: "Playid",
            field_value: state.reducer["CurrentPlayId"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("Playid", state.reducer["CurrentPlayId"]));

      yield put(
        actions.logEvent({
          id: 12000,
          options: {
            field_key: "CurrentDepthChartId",
            field_value: ProductionPointInputVariable["depthchart_id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "CurrentDepthChartId",
          ProductionPointInputVariable["depthchart_id"]
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_QTR() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_QTR);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let QtrIdInputVariable = inputVariables["qtr id input"];

      let ToggleQtrVariable = inputVariables["toggle qtr"];

      yield put(
        actions.logEvent({
          id: 11411,
          options: {
            field_key: "ToggleQtr",
            field_value: ToggleQtrVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ToggleQtr", ToggleQtrVariable));

      yield put(
        actions.logEvent({
          id: 11412,
          options: {
            field_key: "EditQtr",
            field_value: QtrIdInputVariable["qtr"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("EditQtr", QtrIdInputVariable["qtr"]));

      yield put(
        actions.logEvent({
          id: 11413,
          options: {
            field_key: "CurrentPlayId",
            field_value: QtrIdInputVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("CurrentPlayId", QtrIdInputVariable["id"]));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_SERIES() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_SERIES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let SeriesIdInputVariable = inputVariables["series id input"];

      let ToggleSeriesVariable = inputVariables["toggle series"];

      yield put(
        actions.logEvent({
          id: 11459,
          options: {
            field_key: "ToggleSeries",
            field_value: ToggleSeriesVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ToggleSeries", ToggleSeriesVariable));

      yield put(
        actions.logEvent({
          id: 11513,
          options: {
            field_key: "EditSeries",
            field_value: SeriesIdInputVariable["series"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("EditSeries", SeriesIdInputVariable["series"])
      );

      yield put(
        actions.logEvent({
          id: 11514,
          options: {
            field_key: "CurrentPlayId",
            field_value: SeriesIdInputVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentPlayId", SeriesIdInputVariable["id"])
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CURRENT_TYPE() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CURRENT_TYPE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let ToggleTypeVariable = inputVariables["toggle type"];

      let TypeIdInputVariable = inputVariables["type id input"];

      yield put(
        actions.logEvent({
          id: 11401,
          options: {
            field_key: "ToggleType",
            field_value: ToggleTypeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ToggleType", ToggleTypeVariable));

      yield put(
        actions.logEvent({
          id: 11402,
          options: {
            field_key: "EditType",
            field_value: TypeIdInputVariable["type"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("EditType", TypeIdInputVariable["type"]));

      yield put(
        actions.logEvent({
          id: 11403,
          options: {
            field_key: "CurrentPlayId",
            field_value: TypeIdInputVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentPlayId", TypeIdInputVariable["id"])
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DATE_FORMAT() {
  while (true) {
    yield take(actions.SET_DATE_FORMAT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let ReportsDateVariable;
      try {
        ReportsDateVariable = window.FormatDate({
          datestring: state.reducer["FormSearchGradesDate"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 10495,
          options: {
            parameter_mapping: {
              "1eb12004-d380-4086-9731-feecf4591dc6":
                state.reducer["FormSearchGradesDate"],
            },
            return_as: ReportsDateVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      yield put(
        actions.logEvent({
          id: 10496,
          options: {
            field_key: "ReportsDate",
            field_value: ReportsDateVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ReportsDate", ReportsDateVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_COACH() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_COACH);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let CoachidVariable = inputVariables["coachid"];

      yield put(
        actions.logEvent({
          id: 13958,
          options: {
            field_key: "CoachToDelete",
            field_value: CoachidVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("CoachToDelete", CoachidVariable));

      yield put(
        actions.logEvent({
          id: 13963,
          options: {
            field_key: "SuccessMessage",
            field_value: state.reducer["Emptystring"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SuccessMessage", state.reducer["Emptystring"])
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_DEPTH_CHART() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DepthChartIdVariable = inputVariables["Depth Chart Id"];

      let DepthChartDataVariable = inputVariables["Depth Chart Data"];

      yield put(
        actions.logEvent({
          id: 8137,
          options: {
            field_key: "DepthChartToDelete",
            field_value: DepthChartIdVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("DepthChartToDelete", DepthChartIdVariable)
      );

      yield put(
        actions.changeInput(
          "CurrentDepthChartData",
          DepthChartDataVariable?.row
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_DEPTH_CHART_ROW() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_DEPTH_CHART_ROW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DeleteDepthChartRowIdVariable =
        inputVariables["Delete Depth Chart Row Id"];

      yield put(
        actions.logEvent({
          id: 8195,
          options: {
            field_key: "DepthChartRowToDelete",
            field_value: DeleteDepthChartRowIdVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DepthChartRowToDelete",
          DeleteDepthChartRowIdVariable
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_EXERCISE() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_EXERCISE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let ExerciseidVariable = inputVariables["exerciseid"];

      yield put(
        actions.logEvent({
          id: 13849,
          options: {
            field_key: "ExerciseToDelete",
            field_value: ExerciseidVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("ExerciseToDelete", ExerciseidVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_GAME() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_GAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GamePracticeIdVariable = inputVariables["game practice id"];

      let ToggleDeleteGameVariable = inputVariables["toggle delete game"];

      yield put(
        actions.logEvent({
          id: 12114,
          options: {
            field_key: "ToggleGameDelete",
            field_value: ToggleDeleteGameVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("ToggleGameDelete", ToggleDeleteGameVariable)
      );

      yield put(
        actions.logEvent({
          id: 12113,
          options: {
            field_key: "GameToDelete",
            field_value: GamePracticeIdVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("GameToDelete", GamePracticeIdVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_GAME_FILE() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_GAME_FILE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GamefileidVariable = inputVariables["gamefileid"];

      yield put(
        actions.logEvent({
          id: 13850,
          options: {
            field_key: "FileToDelete",
            field_value: GamefileidVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("FileToDelete", GamefileidVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_INJURED_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_INJURED_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InjuredPlayerIdVariable = inputVariables["injured player id"];

      yield put(
        actions.logEvent({
          id: 14661,
          options: {
            field_key: "InjuredPlayerToDelete",
            field_value: InjuredPlayerIdVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("InjuredPlayerToDelete", InjuredPlayerIdVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_INTANGIBLE() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_INTANGIBLE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let IddVariable = inputVariables["idd"];

      yield put(
        actions.logEvent({
          id: 13851,
          options: {
            field_key: "IntangibleToDelete",
            field_value: IddVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("IntangibleToDelete", IddVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_PLAY_GRADES_DROPDOWN() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_PLAY_GRADES_DROPDOWN);

    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DepthChartIdVariable = Number(state?.reducer?.DepthChartPackageId);
      let PlayIdPackageVariable = inputVariables["play_id_package"];

      // yield put(
      //   actions.logEvent({
      //     id: 13851,
      //     options: {
      //       field_key: "IntangibleToDelete",
      //       field_value: IddVariable,
      //     },
      //     type: "event",
      //     time: Date.now(),
      //   })
      // );

      yield put(
        actions.changeInput("PlayIdPackageVariable", PlayIdPackageVariable)
      );
      if (inputVariables["check_title"] === null) {
        yield put(
          actions.changeInput("DepthChartPlayIdVariable", DepthChartIdVariable)
        );
        yield put(
          actions.runAction("UPDATE_PLAY_PACKAGE", {
            inputVariables: {
              enableButton: PlayIdPackageVariable,
            },
          })
        );
      } else {
        yield put(
          actions.changeInput(
            "DepthChartPlayIdVariableFlag",
            !state.reducer["DepthChartPlayIdVariableFlag"]
          )
        );
        yield put(
          actions.changeInput("DepthChartPlayIdVariable", DepthChartIdVariable)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_PLAY_DEPTH_CHART_USER() {
  while (true) {
    let { inputVariables } = yield take(
      actions.SET_DELETE_PLAY_DEPTH_CHART_USER
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputPlayDepthChartUserIdVariable =
        inputVariables["input play depth chart user id"];

      let ToggleDeleteRowVariable = inputVariables["toggle delete row"];

      yield put(
        actions.logEvent({
          id: 11935,
          options: {
            field_key: "ToggleDeletePlayDepthChartUser",
            field_value: ToggleDeleteRowVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "ToggleDeletePlayDepthChartUser",
          ToggleDeleteRowVariable
        )
      );

      yield put(
        actions.logEvent({
          id: 11936,
          options: {
            field_key: "CurrentPlayDepthChartId",
            field_value: InputPlayDepthChartUserIdVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "CurrentPlayDepthChartId",
          InputPlayDepthChartUserIdVariable["id"]
        )
      );

      yield put(
        actions.logEvent({
          id: 11965,
          options: {
            field_key: "CurrentDepthChartId",
            field_value: InputPlayDepthChartUserIdVariable["depthchart_id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "CurrentDepthChartId",
          InputPlayDepthChartUserIdVariable["depthchart_id"]
        )
      );

      yield put(
        actions.logEvent({
          id: 11966,
          options: {
            field_key: "Playid",
            field_value: state.reducer["CurrentPlayId"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("Playid", state.reducer["CurrentPlayId"]));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_PLAY_ROW() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_PLAY_ROW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputDeleteRowIdVariable = inputVariables["input delete row id"];

      let ToggleDeleteVariable = inputVariables["toggle delete"];

      yield put(
        actions.logEvent({
          id: 11925,
          options: {
            field_key: "PlayRowToDelete",
            field_value: ToggleDeleteVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("PlayRowToDelete", ToggleDeleteVariable));

      yield put(
        actions.logEvent({
          id: 11926,
          options: {
            field_key: "CurrentPlayId",
            field_value: InputDeleteRowIdVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("CurrentPlayId", InputDeleteRowIdVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_PLAYER_EXERCISE() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_PLAYER_EXERCISE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayerExerciseIdVariable = inputVariables["player exercise id"];

      yield put(
        actions.logEvent({
          id: 14662,
          options: {
            field_key: "PlayerExerciseToDelete",
            field_value: PlayerExerciseIdVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("PlayerExerciseToDelete", PlayerExerciseIdVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_TAG() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_TAG);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let TagIdVariable = inputVariables["tag id"];

      yield put(
        actions.logEvent({
          id: 12276,
          options: {
            field_key: "TagToDelete",
            field_value: TagIdVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("TagToDelete", TagIdVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DELETE_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let UserIddVariable = inputVariables["user idd"];

      yield put(
        actions.logEvent({
          id: 13536,
          options: {
            field_key: "UserToDelete",
            field_value: UserIddVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("UserToDelete", UserIddVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_VIEW_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_VIEW_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let viewUserVariable = inputVariables["modal"];
      if (viewUserVariable === false) {
        yield put(
          actions.logEvent({
            id: 13536,
            options: {
              field_key: "viewUser",
              field_value: viewUserVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("viewUser", viewUserVariable));
      }
      if (inputVariables?.modal) {
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] = inputVariables?.["viewPlayer"];
        const response = yield call(serverApi.GetUserEndpoint, payload);
        const GetUserResponse = response.data;
        const GetUserResponseStatus = response.status;

        yield put(
          actions.logEvent({
            id: 13536,
            options: {
              field_key: "viewUser",
              field_value: viewUserVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("viewUser", viewUserVariable));

        yield put(
          actions.logEvent({
            id: 4469,
            options: {
              path_variables: {
                "54b3e3e7-63cb-4cad-8e8a-e5dda71b8c07": state.page["userid"],
              },
              response_as: GetUserResponse,
              response_code_as: GetUserResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (GetUserResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 4471,
              options: {
                field_key: "ViewPlayer",
                field_value: GetUserResponse[0],
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.changeInput("ViewPlayer", GetUserResponse));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DEPTH_CHART_CATEGORY() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DEPTH_CHART_CATEGORY);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputCategoryVariable = inputVariables["input category"];

      yield put(
        actions.logEvent({
          id: 3156,
          options: {
            field_key: "DepthChartCategory",
            field_value: InputCategoryVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("DepthChartCategory", InputCategoryVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DEPTH_CHART_LIST_CATEGORY_FILTER() {
  while (true) {
    let { inputVariables } = yield take(
      actions.SET_DEPTH_CHART_LIST_CATEGORY_FILTER
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputCategoryFilterVariable = state.reducer?.SelectedTypeId;

      let InputTagFilterVariable = state.reducer?.SelectedTagId;

      yield put(
        actions.logEvent({
          id: 4462,
          options: {
            field_key: "DepthChartListsTagFilter",
            field_value: InputTagFilterVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("DepthChartListsTagFilter", InputTagFilterVariable)
      );

      yield put(
        actions.logEvent({
          id: 3166,
          options: {
            field_key: "DepthChartListsCategoryFilter",
            field_value: InputCategoryFilterVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DepthChartListsCategoryFilter",
          InputCategoryFilterVariable
        )
      );

      yield put(push(`${state.reducer["PageDepthChartsList"]}`));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DEPTH_CHART_LIST_WEEK_FILTER() {
  while (true) {
    let { inputVariables } = yield take(
      actions.SET_DEPTH_CHART_LIST_WEEK_FILTER
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let CategoryFilterVariable = inputVariables["category filter"];

      let InputDepthChartListWeekFilterVariable = parseInt(
        state.reducer?.["SelectedWeekId"]
      );

      let TagFilterVariable = inputVariables["tag filter"];

      yield put(
        actions.logEvent({
          id: 3177,
          options: {
            field_key: "DepthChartListWeekFilter",
            field_value: InputDepthChartListWeekFilterVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DepthChartListWeekFilter",
          InputDepthChartListWeekFilterVariable
        )
      );

      yield put(
        actions.logEvent({
          id: 9904,
          options: {
            field_key: "DepthChartListsCategoryFilter",
            field_value: CategoryFilterVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DepthChartListsCategoryFilter",
          CategoryFilterVariable
        )
      );

      yield put(
        actions.logEvent({
          id: 9905,
          options: {
            field_key: "DepthChartListsTagFilter",
            field_value: TagFilterVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("DepthChartListsTagFilter", TagFilterVariable)
      );

      yield put(push(`${state.reducer["PageDepthChartsList"]}`));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DEPTH_CHART_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DEPTH_CHART_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var FormDepthChartUserIsValidVariable = true;
      if (FormDepthChartUserIsValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] = state.reducer["CurrentDepthChart"]["Id"];
        payload["body_variables"] = {};
        payload.body_variables["position_id"] =
          state.reducer["CurrentDepthChartPosition"]["position id"];
        payload.body_variables["user_id"] = inputVariables?.["Id"];
        // state.reducer["FormDepthChartUser"]["user id"];
        payload.body_variables["depth_chart_id"] =
          state.reducer["CurrentDepthChart"]["Id"];
        payload.body_variables["rank"] =
          state.reducer["CurrentDepthChartPosition"]["rank"];
        payload.body_variables["row_id"] =
          state.reducer["CurrentDepthChartPosition"]["row id"];
        const response3525 = yield call(
          serverApi.SetDepthChartUserEndpoint,
          payload
        );

        const SetDepthChartUserResponseCodesVariable = response3525.status;
        yield put(
          actions.logEvent({
            id: 3525,
            options: {
              body_variables: {
                "e693a648-5333-43d2-9cad-91f6550edb8a.4586":
                  state.reducer["CurrentDepthChartPosition"]["position id"],
                "e693a648-5333-43d2-9cad-91f6550edb8a.4587":
                  inputVariables?.["Id"],
                "e693a648-5333-43d2-9cad-91f6550edb8a.4589":
                  state.reducer["CurrentDepthChart"]["Id"],
                "e693a648-5333-43d2-9cad-91f6550edb8a.4623":
                  state.reducer["CurrentDepthChartPosition"]["rank"],
                "e693a648-5333-43d2-9cad-91f6550edb8a.5172":
                  state.reducer["CurrentDepthChartPosition"]["row id"],
              },
              path_variables: {
                "b05a195b-c786-4259-80b9-8d13faf382d1":
                  state.reducer["CurrentDepthChart"]["Id"],
              },
              response_code_as: SetDepthChartUserResponseCodesVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          SetDepthChartUserResponseCodesVariable === state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 3527,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_DEPTH_CHART_USERS", { inputVariables: {} })
          );

          yield put(
            actions.logEvent({
              id: 3528,
              options: {
                field_key: "CurrentDepthChartPosition",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("CurrentDepthChartPosition"));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_EDIT_DEPTH_CHART() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_EDIT_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputEditDepthChartVariable =
        inputVariables["input edit depth chart"];

      yield put(
        actions.logEvent({
          id: 3480,
          options: {
            field_key: "EditDepthChart",
            field_value: InputEditDepthChartVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("EditDepthChart", InputEditDepthChartVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_EXPORT_DEPTH_CHART() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_EXPORT_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputExportDepthChartVariable =
        inputVariables["input export depth chart"];

      fetch(
        `${process.env.REACT_APP_BASE_URL}/generate_csv?depth_chart_id=${InputExportDepthChartVariable?.["Id"]}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
        .then((response) => response.text())
        .then((text) => {
          var blob = new Blob([text], { type: "text/csv;charset=utf-8" });
          FileSaver.saveAs(
            blob,
            `${InputExportDepthChartVariable?.["title"]}.csv`
          );
        });
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_DUPLICATE_DEPTH_CHART() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DUPLICATE_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DepthChartVariable = inputVariables?.["duplicate depth chart"];

      let DepthChartName = inputVariables?.["duplicate depth chart name"];

      let DepthChartId = inputVariables?.["duplicate depth chart id"];

      let DepthChartBase = inputVariables?.["duplicate base depth chart"];

      let DepthChartAll = inputVariables?.["duplicate all depth chart"];

      yield put(
        actions.logEvent({
          id: 10891,
          options: {
            field_key: "DepthChartToDuplicate",
            field_value: DepthChartVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("DepthChartToDuplicate", DepthChartVariable)
      );

      yield put(
        actions.logEvent({
          id: 10891,
          options: {
            field_key: "DuplicateDepthChartData",
            field_value: DepthChartVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("DuplicateDepthChartData", {
          DepthChartId,
          DepthChartName,
          DepthChartBase,
          DepthChartAll,
        })
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DUPLICATE_DEPTH_CHART() {
  while (true) {
    let { inputVariables } = yield take(actions.DUPLICATE_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PageRedirectVariable = "/depth_chart_lists";
      let DepthChartVariable = inputVariables?.["duplicate depth chart"];
      let DuplicateDepthChartId =
        state.reducer?.DuplicateDepthChartData?.["DepthChartId"];
      let DuplicateDepthChartName =
        state.reducer?.DuplicateDepthChartData?.["DepthChartName"];
      let DuplicateDepthChartBase =
        state.reducer?.DuplicateDepthChartData?.["DepthChartBase"];
      let DuplicateDepthChartAll =
        state.reducer?.DuplicateDepthChartData?.["DepthChartAll"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["depth_chart_id"] = DuplicateDepthChartId;
      payload.query_variables["depth_chart_name"] = DuplicateDepthChartName;
      payload.query_variables["isBase"] = state?.page?.base
        ? state?.page?.base
        : false;

      const response = yield call(serverApi.DuplicateDepthChart, payload);
      const setDuplicateDepthChartResponse = response.data;
      const setDuplicateDepthChartResponseStatus = response.status;

      if (
        setDuplicateDepthChartResponseStatus === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 3480,
            options: {
              field_key: "DuplicateDepthChartData",
              field_value: setDuplicateDepthChartResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "DuplicateDepthChartData",
            setDuplicateDepthChartResponse
          )
        );
        if (DuplicateDepthChartBase) {
          yield put(
            actions.runAction("GET_BASE_DEPTH_CHARTS", { inputVariables: {} })
          );
        } else if (DuplicateDepthChartAll) {
          yield put(
            actions.runAction("GET_GROUPED_CHARTS", { inputVariables: {} })
          );
        } else {
          yield put(push(`${PageRedirectVariable}`));
        }
      }
      yield put(
        actions.logEvent({
          id: 10891,
          options: {
            field_key: "DepthChartToDuplicate",
            field_value: DepthChartVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("DepthChartToDuplicate", DepthChartVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_EDIT_GAME() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_EDIT_GAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let EditGameVariable = inputVariables["Edit Game"];

      yield put(
        actions.logEvent({
          id: 10891,
          options: {
            field_key: "EditGame",
            field_value: EditGameVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("EditGame", EditGameVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CHECKBOX_BULK_GRADING() {
  while (true) {
    yield take(actions.CHECKBOX_BULK_GRADING);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let checkboxbulkData = state.reducer?.selectedPlayDataArray;
      var FormSignUpValidVariable = true;
      var error;
      error = yield call(
        _getValidationErrors,
        Yup.array().min(1, "Please select checkboxes"),
        checkboxbulkData
      );
      FormSignUpValidVariable = !FormSignUpValidVariable
        ? false
        : error.name !== "ValidationError";
      if (error.name === "ValidationError") {
        console.warn(error.errors);
        yield put(actions.changeInput("PlayCheckboxError", error.errors));
      } else {
        yield put(actions.removeField("PlayCheckboxError"));
      }
      if (checkboxbulkData.length > 0) {
        let gradeValueErrorMessage = ["Please select your bulk type"];
        if (state.reducer?.SelectedBulkType === "delete") {
          yield put(
            actions.changeInput(
              "BulkSelectedDropDownData",
              state.reducer?.selectedPlayDataArray
            )
          );
          yield put(actions.changeInput("BulkDeleteModal", true));
        } else if (state.reducer?.SelectedBulkType === "duplicate") {
          payload = {};
          payload["body_variables"] = {};
          payload.body_variables["id"] = state.reducer?.selectedPlayDataArray;

          const response = yield call(
            serverApi.CreateCheckboxBulkGradingEndpoint,
            payload
          );
          const CheckboxBulkResponseStatus = response.status;
          if (CheckboxBulkResponseStatus === state.reducer["TwoHundred"]) {
            window.RefreshPage();
            yield put(actions.changeInput("selectedPlayDataArray", []));
            yield put(actions.changeInput("checkAll", false));
            yield put(actions.changeInput("SelectedBulkType", ""));
          }
        } else if (state.reducer?.SelectedBulkType === "setpackage") {
          yield put(actions.changeInput("isLoaded", true));
          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["depth_chart_id"] =
            state.reducer?.selectedPackage === ""
              ? state.reducer?.Packages?.[0]?.Id
              : state.reducer?.selectedPackage;
          payload["body_variables"] = {};
          payload.body_variables["id"] = state.reducer?.selectedPlayDataArray;

          const response = yield call(
            serverApi.UpdateAllPlayPackageEndpoint,
            payload
          );
          const UpdatePlayPackageResponseStatus = response.status;

          if (UpdatePlayPackageResponseStatus === state.reducer["TwoHundred"]) {
            payload = {};
            payload["query_variables"] = {};
            payload.query_variables["id"] = state.page?.playgradeid;
            payload.query_variables["year"] = state.page?.year
              ? state.page?.year
              : state.reducer?.selectedSeasonYear;
            const response = yield call(
              serverApi.GetPlayByPlayInfoEndpoint,
              payload
            );
            const GetPlayResponse = response.data;
            const GetPlayResponseStatus = response.status;
            if (GetPlayResponseStatus === state.reducer["TwoHundred"]) {
              yield put(actions.changeInput("SelectedBulkType", ""));
              yield put(actions.changeInput("selectedPlayDataArray", []));
              yield put(actions.changeInput("checkAll", false));
              yield put(actions.changeInput("PlayByPlayInfo", GetPlayResponse));
              yield put(actions.changeInput("selectedPackage", ""));
              yield put(actions.changeInput("isLoaded", false));
            } else {
              yield put(actions.changeInput("isLoaded", false));
            }
          } else {
            yield put(actions.changeInput("isLoaded", false));
          }
        } else {
          yield put(
            actions.changeInput("PlayCheckboxError", gradeValueErrorMessage)
          );
        }
      } else {
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_BULK_PLAY_ROW() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_BULK_PLAY_ROW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let modalValue = inputVariables?.bulkmodal;
      yield put(actions.changeInput("BulkSelectedDropDownData", []));
      yield put(actions.changeInput("BulkDeleteModal", modalValue));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* DELETE_CHECKBOX_BULK_GRADING() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_CHECKBOX_BULK_GRADING);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let modalValue = inputVariables?.bulkmodal;
      payload = {};
      payload["body_variables"] = {};
      payload.body_variables["id"] = state.reducer?.BulkSelectedDropDownData;
      payload.body_variables["play_id"] = state.page?.playgradeid;

      const response = yield call(
        serverApi.DeleteCheckboxBulkGradingEndpoint,
        payload
      );
      const CheckboxBulkResponseStatus = response.status;

      if (CheckboxBulkResponseStatus === state.reducer["TwoHundred"]) {
        // yield put(
        //   actions.changeInput("BulkStatus",  CheckboxBulkResponse?.message)
        // );
        yield put(
          actions.runAction("GET_PLAY_BY_PLAY_INFO", {
            inputVariables: { gameid: state.reducer["CurrentGame"]["Id"] },
          })
        );
        yield put(actions.changeInput("selectedPlayDataArray", []));
        yield put(actions.changeInput("checkAll", false));
        yield put(actions.changeInput("BulkDeleteModal", modalValue));
        yield put(actions.changeInput("BulkSelectedDropDownData", []));
        yield put(actions.changeInput("SelectedBulkType", ""));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_BULK_EDIT_ROW() {
  while (true) {
    yield take(actions.SET_BULK_EDIT_ROW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      yield put(
        actions.changeInput("updateBulkEdit", !state.reducer?.updateBulkEdit)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* CHECKBOX_BULK_GRADING_SCALE_DATA() {
  while (true) {
    let { inputVariables } = yield take(
      actions.CHECKBOX_BULK_GRADING_SCALE_DATA
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("updateGradesBulkData", {}));
      let checkValue = inputVariables?.id;
      var FormSignUpValidVariable = true;
      var error;
      error = yield call(
        _getValidationErrors,
        Yup.array().min(
          1,
          "Please select at least one player to auto-apply grades."
        ),
        checkValue
      );
      FormSignUpValidVariable = !FormSignUpValidVariable
        ? false
        : error.name !== "ValidationError";
      if (error.name === "ValidationError") {
        console.warn(error.errors);
        yield put(actions.changeInput("CheckboxError", error.errors));
      } else {
        yield put(actions.removeField("CheckboxError"));
      }
      if (
        checkValue.length > 0 &&
        FormSignUpValidVariable === state.reducer["TrueCheckbox"]
      ) {
        let playValue = state.reducer?.CurrentPlayId;
        let depthChartValue = state.reducer?.CurrentPackage;
        let gameValue = state.reducer?.CurrentGame?.Id;
        let positionValue = state.reducer?.Positions.filter(
          (item) => item.name === inputVariables?.["position value"]
        ).map((val) => val?.id);
        let userValue = inputVariables?.id;
        let gradingValue = inputVariables?.gradingValue;
        const filteredData = state.reducer?.UserGrades?.filter(
          (item) =>
            inputVariables?.id?.includes(item?.user_id) && item?.user_grades
        );
        let GetSeasonYearResponse;
        if (state.reducer?.selectedSeasonYear === "") {
          const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
          GetSeasonYearResponse = response.data;
        }
        if (filteredData?.length > 0) {
          yield put(actions.changeInput("updateBulkEdit", true));
          yield put(
            actions.changeInput("updateGradesBulkData", {
              positionValue,
              userValue,
              gradingValue,
            })
          );
        } else {
          yield put(actions.changeInput("toggleUserGradeLoader", true));
          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["year"] =
            state.reducer?.selectedSeasonYear === ""
              ? GetSeasonYearResponse?.[0]?.year
              : state.reducer?.selectedSeasonYear;
          payload["body_variables"] = {};
          payload.body_variables["play_id"] = playValue;
          payload.body_variables["depth_chart_id"] = depthChartValue;
          payload.body_variables["game_id"] = gameValue;
          payload.body_variables["position_id"] = positionValue[0];
          payload.body_variables["user_id"] = userValue;
          payload.body_variables["value"] = gradingValue;
          const response = yield call(serverApi.ChangeGradeValue, payload);
          const GradeResponseStatus = response.status;
          if (GradeResponseStatus === state.reducer["TwoHundred"]) {
            yield put(
              actions.runAction("GET_USER_GRADES", {
                inputVariables: {
                  depthChartId: depthChartValue,
                  playRowId: playValue,
                },
              })
            );

            yield put(
              actions.runAction("GET_ALL_PLAY_DEPTH_CHART_USERS", {
                inputVariables: {
                  "input depth chart rowid": depthChartValue,
                  "input play rowid": playValue,
                },
              })
            );
          }
          yield put(
            actions.changeInput(
              "gradingBulkDataClear",
              !state.reducer?.gradingBulkDataClear
            )
          );
        }
      } else {
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_BULK_GRADING_SCALE_DATA() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_BULK_GRADING_SCALE_DATA);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let playValue = state.reducer?.CurrentPlayId;
      let depthChartValue = state.reducer?.CurrentPackage;
      let gameValue = state.reducer?.CurrentGame?.Id;

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] = state.reducer?.selectedSeasonYear;
      payload["body_variables"] = {};
      payload.body_variables["play_id"] = playValue;
      payload.body_variables["depth_chart_id"] = depthChartValue;
      payload.body_variables["game_id"] = gameValue;
      payload.body_variables["position_id"] =
        state.reducer?.updateGradesBulkData?.positionValue[0];
      payload.body_variables["user_id"] =
        state.reducer?.updateGradesBulkData?.userValue;
      payload.body_variables["value"] =
        state.reducer?.updateGradesBulkData?.gradingValue;
      const response = yield call(serverApi.ChangeGradeValue, payload);
      const GradeResponseStatus = response.status;
      if (GradeResponseStatus === state.reducer["TwoHundred"]) {
        yield put(actions.changeInput("toggleUserGradeLoader", true));
        yield put(
          actions.changeInput("updateBulkEdit", !state.reducer?.updateBulkEdit)
        );
        yield put(
          actions.runAction("GET_USER_GRADES", {
            inputVariables: {
              depthChartId: depthChartValue,
              playRowId: playValue,
            },
          })
        );

        yield put(
          actions.runAction("GET_ALL_PLAY_DEPTH_CHART_USERS", {
            inputVariables: {
              "input depth chart rowid": depthChartValue,
              "input play rowid": playValue,
            },
          })
        );
      }
      yield put(
        actions.changeInput(
          "gradingBulkDataClear",
          !state.reducer?.gradingBulkDataClear
        )
      );

      yield put(actions.changeInput("updateGradesBulkData", {}));
    } catch (error) {
      console.warn(error);
    }
  }
}

function* UPDATE_VIDEO_LINK() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_VIDEO_LINK);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var err;
      var FormPlaysIsValidVariable = true;
      err = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter link"),
        inputVariables?.currentVideoValue
      );
      FormPlaysIsValidVariable = !FormPlaysIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput("FormUploadVideoErrors.name", err.errors)
        );
      } else {
        yield put(actions.changeInput("FormUploadVideoErrors.name", null));
      }
      if (FormPlaysIsValidVariable === state.reducer["TrueCheckbox"]) {

          const checkIsVideo = async (url) => {
          try {
            const response = await fetch(url, { method: "HEAD" });
            const contentType = response.headers.get("Content-Type");
            return contentType && contentType.startsWith("video");
          } catch (error) {
            console.error("Error checking video:", error);
            return false;
          }
        };
        
        const isVideo = yield call(checkIsVideo, inputVariables?.currentVideoValue);
        if(isVideo){
          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["id"] = inputVariables?.play_id;
          payload["body_variables"] = {};
          payload.body_variables["video_link"] = JSON.stringify([inputVariables?.currentVideoValue])
  
          const response = yield call(serverApi.updateHudlVideoLink,payload);
          const VideoResponse = response.data
          const VideoResponseStatus = response.status;
  
          if (VideoResponseStatus === state.reducer["TwoHundred"]) {
            yield put(
              actions.changeInput("GetUploadedVideo", JSON.parse(VideoResponse?.hudlVideoLink))
            );
            yield put(
              actions.changeInput("ToggleUploadVideo", !state.reducer?.ToggleUploadVideo)
            );
            const updatedArray = videoUploadValueById(state.reducer?.PlayByPlayInfo, inputVariables?.play_id, JSON.parse(VideoResponse?.hudlVideoLink));
            yield put(
              actions.changeInput("PlayByPlayInfo", updatedArray)
            );
          }
        } else {
          yield put(
            actions.changeInput("FormUploadVideoErrors.name", 'Invalid video url')
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPLOAD_HUDL_VIDEO() {
  while (true) {
    let { inputVariables } = yield take(actions.UPLOAD_HUDL_VIDEO);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      // yield put(actions.changeInput("FormUploadVideoErrors.name",null))
      var err;
      var FormPlaysIsValidVariable = true;
      err = yield call(
        _getValidationErrors,
        Yup.string().trim().required("Please enter link"),
        inputVariables?.link
      );
      FormPlaysIsValidVariable = !FormPlaysIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput("FormUploadVideoErrors.name", err.errors)
        );
      } else {
        yield put(actions.changeInput("FormUploadVideoErrors.name", null));
      }
      if (FormPlaysIsValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["video_url"] = inputVariables?.link;
        const response = yield call(serverApi.verifyUploadHudlVideo, payload);
        const verifyHudlVideoUploadResponseStatus = response.status;

        if (
          verifyHudlVideoUploadResponseStatus === state.reducer["TwoHundred"]
        ) {
          yield put(actions.changeInput("ToggleHudlVideoUpload", false));
          yield put(actions.changeInput("ToggleHudlVideoUploadMessage", true));
          yield put(actions.changeInput("FormUploadVideoErrors.name", null));

          payload = {};
          payload["body_variables"] = {};
          payload.body_variables["video_url"] = inputVariables?.link;
          payload.body_variables["play_id"] = state.page?.["playgradeid"];
          payload.body_variables["year"] = state.page?.["year"];

          const response = yield call(serverApi.uploadHudlVideo, payload);
          const uploadHudlVideoResponse = response.data;
          const uploadHudlVideoeResponseStatus = response.status;

          if (uploadHudlVideoeResponseStatus !== state.reducer["TwoHundred"]) {
            alert(uploadHudlVideoResponse?.message);
          }
        } else {
          yield put(
            actions.changeInput("FormUploadVideoErrors.name", "Link is expired")
          );
        }
        // yield call(serverApi.uploadHudlVideo,payload)
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* REMOVE_UPLOAD_HUDL_VIDEO_ERROR() {
  while (true) {
    yield take(actions.REMOVE_UPLOAD_HUDL_VIDEO_ERROR);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      yield put(actions.changeInput("FormUploadVideoErrors.name", null));
    } catch (error) {
      console.warn(error);
    }
  }
}

function* SET_EDIT_INTANGIBLE() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_EDIT_INTANGIBLE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputEditIntangibleVariable = inputVariables["input edit intangible"];

      yield put(
        actions.logEvent({
          id: 4428,
          options: {
            field_key: "EditIntangible",
            field_value: InputEditIntangibleVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("EditIntangible", InputEditIntangibleVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_EDIT_QUIZ_QUIZ() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_EDIT_QUIZ_QUIZ);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputEditQuizVariable = inputVariables["input edit quiz"];

      yield put(
        actions.logEvent({
          id: 3271,
          options: {
            field_key: "EditQuiz",
            field_value: InputEditQuizVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("EditQuiz", InputEditQuizVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_EDIT_USER() {
  // debugger
  while (true) {
    let { inputVariables } = yield take(actions.SET_EDIT_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let EditUserVariable = inputVariables["Edit User"];
      // debugger
      yield put(
        actions.logEvent({
          id: 3114,
          options: {
            field_key: "EditUser",
            field_value: EditUserVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("EditUser", EditUserVariable));

      yield put(
        actions.logEvent({
          id: 13965,
          options: {
            field_key: "SuccessMessage",
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.removeField("SuccessMessage"));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_ADMIN_EDIT_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_ADMIN_EDIT_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      let EditUserVariable = inputVariables?.editUser;
      if(EditUserVariable && Object.keys(EditUserVariable)?.length > 0 ){
        yield put(actions.changeInput("adminEditUser", EditUserVariable));
      }else{
        yield put(actions.changeInput("adminEditUser", {}));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_GAME_DATE() {
  while (true) {
    yield take(actions.SET_GAME_DATE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GameDateVariable;
      try {
        GameDateVariable = window.FormatDate({
          datestring: state.reducer["CurrentGame"]["date"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 10881,
          options: {
            parameter_mapping: {
              "1eb12004-d380-4086-9731-feecf4591dc6":
                state.reducer["CurrentGame"]["date"],
            },
            return_as: GameDateVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      yield put(
        actions.logEvent({
          id: 10882,
          options: {
            field_key: "GameDateResult",
            field_value: GameDateVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("GameDateResult", GameDateVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_GAME_TYPE_FIELD() {
  while (true) {
    yield take(actions.SET_GAME_TYPE_FIELD);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.logEvent({
          id: 10445,
          options: {
            field_key: "GameTypeMsg",
            field_value: state.reducer["FormSearchGradesType"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "GameTypeMsg",
          state.reducer["FormSearchGradesType"]
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_LOGIN_MESSAGE() {
  while (true) {
    yield take(actions.SET_LOGIN_MESSAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.logEvent({
          id: 12229,
          options: {
            field_key: "Unauthorized",
            field_value: state.reducer["Emptystring"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("Unauthorized", state.reducer["Emptystring"])
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_NAVBAR_DROPDOWN_INDEX() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_NAVBAR_DROPDOWN_INDEX);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputNavbarDropdownIndexVariable =
        inputVariables["input navbar dropdown index"];

      yield put(
        actions.logEvent({
          id: 3167,
          options: {
            field_key: "NavBarDropDownIndex",
            field_value: InputNavbarDropdownIndexVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "NavBarDropDownIndex",
          InputNavbarDropdownIndexVariable
        )
      );

      yield put(
        actions.logEvent({
          id: 13964,
          options: {
            field_key: "SuccessMessage",
            field_value: state.reducer["Emptystring"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SuccessMessage", state.reducer["Emptystring"])
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_NEW_POSITION() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_NEW_POSITION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputNewPositionVariable = inputVariables["input new position"];

      yield put(
        actions.logEvent({
          id: 3157,
          options: {
            field_key: "NewPosition",
            field_value: InputNewPositionVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("NewPosition", InputNewPositionVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_NEW_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_NEW_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputNewUserVariable = inputVariables["input new user"];

      yield put(
        actions.logEvent({
          id: 3316,
          options: {
            field_key: "NewUser",
            field_value: InputNewUserVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("NewUser", InputNewUserVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_PLAY_DEPTH_CHART() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_PLAY_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let CurrentPlayPackageVariable = inputVariables["current play package"];

      let DepthChartInputVariable = inputVariables["depth chart input "];
      let DepthChartIndexVariable = inputVariables["depth chart index"];

      let ToggleDepthChartVariable = inputVariables["toggle depth chart"];

      yield put(actions.changeInput("userGradingError", ""));
      yield put(actions.changeInput("toggleUserGradeLoader", true));
      // yield put(
      //   actions.logEvent({
      //     id: 11924,
      //     options: {
      //       field_key: "CurrentPackage",
      //       field_value: DepthChartInputVariable["depth_chart_id"],
      //     },
      //     type: "event",
      //     time: Date.now(),
      //   })
      // );
      // yield put(
      //   actions.changeInput(
      //     "CurrentPackage",
      //     DepthChartInputVariable["depth_chart_id"]
      //   )
      // );

      // yield put(actions.runAction("GET_USER_GRADES", { inputVariables:
      //   {depthChartId:DepthChartInputVariable["depth_chart_id"],
      //   playRowId:DepthChartInputVariable["id"]}
      // }));
      // yield put(
      //   actions.runAction("GET_ALL_PLAY_DEPTH_CHART_USERS", {
      //     inputVariables: {
      //       "input depth chart rowid":
      //         DepthChartInputVariable["depth_chart_id"],
      //       "input play rowid": DepthChartInputVariable["id"],
      //     },
      //   })
      // );
      //  yield put(
      //     actions.runAction("GET_PLAY_BY_PLAY_INFO", {
      //       inputVariables: { gameid: state.reducer["CurrentGame"]["Id"] },
      //     })
      //   );

      yield put(
        actions.setField("DepthChartIndex", DepthChartIndexVariable + 1)
      );

      yield put(
        actions.changeInput("ToggleDepthChart", ToggleDepthChartVariable)
      );
      yield put(
        actions.changeInput("CurrentPlayId", DepthChartInputVariable["id"])
      );
      yield put(actions.changeInput("Playid", DepthChartInputVariable["id"]));

      yield put(
        actions.changeInput(
          "CurrentPackage",
          DepthChartInputVariable["depth_chart_id"]
        )
      );

      yield put(
        actions.changeInput("CurrentPlay", DepthChartInputVariable["index"])
      );

      yield put(
        actions.changeInput("CurrentName", DepthChartInputVariable["name"])
      );

      yield put(
        actions.changeInput(
          "CurrentPlayPackage",
          DepthChartInputVariable["title"]
        )
      );

      yield put(
        actions.changeInput("ReportsCurrentPackage", CurrentPlayPackageVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_NOTES() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_NOTES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let ToggleNotesVariable = inputVariables["set notes"];
      let ToggleNotesData = inputVariables["set notes data"];
      yield put(
        actions.logEvent({
          id: 11568,
          options: {
            field_key: "NoteInfo",
            field_value: ToggleNotesVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("NoteInfo", ToggleNotesVariable));
      yield put(
        actions.logEvent({
          id: 11568,
          options: {
            field_key: "NoteInfoData",
            field_value: ToggleNotesData,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("NoteInfoData", ToggleNotesData));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_PLAY_DEPTH_CHART_FOR_REPORT_FILTER() {
  while (true) {
    let { currentIndex, inputVariables, firstPosition } = yield take(
      actions.SET_PLAY_DEPTH_CHART_FOR_REPORT_FILTER
    );

    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      yield put(actions.changeInput("isLoaded", true));

      let DepthChartInputVariable = inputVariables["depth chart input "];

      yield put(
        actions.logEvent({
          id: 11923,
          options: {
            field_key: "CurrentPlayId",
            field_value: DepthChartInputVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentPlayId", DepthChartInputVariable["id"])
      );
      yield put(
        actions.logEvent({
          id: 11924,
          options: {
            field_key: "CurrentPackage",
            field_value: DepthChartInputVariable["depth_chart_id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "CurrentPackage",
          DepthChartInputVariable["depth_chart_id"]
        )
      );
      yield put(
        actions.runAction("GET_ALL_PLAY_DEPTH_CHART_USERS", {
          inputVariables: {
            "input depth chart rowid":
              DepthChartInputVariable["depth_chart_id"],
            "input play rowid": DepthChartInputVariable["id"],
          },
        })
      );

      yield put(
        actions.runAction("GET_USER_GRADES_FOR_FILTER", {
          inputVariables: {},
          currentIndex,
          firstPosition,
        })
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_ROSTER_FILTER() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_ROSTER_FILTER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let checkValue =
        state.reducer?.filterPositions === 0
          ? undefined
          : state.reducer?.filterPositions;
      let result = state.reducer?.Positions.find(
        (item) => item.id === checkValue
      );
      let InputRosterfilterVariable = result?.name;
      // let InputRosterfilterVariable = inputVariables["input rosterfilter"];

      yield put(
        actions.logEvent({
          id: 3170,
          options: {
            field_key: "RosterFilter",
            field_value: InputRosterfilterVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("RosterFilter", InputRosterfilterVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_USER_DROPDOWN() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_USER_DROPDOWN);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let ToggleDropdownVariable = inputVariables["toggle dropdown"];

      let UserIdVariable = inputVariables["user id"];

      yield put(actions.changeInput("gradingPlayerNameError", ""));
      yield put(actions.changeInput("currentSelectedPositionData", {}));

      yield put(
        actions.logEvent({
          id: 11967,
          options: {
            field_key: "ToggleUserDropDown",
            field_value: ToggleDropdownVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("ToggleUserDropDown", ToggleDropdownVariable)
      );
      let filterValue = state.reducer?.Users?.find(
        (item) => item["Id"] === UserIdVariable?.user_id
      );

      yield put(
        actions.logEvent({
          id: 19484,
          options: {
            field_key: "currentSelectedPositionData",
            field_value: filterValue,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("currentSelectedPositionData", filterValue)
      );

      yield put(
        actions.logEvent({
          id: 12007,
          options: {
            field_key: "CurrentPlayDepthChartId",
            field_value: UserIdVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentPlayDepthChartId", UserIdVariable["id"])
      );

      yield put(
        actions.logEvent({
          id: 12008,
          options: {
            field_key: "UserToUpdate",
            field_value: UserIdVariable["user_id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("UserToUpdate", UserIdVariable["user_id"]));

      yield put(
        actions.logEvent({
          id: 12011,
          options: {
            field_key: "Playid",
            field_value: state.reducer["CurrentPlayId"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("Playid", state.reducer["CurrentPlayId"]));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_USER_ID() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_USER_ID);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputUserIddVariable = inputVariables["input user idd"];

      let InputUserNameVariable = inputVariables["input user name"];

      yield put(
        actions.logEvent({
          id: 19477,
          options: {
            field_key: "FormDepthChartUser.user id",
            field_value: InputUserIddVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("FormDepthChartUser.user id", InputUserIddVariable)
      );

      yield put(
        actions.logEvent({
          id: 19480,
          options: {
            field_key: "CurrentUserName",
            field_value: InputUserNameVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("CurrentUserName", InputUserNameVariable));

      yield put(
        actions.logEvent({
          id: 19478,
          options: {
            field_key: "DepthChartUserDropDown",
            field_value: state.reducer["FalseCheckbox"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DepthChartUserDropDown",
          state.reducer["FalseCheckbox"]
        )
      );

      yield put(
        actions.logEvent({
          id: 19490,
          options: {
            field_key: "FormNewPlayUser",
            field_value: InputUserIddVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("FormNewPlayUser", InputUserIddVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_CSV_PLAY_MAPPING_TITLES() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_CSV_PLAY_MAPPING_TITLES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let TitlesVariable = inputVariables["titles"];

      yield put(
        actions.logEvent({
          id: 13144,
          options: {
            field_key: "CsvPlayMapping.title",
            field_value: TitlesVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("CsvPlayMapping.title", TitlesVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SHARE_DEPTH_CHART() {
  while (true) {
    let { inputVariables } = yield take(actions.SHARE_DEPTH_CHART);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let ShareMessageVariable = inputVariables["Share Message"];

      let ShareVariable = inputVariables["share"];

      let ConfirmShareVariable = window.confirm(ShareMessageVariable);
      yield put(
        actions.logEvent({
          id: 3491,
          options: {
            message: ShareMessageVariable,
            confirm_as: ConfirmShareVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      let CurrentTagsVariable;
      try {
        CurrentTagsVariable = window.CreateTags({
          tagsstring: state.reducer["CurrentDepthChart"]["tags"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 4516,
          options: {
            parameter_mapping: {
              "c0ac754c-ace8-4a3a-961e-96c1098756b8":
                state.reducer["CurrentDepthChart"]["tags"],
            },
            return_as: CurrentTagsVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (ConfirmShareVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] = state.reducer["CurrentDepthChart"]["Id"];
        payload["body_variables"] = {};
        payload.body_variables["title"] =
          state.reducer["CurrentDepthChart"]["title"];
        payload.body_variables["week"] =
          state.reducer["CurrentDepthChart"]["week"];
        payload.body_variables["category"] =
          state.reducer["CurrentDepthChart"]["category"];
        payload.body_variables["Tags"] = CurrentTagsVariable;
        payload.body_variables["shared"] = ShareVariable;
        const response3493 = yield call(
          serverApi.EditDepthChartEndpoint,
          payload
        );

        const ShareDepthChartResponseCodeVariable = response3493.status;
        yield put(
          actions.logEvent({
            id: 3493,
            options: {
              body_variables: {
                "b3f0233c-dc3d-4155-a22d-e6d028fb4001.6023":
                  state.reducer["CurrentDepthChart"]["title"],
                "b3f0233c-dc3d-4155-a22d-e6d028fb4001.6024":
                  state.reducer["CurrentDepthChart"]["week"],
                "b3f0233c-dc3d-4155-a22d-e6d028fb4001.6025":
                  state.reducer["CurrentDepthChart"]["category"],
                "b3f0233c-dc3d-4155-a22d-e6d028fb4001.6028":
                  CurrentTagsVariable,
                "b3f0233c-dc3d-4155-a22d-e6d028fb4001.6053": ShareVariable,
              },
              path_variables: {
                "89e24fde-9fc3-4ac7-91c4-c52b1ec47d5e":
                  state.reducer["CurrentDepthChart"]["Id"],
              },
              response_code_as: ShareDepthChartResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          ShareDepthChartResponseCodeVariable === state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 3495,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_DEPTH_CHART", { inputVariables: {} })
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SHOW_PLAYER_COMPARISON() {
  while (true) {
    let { inputVariables } = yield take(actions.SHOW_PLAYER_COMPARISON);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputShowPlayerComparisonVariable =
        inputVariables["input show player comparison"];

      yield put(
        actions.logEvent({
          id: 3168,
          options: {
            field_key: "ShowPlayerComprison",
            field_value: InputShowPlayerComparisonVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "ShowPlayerComprison",
          InputShowPlayerComparisonVariable
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_AVERAGE_GAME_GRADE_SORTING() {
  while (true) {
    let { inputVariables } = yield take(
      actions.TOGGLE_AVERAGE_GAME_GRADE_SORTING
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputAverageGameGradeSortingVariable =
        inputVariables["input average game grade sorting"];

      yield put(
        actions.logEvent({
          id: 8173,
          options: {
            field_key: "SortingSettings",
            field_value: InputAverageGameGradeSortingVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "SortingSettings",
          InputAverageGameGradeSortingVariable
        )
      );

      if (
        InputAverageGameGradeSortingVariable === state.reducer["TrueCheckbox"]
      ) {
        yield put(
          actions.logEvent({
            id: 8175,
            options: {
              parameter_mapping: {
                "0deec7b4-50dd-45da-ab30-ded43f05f419":
                  state.reducer["PlayerComparisonIntangible"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_AVERAGE_GAME_GRADES_SORTING_DES", {
            inputVariables: {
              "Intangible iiid": state.reducer["PlayerComparisonIntangible"],
            },
          })
        );
      }
      if (
        InputAverageGameGradeSortingVariable === state.reducer["FalseCheckbox"]
      ) {
        yield put(
          actions.logEvent({
            id: 8177,
            options: {
              parameter_mapping: {
                "b13a7dac-e085-4fe5-b001-5a8796a678fa":
                  state.reducer["PlayerComparisonIntangible"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_AVERAGE_GAME_GRADE_SORTING", {
            inputVariables: {
              "intangible idd": state.reducer["PlayerComparisonIntangible"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_AVERAGE_PRACTICE_GRADE_SORTING() {
  while (true) {
    let { inputVariables } = yield take(
      actions.TOGGLE_AVERAGE_PRACTICE_GRADE_SORTING
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputAveragePracticeGradeSortingVariable =
        inputVariables["input average practice grade sorting"];

      yield put(
        actions.logEvent({
          id: 8187,
          options: {
            field_key: "SortingSettings",
            field_value: InputAveragePracticeGradeSortingVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "SortingSettings",
          InputAveragePracticeGradeSortingVariable
        )
      );

      if (
        InputAveragePracticeGradeSortingVariable ===
        state.reducer["TrueCheckbox"]
      ) {
        yield put(
          actions.logEvent({
            id: 8189,
            options: {
              parameter_mapping: {
                "cadc91e2-2f36-4858-a64b-b1acb5c87382":
                  state.reducer["PlayerComparisonIntangible"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_AVERAGE_PRACTICE_GRADES_SORTING_DES", {
            inputVariables: {
              "intangible  id": state.reducer["PlayerComparisonIntangible"],
            },
          })
        );
      }
      if (
        InputAveragePracticeGradeSortingVariable ===
        state.reducer["FalseCheckbox"]
      ) {
        yield put(
          actions.logEvent({
            id: 8191,
            options: {
              parameter_mapping: {
                "ba3bcc56-a7ed-4ba0-a764-28db2ea08b72":
                  state.reducer["PlayerComparisonIntangible"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_AVERAGE_PRACTICE_GRADE_SORTING", {
            inputVariables: {
              "intangible iddd": state.reducer["PlayerComparisonIntangible"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_BASE_PACKAGES_DROPDOWN() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_BASE_PACKAGES_DROPDOWN);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputBasePackagesDropdownVariable =
        inputVariables["input base packages dropdown"];

      yield put(
        actions.logEvent({
          id: 3172,
          options: {
            field_key: "DropDownBasePackagesFilter",
            field_value: InputBasePackagesDropdownVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DropDownBasePackagesFilter",
          InputBasePackagesDropdownVariable
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_DEPTHCHARTUSER_DROPDOWN() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_DEPTHCHARTUSER_DROPDOWN);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputDepthChartUserVariable =
        inputVariables["input depth chart user"];

      yield put(
        actions.logEvent({
          id: 19476,
          options: {
            field_key: "DepthChartUserDropDown",
            field_value: InputDepthChartUserVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DepthChartUserDropDown",
          InputDepthChartUserVariable
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_DROPDOWN_MANAGE_GAME() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_DROPDOWN_MANAGE_GAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let ManageGameDropdownVariable = inputVariables["manage game dropdown"];

      yield put(
        actions.logEvent({
          id: 7405,
          options: {
            field_key: "DropdownManageGame",
            field_value: ManageGameDropdownVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("DropdownManageGame", ManageGameDropdownVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_DROPDOWN_MANAGE_PACKAGE() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_DROPDOWN_MANAGE_PACKAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let ManagePackageDropdownVariable =
        inputVariables["manage package dropdown"];

      yield put(
        actions.logEvent({
          id: 3470,
          options: {
            field_key: "DropdownManagePackage",
            field_value: ManagePackageDropdownVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DropdownManagePackage",
          ManagePackageDropdownVariable
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_INTANGIBLE_FILTER() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_INTANGIBLE_FILTER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputIntangibleFilterVariable =
        inputVariables["input intangible filter"];

      yield put(
        actions.logEvent({
          id: 4679,
          options: {
            field_key: "DropdownIntangibleFilter",
            field_value: InputIntangibleFilterVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DropdownIntangibleFilter",
          InputIntangibleFilterVariable
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_NEW_DATA() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_NEW_DATA);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DataVariable = inputVariables["data"];

      let InputDataToggleVariable = inputVariables["input data toggle"];

      let PlayVariable = inputVariables["play"];

      let TitleNameVariable = inputVariables["title_name"];

      let TitleVariable = inputVariables["title"];

      yield put(
        actions.logEvent({
          id: 11795,
          options: {
            field_key: "NewData",
            field_value: InputDataToggleVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("NewData", InputDataToggleVariable));

      yield put(
        actions.logEvent({
          id: 11799,
          options: {
            field_key: "CurrentPlayId",
            field_value: PlayVariable["id"],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("CurrentPlayId", PlayVariable["id"]));

      yield put(
        actions.logEvent({
          id: 11809,
          options: {
            field_key: "CurrentTitleId",
            field_value: TitleVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("CurrentTitleId", TitleVariable));

      yield put(
        actions.logEvent({
          id: 12048,
          options: {
            field_key: "EditField",
            field_value: DataVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("EditField", DataVariable));

      yield put(
        actions.logEvent({
          id: 12049,
          options: {
            field_key: "CurrentTitle",
            field_value: TitleNameVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("CurrentTitle", TitleNameVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_NEW_PLAY_ROW() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_NEW_PLAY_ROW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let GameIdRowVariable = inputVariables["game id row"];

      let InputNewPlayRowVariable = inputVariables["input new play row"];
      if (GameIdRowVariable) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game_id"] = GameIdRowVariable;
        const response = yield call(serverApi.GetLastIndexEndpoint, payload);
        const GetLastIndexResponse = response.data;
        const GetLastIndexResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 14237,
            options: {
              query_variables: {
                "de005c4d-0a59-4391-82a2-4cbe8b603d65": GameIdRowVariable,
              },
              response_as: GetLastIndexResponse,
              response_code_as: GetLastIndexResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (GetLastIndexResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 14239,
              options: {
                field_key: "LastIndex",
                field_value: GetLastIndexResponse["index"],
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("LastIndex", GetLastIndexResponse["index"])
          );
        }
      }

      yield put(
        actions.logEvent({
          id: 11494,
          options: {
            field_key: "NewPlayRow",
            field_value: InputNewPlayRowVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("NewPlayRow", InputNewPlayRowVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_NEW_QUIZ() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_NEW_QUIZ);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputShowNewQuizVariable = inputVariables["input show new quiz"];

      yield put(
        actions.logEvent({
          id: 3260,
          options: {
            field_key: "NewQuiz",
            field_value: InputShowNewQuizVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("NewQuiz", InputShowNewQuizVariable));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_NEW_ROW() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_NEW_ROW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputNewRowVariable = inputVariables["input new row"];

      yield put(
        actions.logEvent({
          id: 4524,
          options: {
            field_key: "NewRow",
            field_value: InputNewRowVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("NewRow", InputNewRowVariable));
      yield put(actions.changeInput("FormNewPlayHeadingErrors.title", ""));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_PACKAGE_SELECT() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_PACKAGE_SELECT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputOpenSelectDropdownVariable =
        inputVariables["input open select dropdown"];

      let InputRowPlayIdVariable = inputVariables["input row play id"];

      yield put(
        actions.logEvent({
          id: 12177,
          options: {
            field_key: "DropdownSelectPackage",
            field_value: InputOpenSelectDropdownVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DropdownSelectPackage",
          InputOpenSelectDropdownVariable
        )
      );

      yield put(
        actions.logEvent({
          id: 12178,
          options: {
            field_key: "CurrentDropdownPackage",
            field_value: InputRowPlayIdVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("CurrentDropdownPackage", InputRowPlayIdVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_POSITION_SORTING() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_POSITION_SORTING);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputPositionSortingVariable =
        inputVariables["input position sorting"];

      yield put(
        actions.logEvent({
          id: 7872,
          options: {
            field_key: "SortingPositions",
            field_value: InputPositionSortingVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingPositions", InputPositionSortingVariable)
      );

      if (InputPositionSortingVariable === state.reducer["TrueCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 7874,
            options: {
              parameter_mapping: {
                "7b89d361-9f5e-42e9-ae39-07484421efe8":
                  state.reducer["PlayerComparisonIntangible"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_POSITIONS_DES", {
            inputVariables: {
              intangibleiddd: state.reducer["PlayerComparisonIntangible"],
            },
          })
        );
      }
      if (InputPositionSortingVariable === state.reducer["FalseCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 7876,
            options: {
              parameter_mapping: {
                "520a9e1d-2dad-4244-890f-4e82df9b03d6":
                  state.reducer["PlayerComparisonIntangible"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_POSITIONS_ROSTER", {
            inputVariables: {
              Intangibleidd: state.reducer["PlayerComparisonIntangible"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_QUIZ_QUESTION() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_QUIZ_QUESTION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputNewQuizQuestionVariable =
        inputVariables["input new quiz question"];

      yield put(
        actions.logEvent({
          id: 3270,
          options: {
            field_key: "NewQuizQuestion",
            field_value: InputNewQuizQuestionVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("NewQuizQuestion", InputNewQuizQuestionVariable)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_ROSTER_FILTER() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_ROSTER_FILTER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputOpenRosterFilterVariable =
        inputVariables["input open roster filter"];

      yield put(
        actions.logEvent({
          id: 3169,
          options: {
            field_key: "DropdownRosterFilter",
            field_value: InputOpenRosterFilterVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DropdownRosterFilter",
          InputOpenRosterFilterVariable
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
// function* TOGGLE_SORTING() {
//   while (true) {
//     let { inputVariables } = yield take(actions.TOGGLE_SORTING);
//     let state = yield select();
//     state.page =
//       state.routing && state.routing.locationBeforeTransitions
//         ? state.routing.locationBeforeTransitions.query
//         : {};
//     let payload;
//     try {
//       let InputSortingVariable = inputVariables["input sorting"];
//       yield put(
//         actions.logEvent({
//           id: 7808,
//           options: {
//             field_key: "SortingSettings",
//             field_value: InputSortingVariable,
//           },
//           type: "event",
//           time: Date.now(),
//         })
//       );
//       yield put(actions.changeInput("SortingSettings", InputSortingVariable));

//       if (InputSortingVariable === state.reducer["TrueCheckbox"]) {
//         yield put(
//           actions.logEvent({
//             id: 7811,
//             options: {
//               parameter_mapping: {
//                 "90e53468-a6bb-4213-a289-f5dfaa4000b8":
//                   state.reducer["PlayerComparisonIntangible"],
//               },
//             },
//             type: "event",
//             time: Date.now(),
//           })
//         );
//         yield put(
//           actions.runAction("GET_USERS_DES", {
//             inputVariables: {
//               IntangibleId: state.reducer["PlayerComparisonIntangible"],
//             },
//           })
//         );
//       }
//       if (InputSortingVariable != state.reducer["TrueCheckbox"]) {
//         yield put(
//           actions.logEvent({
//             id: 7813,
//             options: {
//               parameter_mapping: {
//                 "07d7f5ae-8efc-46f6-b7eb-f8207ed35f1b":
//                   state.reducer["PlayerComparisonIntangible"],
//               },
//             },
//             type: "event",
//             time: Date.now(),
//           })
//         );
//         yield put(
//           actions.runAction("GET_USERS", {
//             inputVariables: {},
//           })
//         );
//       }
//     } catch (error) {
//       console.warn(error);
//     }
//   }
// }
function* SORT_PLAYER_TABLE() {
  while (true) {
    let { inputVariables } = yield take(actions.SORT_PLAYER_TABLE);
  }
}
function* TOGGLE_SORTING_DOWN_FOR_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_DOWN_FOR_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputSortingDownForPlayerVariable =
        inputVariables["inputSortingDownForPlayer"];

      yield put(
        actions.logEvent({
          id: 17435,
          options: {
            field_key: "SortingPlayer",
            field_value: InputSortingDownForPlayerVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingPlayer", InputSortingDownForPlayerVariable)
      );

      if (state.reducer["SortingPlayer"] === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        const response17438 = yield call(
          serverApi.GetPlayerReportsByDownDesEndpoint,
          payload
        );
        const GetPlayerReportsByDownDesResponseVariable = response17438.data;
        const GetPlayerReportsByDownDesResponseCodeVariable =
          response17438.status;
        yield put(
          actions.logEvent({
            id: 17438,
            options: {
              query_variables: {
                "12e6b50f-4621-404d-aade-ba49e22af7eb":
                  state.reducer["CurrentGame"]["Id"],
                "20a724a8-a4f3-4c6d-b4b8-9f1df248f1be":
                  state.reducer["CurrentUser"]["id"],
              },
              response_as: GetPlayerReportsByDownDesResponseVariable,
              response_code_as: GetPlayerReportsByDownDesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsByDownDesResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17440,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsByDownDesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsByDownDesResponseVariable
            )
          );
        }
      }
      if (state.reducer["SortingPlayer"] === state.reducer["FalseCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        const response17441 = yield call(
          serverApi.GetPlayerReportsByDownEndpoint,
          payload
        );
        const GetPlayerReportsByDownResponseVariable = response17441.data;
        const GetPlayerReportsByDownResponseCodeVariable = response17441.status;
        yield put(
          actions.logEvent({
            id: 17441,
            options: {
              query_variables: {
                "c6ae5989-90c4-4cd1-b116-72b5ed60089f":
                  state.reducer["CurrentUser"]["id"],
                "f25c60c4-f720-404a-8636-5a067f06934b":
                  state.reducer["CurrentGame"]["Id"],
              },
              response_as: GetPlayerReportsByDownResponseVariable,
              response_code_as: GetPlayerReportsByDownResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsByDownResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17443,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsByDownResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsByDownResponseVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
// function* TOGGLE_SORTING_EXERCISE() {
//   while (true) {
//     let { inputVariables } = yield take(actions.TOGGLE_SORTING_EXERCISE);
//     let state = yield select();
//     state.page =
//       state.routing && state.routing.locationBeforeTransitions
//         ? state.routing.locationBeforeTransitions.query
//         : {};
//     let payload;

//     try {
//       let InputToggleExerciseVariable = inputVariables["InputToggleExercise"];

//       yield put(
//         actions.logEvent({
//           id: 7814,
//           options: {
//             field_key: "SortingSettings",
//             field_value: InputToggleExerciseVariable,
//           },
//           type: "event",
//           time: Date.now(),
//         })
//       );
//       yield put(
//         actions.changeInput("SortingSettings", InputToggleExerciseVariable)
//       );

//       if (state.reducer["SortingSettings"] === state.reducer["TrueCheckbox"]) {
//         yield put(
//           actions.logEvent({
//             id: 7816,
//             options: {},
//             type: "event",
//             time: Date.now(),
//           })
//         );
//         yield put(
//           actions.runAction("GET_AVERAGE_USER_EXERCISES", {
//             inputVariables: {},
//           })
//         );
//       }
//       if (state.reducer["SortingSettings"] === state.reducer["FalseCheckbox"]) {
//         yield put(
//           actions.logEvent({
//             id: 7818,
//             options: {},
//             type: "event",
//             time: Date.now(),
//           })
//         );
//         yield put(
//           actions.runAction("GET_AVERAGE_USER_EXERCISES_DES", {
//             inputVariables: {},
//           })
//         );
//       }
//     } catch (error) {
//       console.warn(error);
//     }
//   }
// }
function* TOGGLE_SORTING_GAME_GRADES() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_GAME_GRADES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputToggleGameGradesVariable =
        inputVariables["InputToggleGameGrades"];

      yield put(
        actions.logEvent({
          id: 7822,
          options: {
            field_key: "SortingSettings",
            field_value: InputToggleGameGradesVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingSettings", InputToggleGameGradesVariable)
      );

      if (state.reducer["SortingSettings"] === state.reducer["TrueCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 7848,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_AVERAGE_GAME_GRADES", { inputVariables: {} })
        );
      }
      if (state.reducer["SortingSettings"] === state.reducer["FalseCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 7850,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_AVERAGE_GAME_GRADES_DES", {
            inputVariables: {},
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_INDEX_FOR_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(
      actions.TOGGLE_SORTING_INDEX_FOR_PLAYER
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputToggleIndexForPlayerVariable =
        inputVariables["inputToggleIndexForPlayer"];

      yield put(
        actions.logEvent({
          id: 17336,
          options: {
            field_key: "SortingPlayer",
            field_value: InputToggleIndexForPlayerVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingPlayer", InputToggleIndexForPlayerVariable)
      );

      if (state.reducer["SortingPlayer"] === state.reducer["FalseCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        const response17338 = yield call(
          serverApi.GetPlayerReportsByIndexDesEndpoint,
          payload
        );
        const GetPlayerReportsByIndexDesResVariable = response17338.data;
        const GetPlayerReportsByIndexDesResCodeVariable = response17338.status;
        yield put(
          actions.logEvent({
            id: 17338,
            options: {
              query_variables: {
                "10a2a45b-9705-4e36-a9a5-d5f89525b246":
                  state.reducer["CurrentUser"]["id"],
                "d541816e-dee7-43a8-9b7c-cf7850ab51c4":
                  state.reducer["CurrentGame"]["Id"],
              },
              response_as: GetPlayerReportsByIndexDesResVariable,
              response_code_as: GetPlayerReportsByIndexDesResCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsByIndexDesResCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17340,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsByIndexDesResVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsByIndexDesResVariable
            )
          );
        }
      }
      if (state.reducer["SortingPlayer"] === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        const response17343 = yield call(
          serverApi.GetPlayerReportsDataEndpoint,
          payload
        );
        const GetReportsResVariable = response17343.data;
        const GetReportsResCodeVariable = response17343.status;
        yield put(
          actions.logEvent({
            id: 17343,
            options: {
              query_variables: {
                "8d5c47a0-5ec8-42d6-99f6-38e127e9e6d9":
                  state.reducer["CurrentUser"]["id"],
                "995112b7-1670-43ca-b038-6322b0877b33":
                  state.reducer["CurrentGame"]["Id"],
              },
              response_as: GetReportsResVariable,
              response_code_as: GetReportsResCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (GetReportsResCodeVariable === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 17345,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetReportsResVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("PlayerReportsData", GetReportsResVariable)
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_NAME_FOR_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_NAME_FOR_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputSortingNameForPlayerVariable =
        inputVariables["inputSortingNameForPlayer"];

      yield put(
        actions.logEvent({
          id: 17349,
          options: {
            field_key: "SortingPlayer",
            field_value: InputSortingNameForPlayerVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingPlayer", InputSortingNameForPlayerVariable)
      );

      if (state.reducer["SortingPlayer"] === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        const response17351 = yield call(
          serverApi.GetPlayerReportsByNameDesEndpoint,
          payload
        );
        const GetPlayerReportsByNameDesResponseVariable = response17351.data;
        const GetPlayerReportsByNameDesResponseCodeVariable =
          response17351.status;
        yield put(
          actions.logEvent({
            id: 17351,
            options: {
              query_variables: {
                "7b02fdfe-6cf6-4502-b169-84bf8bc6b80a":
                  state.reducer["CurrentUser"]["id"],
                "a1cc6552-8145-41b2-98f7-89183fa6e1e5":
                  state.reducer["CurrentGame"]["Id"],
              },
              response_as: GetPlayerReportsByNameDesResponseVariable,
              response_code_as: GetPlayerReportsByNameDesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsByNameDesResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17354,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsByNameDesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsByNameDesResponseVariable
            )
          );
        }
      }
      if (state.reducer["SortingPlayer"] === state.reducer["FalseCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        const response17357 = yield call(
          serverApi.GetPlayerReportsByNameEndpoint,
          payload
        );
        const GetPlayerReportsByNameResponseVariable = response17357.data;
        const GetPlayerReportsByNameResponseCodeVariable = response17357.status;
        yield put(
          actions.logEvent({
            id: 17357,
            options: {
              query_variables: {
                "6a7a097d-c8dd-4c2a-9c5e-5c1db2568e11":
                  state.reducer["CurrentGame"]["Id"],
                "848ebb81-5b3a-43a9-a305-70b3db6058f7":
                  state.reducer["CurrentUser"]["id"],
              },
              response_as: GetPlayerReportsByNameResponseVariable,
              response_code_as: GetPlayerReportsByNameResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsByNameResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17359,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsByNameResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsByNameResponseVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_NUMBER() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_NUMBER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputNumberSortingVariable = inputVariables["input number sorting"];

      yield put(
        actions.logEvent({
          id: 7931,
          options: {
            field_key: "SortingSettings",
            field_value: InputNumberSortingVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingSettings", InputNumberSortingVariable)
      );

      if (InputNumberSortingVariable === state.reducer["TrueCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 7933,
            options: {
              parameter_mapping: {
                "a5387ca2-601a-4b16-98f6-b31099963b1e":
                  state.reducer["PlayerComparisonIntangible"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_NUMBER_ROSTER_DES", {
            inputVariables: {
              intangibleidddd: state.reducer["PlayerComparisonIntangible"],
            },
          })
        );
      }
      if (InputNumberSortingVariable === state.reducer["FalseCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 7935,
            options: {
              parameter_mapping: {
                "74f4653b-e848-4b77-8e73-07dd8c5f8b98":
                  state.reducer["PlayerComparisonIntangible"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_NUMBER_ROSTER", {
            inputVariables: {
              intangibleiiid: state.reducer["PlayerComparisonIntangible"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_PACKAGE_FOR_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(
      actions.TOGGLE_SORTING_PACKAGE_FOR_PLAYER
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputSortingPackageForPlayerVariable =
        inputVariables["inputSortingPackageForPlayer"];

      yield put(
        actions.logEvent({
          id: 17444,
          options: {
            field_key: "SortingPlayer",
            field_value: InputSortingPackageForPlayerVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "SortingPlayer",
          InputSortingPackageForPlayerVariable
        )
      );

      if (state.reducer["SortingPlayer"] === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        const response17447 = yield call(
          serverApi.GetPlayerReportsByPackageDesEndpoint,
          payload
        );
        const GetPlayerReportsByPackageDesResponseVariable = response17447.data;
        const GetPlayerReportsByPackageDesResponseCodeVariable =
          response17447.status;
        yield put(
          actions.logEvent({
            id: 17447,
            options: {
              query_variables: {
                "b240e614-37e8-4570-96be-a0e18f764f96":
                  state.reducer["CurrentGame"]["Id"],
                "ca06aad7-6306-4e08-8035-44859082173f":
                  state.reducer["CurrentUser"]["id"],
              },
              response_as: GetPlayerReportsByPackageDesResponseVariable,
              response_code_as:
                GetPlayerReportsByPackageDesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsByPackageDesResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17449,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsByPackageDesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsByPackageDesResponseVariable
            )
          );
        }
      }
      if (state.reducer["SortingPlayer"] === state.reducer["FalseCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        const response17450 = yield call(
          serverApi.GetPlayerReportsByPackageEndpoint,
          payload
        );
        const GetPlayerReportsByPackageResponseVariable = response17450.data;
        const GetPlayerReportsByPackageResponseCodeVariable =
          response17450.status;
        yield put(
          actions.logEvent({
            id: 17450,
            options: {
              query_variables: {
                "52427c03-17a7-41a4-b5a9-047b4795a5d2":
                  state.reducer["CurrentGame"]["Id"],
                "df8900cd-cec4-4098-a1de-0ab966f38468":
                  state.reducer["CurrentUser"]["id"],
              },
              response_as: GetPlayerReportsByPackageResponseVariable,
              response_code_as: GetPlayerReportsByPackageResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsByPackageResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17462,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsByPackageResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsByPackageResponseVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_PLAY_DOWN() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_PLAY_DOWN);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputTogglePlayDownVariable = inputVariables["inputTogglePlayDown"];

      yield put(
        actions.logEvent({
          id: 12706,
          options: {
            field_key: "SortingPlayIndex",
            field_value: InputTogglePlayDownVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingPlayIndex", InputTogglePlayDownVariable)
      );

      if (state.reducer["SortingPlayIndex"] === state.reducer["TrueCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 12708,
            options: {
              parameter_mapping: {
                "f7809065-db2d-475e-bbb8-a89b13f08e5d":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_BY_DOWN_DES", {
            inputVariables: {
              down_des_game_id: state.reducer["CurrentGame"]["Id"],
            },
          })
        );
      }
      if (
        state.reducer["SortingPlayIndex"] === state.reducer["FalseCheckbox"]
      ) {
        yield put(
          actions.logEvent({
            id: 12710,
            options: {
              parameter_mapping: {
                "a308b033-8421-49a4-afc0-84eec334a7dd":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_BY_DOWN", {
            inputVariables: {
              down_game_id: state.reducer["CurrentGame"]["Id"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* TOGGLE_SORTING_PLAY_NAME() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_PLAY_NAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputTogglePlayNameVariable = inputVariables["inputTogglePlayName"];

      yield put(
        actions.logEvent({
          id: 12661,
          options: {
            field_key: "SortingPlayIndex",
            field_value: InputTogglePlayNameVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingPlayIndex", InputTogglePlayNameVariable)
      );

      if (state.reducer["SortingPlayIndex"] === state.reducer["TrueCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 12665,
            options: {
              parameter_mapping: {
                "13e7f804-5082-4114-8bb3-d37572600017":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_BY_NAME_DES", {
            inputVariables: {
              name_des_game_id: state.reducer["CurrentGame"]["Id"],
            },
          })
        );
      }
      if (
        state.reducer["SortingPlayIndex"] === state.reducer["FalseCheckbox"]
      ) {
        yield put(
          actions.logEvent({
            id: 12666,
            options: {
              parameter_mapping: {
                "3f8b36b2-85b7-49fc-8111-79cd1238fcb6":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_BY_NAME", {
            inputVariables: {
              name_game_id: state.reducer["CurrentGame"]["Id"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_PLAY_PACKAGE() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_PLAY_PACKAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputTogglePlayPackageVariable =
        inputVariables["inputTogglePlayPackage"];

      yield put(
        actions.logEvent({
          id: 12717,
          options: {
            field_key: "SortingPlayIndex",
            field_value: InputTogglePlayPackageVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingPlayIndex", InputTogglePlayPackageVariable)
      );

      if (state.reducer["SortingPlayIndex"] === state.reducer["TrueCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 12719,
            options: {
              parameter_mapping: {
                "5a794c74-cc86-4810-b3be-26b6963b4166":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_BY_PACKAGE_DES", {
            inputVariables: {
              package_des_game_id: state.reducer["CurrentGame"]["Id"],
            },
          })
        );
      }
      if (
        state.reducer["SortingPlayIndex"] === state.reducer["FalseCheckbox"]
      ) {
        yield put(
          actions.logEvent({
            id: 12721,
            options: {
              parameter_mapping: {
                "b5b32098-c144-4c86-976d-790987fa225e":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_BY_PACKAGE", {
            inputVariables: {
              package_game_id: state.reducer["CurrentGame"]["Id"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_PLAY_QTR() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_PLAY_QTR);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputTogglePlayQtrVariable = inputVariables["inputTogglePlayQtr"];

      yield put(
        actions.logEvent({
          id: 12684,
          options: {
            field_key: "SortingPlayIndex",
            field_value: InputTogglePlayQtrVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingPlayIndex", InputTogglePlayQtrVariable)
      );

      if (state.reducer["SortingPlayIndex"] === state.reducer["TrueCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 12686,
            options: {
              parameter_mapping: {
                "d6d62b35-dcb6-4303-8b56-30489775f8e1":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_BY_QTR_DES", {
            inputVariables: {
              qtr_des_game_id: state.reducer["CurrentGame"]["Id"],
            },
          })
        );
      }
      if (
        state.reducer["SortingPlayIndex"] === state.reducer["FalseCheckbox"]
      ) {
        yield put(
          actions.logEvent({
            id: 12688,
            options: {
              parameter_mapping: {
                "1f1fb307-74ca-4178-812b-1eadac32a112":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_BY_QTR", {
            inputVariables: { qtr_game_id: state.reducer["CurrentGame"]["Id"] },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_PLAY_TYPE() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_PLAY_TYPE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputTogglePlayTypeVariable = inputVariables["inputTogglePlayType"];

      yield put(
        actions.logEvent({
          id: 12673,
          options: {
            field_key: "SortingPlayIndex",
            field_value: InputTogglePlayTypeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingPlayIndex", InputTogglePlayTypeVariable)
      );

      if (state.reducer["SortingPlayIndex"] === state.reducer["TrueCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 12675,
            options: {
              parameter_mapping: {
                "ba71d2ae-a719-4c60-b37a-68bcf81a33fc":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_BY_TYPE_DES", {
            inputVariables: {
              type_des_game_id: state.reducer["CurrentGame"]["Id"],
            },
          })
        );
      }
      if (
        state.reducer["SortingPlayIndex"] === state.reducer["FalseCheckbox"]
      ) {
        yield put(
          actions.logEvent({
            id: 12677,
            options: {
              parameter_mapping: {
                "3d645586-b446-45e9-8abb-0c65679a5568":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_BY_TYPE", {
            inputVariables: {
              type_game_id: state.reducer["CurrentGame"]["Id"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* TOGGLE_SORTING_QTR_FOR_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_QTR_FOR_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputSortingQtrForPlayerVariable =
        inputVariables["inputSortingQtrForPlayer"];

      yield put(
        actions.logEvent({
          id: 17401,
          options: {
            field_key: "SortingPlayer",
            field_value: InputSortingQtrForPlayerVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingPlayer", InputSortingQtrForPlayerVariable)
      );

      if (state.reducer["SortingPlayer"] === state.reducer["FalseCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        const response17406 = yield call(
          serverApi.GetPlayerReportsByQtrDesEndpoint,
          payload
        );
        const GetPlayerReportsByQtrDesResponseVariable = response17406.data;
        const GetPlayerReportsByQtrDesResponseCodeVariable =
          response17406.status;
        yield put(
          actions.logEvent({
            id: 17406,
            options: {
              query_variables: {
                "277a09d1-868b-497e-847c-1574b101721a":
                  state.reducer["CurrentGame"]["Id"],
                "7935fd71-9259-4c2f-a81d-5a497f33c050":
                  state.reducer["CurrentUser"]["id"],
              },
              response_as: GetPlayerReportsByQtrDesResponseVariable,
              response_code_as: GetPlayerReportsByQtrDesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsByQtrDesResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17408,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsByQtrDesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsByQtrDesResponseVariable
            )
          );
        }
      }
      if (state.reducer["SortingPlayer"] === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        const response17410 = yield call(
          serverApi.GetPlayerReportsByQtrEndpoint,
          payload
        );
        const GetPlayerReportsByQtrResponseVariable = response17410.data;
        const GetPlayerReportsByQtrResponseCodeVariable = response17410.status;
        yield put(
          actions.logEvent({
            id: 17410,
            options: {
              query_variables: {
                "24b78e27-5f16-4fda-b9b5-fb81feefec3f":
                  state.reducer["CurrentUser"]["id"],
                "d040a716-e431-4c91-9cd1-e16e8d06842e":
                  state.reducer["CurrentGame"]["Id"],
              },
              response_as: GetPlayerReportsByQtrResponseVariable,
              response_code_as: GetPlayerReportsByQtrResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsByQtrResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17413,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsByQtrResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsByQtrResponseVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_SECONDARY() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_SECONDARY);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputSecondarySortingVariable =
        inputVariables["input secondary sorting"];

      yield put(
        actions.logEvent({
          id: 7944,
          options: {
            field_key: "SortingSettings",
            field_value: InputSecondarySortingVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingSettings", InputSecondarySortingVariable)
      );

      if (InputSecondarySortingVariable === state.reducer["TrueCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 7946,
            options: {
              parameter_mapping: {
                "549f264b-290d-4dbe-824c-1e7a97b928d0":
                  state.reducer["PlayerComparisonIntangible"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_SECONDARY_ROSTER_DES", {
            inputVariables: {
              intangiblee: state.reducer["PlayerComparisonIntangible"],
            },
          })
        );
      }
      if (InputSecondarySortingVariable === state.reducer["FalseCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 7948,
            options: {
              parameter_mapping: {
                "52756855-d8b1-4247-9d0c-73f5ffc2bfb0":
                  state.reducer["PlayerComparisonIntangible"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_SECONDARY_ROSTER", {
            inputVariables: {
              intangible: state.reducer["PlayerComparisonIntangible"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_SERIES() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_SERIES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputTogglePlaySeriesVariable =
        inputVariables["inputTogglePlaySeries"];

      yield put(
        actions.logEvent({
          id: 12695,
          options: {
            field_key: "SortingPlayIndex",
            field_value: InputTogglePlaySeriesVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingPlayIndex", InputTogglePlaySeriesVariable)
      );

      if (state.reducer["SortingPlayIndex"] === state.reducer["TrueCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 12697,
            options: {
              parameter_mapping: {
                "5a01b639-883c-46d3-be5c-62cbf8ef10f8":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_BY_SERIES_DES", {
            inputVariables: {
              seres_des_game_id: state.reducer["CurrentGame"]["Id"],
            },
          })
        );
      }
      if (
        state.reducer["SortingPlayIndex"] === state.reducer["FalseCheckbox"]
      ) {
        yield put(
          actions.logEvent({
            id: 12699,
            options: {
              parameter_mapping: {
                "6bcfd79b-dbc3-4d4e-bc5b-cbd7d1ed9bae":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_INFO_BY_SERIES", {
            inputVariables: {
              series_game_id: state.reducer["CurrentGame"]["Id"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_SERIES_FOR_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(
      actions.TOGGLE_SORTING_SERIES_FOR_PLAYER
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputSortingSeriesForPlayerVariable =
        inputVariables["inputSortingSeriesForPlayer"];

      yield put(
        actions.logEvent({
          id: 17425,
          options: {
            field_key: "SortingPlayer",
            field_value: InputSortingSeriesForPlayerVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "SortingPlayer",
          InputSortingSeriesForPlayerVariable
        )
      );

      if (state.reducer["SortingPlayer"] === state.reducer["FalseCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        const response17429 = yield call(
          serverApi.GetPlayerReportsBySeriesDesEndpoint,
          payload
        );
        const GetPlayerReportsBySeriesDesResponseVariable = response17429.data;
        const GetPlayerReportsBySeriesDesResponseCodeVariable =
          response17429.status;
        yield put(
          actions.logEvent({
            id: 17429,
            options: {
              query_variables: {
                "cc9d247d-3ada-48fd-9945-77bdffc466db":
                  state.reducer["CurrentGame"]["Id"],
                "ecd3fe7e-072a-4055-9479-7c82b559ce01":
                  state.reducer["CurrentUser"]["id"],
              },
              response_as: GetPlayerReportsBySeriesDesResponseVariable,
              response_code_as: GetPlayerReportsBySeriesDesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsBySeriesDesResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17431,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsBySeriesDesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsBySeriesDesResponseVariable
            )
          );
        }
      }
      if (state.reducer["SortingPlayer"] === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        const response17432 = yield call(
          serverApi.GetPlayerReportsBySeriesEndpoint,
          payload
        );
        const GetPlayerReportsBySeriesResponseVariable = response17432.data;
        const GetPlayerReportsBySeriesResponseCodeVariable =
          response17432.status;
        yield put(
          actions.logEvent({
            id: 17432,
            options: {
              query_variables: {
                "11735596-75c4-4747-89ae-a7276dacc2aa":
                  state.reducer["CurrentUser"]["id"],
                "4e01f4a3-6313-479b-a779-fa1a4757273c":
                  state.reducer["CurrentGame"]["Id"],
              },
              response_as: GetPlayerReportsBySeriesResponseVariable,
              response_code_as: GetPlayerReportsBySeriesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsBySeriesResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17434,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsBySeriesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsBySeriesResponseVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_TYPE_FOR_PLAYER() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_TYPE_FOR_PLAYER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputSortingTypeForPlayerVariable =
        inputVariables["inputSortingTypeForPlayer"];

      yield put(
        actions.logEvent({
          id: 17360,
          options: {
            field_key: "SortingPlayer",
            field_value: InputSortingTypeForPlayerVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingPlayer", InputSortingTypeForPlayerVariable)
      );

      if (state.reducer["SortingPlayer"] === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        const response17391 = yield call(
          serverApi.GetPlayerReportsByTypeDesEndpoint,
          payload
        );
        const GetPlayerReportsByTypeDesResponseVariable = response17391.data;
        const GetPlayerReportsByTypeDesResponseCodeVariable =
          response17391.status;
        yield put(
          actions.logEvent({
            id: 17391,
            options: {
              query_variables: {
                "36aa1d64-2b69-44ee-8212-5cf27f6cfc86":
                  state.reducer["CurrentUser"]["id"],
                "e9ab6ad4-0155-4858-8f40-0c9363749cfc":
                  state.reducer["CurrentGame"]["Id"],
              },
              response_as: GetPlayerReportsByTypeDesResponseVariable,
              response_code_as: GetPlayerReportsByTypeDesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsByTypeDesResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17394,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsByTypeDesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsByTypeDesResponseVariable
            )
          );
        }
      }
      if (state.reducer["SortingPlayer"] === state.reducer["FalseCheckbox"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["user id"] = state.reducer["CurrentUser"]["id"];
        payload.query_variables["game id"] = state.reducer["CurrentGame"]["Id"];
        const response17396 = yield call(
          serverApi.GetPlayerReportsByTypeEndpoint,
          payload
        );
        const GetPlayerReportsByTypeResponseVariable = response17396.data;
        const GetPlayerReportsByTypeResponseCodeVariable = response17396.status;
        yield put(
          actions.logEvent({
            id: 17396,
            options: {
              query_variables: {
                "5b1e1bb1-acf1-4366-8360-d854598237b2":
                  state.reducer["CurrentUser"]["id"],
                "82c2b096-d2c2-4be2-a328-637af5622a99":
                  state.reducer["CurrentGame"]["Id"],
              },
              response_as: GetPlayerReportsByTypeResponseVariable,
              response_code_as: GetPlayerReportsByTypeResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetPlayerReportsByTypeResponseCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 17398,
              options: {
                field_key: "PlayerReportsData",
                field_value: GetPlayerReportsByTypeResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "PlayerReportsData",
              GetPlayerReportsByTypeResponseVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_YEAR() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_SORTING_YEAR);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputYearSortingVariable = inputVariables["input year sorting"];

      yield put(
        actions.logEvent({
          id: 7918,
          options: {
            field_key: "SortingSettings",
            field_value: InputYearSortingVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput("SortingSettings", InputYearSortingVariable)
      );

      if (InputYearSortingVariable === state.reducer["TrueCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 7920,
            options: {
              parameter_mapping: {
                "162652d2-eb48-4df0-8e99-176c363c28e1":
                  state.reducer["PlayerComparisonIntangible"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_YEAR_ROSTER_DES", {
            inputVariables: {
              intangibleiid: state.reducer["PlayerComparisonIntangible"],
            },
          })
        );
      }
      if (InputYearSortingVariable === state.reducer["FalseCheckbox"]) {
        yield put(
          actions.logEvent({
            id: 7922,
            options: {
              parameter_mapping: {
                "4dce1dab-c332-4746-b4a7-1cef2d14b5b3":
                  state.reducer["PlayerComparisonIntangible"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_YEAR_ROSTER", {
            inputVariables: {
              "intangible iid": state.reducer["PlayerComparisonIntangible"],
            },
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_SORTING_EXERCISE_POSITION() {
  while (true) {
    let { inputVariables } = yield take(
      actions.TOGGLE_SORTING_EXERCISE_POSITION
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputToggleExercisePositionVariable =
        inputVariables["inputToggleExercisePosition"];

      yield put(
        actions.logEvent({
          id: 19426,
          options: {
            field_key: "SortingSettings",
            field_value: InputToggleExercisePositionVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "SortingSettings",
          InputToggleExercisePositionVariable
        )
      );

      if (state.reducer["SortingSettings"] === state.reducer["FalseCheckbox"]) {
        const response19429 = yield call(
          serverApi.GetAverageUserExercisesByPositionEndpoint,
          null
        );
        const GetAverageUserExercisesByPositionResVariable = response19429.data;
        const GetAverageUserExercisesByPositionResCodeVariable =
          response19429.status;
        yield put(
          actions.logEvent({
            id: 19429,
            options: {
              response_as: GetAverageUserExercisesByPositionResVariable,
              response_code_as:
                GetAverageUserExercisesByPositionResCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetAverageUserExercisesByPositionResCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 19431,
              options: {
                field_key: "AverageUserExercises",
                field_value: GetAverageUserExercisesByPositionResVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "AverageUserExercises",
              GetAverageUserExercisesByPositionResVariable
            )
          );
        }
      }
      if (state.reducer["SortingSettings"] === state.reducer["TrueCheckbox"]) {
        const response19432 = yield call(
          serverApi.GetAverageUserExercisesByPositionDesEndpoint,
          null
        );
        const GetAverageUserExercisesByPositionDesResVariable =
          response19432.data;
        const GetAverageUserExercisesByPositionDesResCodeVariable =
          response19432.status;
        yield put(
          actions.logEvent({
            id: 19432,
            options: {
              response_as: GetAverageUserExercisesByPositionDesResVariable,
              response_code_as:
                GetAverageUserExercisesByPositionDesResCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          GetAverageUserExercisesByPositionDesResCodeVariable ===
          state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 19434,
              options: {
                field_key: "AverageUserExercises",
                field_value: GetAverageUserExercisesByPositionDesResVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "AverageUserExercises",
              GetAverageUserExercisesByPositionDesResVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_USER_SETTINGS_DROPDOWN() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_USER_SETTINGS_DROPDOWN);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputToggleUserSettingsVariable =
        inputVariables["input toggle user settings"];

      yield put(
        actions.logEvent({
          id: 3273,
          options: {
            field_key: "DropdownUserSettings",
            field_value: InputToggleUserSettingsVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DropdownUserSettings",
          InputToggleUserSettingsVariable
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_WEEK_PACKAGES_DROPDOWN() {
  while (true) {
    let { inputVariables } = yield take(actions.TOGGLE_WEEK_PACKAGES_DROPDOWN);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let InputWeekPackagesDropdownVariable =
        inputVariables["input week packages dropdown"];

      yield put(
        actions.logEvent({
          id: 3174,
          options: {
            field_key: "DropdownWeekPackagesFilter",
            field_value: InputWeekPackagesDropdownVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(
        actions.changeInput(
          "DropdownWeekPackagesFilter",
          InputWeekPackagesDropdownVariable
        )
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_DEPTH_CHART_ALIAS() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_DEPTH_CHART_ALIAS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.reducer["CurrentAlias"]["alias_id"];
      payload["body_variables"] = {};
      payload.body_variables["alias"] = state.reducer["FormAlias"];
      const response = yield call(
        serverApi.UpdateDepthChartAliasEndpoint,
        payload
      );

      const UpdateDepthChartAliasResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 4557,
          options: {
            body_variables: {
              "d0936632-9309-44cf-9c78-808fdf8a7268.4594":
                state.reducer["FormAlias"],
            },
            path_variables: {
              "2d83525f-a6b1-46c7-966f-0b0bd0c4a46f":
                state.reducer["CurrentAlias"]["alias_id"],
            },
            response_code_as: UpdateDepthChartAliasResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (UpdateDepthChartAliasResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 4560,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_DEPTH_CHART_USERS", { inputVariables: {} })
        );
        if (inputVariables?.value) {
          yield put(
            actions.runAction("GET_DEPTH_CHART_FIELD_VIEW", {
              inputVariables: {},
            })
          );
        }

        yield put(
          actions.logEvent({
            id: 4561,
            options: {
              field_key: "CurrentAlias",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("CurrentAlias"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_GAME() {
  while (true) {
    yield take(actions.UPDATE_GAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("isLoaded", true));
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.reducer["EditGame"]["Id"];
      payload["body_variables"] = {};
      payload.body_variables["title"] = state.reducer["FormEditGame"]["title"];
      payload.body_variables["date"] = state.reducer["FormEditGame"]["date"];
      const response = yield call(serverApi.UpdateGameEndpoint, payload);

      const UpdateGameResponseStatus = response.status;
      if (UpdateGameResponseStatus === state.reducer["TwoHundred"]) {
        yield put(actions.runAction("GET_GAMES", { inputVariables: {} }));
        yield put(actions.runAction("GET_GAME", { inputVariables: {} }));
        yield put(actions.runAction("GET_GAME_BY_ID", { inputVariables: {} }));
        yield put(actions.runAction("SET_GAME_DATE", { inputVariables: {} }));
        yield put(actions.changeInput("EditGame", null));
        yield put(actions.changeInput("isLoaded", false));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_GAME_FILE() {
  while (true) {
    yield take(actions.UPDATE_GAME_FILE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var err3555;
      var FormEditGameFileIsValidVariable = true;
      err3555 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a name"),
        state.reducer["FormEditGameFile"]["name"]
      );
      FormEditGameFileIsValidVariable = !FormEditGameFileIsValidVariable
        ? false
        : err3555.name !== "ValidationError";
      if (err3555.name === "ValidationError") {
        console.warn(err3555.errors);
        yield put(
          actions.changeInput("FormEditGameFileErrors.name", err3555.errors)
        );
      } else {
        yield put(actions.removeField("FormEditGameFileErrors.name"));
      }
      if (FormEditGameFileIsValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] = state.reducer["EditGameFile"]["Id"];
        payload["body_variables"] = {};
        payload.body_variables["shared"] =
          state.reducer["FormEditGameFile"]["shared"];
        payload.body_variables["name"] =
          state.reducer["FormEditGameFile"]["name"];
        const response = yield call(serverApi.UpdateGameFileEndpoint, payload);

        const UpdateGameFileResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 3557,
            options: {
              body_variables: {
                "ca65339e-ba67-4967-8738-f5b6c185a5c5.4803":
                  state.reducer["FormEditGameFile"]["shared"],
                "ca65339e-ba67-4967-8738-f5b6c185a5c5.4804":
                  state.reducer["FormEditGameFile"]["name"],
              },
              path_variables: {
                "9b40ff7b-15d3-4593-80b7-ccd95dc5b751":
                  state.reducer["EditGameFile"]["Id"],
              },
              response_code_as: UpdateGameFileResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (UpdateGameFileResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3559,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_GAME_FILES", { inputVariables: {} })
          );

          yield put(
            actions.logEvent({
              id: 3561,
              options: {
                field_key: "EditGameFile",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("EditGameFile"));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_PLAY_DOWN() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_PLAY_DOWN);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayIdDownVariable = inputVariables["play_id_down"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = PlayIdDownVariable;
      payload["body_variables"] = {};
      payload.body_variables["down"] = state.reducer["FormPlayDown"];
      const response = yield call(serverApi.UpdatePlayDownEndpoint, payload);

      const UpdatePlayDownResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 11540,
          options: {
            body_variables: {
              "6a0ea16a-2ed5-41ef-b068-7917c496cca0.12721":
                state.reducer["FormPlayDown"],
            },
            path_variables: {
              "d173708f-1db1-4db4-90ad-ac30be7d84a0": PlayIdDownVariable,
            },
            response_code_as: UpdatePlayDownResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (UpdatePlayDownResponseStatus === state.reducer["TwoHundred"]) {
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* GET_CONFIGURABLE_COLUMNS() {
  while (true) {
    let { inputVariables } = yield take(actions.CONFIGURABLE_COLUMNS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      const userResponse = yield call(serverApi.GetCurrentUserEndpoint, null);
      const GetCurrentUserResponse = userResponse.data;

      let gameId = state.page["playgradeid"];
      let payload = {};

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] = gameId;
      payload.query_variables["user_id"] = GetCurrentUserResponse?.id;

      const gradingIndexResponse = yield call(
        serverApi.getGradingTableColumns,
        payload
      );
      const getGradingTableColumnResponse = gradingIndexResponse.data;
      const getGradingTableColumnStatus = gradingIndexResponse.status;

      if (getGradingTableColumnStatus === state.reducer?.["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 11930,
            options: {
              field_key: "gradingIndexData",
              field_value: getGradingTableColumnResponse?.index,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "gradingIndexData",
            getGradingTableColumnResponse?.index
          )
        );
      }

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] = gameId;
      payload.query_variables["user_id"] = GetCurrentUserResponse?.id;

      const response = yield call(
        serverApi.getConfigurableTableColumns,
        payload
      );
      const getSelectedTableColumn = response.status;

      const fieldTitleResponse = yield call(
        serverApi.GetCustomFieldTitlesEndpoint,
        {
          query_variables: { game_id: gameId },
        }
      );
      const GetCustomFieldTitlesResponse = fieldTitleResponse.data;
      let obj = {};
      GetCustomFieldTitlesResponse &&
        GetCustomFieldTitlesResponse?.forEach((data) => {
          obj[data?.title] = true;
        });

      const getCheckboxConfigStatus = response.status;
      const columns = response?.data?.columns
        ? response?.data?.columns
        : {
            "#": true,
            play: true,
            qtr: true,
            series: true,
            down: true,
            distance: true,
            type: true,
            package: true,
            ...obj,
          };
      yield put(
        actions.logEvent({
          id: 11928,
          options: {
            query_variables: {
              "0165aa64-9a21-40e4-acc4-eca9e9ee9c7d": columns,
              "9b8ad81f-2f0a-47f3-8eab-59426ac82077": columns,
            },
            response_as: getCheckboxConfigStatus,
            response_code_as: getCheckboxConfigStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );

      yield put(
        actions.logEvent({
          id: 11930,
          options: {
            field_key: "initialCheckbox",
            field_value: columns,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("initialCheckbox", columns));

      if (getSelectedTableColumn === state.reducer?.["FiveHundred"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game_id"] = gameId;
        payload.query_variables["user_id"] = GetCurrentUserResponse?.id;
        payload["body_variables"] = {};
        payload.body_variables["columns"] = columns;
        payload.body_variables["name"] = "Default View";

        const saveViewresponse = yield call(
          serverApi.CreateSavedViewsEndpoint,
          payload
        );

        const saveViewsResponse = saveViewresponse.data;
        const saveViewsResponseStatus = saveViewresponse.status;

        if (saveViewsResponseStatus === state.reducer["TwoHundred"]) {
          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["game_id"] = gameId;
          payload.query_variables["user_id"] = GetCurrentUserResponse?.id;

          const response = yield call(
            serverApi.GetDefaultViewEndpoint,
            payload
          );
          const defaultViewResponse = response.data;
          const defaultViewResponseStatus = response.status;

          if (defaultViewResponseStatus === state.reducer["TwoHundred"]) {
            yield put(
              actions.changeInput("defaultViewData", defaultViewResponse)
            );
          }
        }
      } else {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game_id"] = gameId;
        payload.query_variables["user_id"] = GetCurrentUserResponse?.id;

        const response = yield call(serverApi.GetDefaultViewEndpoint, payload);

        const defaultViewResponse = response.data;
        const defaultViewResponseStatus = response.status;

        if (defaultViewResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.changeInput("defaultViewData", defaultViewResponse)
          );

          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["id"] = defaultViewResponse?.[0]?.id;

          const getColumnsResponse = yield call(
            serverApi.GetSavedViewEndpoint,
            payload
          );

          const getColumnsNameResponse = getColumnsResponse.data;
          const getColumnsNameResponseStatus = getColumnsResponse.status;
          if (getColumnsNameResponseStatus === state.reducer["TwoHundred"]) {
            yield put(
              actions.logEvent({
                id: 11930,
                options: {
                  field_key: "initialCheckbox",
                  field_value: getColumnsNameResponse?.[0]?.columns,
                },
                type: "event",
                time: Date.now(),
              })
            );

            yield put(
              actions.changeInput(
                "initialCheckbox",
                getColumnsNameResponse?.[0]?.columns
              )
            );
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* UPDATE_CONFIGURABLE_COLUMNS() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_CONFIGURABLE_COLUMNS);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload = {};
    try {
      const userResponse = yield call(serverApi.GetCurrentUserEndpoint, null);
      const GetCurrentUserResponse = userResponse.data;

      let gameId = state.page["playgradeid"];
      // let payload = {};
      // payload["query_variables"] = {};
      // payload.query_variables["game_id"] = gameId;
      // payload.query_variables["user_id"] = GetCurrentUserResponse?.id;
      // payload["body_variables"] = {};
      // payload.body_variables["columns"] = inputVariables;

      // const response = yield call(serverApi.updateConfigurableTableColumns, payload);
      // const getCheckboxConfigStatus = response.status;
      // const columns = response.data?.columns;

      // yield put(
      //   actions.logEvent({
      //     id: 11928,
      //     options: {
      //       query_variables: {
      //         "0165aa64-9a21-40e4-acc4-eca9e9ee9c7d": columns,
      //         "9b8ad81f-2f0a-47f3-8eab-59426ac82077":
      //         columns,
      //       },
      //       response_as: getCheckboxConfigStatus,
      //       response_code_as: getCheckboxConfigStatus,
      //     },
      //     type: "event",
      //     time: Date.now(),
      //   })
      // );
      // if ( getCheckboxConfigStatus === state.reducer["TwoHundred"]) {
      //   yield put(
      //     actions.logEvent({
      //       id: 11930,
      //       options: {
      //         field_key: "initialCheckbox",
      //         field_value: columns,
      //       },
      //       type: "event",
      //       time: Date.now(),
      //     })
      //   );
      //   yield put(
      //     actions.changeInput(
      //       "initialCheckbox",
      //       columns
      //     )
      //   );
      // }
      let defaultName = state.reducer?.defaultViewData.find(
        (v) => v.id === state.reducer?.selectedDefaultView
      );

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] = gameId;
      payload.query_variables["user_id"] = GetCurrentUserResponse?.id;
      payload["body_variables"] = {};
      payload.body_variables["columns"] = inputVariables;
      payload.body_variables["name"] = defaultName?.name
        ? defaultName?.name
        : state.reducer?.defaultViewData?.[0]?.name;

      const saveViewresponse = yield call(
        serverApi.CreateSavedViewsEndpoint,
        payload
      );

      const saveViewsResponse = saveViewresponse.data;
      const saveViewsResponseStatus = saveViewresponse.status;

      if (saveViewsResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 11930,
            options: {
              field_key: "initialCheckbox",
              field_value: saveViewsResponse?.columns,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("initialCheckbox", saveViewsResponse?.columns)
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* SET_DEFAULT_VIEW() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DEFAULT_VIEW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var err;
      var IsTitleValidVariable = true;
      err = yield call(
        _getValidationErrors,
        Yup.string().trim().required("Please enter title"),
        state.reducer["FormNewDefaultViewTitle"]
      );
      IsTitleValidVariable = !IsTitleValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput("FormNewPlayHeadingErrors.title", err.errors)
        );
      } else {
        // yield put(actions.removeField("FormNewPlayHeadingErrors.title"));
      }
      if (IsTitleValidVariable === state.reducer["TrueCheckbox"]) {
        // let PlayIdDownVariable = inputVariables["play_id_distance"];
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game_id"] = state.reducer?.CurrentGame?.Id;
        payload.query_variables["user_id"] = state.reducer?.CurrentUser?.id;
        payload["body_variables"] = {};
        payload.body_variables["columns"] =
          Object.keys(inputVariables?.value).length > 0
            ? inputVariables?.value
            : state.reducer?.initialCheckbox;
        payload.body_variables["name"] = state.reducer?.FormNewDefaultViewTitle;

        const response = yield call(
          serverApi.CreateSavedViewsEndpoint,
          payload
        );

        const saveViewsResponse = response.data;
        const saveViewsResponseStatus = response.status;

        let obj = {};
        state.reducer?.CustomFieldTitles &&
          state.reducer?.CustomFieldTitles?.forEach((data) => {
            obj[data?.title] = true;
          });
        let column = {
          "#": true,
          play: true,
          qtr: true,
          series: true,
          down: true,
          distance: true,
          type: true,
          package: true,
          ...obj,
        };

        if (saveViewsResponseStatus === state.reducer["TwoHundred"]) {
          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["game_id"] = state.reducer?.CurrentGame?.Id;
          payload.query_variables["user_id"] = state.reducer?.CurrentUser?.id;
          payload["body_variables"] = {};
          payload.body_variables["columns"] = column;
          payload.body_variables["name"] =
            state.reducer?.defaultViewData?.[0]?.name;

          const defaultSaveViewResponse = yield call(
            serverApi.CreateSavedViewsEndpoint,
            payload
          );

          const defaultSaveViewsResponse = defaultSaveViewResponse.data;
          const defaultSaveViewsResponseStatus = defaultSaveViewResponse.status;

          if (defaultSaveViewsResponseStatus === state.reducer["TwoHundred"]) {
            yield put(
              actions.changeInput("FormNewPlayHeadingErrors.title", "")
            );

            payload = {};
            payload["query_variables"] = {};
            payload.query_variables["game_id"] = state.reducer?.CurrentGame?.Id;
            payload.query_variables["user_id"] = state.reducer?.CurrentUser?.id;

            const response = yield call(
              serverApi.GetDefaultViewEndpoint,
              payload
            );

            const defaultViewResponse = response.data;
            const defaultViewResponseStatus = response.status;

            if (defaultViewResponseStatus === state.reducer["TwoHundred"]) {
              yield put(
                actions.changeInput("defaultViewData", defaultViewResponse)
              );
              yield put(
                actions.changeInput(
                  "currentSelectedView",
                  saveViewsResponse?.id
                )
              );
            }
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* SET_DEFAULT_VALUE() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DEFAULT_VALUE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      // yield put(
      //   actions.removeField("customizeView"));
      yield put(
        actions.changeInput("customizeView", !state.reducer?.customizeView)
      );
      yield put(
        actions.changeInput(
          "selectedDefaultView",
          state.reducer?.defaultViewData?.[0]?.id
        )
      );
      yield put(actions.changeInput("currentSelectedView", null));
      if (
        state.reducer?.defaultViewData?.[0]?.id ===
        state.reducer?.selectedDefaultView
      ) {
      } else {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["id"] = state.reducer?.defaultViewData?.[0]?.id;

        const getColumnsResponse = yield call(
          serverApi.GetSavedViewEndpoint,
          payload
        );

        const getColumnsNameResponse = getColumnsResponse.data;
        const getColumnsNameResponseStatus = getColumnsResponse.status;
        if (getColumnsNameResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 11930,
              options: {
                field_key: "initialCheckbox",
                field_value: getColumnsNameResponse?.[0]?.columns,
              },
              type: "event",
              time: Date.now(),
            })
          );

          yield put(
            actions.changeInput(
              "initialCheckbox",
              getColumnsNameResponse?.[0]?.columns
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* DRAG_AND_DROP_GRADING() {
  while (true) {
    let { inputVariables } = yield take(actions.DRAG_AND_DROP_GRADING);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      if (inputVariables?.["isDefault"] !== undefined) {
        yield put(
          actions.changeInput("isDefaultLoaded", inputVariables?.isDefault)
        );
      }
      yield put(actions.changeInput("isLoaded", inputVariables?.loader));
    } catch (error) {
      console.warn(error);
    }
  }
}
function* TOGGLE_CUSTOMIZE_VIEW() {
  while (true) {
    yield take(actions.TOGGLE_CUSTOMIZE_VIEW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.changeInput("customizeView", !state.reducer?.customizeView)
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* SET_SAVED_VIEW() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_SAVED_VIEW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      // let PlayIdDownVariable = inputVariables["play_id_distance"];
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = state.reducer?.selectedDefaultView;

      const response = yield call(serverApi.GetSavedViewEndpoint, payload);

      const savedViewResponse = response.data;
      const savedViewResponseStatus = response.status;
      if (savedViewResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 11930,
            options: {
              field_key: "initialCheckbox",
              field_value: savedViewResponse?.[0]?.columns,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(
          actions.changeInput(
            "initialCheckbox",
            savedViewResponse?.[0]?.columns
          )
        );
        yield put(actions.changeInput("customizeView", true));
        // let gameId = state.page["playgradeid"];

        // payload = {};
        // payload["query_variables"] = {};
        // payload.query_variables["game_id"] = gameId;
        // payload.query_variables["user_id"] = state.reducer?.CurrentUser?.id;

        // const gradingIndexResponse = yield call(serverApi.getGradingTableColumns, payload);
        // const getGradingTableColumnResponse = gradingIndexResponse.data;
        // const getGradingTableColumnStatus = gradingIndexResponse.status;

        // if(getGradingTableColumnStatus === state.reducer?.['TwoHundred']){
        //   yield put(
        //     actions.logEvent({
        //       id: 11930,
        //       options: {
        //         field_key: "gradingIndexData",
        //         field_value: getGradingTableColumnResponse?.index,
        //       },
        //       type: "event",
        //       time: Date.now(),
        //     })
        //   );
        //   yield put(
        //     actions.changeInput(
        //       "gradingIndexData",
        //       getGradingTableColumnResponse?.index
        //     )
        //   );
        // }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* DELETE_SAVED_VIEW() {
  while (true) {
    let { inputVariables } = yield take(actions.DELETE_SAVED_VIEW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = state.reducer?.selectedDefaultView;

      const response = yield call(serverApi.DeleteSavedViewEndpoint, payload);

      const RemoveSaveViewResponse = response.data;
      const RemoveSaveViewResponseStatus = response.status;
      if (RemoveSaveViewResponseStatus === state.reducer["TwoHundred"]) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["game_id"] = state.reducer?.CurrentGame?.Id;
        payload.query_variables["user_id"] = state.reducer?.CurrentUser?.id;

        const response = yield call(serverApi.GetDefaultViewEndpoint, payload);

        const defaultViewResponse = response.data;
        const defaultViewResponseStatus = response.status;

        if (defaultViewResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.changeInput("defaultViewData", defaultViewResponse)
          );
          yield put(
            actions.changeInput(
              "selectedDefaultView",
              state.reducer?.defaultViewData?.[0]?.id
            )
          );
          yield put(actions.changeInput("saveViewModal", false));
          payload = {};
          payload["query_variables"] = {};
          payload.query_variables["id"] =
            state.reducer?.defaultViewData?.[0]?.id;

          const response = yield call(serverApi.GetSavedViewEndpoint, payload);

          const savedViewResponse = response.data;
          const savedViewResponseStatus = response.status;
          if (savedViewResponseStatus === state.reducer["TwoHundred"]) {
            yield put(
              actions.logEvent({
                id: 11930,
                options: {
                  field_key: "initialCheckbox",
                  field_value: savedViewResponse?.[0]?.columns,
                },
                type: "event",
                time: Date.now(),
              })
            );

            yield put(
              actions.changeInput(
                "initialCheckbox",
                savedViewResponse?.[0]?.columns
              )
            );
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* SET_DELETE_SAVED_VIEW() {
  while (true) {
    let { inputVariables } = yield take(actions.SET_DELETE_SAVED_VIEW);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;
    yield put(actions.changeInput("saveViewModal", inputVariables?.value));
    try {
    } catch (error) {
      console.warn(error);
    }
  }
}
// function* GET_GRADING_TABLE_COLUMNS_INDEX() {
//   while (true) {
//     let { inputVariables } = yield take(actions.GET_GRADING_TABLE_COLUMNS_INDEX);
//     let state = yield select();
//     state.page =
//       state.routing && state.routing.locationBeforeTransitions
//         ? state.routing.locationBeforeTransitions.query
//         : {};
//     let payload;
//     try {
//       console.log('inputVariables :>> ', state.reducer,inputVariables);
//       // payload = {};
//       //   payload["query_variables"] = {};
//       //   payload.query_variables["game_id"] = inputVariables?.CurrentGame?.Id;
//       //   payload.query_variables["user_id"] = inputVariables?.CurrentUser?.id;
//       //   payload["body_variables"] = {};
//       //   payload.body_variables["index"] = inputVariables?.idArray;
//       //   const response = yield call(
//       //     serverApi.updateGradingTableColumns,
//       //     payload
//       //   );

//       //   const updateGradingIndexResponse = response.data;
//       //   const updateGradingIndexResponseStatus = response.status;
//       //   if (updateGradingIndexResponseStatus === state.reducer["TwoHundred"]) {
//       //     // yield put(
//       //     //   actions.logEvent({
//       //     //     id: 11930,
//       //     //     options: {
//       //     //       field_key: "initialCheckbox",
//       //     //       field_value:savedViewResponse?.[0]?.columns,
//       //     //     },
//       //     //     type: "event",
//       //     //     time: Date.now(),
//       //     //   })
//       //     // );

//       //     // yield put(
//       //     //   actions.changeInput(
//       //     //     "initialCheckbox",
//       //     //     savedViewResponse?.[0]?.columns
//       //     //   )
//       //     // );
//       //   }
//     } catch (error) {
//       console.warn(error);
//     }
//   }
// }
function* UPDATE_GRADING_TABLE_COLUMNS_INDEX() {
  while (true) {
    let { inputVariables } = yield take(
      actions.UPDATE_GRADING_TABLE_COLUMNS_INDEX
    );
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;
    try {
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] = state.reducer?.CurrentGame?.Id;
      payload.query_variables["user_id"] = state.reducer?.CurrentUser?.id;
      payload["body_variables"] = {};
      payload.body_variables["index"] = inputVariables?.idArray;
      const response = yield call(serverApi.updateGradingTableColumns, payload);

      const updateGradingIndexResponse = response.data;
      const updateGradingIndexResponseStatus = response.status;
      if (updateGradingIndexResponseStatus === state.reducer["TwoHundred"]) {
        // yield put(
        //   actions.logEvent({
        //     id: 11930,
        //     options: {
        //       field_key: "initialCheckbox",
        //       field_value:savedViewResponse?.[0]?.columns,
        //     },
        //     type: "event",
        //     time: Date.now(),
        //   })
        // );
        // yield put(
        //   actions.changeInput(
        //     "initialCheckbox",
        //     savedViewResponse?.[0]?.columns
        //   )
        // );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* UPDATE_PLAY_DISTANCE() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_PLAY_DISTANCE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayIdDownVariable = inputVariables["play_id_distance"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = PlayIdDownVariable;
      payload["body_variables"] = {};
      payload.body_variables["distance"] = state.reducer["FormPlayDistance"];
      const response = yield call(
        serverApi.UpdatePlayDistanceEndpoint,
        payload
      );

      const UpdatePlayDownResponseStatus = response.status;
      // yield put(
      //   actions.logEvent({
      //     id: 11540,
      //     options: {
      //       body_variables: {
      //         "6a0ea16a-2ed5-41ef-b068-7917c496cca0.12721":
      //           state.reducer["FormPlayDistance"],
      //       },
      //       path_variables: {
      //         "d173708f-1db1-4db4-90ad-ac30be7d84a0": PlayIdDownVariable,
      //       },
      //       response_code_as: UpdatePlayDownResponseStatus,
      //     },
      //     type: "event",
      //     time: Date.now(),
      //   })
      // );
      if (UpdatePlayDownResponseStatus === state.reducer["TwoHundred"]) {
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_PLAY_FIELD() {
  while (true) {
    yield take(actions.UPDATE_PLAY_FIELD);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.reducer["CurrentFieldId"];
      payload["query_variables"] = {};
      payload.query_variables["playid"] = state.reducer["CurrentPlayId"];
      payload["body_variables"] = {};
      payload.body_variables["data"] = state.reducer["FormCustomField"];
      const response11641 = yield call(
        serverApi.UpdatePlayFieldEndpoint,
        payload
      );

      const UpdatePlayFieldResponseCodeVariable = response11641.status;
      yield put(
        actions.logEvent({
          id: 11641,
          options: {
            body_variables: {
              "9799c53a-051f-4fcf-9eff-731e84af5551.12667":
                state.reducer["FormCustomField"],
            },
            path_variables: {
              "0b2d52de-105e-44f1-a51d-dbf6ce2e37b9":
                state.reducer["CurrentFieldId"],
            },
            query_variables: {
              "5c58d0b8-8b58-43f4-adb9-b55ce7c061e6":
                state.reducer["CurrentPlayId"],
            },
            response_code_as: UpdatePlayFieldResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (UpdatePlayFieldResponseCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 11629,
            options: {
              parameter_mapping: {
                "e556c934-6791-465b-99d5-4ccad85c63f3":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_PLAY_BY_PLAY_INFO", {
            inputVariables: { gameid: state.reducer["CurrentGame"]["Id"] },
          })
        );

        yield put(
          actions.logEvent({
            id: 11633,
            options: {
              parameter_mapping: {
                "622746b7-b556-48d5-8936-04dab55ffddc":
                  state.reducer["CurrentGame"]["Id"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("GET_CUSTOM_FIELD_TITLES", {
            inputVariables: { "game id": state.reducer["CurrentGame"]["Id"] },
          })
        );

        yield put(
          actions.logEvent({
            id: 11630,
            options: {
              field_key: "ToggleField",
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.removeField("ToggleField"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_PLAY_INDEX() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_PLAY_INDEX);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayIdIndexVariable = inputVariables["play_id_index"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = PlayIdIndexVariable;
      payload["body_variables"] = {};
      payload.body_variables["index"] = state.reducer["FormPlayIndex"];
      const response12044 = yield call(
        serverApi.UpdatePlayIndexEndpoint,
        payload
      );

      const UpdatePlayIndexResponseCodeVariable = response12044.status;
      yield put(
        actions.logEvent({
          id: 12044,
          options: {
            body_variables: {
              "2c8d8d1a-d1c1-4832-972e-6f135305004b.13027":
                state.reducer["FormPlayIndex"],
            },
            query_variables: {
              "1be997d2-e092-43d9-b193-17e5c3c632ae": PlayIdIndexVariable,
            },
            response_code_as: UpdatePlayIndexResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (UpdatePlayIndexResponseCodeVariable === state.reducer["TwoHundred"]) {
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_PLAY_NAME() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_PLAY_NAME);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayIdVariable = inputVariables["play_id"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = PlayIdVariable;
      payload["body_variables"] = {};
      payload.body_variables["name"] = state.reducer["FormPlayName"];
      const response14113 = yield call(
        serverApi.UpdatePlayNameEndpoint,
        payload
      );

      const UpdatePlayNameResponseCodeVariable = response14113.status;
      yield put(
        actions.logEvent({
          id: 14113,
          options: {
            body_variables: {
              "1a476157-1795-486e-ac3a-894387c35750.12716":
                state.reducer["FormPlayName"],
            },
            path_variables: {
              "4fdd3c62-8820-418a-bc9a-2c66a90d00db": PlayIdVariable,
            },
            response_code_as: UpdatePlayNameResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (UpdatePlayNameResponseCodeVariable === state.reducer["TwoHundred"]) {
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_PLAY_PACKAGE() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_PLAY_PACKAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DepthChartIdVariable = state.reducer["DepthChartPlayIdVariable"];

      let PlayIdPackageVariable = state.reducer["PlayIdPackageVariable"];

      let enableId = inputVariables["enableButton"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = PlayIdPackageVariable;
      payload["body_variables"] = {};
      payload.body_variables["depth_chart_id"] = DepthChartIdVariable;
      const response = yield call(serverApi.UpdatePlayPackageEndpoint, payload);
      const UpdatePlayPackageResponse = response.data;
      const UpdatePlayPackageResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 11544,
          options: {
            body_variables: {
              "da31d56f-969f-487f-9b46-104cdad06fb7.12722":
                DepthChartIdVariable,
            },
            path_variables: {
              "d6afdce6-13c2-4bca-928d-f26f28a5da30": PlayIdPackageVariable,
            },
            response_as: UpdatePlayPackageResponse,
            response_code_as: UpdatePlayPackageResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );

      yield put(
        actions.logEvent({
          id: 11975,
          options: {
            field_key: "DepthChartPackageEnableButton",
            field_value: {},
          },
          type: "event",
          time: Date.now(),
        })
      );

      yield put(
        actions.changeInput("DepthChartPackageEnableButton", {
          ...state?.reducer?.["DepthChartPackageEnableButton"],
          [enableId]: enableId,
        })
      );

      if (UpdatePlayPackageResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 11958,
            options: {
              parameter_mapping: {
                "5b35f5c7-951d-4140-b222-c75d0746f3e5": DepthChartIdVariable,
                "7b805431-d93f-4587-af60-fecfc9645964": PlayIdPackageVariable,
              },
            },
            type: "event",
            time: Date.now(),
          })
        );

        if (DepthChartIdVariable && PlayIdPackageVariable) {
          const packageTitle = state.reducer?.Packages.find(
            (obj) => obj?.Id === DepthChartIdVariable
          );
          const lastestData = updateDepthChartId(
            state.reducer?.PlayByPlayInfo,
            PlayIdPackageVariable,
            DepthChartIdVariable,
            packageTitle?.title
          );
          yield put(actions.changeInput("PlayByPlayInfo", lastestData));
        }

        yield put(
          actions.runAction("CREATE_PLAY_DEPTH_CHART_ROW", {
            inputVariables: {
              "input depth chart id": DepthChartIdVariable,
              "input play": PlayIdPackageVariable,
            },
          })
        );

        yield put(
          actions.logEvent({
            id: 12179,
            options: {
              parameter_mapping: {
                "d57dac86-729e-4209-8d53-77b59c1ef49f":
                  state.reducer["FalseCheckbox"],
                "e21713ce-3e31-4b14-8a35-ac1f59e69198": state.reducer["Zero"],
              },
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.runAction("TOGGLE_PACKAGE_SELECT", {
            inputVariables: {
              "input open select dropdown": state.reducer["FalseCheckbox"],
              "input row play id": state.reducer["Zero"],
            },
          })
        );

        yield put(
          actions.logEvent({
            id: 11424,
            options: {
              field_key: "TogglePackage",
              field_value: false,
            },
            type: "event",
            time: Date.now(),
          })
        );

        yield put(actions.changeInput("TogglePackage", false));
        yield put(actions.changeInput("DepthChartPlayIdVariable", 0));
        yield put(actions.changeInput("DepthChartPlayIdVariableFlag", false));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_PLAY_QTR() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_PLAY_QTR);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayIdQtrVariable = inputVariables["play_id_qtr"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = PlayIdQtrVariable;
      payload["body_variables"] = {};
      payload.body_variables["qtr"] = state.reducer["FormPlayQtr"];
      const response = yield call(serverApi.UpdatePlayQtrEndpoint, payload);

      const UpdatePlayQtrResponseStatus = response.status;
      // yield put(
      //   actions.logEvent({
      //     id: 11535,
      //     options: {
      //       body_variables: {
      //         "2561cf96-b4fa-4518-8ec4-77c36e4142a7.12719":
      //           state.reducer["FormPlayQtr"],
      //       },
      //       path_variables: {
      //         "4833b6c1-50b5-45b3-9c24-4a6930b27c61": PlayIdQtrVariable,
      //       },
      //       response_code_as: UpdatePlayQtrResponseStatus,
      //     },
      //     type: "event",
      //     time: Date.now(),
      //   })
      // );
      if (UpdatePlayQtrResponseStatus === state.reducer["TwoHundred"]) {
        // yield put(
        //   actions.logEvent({
        //     id: 11417,
        //     options: {
        //       field_key: "ToggleQtr",
        //     },
        //     type: "event",
        //     time: Date.now(),
        //   })
        // );
        // yield put(actions.removeField("ToggleQtr"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_PLAY_SERIES() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_PLAY_SERIES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayIdSeriesVariable = inputVariables["play_id_series"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = PlayIdSeriesVariable;
      payload["body_variables"] = {};
      payload.body_variables["series"] = state.reducer["FormPlaySeries"];
      const response = yield call(serverApi.UpdatePlaySeriesEndpoint, payload);

      const UpdatePlaySeriesResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 11536,
          options: {
            body_variables: {
              "acaeca91-34b0-4c34-9294-aef8e8d18c9d.12720":
                state.reducer["FormPlaySeries"],
            },
            path_variables: {
              "df0e1536-6610-46d3-93d8-9b579c528724": PlayIdSeriesVariable,
            },
            response_code_as: UpdatePlaySeriesResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (UpdatePlaySeriesResponseStatus === state.reducer["TwoHundred"]) {
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_PLAY_TYPE() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_PLAY_TYPE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayIdTypeVariable = inputVariables["play_id_type"];

      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = PlayIdTypeVariable;
      payload["body_variables"] = {};
      payload.body_variables["type"] = state.reducer["FormPlayType"];
      const response = yield call(serverApi.UpdatePlayTypeEndpoint, payload);

      const UpdatePlayTypeResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 11534,
          options: {
            body_variables: {
              "98366579-608c-4be4-8511-1721781d0ab5.12718":
                state.reducer["FormPlayType"],
            },
            path_variables: {
              "eaf7199b-c189-40f2-bf8c-a3685d600253": PlayIdTypeVariable,
            },
            response_code_as: UpdatePlayTypeResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (UpdatePlayTypeResponseStatus === state.reducer["TwoHundred"]) {
        // yield put(
        //   actions.logEvent({
        //     id: 11406,
        //     options: {
        //       parameter_mapping: {
        //         "e556c934-6791-465b-99d5-4ccad85c63f3":
        //           state.reducer["CurrentGame"]["Id"],
        //       },
        //     },
        //     type: "event",
        //     time: Date.now(),
        //   })
        // );
        // yield put(
        //   actions.runAction("GET_PLAY_BY_PLAY_INFO", {
        //     inputVariables: { gameid: state.reducer["CurrentGame"]["Id"] },
        //   })
        // );
        // yield put(
        //   actions.logEvent({
        //     id: 11407,
        //     options: {
        //       field_key: "ToggleType",
        //     },
        //     type: "event",
        //     time: Date.now(),
        //   })
        // );
        // yield put(actions.removeField("ToggleType"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_PLAY_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_PLAY_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      if (inputVariables?.currentSelectedPositionData?.Id) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["play_user_row_id"] =
          state.reducer["CurrentPlayDepthChartId"];
        payload["body_variables"] = {};
        payload.body_variables["user_id"] =
          inputVariables?.currentSelectedPositionData?.Id;
        payload.body_variables["production_point"] = state.reducer["TD"];
        payload.body_variables["notes"] = state.reducer["Emptystring"];
        const response = yield call(serverApi.UpdatePlayUserEndpoint, payload);

        const UpdatePlayUserResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 12006,
            options: {
              body_variables: {
                "57f87b40-23f0-492f-9c73-e7e16c771aba.12988":
                  state.reducer["FormNewPlayUser"],
                "57f87b40-23f0-492f-9c73-e7e16c771aba.12989":
                  state.reducer["TD"],
                "57f87b40-23f0-492f-9c73-e7e16c771aba.12990":
                  state.reducer["Emptystring"],
              },
              query_variables: {
                "0a60af30-9316-4f6b-beb2-08e4e3f46376":
                  state.reducer["CurrentPlayDepthChartId"],
              },
              response_code_as: UpdatePlayUserResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (UpdatePlayUserResponseStatus === state.reducer["TwoHundred"]) {
          yield put(actions.changeInput("toggleUserGradeLoader", true));
          yield put(
            actions.logEvent({
              id: 12010,
              options: {
                parameter_mapping: {
                  "1d2405e9-cea9-47fe-9e00-d74c7ccba441":
                    state.reducer["CurrentPackage"],
                  "ec35656c-4c16-4a0c-a184-001a22c7ff79":
                    state.reducer["Playid"],
                },
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_ALL_PLAY_DEPTH_CHART_USERS", {
              inputVariables: {
                "input depth chart rowid": state.reducer["CurrentPackage"],
                "input play rowid": state.reducer["Playid"],
              },
            })
          );

          yield put(
            actions.logEvent({
              id: 12012,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_USER_GRADES", {
              inputVariables: {
                depthChartId: state.reducer["CurrentPackage"],
                playRowId: state.reducer["Playid"],
              },
            })
          );

          yield put(
            actions.logEvent({
              id: 12013,
              options: {
                field_key: "ToggleUserDropDown",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.changeInput("ToggleUserDropDown", false));
          yield put(actions.changeInput("currentSelectedPositionData", {}));
        }
      } else {
        yield put(
          actions.changeInput(
            "gradingPlayerNameError",
            "Please select player name"
          )
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_PLAY_USER_NOTES() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_PLAY_USER_NOTES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DepthChartIdNotesVariable = inputVariables["depth_chart_id_notes"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = DepthChartIdNotesVariable;
      payload["body_variables"] = {};
      payload.body_variables["notes"] = state.reducer["FormPlayUserNotes"];
      const response = yield call(
        serverApi.UpdatePlayUserNotesEndpoint,
        payload
      );

      const UpdatePlayUserNotesResponseStatus = response.status;
      if (UpdatePlayUserNotesResponseStatus === state.reducer["TwoHundred"]) {
        const data = updateNotesById(
          DepthChartIdNotesVariable,
          state.reducer["FormPlayUserNotes"],
          state.reducer?.UserGrades
        );
        yield put(actions.changeInput("UserGrades", data));
        // yield put(
        //   actions.runAction("GET_USER_GRADES", {
        //     inputVariables: {depthChartId :state.reducer["CurrentPackage"],
        //     playRowId : state.reducer["Playid"]},
        //   })
        // );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* EDIT_PLAY_USER_NOTES() {
  while (true) {
    let { inputVariables } = yield take(actions.EDIT_PLAY_USER_NOTES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      yield put(
        actions.changeInput("FormPlayUserNotes", inputVariables["value"])
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_POSITION() {
  while (true) {
    yield take(actions.UPDATE_POSITION);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var schema3535;
      var err3535;
      var FormEditPositionIsValidVariable = true;
      err3535 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a name"),
        state.reducer["FormEditPosition"]["name"]
      );
      FormEditPositionIsValidVariable = !FormEditPositionIsValidVariable
        ? false
        : err3535.name !== "ValidationError";
      if (err3535.name === "ValidationError") {
        console.warn(err3535.errors);
        yield put(
          actions.changeInput("FormEditPositionErrors.name", err3535.errors)
        );
      } else {
        yield put(actions.removeField("FormEditPositionErrors.name"));
      }
      err3535 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter an abbreviation"),
        state.reducer["FormEditPosition"]["abbreviation"]
      );
      FormEditPositionIsValidVariable = !FormEditPositionIsValidVariable
        ? false
        : err3535.name !== "ValidationError";
      if (err3535.name === "ValidationError") {
        console.warn(err3535.errors);
        yield put(
          actions.changeInput(
            "FormEditPositionErrors.abbreviation",
            err3535.errors
          )
        );
      } else {
        yield put(actions.removeField("FormEditPositionErrors.abbreviation"));
      }
      if (FormEditPositionIsValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] = state.reducer["EditPosition"]["Id"];
        payload["body_variables"] = {};
        payload.body_variables["name"] =
          state.reducer["FormEditPosition"]["name"];
        payload.body_variables["abbreviation"] =
          state.reducer["FormEditPosition"]["abbreviation"];
        payload.body_variables["category"] =
          state.reducer["FormEditPosition"]["category"];
        payload.body_variables["rank"] =
          state.reducer["FormEditPosition"]["rank"];
        const response3537 = yield call(
          serverApi.UpdatePositionEndpoint,
          payload
        );

        const UpdatePositionResponseCodeVariable = response3537.status;
        yield put(
          actions.logEvent({
            id: 3537,
            options: {
              body_variables: {
                "35d1e01e-8304-490e-9633-03d5721aabcb.4570":
                  state.reducer["FormEditPosition"]["name"],
                "35d1e01e-8304-490e-9633-03d5721aabcb.4571":
                  state.reducer["FormEditPosition"]["abbreviation"],
                "35d1e01e-8304-490e-9633-03d5721aabcb.4572":
                  state.reducer["FormEditPosition"]["category"],
                "35d1e01e-8304-490e-9633-03d5721aabcb.4573":
                  state.reducer["FormEditPosition"]["rank"],
              },
              path_variables: {
                "cefaa2ad-d192-4b4e-9830-1b9a59b051ba":
                  state.reducer["EditPosition"]["Id"],
              },
              response_code_as: UpdatePositionResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          UpdatePositionResponseCodeVariable === state.reducer["TwoHundred"]
        ) {
          yield put(
            actions.logEvent({
              id: 3539,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.runAction("GET_POSITIONS", { inputVariables: {} }));

          yield put(
            actions.logEvent({
              id: 3540,
              options: {
                field_key: "EditPosition",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("EditPosition"));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_POSITION_COACH() {
  // debugger
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_POSITION_COACH);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var DuplicateEmailEditErrorVariable =
        "That email is already being used. Please use another email address.";

      var schema7768;
      var err7768;
      var FormEditPositionCoachValidVariable = true;
      err7768 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter name"),
        state.reducer["FormEditPositionCoach"]["name"]
      );
      FormEditPositionCoachValidVariable = !FormEditPositionCoachValidVariable
        ? false
        : err7768.name !== "ValidationError";
      if (err7768.name === "ValidationError") {
        console.warn(err7768.errors);
        yield put(
          actions.changeInput("FormEditPositionCoachError.name", err7768.errors)
        );
      } else {
        yield put(actions.removeField("FormEditPositionCoachError.name"));
      }
      err7768 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter valid email"),
        state.reducer["FormEditPositionCoach"]["email"]
      );
      FormEditPositionCoachValidVariable = !FormEditPositionCoachValidVariable
        ? false
        : err7768.name !== "ValidationError";
      if (err7768.name === "ValidationError") {
        console.warn(err7768.errors);
        yield put(
          actions.changeInput(
            "FormEditPositionCoachError.email",
            err7768.errors
          )
        );
      } else {
        yield put(actions.removeField("FormEditPositionCoachError.email"));
      }
      if (state.reducer["storeEditedPosition"]?.charAt(0) === ",") {
        state.reducer["storeEditedPosition"]?.substring(1);
      }

      let allPositionObject = state.reducer["Positions"]?.filter((item) =>
        state.reducer["storeEditedPosition"]?.split(",")?.includes(item.name)
      );
      let finalArray = allPositionObject?.map((item) => ({ id: item.id }));
      const errMessage = _isEmpty(finalArray);
      if (errMessage) {
        yield put(
          actions.changeInput(
            "FormEditPositionCoachError.position_data",
            errMessage
          )
        );
      } else {
        yield put(
          actions.removeField("FormEditPositionCoachError.position_data")
        );
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] = state.reducer["EditUser"]["Id"];
        payload["body_variables"] = {};
        payload.body_variables["name"] =
          state.reducer["FormEditPositionCoach"]["name"];
        payload.body_variables["email"] =
          state.reducer["FormEditPositionCoach"]["email"];
        payload.body_variables["category"] =
          state.reducer["FormEditPositionCoach"]["category"];
        payload.body_variables["position_id"] = finalArray;

        payload.body_variables["position"] =
          state.reducer["FormEditPositionCoach"]["position"];
        payload.body_variables["active"] =
          state.reducer["FormEditPositionCoach"]["active"];

        const response = yield call(
          serverApi.UpdatePositionCoachEndpoint,
          payload
        );
        const UpdatePositionCoachResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 7803,
            options: {
              body_variables: {
                "ad25498a-9aea-41d0-b7c9-0443b4d2d961.9476":
                  state.reducer["FormEditPositionCoach"]["name"],
                "ad25498a-9aea-41d0-b7c9-0443b4d2d961.9477":
                  state.reducer["FormEditPositionCoach"]["email"],
                "ad25498a-9aea-41d0-b7c9-0443b4d2d961.9478":
                  state.reducer["FormEditPositionCoach"]["category"],
                "ad25498a-9aea-41d0-b7c9-0443b4d2d961.9479":
                  state.reducer["FormEditPositionCoach"]["position id"],
                "ad25498a-9aea-41d0-b7c9-0443b4d2d961.9480":
                  state.reducer["FormEditPositionCoach"]["active"],
              },
              path_variables: {
                "268a662f-6dda-4862-9507-450f882e7229":
                  state.reducer["EditUser"]["Id"],
              },
              response_code_as: UpdatePositionCoachResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (UpdatePositionCoachResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 7805,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_POSITION_COACHES", { inputVariables: {} })
          );

          yield put(
            actions.logEvent({
              id: 7806,
              options: {
                field_key: "EditUser",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("EditUser"));
        }
        if (UpdatePositionCoachResponseStatus != state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 15968,
              options: {
                field_key: "FormEditPositionCoachError.email",
                field_value: DuplicateEmailEditErrorVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "FormEditPositionCoachError.email",
              DuplicateEmailEditErrorVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_PRODUCTION_POINT() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_PRODUCTION_POINT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayDepthChartPointVariable =
        inputVariables["play_depth_chart_point"];
      let productionPointValues = inputVariables["data"];

      if (
        productionPointValues !== undefined ||
        productionPointValues.length > 0
      ) {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["id"] = PlayDepthChartPointVariable;
        payload["body_variables"] = {};
        payload.body_variables["production_point"] = productionPointValues;
        // state.reducer["FormProductionPoint"];
        const response = yield call(
          serverApi.UpdateProductionPointEndpoint,
          payload
        );

        const UpdateProductionPointResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 12001,
            options: {
              body_variables: {
                "b7ed0b7a-59f7-4a7f-bbd6-d4daa4e75d37.12961":
                  state.reducer["FormProductionPoint"],
              },
              query_variables: {
                "8f81f0fc-38c9-45a6-b2a5-9ab2f32d4557":
                  PlayDepthChartPointVariable,
              },
              response_code_as: UpdateProductionPointResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (
          UpdateProductionPointResponseStatus === state.reducer["TwoHundred"]
        ) {
          // yield put(
          //   actions.logEvent({
          //     id: 12005,
          //     options: {
          //       field_key: "ToggleProductionPoint",
          //     },
          //     type: "event",
          //     time: Date.now(),
          //   })
          // );
          // yield put(actions.removeField("ToggleProductionPoint"));
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* REMOVE_PRODUCTION_POINT() {
  while (true) {
    let { inputVariables } = yield take(actions.REMOVE_PRODUCTION_POINT);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let PlayDepthChartPointVariable =
        state.reducer?.UpdatedProductionPointData?.["play_depth_chart_point"];
      let productionPointValues =
        state.reducer?.UpdatedProductionPointData?.["data"];

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["id"] = PlayDepthChartPointVariable;
      payload["body_variables"] = {};
      payload.body_variables["production_point"] = productionPointValues;
      // state.reducer["FormProductionPoint"];
      const response = yield call(
        serverApi.UpdateProductionPointEndpoint,
        payload
      );

      const UpdateProductionPointResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 12001,
          options: {
            body_variables: {
              "b7ed0b7a-59f7-4a7f-bbd6-d4daa4e75d37.12961":
                state.reducer["FormProductionPoint"],
            },
            query_variables: {
              "8f81f0fc-38c9-45a6-b2a5-9ab2f32d4557":
                PlayDepthChartPointVariable,
            },
            response_code_as: UpdateProductionPointResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (UpdateProductionPointResponseStatus === state.reducer["TwoHundred"]) {
        // yield put(
        //   actions.logEvent({
        //     id: 12005,
        //     options: {
        //       field_key: "ToggleProductionPoint",
        //     },
        //     type: "event",
        //     time: Date.now(),
        //   })
        // );
        // yield put(actions.removeField("ToggleProductionPoint"));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPDATE_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let SecondaryPositionsVariable = inputVariables["secondary positions"];

      var DuplicateEmailEditUserErrorVariable =
        "That email is already being used. Please use another email address.";

      var err3438;
      var FormEditUserValidVariable = true;
      err3438 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a name"),
        state.reducer["FormEditUser"]["name"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        console.warn(err3438.errors);
        yield put(
          actions.changeInput("FormEditUserErrors.name", err3438.errors)
        );
      } else {
        yield put(actions.removeField("FormEditUserErrors.name"));
      }

      err3438 = yield call(
        _getValidationErrors,
        Yup.string().email("Please enter a valid email"),
        // .min(1, "Please enter a email"),
        state.reducer["FormEditUser"]["email"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        console.warn(err3438.errors);
        yield put(
          actions.changeInput("FormEditUserErrors.email", err3438.errors)
        );
      } else {
        yield put(actions.removeField("FormEditUserErrors.email"));
      }

      err3438 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormEditUser"]["weight"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        console.warn(err3438.errors);
        yield put(
          actions.changeInput("FormEditUserErrors.weight", err3438.errors)
        );
      } else {
        yield put(actions.removeField("FormEditUserErrors.weight"));
      }
      err3438 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormEditUser"]["number"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        console.warn(err3438.errors);
        yield put(
          actions.changeInput("FormEditUserErrors.number", err3438.errors)
        );
      } else {
        yield put(actions.removeField("FormEditUserErrors.number"));
      }
      err3438 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormEditUser"]["graduation year"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        console.warn(err3438.errors);
        yield put(
          actions.changeInput(
            "FormEditUserErrors.graduation year",
            err3438.errors
          )
        );
      } else {
        yield put(actions.removeField("FormEditUserErrors.graduation year"));
      }
      err3438 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormEditUser"]["grade"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        console.warn(err3438.errors);
        yield put(
          actions.changeInput("FormEditUserErrors.grade", err3438.errors)
        );
      } else {
        yield put(actions.removeField("FormEditUserErrors.grade"));
      }
      if (FormEditUserValidVariable === state.reducer["TrueCheckbox"]) {
        yield put(actions.changeInput("isLoaded", true));
        let formData = new FormData();
        formData.append("active", state.reducer["FormEditUser"]["active"]);
        formData.append("email", state.reducer["FormEditUser"]["email"]);
        formData.append("name", state.reducer["FormEditUser"]["name"]);
        formData.append("weight", state.reducer["FormEditUser"]["weight"]);
        formData.append("number", state.reducer["FormEditUser"]["number"]);
        formData.append(
          "graduation_year",
          state.reducer["FormEditUser"]["graduation year"]
        );
        formData.append("grade", state.reducer["FormEditUser"]["grade"]);
        formData.append("height", state.reducer["FormEditUser"]["height"]);
        formData.append(
          "position_id",
          state.reducer["FormEditUser"]["position id"]
        );
        formData.append("notes", state.reducer["FormEditUser"]["notes"]);
        formData.append(
          "secondary_positions",
          SecondaryPositionsVariable.length > 0
            ? JSON.stringify(SecondaryPositionsVariable)
            : JSON.stringify(SecondaryPositionsVariable)
        );
        formData.append("category", state.reducer["FormEditUser"]["category"]);
        if (inputVariables?.["image"] !== "") {
          formData.append(
            "uploadfile",
            inputVariables?.["image"] ? inputVariables?.["image"] : ""
          );
        }
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] = state.reducer["EditUser"]["Id"];
        payload["body_variables"] = {};
        payload.body_variables = formData;

        const response = yield call(serverApi.UpdateUserEndpoint, payload);

        const UpdateUserResponseStatus = response.status;
        if (UpdateUserResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3443,
              options: {
                field_key: "EditUser",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("EditUser"));

          yield put(actions.runAction("GET_USERS", { inputVariables: {} }));
        } else {
          yield put(actions.changeInput("isLoaded", false));
        }
        if (UpdateUserResponseStatus !== state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 15973,
              options: {
                field_key: "FormEditUserErrors.email",
                field_value: DuplicateEmailEditUserErrorVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "FormEditUserErrors.email",
              DuplicateEmailEditUserErrorVariable
            )
          );
          yield put(actions.changeInput("isLoaded", false));
        }
      }
    } catch (error) {
      yield put(actions.changeInput("isLoaded", false));
      console.warn(error);
    }
  }
}
function* UPDATE_ADMIN_EDIT_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_ADMIN_EDIT_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      var err3438;
      var FormEditUserValidVariable = true;
      err3438 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a name"),
        state.reducer["FormAdminEditUser"]["name"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        yield put(
          actions.changeInput("FormAdminEditUserErrors.name", err3438.errors)
        );
      } else {
        yield put(actions.removeField("FormAdminEditUserErrors.name"));
      }
      if (FormEditUserValidVariable === state.reducer["TrueCheckbox"]) {
        yield put(actions.changeInput("isLoaded", true));
        let formData = new FormData();
        formData.append('school_id', state.reducer["adminEditUser"]["school"]);
        formData.append('name', state.reducer["FormAdminEditUser"]["name"]);
        if(inputVariables?.['image'] !== ''){
          formData.append('school_logo', inputVariables?.['image']?inputVariables?.['image'] :'' )
        }
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables = formData;

        const response = yield call(serverApi.UpdateAdminUserEndpoint, payload);

        const UpdateUserResponseStatus = response.status;
        if (UpdateUserResponseStatus === state.reducer["TwoHundred"]) {
         const result = state.reducer["adminEditUser"]["school"] === state.reducer?.CurrentUser?.school_id
         if(result){
          yield put(
            actions.runAction("GET_CURRENT_USER")
          );
          yield put(
            actions.runAction("GET_SCHOOL_REPORT")
          );
         }else{
           yield put(
             actions.runAction("GET_SCHOOL_REPORT")
            );
          }
          yield put(actions.changeInput("adminEditUser", {}));
        }else{
          yield put(actions.changeInput("isLoaded", false));
        }
      }
    } catch (error) {
      yield put(actions.changeInput("isLoaded", false));
      console.warn(error);
    }
  }
}

function* REMOVE_IMAGE() {
  while (true) {
    yield take(actions.REMOVE_IMAGE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;
    try {
      payload = {};
      payload["path_variables"] = {};
      payload.path_variables["id"] = state.reducer["EditUser"]["Id"];

      yield call(serverApi.RemoveImageEndpoint, payload);
    } catch (error) {
      console.warn(error);
    }
  }
}

function* UPDATE_VIEW_USER() {
  while (true) {
    let { inputVariables } = yield take(actions.UPDATE_VIEW_USER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload, payloadNew;

    try {
      let SecondaryPositionsVariable = inputVariables["secondary positions"];

      var DuplicateEmailEditUserErrorVariable =
        "That email is already being used. Please use another email address.";

      var schema3438;
      var err3438;
      var FormEditUserValidVariable = true;
      err3438 = yield call(
        _getValidationErrors,
        Yup.string().required("Please enter a name"),
        state.reducer["FormEditUser"]["name"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        console.warn(err3438.errors);
        yield put(
          actions.changeInput("FormEditUserErrors.name", err3438.errors)
        );
      } else {
        yield put(actions.removeField("FormEditUserErrors.name"));
      }

      err3438 = yield call(
        _getValidationErrors,
        Yup.string()
          .email("Please enter a valid email")
          .min(1, "Please enter a email"),
        state.reducer["FormEditUser"]["email"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        console.warn(err3438.errors);
        yield put(
          actions.changeInput("FormEditUserErrors.email", err3438.errors)
        );
      } else {
        yield put(actions.removeField("FormEditUserErrors.email"));
      }

      err3438 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormEditUser"]["weight"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        console.warn(err3438.errors);
        // debugger
        yield put(
          actions.changeInput("FormEditUserErrors.weight", err3438.errors)
        );
      } else {
        yield put(actions.removeField("FormEditUserErrors.weight"));
      }
      err3438 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormEditUser"]["number"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        console.warn(err3438.errors);
        yield put(
          actions.changeInput("FormEditUserErrors.number", err3438.errors)
        );
      } else {
        yield put(actions.removeField("FormEditUserErrors.number"));
      }
      err3438 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormEditUser"]["graduation year"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        console.warn(err3438.errors);
        yield put(
          actions.changeInput(
            "FormEditUserErrors.graduation year",
            err3438.errors
          )
        );
      } else {
        yield put(actions.removeField("FormEditUserErrors.graduation year"));
      }
      err3438 = yield call(
        _getValidationErrors,
        Yup.number().integer().min(0, "Please enter a positive number"),
        state.reducer["FormEditUser"]["grade"]
      );
      FormEditUserValidVariable = !FormEditUserValidVariable
        ? false
        : err3438.name !== "ValidationError";
      if (err3438.name === "ValidationError") {
        console.warn(err3438.errors);
        yield put(
          actions.changeInput("FormEditUserErrors.grade", err3438.errors)
        );
      } else {
        yield put(actions.removeField("FormEditUserErrors.grade"));
      }
      if (FormEditUserValidVariable === state.reducer["TrueCheckbox"]) {
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] = state.reducer["EditUser"]["Id"];
        payload["body_variables"] = {};
        payload.body_variables["active"] =
          state.reducer["FormEditUser"]["active"];
        payload.body_variables["email"] =
          state.reducer["FormEditUser"]["email"];
        payload.body_variables["name"] = state.reducer["FormEditUser"]["name"];
        payload.body_variables["weight"] =
          state.reducer["FormEditUser"]["weight"];
        payload.body_variables["number"] =
          state.reducer["FormEditUser"]["number"];
        payload.body_variables["graduation_year"] =
          state.reducer["FormEditUser"]["graduation year"];
        payload.body_variables["grade"] =
          state.reducer["FormEditUser"]["grade"];
        payload.body_variables["height"] =
          state.reducer["FormEditUser"]["height"];
        payload.body_variables["position_id"] =
          state.reducer["FormEditUser"]["position id"];
        payload.body_variables["notes"] =
          state.reducer["FormEditUser"]["notes"];
        payload.body_variables["secondary_positions"] =
          SecondaryPositionsVariable;
        payload.body_variables["category"] =
          state.reducer["FormEditUser"]["category"];
        const response3440 = yield call(serverApi.UpdateUserEndpoint, payload);
        const UpdateUserResponseCodeVariable = response3440.status;
        yield put(
          actions.logEvent({
            id: 3440,
            options: {
              body_variables: {
                "4d76b0bf-8e85-4d5d-887f-e4ee770fce25.5754":
                  state.reducer["FormEditUser"]["active"],
                "4d76b0bf-8e85-4d5d-887f-e4ee770fce25.5755":
                  state.reducer["FormEditUser"]["email"],
                "4d76b0bf-8e85-4d5d-887f-e4ee770fce25.5756":
                  state.reducer["FormEditUser"]["name"],
                "4d76b0bf-8e85-4d5d-887f-e4ee770fce25.5758":
                  state.reducer["FormEditUser"]["weight"],
                "4d76b0bf-8e85-4d5d-887f-e4ee770fce25.5759":
                  state.reducer["FormEditUser"]["number"],
                "4d76b0bf-8e85-4d5d-887f-e4ee770fce25.5760":
                  state.reducer["FormEditUser"]["graduation year"],
                "4d76b0bf-8e85-4d5d-887f-e4ee770fce25.5761":
                  state.reducer["FormEditUser"]["grade"],
                "4d76b0bf-8e85-4d5d-887f-e4ee770fce25.5762":
                  state.reducer["FormEditUser"]["height"],
                "4d76b0bf-8e85-4d5d-887f-e4ee770fce25.5764":
                  state.reducer["FormEditUser"]["position id"],
                "4d76b0bf-8e85-4d5d-887f-e4ee770fce25.5765":
                  state.reducer["FormEditUser"]["notes"],
                "4d76b0bf-8e85-4d5d-887f-e4ee770fce25.5770":
                  SecondaryPositionsVariable,
                "4d76b0bf-8e85-4d5d-887f-e4ee770fce25.9459":
                  state.reducer["FormEditUser"]["category"],
              },
              path_variables: {
                "877478a1-f75e-4af0-928d-5fc891a054f8":
                  state.reducer["EditUser"]["Id"],
              },
              response_code_as: UpdateUserResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );

        payloadNew = {};
        payloadNew["path_variables"] = {};
        payloadNew.path_variables["id"] = state.page["userid"];
        const response4469 = yield call(serverApi.GetUserEndpoint, payloadNew);
        const GetUserResponseVariable = response4469.data;
        const GetUserResponseCodeVariable = response4469.status;
        yield put(
          actions.logEvent({
            id: 4469,
            options: {
              path_variables: {
                "54b3e3e7-63cb-4cad-8e8a-e5dda71b8c07": state.page["userid"],
              },
              response_as: GetUserResponseVariable,
              response_code_as: GetUserResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (GetUserResponseCodeVariable === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 4471,
              options: {
                field_key: "ViewPlayer",
                field_value: GetUserResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.changeInput("ViewPlayer", GetUserResponseVariable));
        }
        if (UpdateUserResponseCodeVariable === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3442,
              options: {
                parameter_mapping: {
                  "07d7f5ae-8efc-46f6-b7eb-f8207ed35f1b": state.reducer["Zero"],
                },
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_USERS", {
              inputVariables: {},
            })
          );

          yield put(
            actions.logEvent({
              id: 3443,
              options: {
                field_key: "EditUser",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("EditUser"));
        }

        if (UpdateUserResponseCodeVariable != state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 15973,
              options: {
                field_key: "FormEditUserErrors.email",
                field_value: DuplicateEmailEditUserErrorVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput(
              "FormEditUserErrors.email",
              DuplicateEmailEditUserErrorVariable
            )
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* UPDATE_USER_EXERCISE() {
  while (true) {
    yield take(actions.UPDATE_USER_EXERCISE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let DateStringUpdatedVariable;
      try {
        DateStringUpdatedVariable = window.FormatDate({
          datestring: state.reducer["FormEditUserExercise"]["date"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 4392,
          options: {
            parameter_mapping: {
              "1eb12004-d380-4086-9731-feecf4591dc6":
                state.reducer["FormEditUserExercise"]["date"],
            },
            return_as: DateStringUpdatedVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      var err;
      var FormEditUserExerciseIsValidVariable = true;
      err = yield call(
        _getValidationErrors,
        Yup.number().integer().min(1, "Please select an exercise"),
        state.reducer["FormEditUserExercise"]["exercise id"]
      );
      FormEditUserExerciseIsValidVariable = !FormEditUserExerciseIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput(
            "FormEditUserExerciseErrors.exercise id",
            err.errors
          )
        );
      } else {
        yield put(
          actions.removeField("FormEditUserExerciseErrors.exercise id")
        );
      }
      err = yield call(
        _getValidationErrors,
        Yup.number().min(0, "Please enter a positive value"),
        state.reducer["FormEditUserExercise"]["integer value"]
      );
      FormEditUserExerciseIsValidVariable = !FormEditUserExerciseIsValidVariable
        ? false
        : err.name !== "ValidationError";
      if (err.name === "ValidationError") {
        console.warn(err.errors);
        yield put(
          actions.changeInput(
            "FormEditUserExerciseErrors.integer value",
            err.errors
          )
        );
      } else {
        yield put(
          actions.removeField("FormEditUserExerciseErrors.integer value")
        );
      }
      if (
        FormEditUserExerciseIsValidVariable === state.reducer["TrueCheckbox"]
      ) {
        payload = {};
        payload["path_variables"] = {};
        payload.path_variables["id"] = state.reducer["EditUserExercise"]["id"];
        payload["body_variables"] = {};
        payload.body_variables["exercise_id"] =
          state.reducer["FormEditUserExercise"]["exercise id"];
        payload.body_variables["integer_value"] =
          state.reducer["FormEditUserExercise"]["integer value"];
        payload.body_variables["date"] = DateStringUpdatedVariable;
        const response = yield call(
          serverApi.UpdateUserExerciseEndpoint,
          payload
        );

        const UpdateUserExerciseResponseStatus = response.status;
        yield put(
          actions.logEvent({
            id: 3917,
            options: {
              body_variables: {
                "60374e96-7f50-4d49-b4bc-f8d003781e44.4885":
                  state.reducer["FormEditUserExercise"]["exercise id"],
                "60374e96-7f50-4d49-b4bc-f8d003781e44.4887":
                  state.reducer["FormEditUserExercise"]["integer value"],
                "60374e96-7f50-4d49-b4bc-f8d003781e44.4888":
                  DateStringUpdatedVariable,
              },
              path_variables: {
                "0912d356-aece-4b50-9530-4eef17cb4453":
                  state.reducer["EditUserExercise"]["id"],
              },
              response_code_as: UpdateUserExerciseResponseStatus,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (UpdateUserExerciseResponseStatus === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 3919,
              options: {},
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_USER_EXERCISES", { inputVariables: {} })
          );

          yield put(
            actions.logEvent({
              id: 4387,
              options: {
                field_key: "EditUserExercise",
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(actions.removeField("EditUserExercise"));

          yield put(
            actions.logEvent({
              id: 4585,
              options: {
                parameter_mapping: {
                  "8caa3ef6-671b-4bb5-a15c-a0fb85f9d244":
                    state.reducer["CurrentUser"]["id"],
                },
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.runAction("GET_EXERCISES_GRAPH_USER", {
              inputVariables: {
                "userid input": state.reducer["CurrentUser"]["id"],
              },
            })
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPLOAD_GAME_FILE() {
  while (true) {
    yield take(actions.UPLOAD_GAME_FILE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      let FileNameVariable;
      try {
        FileNameVariable = window.GetFileName({
          file: state.reducer["gamefileupload"],
        });
      } catch (e) {}
      yield put(
        actions.logEvent({
          id: 4457,
          options: {
            parameter_mapping: {
              "eefc7702-8d76-4757-a8b0-2e27e872f2ba":
                state.reducer["gamefileupload"],
            },
            return_as: FileNameVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["name"] = FileNameVariable;
      payload["body_variables"] = {};
      payload.body_variables = state.reducer["gamefileupload"];
      const response = yield call(serverApi.UploadGameFileEndpoint, payload);

      const UploadGameFileResponseStatus = response.status;
      yield put(
        actions.logEvent({
          id: 3547,
          options: {
            body_variables: {
              "1ee0f880-1912-48de-b4cf-a7484ca7edad":
                state.reducer["gamefileupload"],
            },
            query_variables: {
              "34b7942f-8cd5-4965-873f-14cb7dcfc245": FileNameVariable,
            },
            response_code_as: UploadGameFileResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (UploadGameFileResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3550,
            options: {},
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.runAction("GET_GAME_FILES", { inputVariables: {} }));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPLOAD_GRADE() {
  while (true) {
    yield take(actions.UPLOAD_GRADE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("uploadGradeError", ""));
      if (state.reducer["GradeUpload"]) {
        let fileValue = state.reducer["GradeUpload"];
        if (!fileValue.has("game_id")) {
          // Append game_id only if it's not already present
          fileValue.append("game_id", state.page.playgradeid);
        }
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["year"] = state.page?.year;
        payload["body_variables"] = {};
        payload.body_variables = fileValue;

        const response = yield call(serverApi.UploadGradeEndpoint, payload);
        const UploadGradeResponse = response.data;
        const UploadGradeResponseStatus = response.status;

        if (UploadGradeResponseStatus === state.reducer["TwoHundred"]) {
          yield put(actions.removeField("GradeUpload"));
          const fileInput = document.getElementById("fileInput");
          fileInput.value = "";
          fileInput.type = "text";
          fileInput.type = "file";

          yield put(
            actions.changeInput(
              "uploadGradeMessage",
              UploadGradeResponse?.message
            )
          );
        
          yield put (actions.runAction("REMOVE_VALUE"))
        }else{
          yield put(
            actions.removeField("GradeUpload")
          );
          const fileInput = document.getElementById("fileInput");
          fileInput.value = "";
          fileInput.type = "text";
          fileInput.type = "file";

          yield put(
            actions.changeInput(
              "uploadGradeMessage",
              UploadGradeResponse?.message
            )
          );
         yield put (actions.runAction("REMOVE_VALUE"))
        }
      } else {
        yield put(
          actions.changeInput("uploadGradeError", "Please choose a file")
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* REMOVE_VALUE() {
  while (true) {
    yield take(actions.REMOVE_VALUE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};

    try {
      yield call(actions.delay,5000)
      yield put(
        actions.changeInput("uploadGradeMessage", '')
      );
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPLOAD_PLAY() {
  while (true) {
    yield take(actions.UPLOAD_PLAY);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(
        actions.runAction("GET_CUSTOM_FIELD_TITLES", {
          inputVariables: { "game id": state.reducer["CurrentGame"]["Id"] },
        })
      );

      payload = {};
      payload["body_variables"] = {};
      payload.body_variables = state.reducer["PlayUpload"];
      const response = yield call(serverApi.UploadPlayEndpoint, payload);
      const UploadPlayResponse = response.data;
      const UploadPlayResponseStatus = response.status;
      let prePlayProcessData = UploadPlayResponse;
      try {
        if (
          prePlayProcessData?.mapping &&
          isArray(prePlayProcessData?.mapping)
        ) {
          UploadPlayResponse?.mapping.unshift({
            index: 99,
            name: "None",
          });
        }
      } catch (error) {
        console.log("error", error);
      }
      yield put(
        actions.logEvent({
          id: 12296,
          options: {
            body_variables: {
              "2d972b12-9b53-4d7c-8f34-79db93877fb3":
                state.reducer["PlayUpload"],
            },
            response_as: UploadPlayResponse,
            response_code_as: UploadPlayResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (UploadPlayResponseStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 12298,
            options: {
              field_key: "playmapping",
              field_value: UploadPlayResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("playmapping", UploadPlayResponse)
        );
        yield put(
          actions.changeInput("PlayUploadMappingError", '')
        );
        yield put(
          actions.removeField("CsvPlayMappingPackage")
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }
}

function* IMPORT_PLAY() {
  while (true) {
    let { inputVariables } = yield take(actions.IMPORT_PLAY);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("importPlayLoader", true));
      const formData = new FormData();
      formData.append("uploadfile", inputVariables?.file);
      payload = {};
      payload["body_variables"] = {};
      payload.body_variables = formData;
      // payload.body_variables["uploadfile"] = inputVariables?.file
      const response = yield call(serverApi.UploadPlayEndpoint, payload);
      const UploadPlayResponse = response.data;
      const UploadPlayResponseStatus = response.status;
      let prePlayProcessData = UploadPlayResponse;
      try {
        if (
          prePlayProcessData?.mapping &&
          isArray(prePlayProcessData?.mapping)
        ) {
          UploadPlayResponse?.mapping.unshift({
            index: 99,
            name: "None",
          });
        }
      } catch (error) {
        console.log("error", error);
      }
      yield put(
        actions.logEvent({
          id: 12296,
          options: {
            body_variables: {
              "2d972b12-9b53-4d7c-8f34-79db93877fb3":
                state.reducer["PlayUpload"],
            },
            response_as: UploadPlayResponse,
            response_code_as: UploadPlayResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (UploadPlayResponseStatus === state.reducer["TwoHundred"]) {
        payload = {};
        payload["body_variables"] = {};
        payload.body_variables["type"] = state.reducer["FormGames"]["type"];
        payload.body_variables["title"] = state.reducer["FormGames"]["title"];
        payload.body_variables["school_id"] =
          state.reducer["CurrentUser"]["school_id"];
        payload.body_variables["date"] = state.reducer["FormGames"]["date"];
        payload.body_variables["team"] = inputVariables?.teamData;
        const gameResponse = yield call(serverApi.CreateGamesEndpoint, payload);
        const CreateGameResposne = gameResponse.data;
        const CreateGameResponseStatus = gameResponse.status;
        if (CreateGameResponseStatus === state.reducer["TwoHundred"]) {
          yield put(actions.changeInput("CurrentGame", CreateGameResposne));

          yield put(actions.changeInput("importMapping", UploadPlayResponse));
          yield put(actions.changeInput("PlayUploadMappingError", ""));
          yield put(actions.removeField("CsvPlayMappingPackage"));
          yield put(actions.changeInput("importPlayLoader", false));
        } else {
          yield put(actions.changeInput("importPlayLoader", false));
        }
      } else {
        yield put(actions.changeInput("importPlayLoader", false));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* UPLOAD_ROSTER() {
  while (true) {
    yield take(actions.UPLOAD_ROSTER);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    try {
      yield put(actions.changeInput("SuccessMessage", ""));
      payload = {};
      payload["body_variables"] = {};
      payload.body_variables = state.reducer["RosterUpload"];
      const response = yield call(serverApi.UploadRosterEndpoint, payload);
      const UploadRosterResponse = response.data;
      const UploadRosterResponseStatus = response.status;
      let preProcessData = UploadRosterResponse;
      try {
        if (preProcessData?.mapping && isArray(preProcessData?.mapping)) {
          // Adding this object for default value in roster upload
          UploadRosterResponse?.mapping.unshift({
            index: 99,
            name: "None",
          });
        }
      } catch (error) {
        console.log("error", error);
      }
      yield put(
        actions.logEvent({
          id: 4447,
          options: {
            body_variables: {
              "44700530-5fa6-45e3-a971-8cf0883d0c10":
                state.reducer["RosterUpload"],
            },
            response_as: UploadRosterResponse,
            response_code_as: UploadRosterResponseStatus,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (UploadRosterResponseStatus === state.reducer["TwoHundred"]) {
        yield put(actions.changeInput("RosterMappingError", ""));
        yield put(
          actions.logEvent({
            id: 4449,
            options: {
              field_key: "rostermapping",
              field_value: UploadRosterResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("rostermapping", UploadRosterResponse));
      }
    } catch (error) {
      console.warn(error);
    }
  }
}
function* on_app_started() {
  while (true) {
    yield take(actions.on_app_started);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;

    // try {
    //   yield put(
    //     actions.logEvent({
    //       id: 3081,
    //       options: {
    //         field_key: "IsLoggedIn",
    //         field_value: state.reducer["TrueCheckbox"],
    //       },
    //       type: "event",
    //       time: Date.now(),
    //     })
    //   );
    //   yield put(
    //     actions.changeInput("IsLoggedIn", state.reducer["TrueCheckbox"])
    //   );
    //   yield put(actions.setField("_app_initialized", true));

    // }catch (error) {
    //     console.warn(error);
    //   }

    // try {
    //   const response3078 = yield call(serverApi.GetCurrentUserEndpoint, null);
    //   const GetCurrentUserResponseVariable = response3078.data;
    //   const GetCurrentUserResponseCodeVariable = response3078.status;
    //   yield put(
    //     actions.logEvent({
    //       id: 3078,
    //       options: {
    //         response_as: GetCurrentUserResponseVariable,
    //         response_code_as: GetCurrentUserResponseCodeVariable,
    //       },
    //       type: "event",
    //       time: Date.now(),
    //     })
    //   );
    //   if (GetCurrentUserResponseCodeVariable === state.reducer["TwoHundred"]) {
    //     yield put(
    //       actions.logEvent({
    //         id: 3080,
    //         options: {
    //           field_key: "CurrentUser",
    //           field_value: GetCurrentUserResponseVariable,
    //         },
    //         type: "event",
    //         time: Date.now(),
    //       })
    //     );
    //     yield put(
    //       actions.changeInput("CurrentUser", GetCurrentUserResponseVariable)
    //     );

    //     yield put(
    //       actions.logEvent({
    //         id: 3081,
    //         options: {
    //           field_key: "IsLoggedIn",
    //           field_value: state.reducer["TrueCheckbox"],
    //         },
    //         type: "event",
    //         time: Date.now(),
    //       })
    //     );
    //     yield put(
    //       actions.changeInput("IsLoggedIn", state.reducer["TrueCheckbox"])
    //     );
    //   }
    //   yield put(actions.setField("_app_initialized", true));
    // } catch (error) {
    //   console.warn(error);
    // }
  }
}

function* EDIT_SCALE_TYPE() {
  while (true) {
    const { inputVariables } = yield take(actions.EDIT_SCALE_TYPE);
    let state = yield select();
    let payload;
    try {
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const seasonYearResponse = yield call(
          serverApi.GetSeasonYearEndpoint,
          payload
        );
        GetSeasonYearResponse = seasonYearResponse.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["position_id"] = inputVariables.positionId;
      payload.query_variables["scale_type"] =
        state.reducer["FormEditCoach"]["scale type"];
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.EditScaleType, payload);
      const EditScaleTypeResponse = response.data;
      const EditScaleTypeStatus = response.status;
      if (EditScaleTypeStatus === state.reducer["TwoHundred"]) {
        yield put(actions.runAction("GET_SCALE_TYPE", null));
      }

      if (EditScaleTypeStatus !== state.reducer["TwoHundred"]) {
        yield put(
          actions.changeInput(
            "EditScaleTypeError",
            EditScaleTypeResponse?.message
          )
        );
      }
    } catch (error) {}
  }
}
function* GET_SCALE_TYPE() {
  while (true) {
    yield take(actions.GET_SCALE_TYPE);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload;
    try {
      let GetSeasonYearResponse;
      if (
        state.reducer?.selectedSeasonYear === "" ||
        state.reducer?.selectedSeasonYear === 0
      ) {
        const seasonYearResponse = yield call(
          serverApi.GetSeasonYearEndpoint,
          payload
        );
        GetSeasonYearResponse = seasonYearResponse.data;
      }
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === "" ||
          state.reducer?.selectedSeasonYear === 0
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;
      const response = yield call(serverApi.GetScaleType, payload);
      const GetScaleTypeResponse = response.data;
      const GetScaleTypeStatus = response.status;

      if (GetScaleTypeStatus === state.reducer["TwoHundred"]) {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
        yield put(actions.setField("GetScaleTypeData", GetScaleTypeResponse));
      } else {
        yield put(
          actions.runAction("LOADING_EVENT", {
            inputVariables: { loader: false },
          })
        );
      }
    } catch (error) {
      yield put(
        actions.runAction("LOADING_EVENT", {
          inputVariables: { loader: false },
        })
      );
    }
  }
}

function* CLEAR_GAME_PRACTICE_REPORT_VALUES() {
  while (true) {
    yield take(actions.CLEAR_GAME_PRACTICE_REPORT_VALUES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    try {
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: "PlayReportsData",
            field_value: [],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("PlayReportsData", []));
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: "PlayReportsMultipleData",
            field_value: [],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("PlayReportsMultipleData", {}));
      yield put(actions.changeInput("SelectedCustomFields", null));
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: " AverageByPlay",
            field_value: [],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("AverageByPlay", []));
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: " AverageByIntangible",
            field_value: [],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("AverageByIntangible", []));
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: " PlayAverages",
            field_value: [],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("PlayAverages", []));
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: " PlayerReportDataUser",
            field_value: -1,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("PlayerReportDataUser", -1));
      // yield put(actions.changeInput("toggleCustomField", false));
      // yield put(actions.changeInput("customFieldData", []));
      // yield put(actions.changeInput("SelectedCustomFieldDataId", null));
      // yield put(actions.changeInput("SelectedCustomFieldId", null));
    } catch (error) {}
  }
}
function* CLEAR_PLAYER_DROPDOWN_VALUES() {
  while (true) {
    yield take(actions.CLEAR_PLAYER_DROPDOWN_VALUES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    try {
      yield put(actions.removeField("SelectedPlayerGameAndPracticeId"));
      yield put(actions.removeField("PlayerReportDataUser"));
    } catch (error) {}
  }
}
function* CLEAR_PLAYER_REPORT_VALUES() {
  while (true) {
    yield take(actions.CLEAR_PLAYER_REPORT_VALUES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    try {
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: "PlayerReportsData",
            field_value: [],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("PlayerReportsData", []));
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: "PlayerProductionPointData,",
            field_value: [],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("PlayerProductionPointData", []));
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: "AverageIntangible,",
            field_value: {},
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("AverageIntangible", {}));
      yield put(actions.removeField("SelectedPlayerGameAndPracticeId", -1));
      yield put(actions.removeField("PlayerReportDataUser", -1));
    } catch (error) {}
  }
}
function* CLEAR_PLAYER_ACCOUNT_VALUES() {
  while (true) {
    yield take(actions.CLEAR_PLAYER_ACCOUNT_VALUES);
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    try {
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: "PlayerReportsData",
            field_value: [],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("PlayerReportsData", []));
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: "PlayerProductionPointData,",
            field_value: [],
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("PlayerProductionPointData", []));
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: "AverageIntangible,",
            field_value: {},
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("AverageIntangible", {}));
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: "NoteInfoData,",
            field_value: {},
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("NoteInfoData", ""));
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: "NoteInfo,",
            field_value: {},
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("NoteInfo", false));
    } catch (error) {}
  }
}
function* ON_POSITION_FILTER_DATA() {
  while (true) {
    const { inputVariables } = yield take(actions.ON_POSITION_FILTER_DATA);
    const { isDefault, initialState, customFieldData } = inputVariables;
    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload, payloadNew;

    try {
      if (isDefault) {
        yield put(actions.changeInput("isDefaultLoaded", true));
        yield put(actions.changeInput("isLoaded", true));
      }
      let GetSeasonYearResponse;
      if (state.reducer?.selectedSeasonYear === "") {
        const response = yield call(serverApi.GetSeasonYearEndpoint, payload);
        GetSeasonYearResponse = response.data;
      }
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: "collapse",
            field_value: initialState,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("collapse", initialState));
      yield put(
        actions.logEvent({
          id: 3142,
          options: {
            field_key: "PlayReportsMultipleData",
            field_value: {},
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("PlayReportsMultipleData", {}));
      yield put(
        actions.logEvent({
          id: 11977,
          options: {
            field_key: "currentPosition",
            field_value: 0,
          },
          type: "event",
          time: Date.now(),
        })
      );
      yield put(actions.changeInput("currentPosition", 0));
      if (
        state.reducer?.SelectedPositionId === "" ||
        state.reducer?.SelectedPositionId === "-1" ||
        state.reducer?.SelectedPositionId === null
      ) {
        yield put(
          actions.logEvent({
            id: 12243,
            options: {
              field_key: "Intangibles",
              field_value: state.reducer?.IntangibleValueName,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("Intangibles", state.reducer?.IntangibleValueName)
        );
      } else {
        payload = {};
        payload["query_variables"] = {};
        payload.query_variables["position_id"] =
          state.reducer["SelectedPositionId"] === ""
            ? state.reducer?.Positions[0]?.id
            : state.reducer["SelectedPositionId"];
        payload.query_variables["year"] = state.page?.year
          ? state.page?.year
          : state.reducer?.selectedSeasonYear === ""
          ? GetSeasonYearResponse?.[0]?.year
          : state.reducer?.selectedSeasonYear;
        let response3141;
        response3141 = yield call(serverApi.FilterIntangibles, payload);

        const GetIntagiblesResponseVariable = response3141.data;
        const GetIntagiblesResponseCodeVariable = response3141.status;
        yield put(
          actions.logEvent({
            id: 3140,
            options: {
              response_as: GetIntagiblesResponseVariable,
              response_code_as: GetIntagiblesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (GetIntagiblesResponseCodeVariable === state.reducer["TwoHundred"]) {
          yield put(
            actions.logEvent({
              id: 12366,
              options: {
                parameter_mapping: {
                  "2844db8a-960c-430b-987d-7003aa903ade":
                    GetIntagiblesResponseVariable,
                },
                return_as: GetIntagiblesResponseVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("Intangibles", GetIntagiblesResponseVariable)
          );
        }
      }
      payloadNew = {};
      payloadNew["query_variables"] = {};
      payloadNew.query_variables["game_id"] = state.page.playgradeid;
      payloadNew.query_variables["position_id"] =
        state.reducer["SelectedPositionId"] === ""
          ? -1
          : state.reducer["SelectedPositionId"];
      payloadNew.query_variables["qtr"] =
        state.reducer["SelectedQuarterId"] === ""
          ? state.reducer?.QuarterData[0]?.value
          : state.reducer["SelectedQuarterId"];
      payloadNew.query_variables["type"] =
        state.reducer["SelectedPlayTypeId"] === ""
          ? state.reducer?.PlayTypeArray[0]?.value
          : state.reducer["SelectedPlayTypeId"];
      payloadNew.query_variables["distance"] =
        state.reducer["SelectedPlayDistanceId"] === ""
          ? state.reducer?.PlayDistanceArray[0]?.value
          : state.reducer["SelectedPlayDistanceId"];
      payloadNew.query_variables["down"] =
        state.reducer["SelectedDownId"] === ""
          ? state?.reducer?.DownDropDownData[0]?.value
          : state.reducer["SelectedDownId"];
      payloadNew.query_variables["series"] =
        state.reducer["SelectedSeriesId"] === ""
          ? state.reducer["SeriesTypeArray"][0].value
          : state.reducer["SelectedSeriesId"];
      payloadNew.query_variables["distance"] = -1;
      payloadNew.query_variables["year"] = state.page?.year
        ? state.page?.year
        : state.reducer?.selectedSeasonYear === ""
        ? GetSeasonYearResponse?.[0]?.year
        : state.reducer?.selectedSeasonYear;
      payloadNew.query_variables["column_data"] = customFieldData
        ? JSON.stringify(customFieldData)
        : state.reducer?.SelectedCustomFields
        ? JSON.stringify(state.reducer?.SelectedCustomFields)
        : JSON.stringify({});

      const response12651 = yield call(
        serverApi.GetAverageIntangibleUsersEndpoint,
        payloadNew
      );

      const GetAverageIntangibleUsersResponseVariable = response12651.data;
      const GetAverageIntangibleUsersResponseCodeVariable =
        response12651.status;
      yield put(
        actions.logEvent({
          id: 12651,
          options: {
            query_variables: {
              "0158728a-1919-47cd-9e87-e5d3898efcc5": state.page.playgradeid,
            },
            response_as: GetAverageIntangibleUsersResponseVariable,
            response_code_as: GetAverageIntangibleUsersResponseCodeVariable,
          },
          type: "event",
          time: Date.now(),
        })
      );
      if (
        GetAverageIntangibleUsersResponseCodeVariable ===
        state.reducer["TwoHundred"]
      ) {
        let getGamePracticeData = [];
        if (
          state.reducer?.SelectedCategoryId !== "" &&
          state.reducer?.SelectedCategoryId !== "All" &&
          GetAverageIntangibleUsersResponseVariable?.length > 0
        ) {
          getGamePracticeData =
            GetAverageIntangibleUsersResponseVariable?.filter(
              (item) => item.category === state.reducer?.SelectedCategoryId
            );
        } else {
          getGamePracticeData = GetAverageIntangibleUsersResponseVariable;
        }
        yield put(
          actions.logEvent({
            id: 12653,
            options: {
              field_key: "PlayReportsData",
              field_value: getGamePracticeData,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("PlayReportsData", getGamePracticeData));
        const averagePlay = getGamePracticeData
          ?.filter((data) => data["package"] !== null)
          ?.map((val) => val.index);
        yield put(
          actions.logEvent({
            id: 12243,
            options: {
              field_key: "AverageByPlay",
              field_value: averagePlay,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(actions.changeInput("AverageByPlay", averagePlay));
        if (
          state.reducer?.SelectedPositionId === "" ||
          state.reducer?.SelectedPositionId === "-1" ||
          state.reducer?.SelectedPositionId === null
        ) {
          if (getGamePracticeData && isArray(getGamePracticeData)) {
            let averageArray = [];
            getGamePracticeData
              ?.filter((data) => data["package"] !== null)
              ?.forEach((value) => {
                let average = averageCalculate(
                  value["position_averages"],
                  "position_averages"
                );
                average === "None"
                  ? averageArray.push(0.0)
                  : averageArray.push(Number(average?.replace(/.$/, "")));
              });
            yield put(
              actions.logEvent({
                id: 12243,
                options: {
                  field_key: "PlayAverages",
                  field_value: averageArray,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(actions.changeInput("PlayAverages", averageArray));
          } else return [];
        } else {
          if (getGamePracticeData && isArray(getGamePracticeData)) {
            let averageArray = [];
            getGamePracticeData
              ?.filter((data) => data["package"] !== null)
              ?.forEach((value) => {
                let average = averageCalculate(value["averages"], "averages");
                average === "None"
                  ? averageArray.push(0.0)
                  : averageArray.push(Number(average?.replace(/.$/, "")));
              });
            yield put(
              actions.logEvent({
                id: 12243,
                options: {
                  field_key: "PlayAverages",
                  field_value: averageArray,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(actions.changeInput("PlayAverages", averageArray));
          } else return [];
        }
        if (isDefault) {
          yield put(actions.changeInput("isDefaultLoaded", false));
          yield put(actions.changeInput("isLoaded", false));
        }
      }
    } catch (error) {}
  }
}

function* ON_PLAYER_POSITION_FILTER_DATA() {
  while (true) {
    const { inputVariables } = yield take(
      actions.ON_PLAYER_POSITION_FILTER_DATA
    );

    let state = yield select();
    state.page =
      state.routing && state.routing.locationBeforeTransitions
        ? state.routing.locationBeforeTransitions.query
        : {};
    let payload, payloadNew;

    try {
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] =
        state.reducer?.SelectedPlayerGameAndPracticeId === ""
          ? inputVariables?.["game iddd"]
          : state.reducer?.SelectedPlayerGameAndPracticeId;
      payload.query_variables["user_id"] = state.reducer?.PlayerReportDataUser;
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? state.reducer?.SeasonYearData?.[0]?.year?.[0]?.year
          : state.reducer?.selectedSeasonYear;

      // start
      let response3141;

      if (
        state.reducer["SelectedPlayerGameAndPracticeId"] !== "" &&
        Number(state.reducer["SelectedPlayerGameAndPracticeId"]) !== -1 &&
        state.reducer.PlayerReportDataUser !== -1
      ) {
        //  debugger
        response3141 = yield call(serverApi.PlayerIntangibles, payload);

        const GetIntagiblesResponseVariable = response3141.data;
        const GetIntagiblesResponseCodeVariable = response3141.status;
        yield put(
          actions.logEvent({
            id: 3140,
            options: {
              response_as: GetIntagiblesResponseVariable,
              response_code_as: GetIntagiblesResponseCodeVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        if (GetIntagiblesResponseCodeVariable === state.reducer["TwoHundred"]) {
          let IntangibleswithcolorVariable;
          try {
            IntangibleswithcolorVariable = window.GenerateColor({
              somelist: GetIntagiblesResponseVariable,
            });
          } catch (e) {}
          yield put(
            actions.logEvent({
              id: 12366,
              options: {
                parameter_mapping: {
                  "2844db8a-960c-430b-987d-7003aa903ade":
                    GetIntagiblesResponseVariable,
                },
                return_as: IntangibleswithcolorVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );

          yield put(
            actions.logEvent({
              id: 3142,
              options: {
                field_key: "Intangibles",
                field_value: IntangibleswithcolorVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          yield put(
            actions.changeInput("Intangibles", IntangibleswithcolorVariable)
          );

          // end

          payloadNew = {};
          payloadNew["query_variables"] = {};
          payloadNew.query_variables["game_id"] =
            state.reducer["SelectedPlayerGameAndPracticeId"] === ""
              ? inputVariables?.gameAndPracticeId
              : state.reducer["SelectedPlayerGameAndPracticeId"];

          payloadNew.query_variables["user_id"] =
            state.reducer.PlayerReportDataUser;
          payloadNew.query_variables["year"] =
            state.reducer?.selectedSeasonYear === ""
              ? state.reducer?.SeasonYearData?.[0]?.year?.[0]?.year
              : state.reducer?.selectedSeasonYear;

          // start

          const response12651 = yield call(
            serverApi.PlayerFilterIntangibles,
            payloadNew
          );

          const GetAverageIntangibleUsersResponseVariable = response12651.data;

          const GetAverageIntangibleUsersResponseCodeVariable =
            response12651.status;
          yield put(
            actions.logEvent({
              id: 12651,
              options: {
                query_variables: {
                  "0158728a-1919-47cd-9e87-e5d3898efcc5":
                    state.page.playgradeid,
                },
                response_as: GetAverageIntangibleUsersResponseVariable,
                response_code_as: GetAverageIntangibleUsersResponseCodeVariable,
              },
              type: "event",
              time: Date.now(),
            })
          );
          if (
            GetAverageIntangibleUsersResponseCodeVariable ===
            state.reducer["TwoHundred"]
          ) {
            yield put(
              actions.logEvent({
                id: 12653,
                options: {
                  field_key: "PlayerReportsData",
                  field_value: GetAverageIntangibleUsersResponseVariable,
                },
                type: "event",
                time: Date.now(),
              })
            );
            yield put(
              actions.changeInput(
                "PlayerReportsData",
                GetAverageIntangibleUsersResponseVariable
              )
            );
          }
        }
      } else {
        // debugger
        yield put(actions.removeField("PlayerReportsData"));
        yield put(actions.removeField("Intangibles"));
      }
      // end

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] =
        state.reducer["SelectedPlayerGameAndPracticeId"] === ""
          ? inputVariables?.gameAndPracticeId
          : state.reducer["SelectedPlayerGameAndPracticeId"];
      payload.query_variables["user_id"] = state.reducer?.PlayerReportDataUser;
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? state.reducer?.SeasonYearData?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response = yield call(
        serverApi.GetPlayerProductionPointEndpoint,
        payload
      );
      const GetPlayerProductionPointResponse = response.data;
      const GetPlayerProductionPointResponseStatus = response.status;
      if (
        GetPlayerProductionPointResponseStatus === state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12895,
            options: {
              field_key: "PlayerProductionPointData",
              field_value: GetPlayerProductionPointResponse,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayerProductionPointData",
            GetPlayerProductionPointResponse
          )
        );
      }

      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] =
        state.reducer["SelectedPlayerGameAndPracticeId"] === ""
          ? inputVariables?.gameAndPracticeId
          : state.reducer["SelectedPlayerGameAndPracticeId"];
      payload.query_variables["user_id"] = state.reducer?.PlayerReportDataUser;
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? state.reducer?.SeasonYearData?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      // payload.query_variables["position_id"] = state.reducer?.Positions[0]?.Id;
      const response12893 = yield call(
        serverApi.GetPlayerAverageIntangibleEndpoint,
        payload
      );
      const GetAverageIntangibleResVariable = response12893.data;

      const GetAverageIntangibleResCodeVariable = response12893.status;
      // yield put(
      //   actions.logEvent({
      //     id: 12893,
      //     options: {
      //       query_variables: {
      //         "07f07538-95a0-4013-8586-2968fa4cd632":
      //           GetFirstUserResponseVariable["Id"],
      //         "30e6e7d2-bd5c-4a0e-abba-8385ee7c6986":
      //           GetGameResponseVariable["Id"],
      //       },
      //       response_as: GetAverageIntangibleResVariable,
      //       response_code_as: GetAverageIntangibleResCodeVariable,
      //     },
      //     type: "event",
      //     time: Date.now(),
      //   })
      // );
      if (GetAverageIntangibleResCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 12895,
            options: {
              field_key: "AverageIntangible",
              field_value: GetAverageIntangibleResVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "AverageIntangible",
            GetAverageIntangibleResVariable
          )
        );
      }
      if (state.reducer["SelectedPlayerGameAndPracticeId"]) {
        yield put(
          actions.runAction("GET_CUSTOM_FIELD_TITLES", {
            inputVariables: {
              "game id": state.reducer["SelectedPlayerGameAndPracticeId"],
            },
          })
        );
      } else {
        yield put(actions.changeInput("CustomFieldTitles", []));
      }

      yield put(
        actions.runAction("GET_TOTAL_PLAYS", {
          inputVariables: {
            "input game id":
              state.reducer["SelectedPlayerGameAndPracticeId"] === ""
                ? inputVariables?.gameAndPracticeId
                : state.reducer["SelectedPlayerGameAndPracticeId"],
          },
        })
      );

      // start
      payload = {};
      payload["query_variables"] = {};
      payload.query_variables["game_id"] =
        state.reducer["SelectedPlayerGameAndPracticeId"] === ""
          ? inputVariables?.gameAndPracticeId
          : state.reducer["SelectedPlayerGameAndPracticeId"];
      payload.query_variables["user_id"] = state.reducer.PlayerReportDataUser;
      payload.query_variables["year"] =
        state.reducer?.selectedSeasonYear === ""
          ? state.reducer?.SeasonYearData?.[0]?.year
          : state.reducer?.selectedSeasonYear;
      const response12767 = yield call(
        serverApi.GetPlayerReportsCountEndpoint,
        payload
      );
      const GetPlayerReportsCountResponseVariable = response12767.data;
      const GetPlayerReportsCountResponseCodeVariable = response12767.status;
      // yield put(
      //   actions.logEvent({
      //     id: 12767,
      //     options: {
      //       query_variables: {
      //         "5f8aa922-f965-430f-b07f-2d477bc71ffc":
      //           GetGameResponseVariable["Id"],
      //         "f9688959-26fd-4a53-98d3-b8c68200eece":
      //           GetFirstUserResponseVariable["Id"],
      //       },
      //       response_as: GetPlayerReportsCountResponseVariable,
      //       response_code_as: GetPlayerReportsCountResponseCodeVariable,
      //     },
      //     type: "event",
      //     time: Date.now(),
      //   })
      // );
      if (
        GetPlayerReportsCountResponseCodeVariable ===
        state.reducer["TwoHundred"]
      ) {
        yield put(
          actions.logEvent({
            id: 12769,
            options: {
              field_key: "PlayerReportUsersCount",
              field_value: GetPlayerReportsCountResponseVariable["count"],
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput(
            "PlayerReportUsersCount",
            GetPlayerReportsCountResponseVariable["count"]
          )
        );
      }
      // end
    } catch (error) {}
  }
}

function* GET_ALL_SERIES() {
  while (true) {
    yield take(actions.GET_ALL_SERIES);
    let state = yield select();
    try {
      const response4449 = yield call(serverApi.GetAllSeries, null);
      let GetAllSeriesResponseVariable = response4449.data;
      yield (GetAllSeriesResponseVariable =
        GetAllSeriesResponseVariable?.filter((item) => item.series != null));
      yield (GetAllSeriesResponseVariable =
        GetAllSeriesResponseVariable &&
        isArray(GetAllSeriesResponseVariable) &&
        GetAllSeriesResponseVariable &&
        GetAllSeriesResponseVariable.map(
          ({ series: label, series: value }) => ({ label, value })
        ));
      GetAllSeriesResponseVariable.unshift({ label: "All", value: -1 });
      const GetAllSeriesCodeVariable = response4449.status;

      if (GetAllSeriesCodeVariable === state.reducer["TwoHundred"]) {
        yield put(
          actions.logEvent({
            id: 3542,
            options: {
              field_key: "SeriesTypeArray",
              field_value: GetAllSeriesResponseVariable,
            },
            type: "event",
            time: Date.now(),
          })
        );
        yield put(
          actions.changeInput("SeriesTypeArray", GetAllSeriesResponseVariable)
        );

        // yield put(actions.setField("SeriesTypeArray",GetAllSeriesResponseVariable));
      }
    } catch (error) {}
  }
}

export default function* saga() {
  yield fork(log_event);
  // yield fork(_initializeWebSocketsChannel);
  yield fork(ADD_NEW_FIELD);
  yield fork(CREATE_CUSTOM_FIELD_DATA);
  yield fork(UPDATE_CUSTOM_FIELD_DATA);
  yield fork(CREATE_CUSTOM_PLAY_DATA);
  yield fork(CREATE_DEPTH_CHART);
  yield fork(CREATE_DEPTH_CHART_ALIAS);
  yield fork(CREATE_DEPTH_CHART_REGISTER);
  yield fork(CREATE_EXERCISE);
  yield fork(CREATE_GAMES);
  yield fork(VALIDATE_QWIKCUT_GAMES);
  yield fork(CREATE_GRADE);
  yield fork(CREATE_INJURED_PLAYER);
  yield fork(CREATE_INTANGIBLE);
  yield fork(CREATE_NEW_COLUMN);
  yield fork(CREATE_NEW_PLAY_ROW);
  yield fork(CREATE_NEW_POSITION_COACH);
  yield fork(CREATE_NEW_ROW);
  yield fork(CREATE_PLAY_DEPTH_CHART_ROW);
  yield fork(CREATE_POSITION);
  yield fork(CREATE_QUIZ);
  yield fork(CREATE_QUIZ_QUESTION);
  yield fork(CREATE_QUIZ_RESPONSE);
  yield fork(CREATE_USER);
  yield fork(CREATE_USER_EXERCISE);
  yield fork(CREATE_USER_GRADE);
  yield fork(DELETE_DEPTH_CHART);
  yield fork(DELETE_DEPTH_CHART_ROW);
  yield fork(DELETE_DEPTH_CHART_ROW_USER);
  yield fork(DELETE_DEPTH_CHART_USER);
  yield fork(DELETE_DEPTH_CHARTS_USERS);
  yield fork(DELETE_EXERCISE);
  yield fork(DELETE_GAME);
  yield fork(DELETE_GAME_FILE);
  yield fork(DELETE_INJURED_PLAYER);
  yield fork(DELETE_INTANGIBLE);
  yield fork(DELETE_PLAY_ROW);
  yield fork(DELETE_POSITION);
  yield fork(DELETE_POSITION_COACH);
  yield fork(DELETE_QUIZ);
  yield fork(DELETE_QUIZ_QUESTION);
  yield fork(DELETE_TAG);
  yield fork(DELETE_USER);
  yield fork(DELETE_VIEW_USER);
  yield fork(DELETE_USER_DEPTH_CHART);
  yield fork(DELETE_USER_EXERCISE);
  yield fork(DEPTH_CHART_USERS_WORKFLOWS);
  yield fork(DEPTH_CHART_WORKFLOW);
  yield fork(EDIT_COACH_USER);
  yield fork(EDIT_DEPTH_CHART);
  yield fork(EDIT_GAME_FILE);
  yield fork(EDIT_PLAYER);
  yield fork(EDIT_PLAYER_PASSWORD);
  yield fork(EDIT_POSITION);
  yield fork(EDIT_USER_EXERCISE);
  yield fork(EDIT_USER_PASSWORD);
  yield fork(EDITPLAYERPOSITION);
  yield fork(GET_ALL_PLAY_DEPTH_CHART_USERS);
  yield fork(GET_AVERAGE_GAME_GRADE_SORTING);
  yield fork(GET_AVERAGE_GAME_GRADES);
  yield fork(GET_AVERAGE_GAME_GRADES_DES);
  yield fork(GET_AVERAGE_GAME_GRADES_REPORTS);
  yield fork(GET_AVERAGE_GAME_GRADES_SORTING_DES);
  yield fork(GET_AVERAGE_PRACTICE_GRADE_SORTING);
  yield fork(GET_AVERAGE_PRACTICE_GRADES_SORTING_DES);
  yield fork(GET_AVERAGE_USER_EXERCISES);
  yield fork(GET_AVERAGE_USER_EXERCISES_DES);
  yield fork(GET_BASE_DEPTH_CHARTS);
  yield fork(TOGGLE_SEASON_YEAR_DEPTH_CHART);
  yield fork(TOGGLE_SEASON_YEAR_GRADING);
  yield fork(TOGGLE_SEASON_YEAR_GAME_AND_PRACTICE_REPORT);
  yield fork(GET_CURRENT_USER);
  yield fork(GET_CUSTOM_FIELD_TITLES);
  yield fork(GET_DEPTH_CHART);
  yield fork(GET_DEPTH_CHART_FIELD_VIEW);
  yield fork(SELECT_DEPTH_CHART_VIEW);
  yield fork(UPDATE_DEPTH_CHART_FIELD_VIEW);
  yield fork(SET_FIELD_VIEW_PLAYER);
  yield fork(UPDATE_FIELD_VIEW_PLAYER);
  yield fork(CLEAR_DEPTH_CHART_VALUES);
  yield fork(SET_DEPTH_CHART_WEEK_FILTER);
  yield fork(GET_DEPTH_CHART_ID);
  yield fork(GET_DEPTH_CHART_POSITION_USERS);
  yield fork(GET_DEPTH_CHART_USERS);
  yield fork(UPDATE_DRAG_USER);
  yield fork(UPDATE_DROP_USER);
  yield fork(GET_PLAYER_DEPTH_CHART_USERS);
  yield fork(GET_DEPTH_CHART_BY_SCHOOL);
  yield fork(GET_EXERCISES);
  yield fork(GET_EXERCISES_GRAPH);
  yield fork(GET_EXERCISES_GRAPH_USER);
  yield fork(GET_GAME);
  yield fork(GET_GAME_BY_ID);
  yield fork(GET_GAME_PRACTICE_GAME);
  yield fork(CLEAR_GRADING_INDEX);
  yield fork(GET_PLAYER_GAME);
  yield fork(GET_GAME_FILES);
  yield fork(GET_GAME_FOR_PLAYER);
  yield fork(GET_GAME_GRADES);
  yield fork(GET_GAME_GRADES_DES);
  yield fork(GET_GAMES);
  yield fork(GET_TEAMS);
  yield fork(GET_GAMES_AND_PRACTICES);
  yield fork(GET_GRADES_GRAPH);
  yield fork(GET_PLAYER_USERS);
  yield fork(PLAYER_POSITION_COLLECTION);
  yield fork(CLEAR_COMPARE_VALUE);

  yield fork(PLAYER_INTANGIBLE_COLLECTION);
  yield fork(GET_GROUPED_CHARTS);
  yield fork(GET_USER_GRADES_FOR_FILTER);
  yield fork(GET_INJURED_PLAYERS);
  yield fork(GET_INTANGIBLE_SORTING);
  yield fork(GET_INTANGIBLES);
  yield fork(GET_SCHOOL_REPORT);
  yield fork(GET_PRODUCTION_POINT);
  yield fork(GET_SCHOOL_PRODUCTION_POINT);
  yield fork(ADD_SCHOOL_PRODUCTION_POINT);
  yield fork(ADD_PRODUCTION_POINT);
  yield fork(SEND_NEW_PRODUCTION_POINT_REQUEST);
  yield fork(PRODUCTION_POINT_ERROR_MESSAGE);
  yield fork(GET_INTANGIBLES_FOR_REPORT);
  yield fork(GET_INTANGIBLES_FOR_VIEW_ROW);
  yield fork(GET_INTANGIBLES_GRAPH);
  yield fork(GET_NUMBER_ROSTER);
  yield fork(GET_NUMBER_ROSTER_DES);
  yield fork(GET_PACKAGES);
  yield fork(GET_PLAY_BY_PLAY_INFO);
  yield fork(GET_PLAY_INFO_AFTER_REFRESH);
  yield fork(GET_PLAY_INFO_BY_DOWN);
  yield fork(GET_PLAY_INFO_BY_DOWN_DES);
  yield fork(GET_PLAY_INFO_BY_INTANGIBLE);
  yield fork(GET_PLAY_INFO_BY_NAME);
  yield fork(GET_PLAY_INFO_BY_NAME_DES);
  yield fork(GET_PLAY_INFO_BY_PACKAGE);
  yield fork(GET_PLAY_INFO_BY_PACKAGE_DES);
  yield fork(GET_PLAY_INFO_BY_QTR);
  yield fork(GET_PLAY_INFO_BY_QTR_DES);
  yield fork(GET_PLAY_INFO_BY_SERIES);
  yield fork(GET_PLAY_INFO_BY_SERIES_DES);
  yield fork(GET_PLAY_INFO_BY_TYPE);
  yield fork(GET_PLAY_INFO_BY_TYPE_DES);
  yield fork(GET_PLAY_REPORTS_DATA);
  yield fork(GET_PLAYER_GRADES);
  yield fork(GET_PLAYER_GRADES_DATA);
  yield fork(GET_PLAYER_REPORTS_COUNT);
  yield fork(GET_POSITION);
  yield fork(GET_POSITION_COACHES);
  yield fork(GET_POSITIONS);
  yield fork(GET_POSITIONS_DES);
  yield fork(GET_POSITIONS_ROSTER);
  yield fork(GET_PRACTICES);
  yield fork(GET_QUIZ_QUESTIONS);
  yield fork(GET_QUIZZES);
  yield fork(GET_REPORTS_POSITION_FIELD);
  // yield fork(GET_SCHOOLS);
  yield fork(GET_SECONDARY_ROSTER);
  yield fork(GET_SECONDARY_ROSTER_DES);
  yield fork(GET_SUPPORT_PAGE);
  yield fork(GET_TAGS);
  yield fork(GET_TOTAL_PLAYS);
  yield fork(GET_USER);
  yield fork(GET_USER_EXERCISES);
  yield fork(GET_USER_GRADES);
  yield fork(GET_USER_GRADES_GROUPED);
  yield fork(GET_USERS);
  yield fork(LOADING_EVENT);
  yield fork(GET_SEASON_YEAR);
  yield fork(SELECT_SEASON_YEAR);
  // yield fork(GET_USERS_DES);
  yield fork(GET_YEAR_ROSTER);
  yield fork(GET_YEAR_ROSTER_DES);
  yield fork(GET_CSV_PLAY_MAPPING_TITLES_CACHE);
  yield fork(HIDE_PLAY_MAPPING);
  yield fork(HIDE_IMPORT_MAPPING);
  yield fork(HIDE_ROSTER_MAPPING);
  yield fork(HIDE_USER_GRAPH);
  yield fork(INIT_GAME_GRADES_PAGE);
  yield fork(INIT_GAME_GRADES_PAGE_DES);
  yield fork(LOGIN);
  yield fork(LOGOUT);
  yield fork(MAP_CSV);
  yield fork(MAP_PLAY_CSV);
  yield fork(IMPORT_PLAY_CSV);
  yield fork(NEW_EXERCISE);
  yield fork(NEW_GAME);
  yield fork(NEW_INJURED_PLAYER);
  // yield fork(NEW_INTANGIBLE);
  yield fork(NEW_POSITION_COACH);
  yield fork(NEW_USER);
  yield fork(NEW_USER_EXERCISE);
  yield fork(ON_POSITION_FILTER_DATA);
  yield fork(CLEAR_PLAYER_REPORT_VALUES);
  yield fork(CLEAR_GAME_PRACTICE_REPORT_VALUES);
  yield fork(CLEAR_PLAYER_ACCOUNT_VALUES);
  yield fork(CLEAR_PLAYER_DROPDOWN_VALUES);
  yield fork(ON_PLAYER_POSITION_FILTER_DATA);
  yield fork(PLAY_BY_PLAY_POSITION_SORTING);
  yield fork(PRINT_REPORTS);
  yield fork(PRINT_REPORTS_PARTICULAR);
  yield fork(REFRESH_PAGE);
  yield fork(RELOADPAGE);
  yield fork(RELOADPLAYGRADES);
  yield fork(REMOVE_ERRORS);
  yield fork(REMOVE_ADMIN_EDIT_USER_ERROR);
  yield fork(REMOVE_SUCCESSEMAILMESSAGE);
  yield fork(REMOVE_TOGGLEDEPTHCHART);
  yield fork(ADD_GRADING_PLAY);
  yield fork(SET_NEXT_DEPTH_CHART);
  yield fork(REMOVEVALUE);
  yield fork(REQUEST_PASSWORD_RESET);
  yield fork(RESEND_EMAIL);
  yield fork(SET_EMAIL_ALL_PLAYER);
  yield fork(RESEND_EMAIL_ALL_PLAYER);
  yield fork(RESET_DEPTH_CHART);
  yield fork(RESET_PASSWORD);
  yield fork(SET_ACCORDION_INDEX);
  yield fork(SET_AVERAGE_GRADES_FIELD);
  yield fork(SET_CURRENT_ALIAS);
  yield fork(SET_CURRENT_DEPTH_CHART_POSITION);
  yield fork(SET_CURRENT_DOWN);
  yield fork(SET_CURRENT_FIELD);
  yield fork(SET_CURRENT_INDEX);
  yield fork(SET_CURRENT_INTANGIBLE);
  yield fork(SET_CURRENT_NAME);
  yield fork(SET_CURRENT_NOTES);
  yield fork(SET_CURRENT_PACKAGE);
  yield fork(SET_CURRENT_POSITION);
  yield fork(SET_CURRENT_PRODUCTION_POINT);
  yield fork(SET_CURRENT_QTR);
  yield fork(SET_CURRENT_SERIES);
  yield fork(SET_CURRENT_TYPE);
  yield fork(SET_DATE_FORMAT);
  yield fork(SET_DELETE_COACH);
  yield fork(SET_DELETE_DEPTH_CHART);
  yield fork(SET_DELETE_DEPTH_CHART_ROW);
  yield fork(SET_DELETE_EXERCISE);
  yield fork(SET_DELETE_GAME);
  yield fork(SET_DELETE_GAME_FILE);
  yield fork(SET_DELETE_INJURED_PLAYER);
  yield fork(SET_DELETE_INTANGIBLE);
  yield fork(SET_DELETE_PLAY_DEPTH_CHART_USER);
  yield fork(SET_DELETE_PLAY_ROW);
  yield fork(SET_DELETE_PLAYER_EXERCISE);
  yield fork(SET_DELETE_TAG);
  yield fork(SET_DELETE_USER);
  yield fork(SET_VIEW_USER);
  yield fork(SET_DEPTH_CHART_CATEGORY);
  yield fork(SET_DEPTH_CHART_LIST_CATEGORY_FILTER);
  yield fork(SET_DEPTH_CHART_LIST_WEEK_FILTER);
  yield fork(SET_DEPTH_CHART_USER);
  yield fork(SET_EDIT_DEPTH_CHART);
  yield fork(SET_EXPORT_DEPTH_CHART);
  yield fork(SET_DUPLICATE_DEPTH_CHART);
  yield fork(DUPLICATE_DEPTH_CHART);
  yield fork(SET_EDIT_GAME);
  yield fork(CHECKBOX_BULK_GRADING);
  yield fork(SET_BULK_PLAY_ROW);
  yield fork(DELETE_CHECKBOX_BULK_GRADING);
  yield fork(SET_BULK_EDIT_ROW);
  yield fork(CHECKBOX_BULK_GRADING_SCALE_DATA);
  yield fork(UPDATE_BULK_GRADING_SCALE_DATA);
  yield fork(UPDATE_VIDEO_LINK);
  yield fork(UPLOAD_HUDL_VIDEO);
  yield fork(REMOVE_UPLOAD_HUDL_VIDEO_ERROR);
  yield fork(SET_EDIT_INTANGIBLE);
  yield fork(SET_EDIT_QUIZ_QUIZ);
  yield fork(SET_EDIT_USER);
  yield fork(SET_ADMIN_EDIT_USER);
  yield fork(SET_GAME_DATE);
  yield fork(SET_GAME_TYPE_FIELD);
  yield fork(SET_LOGIN_MESSAGE);
  yield fork(SET_NAVBAR_DROPDOWN_INDEX);
  yield fork(SET_NEW_POSITION);
  yield fork(SET_NEW_USER);
  yield fork(SET_PLAY_DEPTH_CHART);
  yield fork(SET_NOTES);
  yield fork(SET_PLAY_DEPTH_CHART_FOR_REPORT_FILTER);
  yield fork(SET_ROSTER_FILTER);
  yield fork(SET_USER_DROPDOWN);
  yield fork(SET_USER_ID);
  yield fork(SET_CSV_PLAY_MAPPING_TITLES);
  yield fork(SHARE_DEPTH_CHART);
  yield fork(SHOW_PLAYER_COMPARISON);
  yield fork(SET_PLAY_GRADES_DROPDOWN);
  yield fork(TOGGLE_AVERAGE_GAME_GRADE_SORTING);
  yield fork(TOGGLE_AVERAGE_PRACTICE_GRADE_SORTING);
  yield fork(TOGGLE_BASE_PACKAGES_DROPDOWN);
  yield fork(TOGGLE_DEPTHCHARTUSER_DROPDOWN);
  yield fork(TOGGLE_DROPDOWN_MANAGE_GAME);
  yield fork(TOGGLE_DROPDOWN_MANAGE_PACKAGE);
  yield fork(TOGGLE_INTANGIBLE_FILTER);
  yield fork(TOGGLE_NEW_DATA);
  yield fork(TOGGLE_NEW_PLAY_ROW);
  yield fork(TOGGLE_NEW_QUIZ);
  yield fork(TOGGLE_NEW_ROW);
  yield fork(TOGGLE_PACKAGE_SELECT);
  yield fork(TOGGLE_POSITION_SORTING);
  yield fork(TOGGLE_QUIZ_QUESTION);
  yield fork(TOGGLE_ROSTER_FILTER);
  // yield fork(TOGGLE_SORTING);
  yield fork(SORT_PLAYER_TABLE);
  yield fork(TOGGLE_SORTING_DOWN_FOR_PLAYER);
  // yield fork(TOGGLE_SORTING_EXERCISE);
  yield fork(TOGGLE_SORTING_GAME_GRADES);
  yield fork(TOGGLE_SORTING_INDEX_FOR_PLAYER);
  yield fork(TOGGLE_SORTING_NAME_FOR_PLAYER);
  yield fork(TOGGLE_SORTING_NUMBER);
  yield fork(TOGGLE_SORTING_PACKAGE_FOR_PLAYER);
  yield fork(TOGGLE_SORTING_PLAY_DOWN);
  yield fork(TOGGLE_SORTING_PLAY_NAME);
  yield fork(TOGGLE_SORTING_PLAY_PACKAGE);
  yield fork(TOGGLE_SORTING_PLAY_QTR);
  yield fork(TOGGLE_SORTING_PLAY_TYPE);
  yield fork(TOGGLE_SORTING_QTR_FOR_PLAYER);
  yield fork(TOGGLE_SORTING_SECONDARY);
  yield fork(TOGGLE_SORTING_SERIES);
  yield fork(TOGGLE_SORTING_SERIES_FOR_PLAYER);
  yield fork(TOGGLE_SORTING_TYPE_FOR_PLAYER);
  yield fork(TOGGLE_SORTING_YEAR);
  yield fork(TOGGLE_SORTING_EXERCISE_POSITION);
  yield fork(TOGGLE_USER_SETTINGS_DROPDOWN);
  yield fork(TOGGLE_WEEK_PACKAGES_DROPDOWN);
  yield fork(UPDATE_DEPTH_CHART_ALIAS);
  yield fork(UPDATE_GAME);
  yield fork(UPDATE_GAME_FILE);
  yield fork(UPDATE_PLAY_DOWN);
  yield fork(UPDATE_PLAY_DISTANCE);
  yield fork(GET_CONFIGURABLE_COLUMNS);
  yield fork(UPDATE_CONFIGURABLE_COLUMNS);
  yield fork(SET_DEFAULT_VIEW);
  yield fork(SET_DEFAULT_VALUE);
  yield fork(TOGGLE_CUSTOMIZE_VIEW);
  yield fork(DRAG_AND_DROP_GRADING);
  yield fork(SET_SAVED_VIEW);
  yield fork(DELETE_SAVED_VIEW);
  yield fork(SET_DELETE_SAVED_VIEW);
  // yield fork(GET_GRADING_TABLE_COLUMNS_INDEX);
  yield fork(UPDATE_GRADING_TABLE_COLUMNS_INDEX);
  yield fork(UPDATE_PLAY_FIELD);
  yield fork(UPDATE_PLAY_INDEX);
  yield fork(UPDATE_PLAY_NAME);
  yield fork(UPDATE_PLAY_PACKAGE);
  yield fork(UPDATE_PLAY_QTR);
  yield fork(UPDATE_PLAY_SERIES);
  yield fork(UPDATE_PLAY_TYPE);
  yield fork(UPDATE_PLAY_USER);
  yield fork(UPDATE_PLAY_USER_NOTES);
  yield fork(EDIT_PLAY_USER_NOTES);
  yield fork(UPDATE_POSITION);
  yield fork(UPDATE_POSITION_COACH);
  yield fork(UPDATE_PRODUCTION_POINT);
  yield fork(REMOVE_PRODUCTION_POINT);
  yield fork(UPDATE_USER);
  yield fork(UPDATE_ADMIN_EDIT_USER);
  yield fork(REMOVE_IMAGE);
  yield fork(UPDATE_VIEW_USER);
  yield fork(UPDATE_USER_EXERCISE);
  yield fork(UPLOAD_GAME_FILE);
  yield fork(UPLOAD_GRADE);
  yield fork(REMOVE_VALUE);
  yield fork(UPLOAD_PLAY);
  yield fork(IMPORT_PLAY);
  yield fork(GET_BASE_DEPTH_CHART_BY_PLAYER);
  yield fork(GET_DEPTH_CHART_BY_PLAYER);
  yield fork(GET_ALL_SERIES);
  yield fork(EDIT_SCALE_TYPE);
  yield fork(GET_SCALE_TYPE);
  yield fork(UPLOAD_ROSTER);
  yield fork(on_app_started);
}
