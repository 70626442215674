import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Body, Button, DivBlock } from "../modules/Basic";
import { FormBlock, Label, TextInput } from "../modules/Forms";
import { Column, Columns } from "../modules/Layout";
import { If, Then } from "../modules/Logic";
import { Heading, Span } from "../modules/Typography";
import PlayerSideNavComponent from "../components/PlayerSideNavComponent";
import TopNavbarComponent from "../components/TopNavbarComponent";
import { Image } from "../modules/Media";
import ImageCrop from "../modules/ImageCrop";
import { startPageTimer, trackPageDuration } from "../utils/function";

class EditPlayerPage extends Component {
  constructor(props){
    super(props)
    this.imageRef= React.createRef();
    this.state={
      changeImage:'',
      setCropper:'',
      image:'',
      imageName : '',
      width:0,
      imageCrop : 300,
      imageURl:'',
      errorMessage:'Click image above to edit.',
      startTime: null,
    }
  }
  
  componentDidMount(){
    startPageTimer('Edit Profile Player');
    this.setState({ startTime: Date.now() });
    if(this.props?.CurrentUser){
      this.setState({changeImage:process.env.REACT_APP_PROFILE + this.props?.CurrentUser?.profile_image?.medium})
    }
  }
  
  componentDidUpdate(previousProps,previousState){
    if(previousProps.CurrentUser !== this.props.CurrentUser){
      this.setState({changeImage:process.env.REACT_APP_PROFILE + this.props?.CurrentUser?.profile_image?.medium})
    }
  }

  componentWillUnmount(){
    trackPageDuration('Edit Profile Player',this.state.startTime)
  }

  handleChange = (e) => { 
    e.preventDefault();   
    let files;
    if (e.target) {
      var img = document.createElement("img");
      var that = this
      files = e.target.files;
      this.setState({imageName:e.target.files?.[0]?.name})
    }
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend =  (ended) => {
      img.src = ended.target.result;
      img.onload = function () {  
        that.setState({width:this.width});
        if(this.width >= 300){
          if(this.width >= 600){
            that.setState({imageCrop:600})
          }
            that.setState({changeImage: reader.result,modal:true,errorMessage:''});
        }else{
          that.setState({errorMessage:'Image resolution should be more than 300px.'})
        }
      };
    }
  };


  render() {
    let { CurrentUser, FormEditPlayerErrors } = this.props;

    const getCropData = () => {
      if (this.state.setCropper !== "undefined") {
       this.setState({changeImage:this.state.setCropper.getCroppedCanvas({
        width: this.state.imageCrop,
        height: this.state.imageCrop
      }).toDataURL(),modal:false})
      }
      
      const imageData =  dataURLtoFile(this.state.setCropper.getCroppedCanvas({
        width: this.state.imageCrop,
        height: this.state.imageCrop
      }).toDataURL(),this.state.imageName);
      this.setState({image:imageData,changeImage:URL.createObjectURL(imageData)})

    };

    function dataURLtoFile(dataurl, filename) {
 
      var arr = dataurl?.split(','),
          mime = arr?.[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr?.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      } 
      return new File([u8arr], filename, {type:mime});
    }

    return (
      <Fragment>
        <Body className={"body"}>
          <DivBlock
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <TopNavbarComponent
              data-snippet-id={297}
              style={{
                alignItems: "center",
                backgroundColor: "#000000",
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
              }}
            ></TopNavbarComponent>
            <DivBlock className={"body-container"} style={{}}>
              <PlayerSideNavComponent
                data-snippet-id={302}
                style={{ backgroundColor: "#000000", height: "100%" }}
              ></PlayerSideNavComponent>
              <DivBlock
                style={{
                  backgroundColor: "#f8f8fb",
                  flex: "1 0 auto",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <DivBlock
                  style={{
                    bottom: "0px",
                    left: "0px",
                    overflow: "auto",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                >
                  <DivBlock>
                    <DivBlock className={"body-main"}>
                      <Heading
                        className={"h2"}
                        style={{ justifyContent: "center" }}
                        type={"h2"}
                      >
                        Edit Profile
                      </Heading>
                      <Columns
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Column
                          lg={8}
                          md={8}
                          sm={12}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          xs={12}
                        >
                          <DivBlock className={"card"} style={{}}>
                            <DivBlock className={"card-body"}>
                              <FormBlock
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                                actions={[
                                  {
                                    action: "Login",
                                    trigger: "onsubmit",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Email</Label>
                                  <TextInput
                                    className={"form-control"}
                                    initialValue={CurrentUser["email"]}
                                    name={"FormEditPlayer.email"}
                                    placeholder={""}
                                    enableReinitialize={true}
                                  ></TextInput>
                                  <If
                                    condition={
                                      FormEditPlayerErrors &&
                                      FormEditPlayerErrors["email"]
                                        ? true
                                        : false
                                    }
                                    show={true}
                                  >
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {FormEditPlayerErrors
                                          ? FormEditPlayerErrors["email"]
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>
                                </DivBlock>
                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Name</Label>
                                  <TextInput
                                    className={"form-control"}
                                    initialValue={CurrentUser["name"]}
                                    name={"FormEditPlayer.name"}
                                    placeholder={""}
                                    enableReinitialize={true}
                                  ></TextInput>
                                  <If
                                    condition={
                                      FormEditPlayerErrors &&
                                      FormEditPlayerErrors["email"]
                                        ? true
                                        : false
                                    }
                                    show={true}
                                  >
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {FormEditPlayerErrors
                                          ? FormEditPlayerErrors["name"]
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>
                                </DivBlock>
                                <DivBlock className={"form-group"} onClick={()=>this.imageRef.current.click()}>
                                  <Label className={"label"}>Profile Image</Label>
                                  <Image src={this.state.changeImage}
                                  style={{height:'150px',width:'150px',cursor:'pointer'}}/>
                                  
                                </DivBlock>
                                <Span className={"invalid-feedback"}>{this.state.errorMessage}</Span>
                                <DivBlock className="remove-input">
                                  <input type="file" ref={this.imageRef} onChange={(e)=>this.handleChange(e)}/>
                                </DivBlock>
                                <Button
                                  className={"btn btn-primary"}
                                  actions={[
                                    {
                                      action: "Edit Player",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {
                                        image:this.state.image
                                      },
                                    },
                                  ]}
                                >
                                  Update
                                </Button>
                              </FormBlock>
                            </DivBlock>
                          </DivBlock>
                        </Column>
                      </Columns>
                      <Columns
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Column
                          lg={8}
                          md={8}
                          sm={12}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                          xs={12}
                        >
                          <DivBlock className={"card"} style={{}}>
                            <DivBlock className={"card-body"}>
                              <FormBlock
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                                actions={[
                                  {
                                    action: "Login",
                                    trigger: "onsubmit",
                                    timeout: "",
                                    pollingPeriod: "",
                                    inputVariables: {},
                                  },
                                ]}
                              >
                                <DivBlock className={"form-group"}>
                                  <Label className={"label"}>Password</Label>
                                  <TextInput
                                    className={"form-control"}
                                    name={"FormEditPlayer.password"}
                                    placeholder={""}
                                    type={"password"}
                                  ></TextInput>
                                  <If
                                    condition={
                                      FormEditPlayerErrors &&
                                      FormEditPlayerErrors["password"]
                                        ? true
                                        : false
                                    }
                                    show={true}
                                  >
                                    <Then>
                                      <Span className={"invalid-feedback"}>
                                        {FormEditPlayerErrors
                                          ? FormEditPlayerErrors["password"]
                                          : ""}
                                      </Span>
                                    </Then>
                                  </If>
                                </DivBlock>
                                <Button
                                  className={"btn btn-primary"}
                                  actions={[
                                    {
                                      action: "Edit Player Password",
                                      trigger: "onclick",
                                      timeout: "",
                                      pollingPeriod: "",
                                      inputVariables: {},
                                    },
                                  ]}
                                >
                                  Update
                                </Button>
                              </FormBlock>
                            </DivBlock>
                          </DivBlock>
                        </Column>
                      </Columns>
                      <If condition={this.state.modal ? true : false} show={false}>
                          <Then>
                            <DivBlock
                              className={"modal"}
                            >
                              <DivBlock className={"modal-dialog"}>
                                <DivBlock className={"modal-dialog"}>
                                  <DivBlock className={"modal-content"}>
                                    <DivBlock className={"modal-header"}>
                                      <Heading
                                        className="modal-heading"
                                        type={"h5"}
                                      >
                                        Crop Image Before Upload
                                      </Heading>

                                      <span
                                        className={"close"}
                                        onClick={()=>this.setState({modal:false,uploadImage:''})}
                                      >
                                        x
                                      </span>
                                    </DivBlock>
                                    <DivBlock style={{    
                                        flexDirection: 'row',
                                        display: 'flex',
                                        marginLeft:'10px',
                                        marginRight:'10px',
                                        marginTop:'10px',
                                        marginBottom:'10px',
                                        overflow:'auto'
                                      }}>
                                        <DivBlock style={{ width: "70%" }}>
                                          <ImageCrop
                                            initialAspectRatio={1 / 1}
                                            // aspectRatio
                                            preview=".img-preview"
                                            guides={true}
                                            src={this.state.changeImage}
                                            onInitialized={(instance) => {
                                              this.setState({setCropper:instance})
                                            }}
                                            viewMode={1}
                                            dragMode="move"
                                            cropBoxMovable={false}
                                            checkOrientation={false}
                                            toggleDragModeOnDblclick={false}
                                            modal
                                            background={false}
                                            minCropBoxHeight={50}
                                            minCropBoxWidth={50}                                            
                                        />
                                      </DivBlock>
                                      <DivBlock style={{width:'30%',marginLeft:'10px'}}>
                                          <DivBlock className="img-preview" style={{ width: "100%", height: "300px" }}/>
                                      </DivBlock>
                                   
                                    </DivBlock>
                                    <DivBlock style={{marginLeft:'10px',}}>
                                      <Span className={"invalid-feedback"}>
                                       Note : Zoom in or zoom out for cropping the image. 
                                      </Span> 
                                    </DivBlock>
                                    <DivBlock
                                      style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginRight:'20px'
                                    }}>
                                    <button
                                      className={"btn btn-reset DeleteBtn"}
                                      onClick={()=>this.setState({modal:false,uploadImage:''})}
                                    >
                                      Cancel
                                    </button>

                                    <button
                                      className={"btn btn-primary"}
                                      onClick={getCropData}
                                    >
                                      Crop
                                    </button>
                                  </DivBlock>
                                  </DivBlock>
                                </DivBlock>
                              </DivBlock>
                            </DivBlock>
                          </Then>
                      </If>
                    </DivBlock>
                  </DivBlock>
                </DivBlock>
              </DivBlock>
            </DivBlock>
          </DivBlock>
        </Body>
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return state.reducer;
};

export default connect(mapStateToProps, null)(EditPlayerPage);
